<form class="attachment-picker" (ngSubmit)="save()" *ngIf="hasLoaded">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="kstandards.attachmentPicker.title">Attachment picker</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>
    <div class="modal-body">

        <div class="form-row filters">
            <div class="form-group col-md-12">
                <label for="attachment-picker-search" jhiTranslate="kstandards.attachmentPicker.search">Search
                    string</label>
                <input id="attachment-picker-search" type="text" class="form-control" name="search" [formControl]="search">
            </div>
        </div>
        <div class="row">
            <ng-container *ngIf="attachments.length > 0; else noAttachments">
                <div class="col-md-12 attachment" [class.selected]="selectedAttachment?.id === attachment.id" (click)="selectAttachment(attachment)" *ngFor="let attachment of attachments; let i = index;">
                    <h3>{{ attachment.name }}</h3>
                    <div>
                        <ul class="badge-list">
                            <li *ngFor="let buildingType of attachment.buildingTypes">
                                <custom-badge [str]="buildingType.alias ? buildingType.alias : buildingType.name">
                                </custom-badge>
                            </li>
                            <li *ngFor="let country of attachment.countries" class="flag-icon flag-icon-{{ FlagUtil.getFlag(country.code) }}" [title]="country.name">
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-container>

            <ng-template #noAttachments>
                <div class="col-md-12">
                    <p jhiTranslate="kstandards.attachmentPicker.noAttachments">No attachments found.</p>
                </div>
            </ng-template>
        </div>
        <div class="row" *ngIf="totalCount > itemsPerPage">
            <div class="col-md-12 d-flex justify-content-center">
                <ngb-pagination [collectionSize]="totalCount" [pageSize]="itemsPerPage" [maxSize]="10" (pageChange)="onPageChange($event)" [(page)]="page" aria-label="Pagination"></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
            <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
        </button>
        <button type="submit" class="btn btn-primary">
            <span jhiTranslate="entity.action.save">Save</span>
        </button>
    </div>
</form>