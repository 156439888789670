import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { TranslationBatch } from './translation-batch.model'
import { TranslationJob } from '../translation-job/translation-job.model'
import { TranslationBatchView } from './translation-batch-view.model'
import { TranslationJobView } from '../translation-job'
import { ContentView } from '../content/content-view.model'

@Injectable()
export class TranslationBatchService {

    private readonly resourceUrl = SERVER_API_URL + 'api/translation-batches'

    constructor(private readonly httpClient: HttpClient) {
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req)
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    getZip(id: number): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${this.resourceUrl}/${id}/zip`, {observe: 'response', responseType: 'blob'})
    }

    importZip(file: File): Observable<TranslationJob[]> {
        return this.httpClient.post<TranslationJob[]>(this.resourceUrl + '/import', file)
    }

    find(id: number): Observable<TranslationBatchView> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(
            map((res) => this.convertTranslationBatchViewItem(res)))
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertTranslationItem(res.body[i]))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to TranslationJobView.
     */
    private convertTranslationBatchViewItem(json: any): TranslationBatchView {
        const translationBatchView: TranslationBatchView = Object.assign(new TranslationBatchView(), json)

        if (translationBatchView.createdAt) {
            translationBatchView.createdAt = DateUtil.parse(translationBatchView.createdAt)
        }

        if (translationBatchView.updatedAt) {
            translationBatchView.updatedAt = DateUtil.parse(translationBatchView.updatedAt)
        }

        if (translationBatchView.deletedAt) {
            translationBatchView.deletedAt = DateUtil.parse(translationBatchView.deletedAt)
        }

        if (translationBatchView.translationJobs) {
            translationBatchView.translationJobs = translationBatchView.translationJobs.map((translationJob) => {
                const translationJobView: TranslationJobView = Object.assign(new TranslationJobView(), translationJob)
                translationJobView.contentView = Object.assign(new ContentView(), translationJob.contentView)
                if (translationJobView.createdAt) {
                    translationJobView.createdAt = DateUtil.parse(translationJobView.createdAt)
                }

                if (translationBatchView.updatedAt) {
                    translationJobView.updatedAt = DateUtil.parse(translationJobView.updatedAt)
                }

                if (translationJobView.deletedAt) {
                    translationJobView.deletedAt = DateUtil.parse(translationJobView.deletedAt)
                }
                return translationJobView
            })
        }
        return translationBatchView
    }

    /**
     * Convert a returned JSON object to TranslationJob.
     */
    private convertTranslationItem(json: any): TranslationBatch {
        const entity: TranslationBatch = Object.assign(new TranslationBatch(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }
}
