import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { OAuth2Service } from '../../auth/oauth2.service'

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {

    constructor(private readonly injector: Injector) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    // If the token is expired (401 returned from the API) go to the login portal
                    const oauth2Service: OAuth2Service = this.injector.get(OAuth2Service)

                    const router: Router = this.injector.get(Router)
                    const url = router ? router.url : undefined

                    oauth2Service.login(url)
                }

                return observableThrowError(err)
            }))
    }
}
