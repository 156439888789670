import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { DatePipe } from '@angular/common'
import { NgxDnDModule } from '@swimlane/ngx-dnd'
import { RouterModule } from '@angular/router'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight'

import {
    ConsumerViewConfig,
    CustomBadgeComponent,
    EntityArrangementComponent,
    ExpandableListComponent,
    HumanReadableFilesizePipe,
    JhipsterSharedCommonModule,
    JhipsterSharedLibsModule,
    LanguageChoiceService,
    MultiSearchComponent,
    OAuth2Config,
    EmbeddedImageComponent,
    PdfPreviewComponent,
    NgSelectGroupSelectableComponent,
    PendingChangesGuard,
    PreviewPipe,
    SearchComponent,
    TagHolderComponent,
    TagInputComponent,
    TruncatePipe,
    LoadingComponent,
    PreviewMaterialCategoryIconPipe
} from './'

@NgModule({
    imports: [
        JhipsterSharedLibsModule,
        JhipsterSharedCommonModule,
        NgxDnDModule,
        NgSelectModule,
        NgOptionHighlightModule,
        RouterModule
    ],
    declarations: [
        PreviewPipe,
        HumanReadableFilesizePipe,
        EntityArrangementComponent,
        MultiSearchComponent,
        SearchComponent,
        CustomBadgeComponent,
        TagHolderComponent,
        TagInputComponent,
        ExpandableListComponent,
        EmbeddedImageComponent,
        PdfPreviewComponent,
        NgSelectGroupSelectableComponent,
        TruncatePipe,
        LoadingComponent,
        PreviewMaterialCategoryIconPipe
    ],
    providers: [
        DatePipe,
        PendingChangesGuard,
        OAuth2Config,
        LanguageChoiceService,
        ConsumerViewConfig,
    ],
    exports: [
        JhipsterSharedCommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        SearchComponent,
        MultiSearchComponent,
        DatePipe,
        PreviewPipe,
        HumanReadableFilesizePipe,
        NgxDnDModule,
        EntityArrangementComponent,
        EmbeddedImageComponent,
        PdfPreviewComponent,
        NgSelectGroupSelectableComponent,
        CustomBadgeComponent,
        TagHolderComponent,
        ExpandableListComponent,
        TruncatePipe,
        LoadingComponent,
        PreviewMaterialCategoryIconPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class JhipsterSharedModule {
}
