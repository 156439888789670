import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { concat, Observable, of, ReplaySubject, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators'
import { ResponseWrapper } from '@app/data-access'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { EntityService } from '@app/data-access'

@Component({
    selector: 'multi-search',
    templateUrl: './multi-search.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSearchComponent),
            multi: true
        }
    ]
})
export class MultiSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    model: any

    public modelChange$ = new ReplaySubject<never>(1)

    @Input()
    service: EntityService<any>

    @Input()
    entityName: string

    @Input()
    searchFields: string[]

    @Input()
    readonly: boolean

    @Output()
    onAdded = new EventEmitter<any>()

    items$: Observable<any>
    itemsLoading = false
    itemsInput$ = new Subject<string>()

    public propagateChange = (_: any) => { }
    public onTouched = () => { }

    constructor() {

    }

    onChange(): void {
        this.propagateChange(this.model)
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    ngOnInit(): void {
        this.items$ = concat(
            of([]), // default items
            this.itemsInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.itemsLoading = true),
                switchMap((term) => this.service.searchAutocomplete(term).pipe(
                    tap(() => this.itemsLoading = false),
                    map((res: ResponseWrapper) => {
                        return res.json
                    })
                ))
            )
        )
    }

    trackByFn(item) {
        return item.id
    }

    compareById(selected: any, item: any) {
        return selected.id === item.id
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        this.model = obj
        this.modelChange$.next()
    }

}
