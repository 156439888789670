import { TranslationJobStatus } from './translation-job-status'
import { Language } from '../language/language.model'
import { ContentView } from '../content/content-view.model'

export class TranslationJobView {

    constructor(
        public id?: number,
        public sourceText?: string,
        public contentView?: ContentView,
        public status?: TranslationJobStatus,
        public sourceLanguage?: Language,
        public targetLanguage?: Language,
        public xliff?: string,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public wordCount?: number
    ) {

    }

}
