<form name="deleteForm" (ngSubmit)="confirmDelete()">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="entity.delete.title">Confirm delete operation</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>
    <div class="modal-body">

        <p jhiTranslate="kstandards.release.delete.questionDeleteUnpublishedReleases">Are you sure you want to delete this Release?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()" [disabled]="disabled">
            <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
        </button>
        <button type="submit" class="btn btn-danger" [disabled]="disabled">
            <fa-icon icon="times"></fa-icon>&nbsp;<span jhiTranslate="entity.action.delete">Delete</span>
        </button>
    </div>
</form>
