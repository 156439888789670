import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiEventManager } from 'ng-jhipster'
import { ReportService } from './report.service'
import { Report } from './report.model'
import { ReportPopupService } from './report-popup.service'

@Component({
    selector: 'jhi-report-delete-all-dialog',
    templateUrl: './report-delete-all-dialog.component.html'
})
export class ReportDeleteAllDialogComponent {

    report: Report

    constructor(private readonly reportService: ReportService,
                public activeModal: NgbActiveModal,
                private readonly eventManager: JhiEventManager) {
    }

    clear() {
        this.activeModal.dismiss('cancel')
    }

    confirmDelete(report: Report) {
        this.reportService.deleteAllReportsByType({reportType: report.reportType,
        currentReportId: (report.id) ? report.id : -1}).subscribe((response) => {
                this.eventManager.broadcast({
                    name: 'reportsDeleteByType',
                    content: 'All reports by type ' + report.reportType + ' are deleted'
                })
                this.activeModal.dismiss(true)
            }, (response) => {
                this.activeModal.dismiss(true)
            }
        )
    }
}

@Component({
    selector: 'jhi-report-delete-all-popup',
    template: ''
})
export class ReportDeleteAllPopupComponent implements OnInit, OnDestroy {

    routeSub: any

    constructor(private readonly route: ActivatedRoute,
                private readonly reportPopupService: ReportPopupService) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.reportPopupService
                .open(ReportDeleteAllDialogComponent as Component, params)
        })
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe()
    }
}
