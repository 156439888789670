import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { NgJhipsterModule } from 'ng-jhipster'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core'

@NgModule({
    imports: [
        NgbModule,
        NgJhipsterModule.forRoot({
            // set below to true to make alerts look like toast
            alertAsToast: true,
            i18nEnabled: true,
            alertTimeout: 0,
            // Preferred language when the app is open is set up in JhiMainComponent
            defaultI18nLang: 'de'
        }),
        InfiniteScrollModule,
        TranslateModule,
        FontAwesomeModule,
        NgxPageScrollCoreModule.forRoot()
    ],
    exports: [
        FormsModule,
        CommonModule,
        NgbModule,
        NgJhipsterModule,
        InfiniteScrollModule,
        TranslateModule,
        FontAwesomeModule
    ]
})
export class JhipsterSharedLibsModule {
}
