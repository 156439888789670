import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiEventManager } from 'ng-jhipster'
import { ReleasePopupService } from './release-popup.service'
import { Release, ReleaseService } from '@app/data-access'

@Component({
    selector: 'jhi-release-delete-dialog',
    templateUrl: './release-delete-dialog.component.html'
})
export class ReleaseDeleteDialogComponent {

    release: Release

    constructor(private readonly releaseService: ReleaseService,
                public activeModal: NgbActiveModal,
                private readonly eventManager: JhiEventManager,
                private readonly router: Router) {
    }

    clear() {
        this.activeModal.dismiss('cancel')
    }

    confirmDelete(id: number) {
        this.releaseService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'releaseDeleted',
                content: 'Delete a Release'
            })
            this.activeModal.dismiss(true)
            setTimeout(() => {
                this.router.navigate([`release-context/${this.release.releaseContext.id}/edit`])
            }, 0)
        }, (response) => {
                this.activeModal.dismiss(true)
            }
        )
    }
}

@Component({
    selector: 'jhi-release-delete-popup',
    template: ''
})
export class ReleaseDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any

    constructor(private readonly route: ActivatedRoute,
                private readonly releasePopupService: ReleasePopupService) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.releasePopupService
                .open(ReleaseDeleteDialogComponent as Component, params['releaseId'])
        })
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe()
    }
}
