import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '../../app.constants'

import { createHttpParams, ResponseWrapper } from '../common'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'
import { FaqEntry } from './faq-entry.model'


@Injectable()
export class FaqEntryService implements EntityService<FaqEntry> {

    private readonly resourceUrl = SERVER_API_URL + 'api/faq-entry'

    private readonly elasticSearchUrl = SERVER_API_URL + 'api/_search/faq-entry'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(faqEntry: FaqEntry): Observable<FaqEntry> {
        const copy = this.convert(faqEntry)
        return this.httpClient.post<FaqEntry>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(faqEntry: FaqEntry): Observable<FaqEntry> {
        const copy = this.convert(faqEntry)
        return this.httpClient.put<FaqEntry>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<FaqEntry> {
        return this.httpClient.get<FaqEntry>(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    findByCategoryIds(ids: number[]): Observable<FaqEntry[]> {
        const params = new HttpParams()
            .set('categoryIds', ids.join(','))
        return this.httpClient.get<FaqEntry[]>(`${SERVER_API_URL}api/faq-category/entries`, {params})
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get<FaqEntry[]>(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<FaqEntry[]>(this.elasticSearchUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to faqEntry.
     */
    private convertItemFromServer(json: any): FaqEntry {
        const entity: FaqEntry = Object.assign(new FaqEntry(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }

    /**
     * Convert a faqEntry to a JSON which can be sent to the server.
     */
    private convert(faqEntry: FaqEntry): FaqEntry {
        const copy: FaqEntry = Object.assign({}, faqEntry)
        return copy
    }
}
