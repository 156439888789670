export enum StepStatus {
    RUNNING,
    FINISHED,
    FAILED
}

export class ProjectCopyProgressReport {

    constructor(public messageKey: string, public status: StepStatus, public detailedError?: string) { }

}
