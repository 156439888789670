import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { Content } from '../content'
import { DateUtil } from '../../util/date.util'
import { ProductGroup } from './product-group.model'
import { Product } from '../product/product.model'
import { EntityService } from '../entity'

@Injectable()
export class ProductGroupService implements EntityService<ProductGroup> {

    private readonly resourceUrl = SERVER_API_URL + 'api/product-groups'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/product-groups'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(productGroup: ProductGroup): Observable<ProductGroup> {
        const copy = this.convert(productGroup)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(productGroup: ProductGroup): Observable<ProductGroup> {
        const copy = this.convert(productGroup)
        return this.httpClient.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<ProductGroup> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    queryProducts(id: number): Observable<Product[]> {
        return this.httpClient.get<Product[]>(`${this.resourceUrl}/${id}/products`).pipe(
            map((rms) => this.convertProducts(rms)))
    }

    convertProducts(products: Product []): Product [] {
        return products.map((product) => {
            return Object.assign(new Product(), products)
        })
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to ProductGroup.
     */
    private convertItemFromServer(json: any): ProductGroup {
        const entity: ProductGroup = Object.assign(new ProductGroup(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        entity.content = Object.assign(new Content(), entity.content)
        return entity
    }

    /**
     * Convert a RoomGroup to a JSON which can be sent to the server.
     */
    private convert(productGroup: ProductGroup): ProductGroup {
        const copy: ProductGroup = Object.assign({}, productGroup)
        return copy
    }
}
