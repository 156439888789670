import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Audit } from './audit.model'
import { Observable } from 'rxjs'

@Injectable()
export class AuditsService {
    constructor(private readonly httpClient: HttpClient) {
    }

    query(req: any): Observable<any> {
        const params: HttpParams =
            new HttpParams()
                .set('fromDate', req.fromDate)
                .set('toDate', req.toDate)
                .set('page', req.page)
                .set('size', req.size)
                .set('sort', req.sort)

        return this.httpClient.get<Audit>('management/audits', {params, observe: 'response'})
    }
}
