<div *ngIf="items?.length > 0">
    <span>{{title | translate}}</span>:
    <ul class="badge-list">
        <ng-container *ngFor="let item of items; let i = index; trackBy: trackById">
            <li *ngIf="i < limit && hasEditPermissions" class="badge badge-info" [title]="item.label"><a [routerLink]="[editLinkPrefix, item.id, 'edit']">{{item.label | truncate : 60 : true}}</a></li>
            <li *ngIf="i < limit && !hasEditPermissions" class="badge badge-info" [title]="item.label">{{item.label | truncate : 60 : true}}</li>
        </ng-container>
    </ul>
    <button type="button" *ngIf="limit < items?.length || isExpanded" (click)="collapse()" class="btn btn-link expandable-list-collapse-link" [jhiTranslate]="isExpanded ? 'global.expandable-list.hide':'global.expandable-list.more'"></button>
</div>
