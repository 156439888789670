import { BaseEntity } from '../common'
import { ConstructionType } from '../common'
import { ContentType } from './content.model'
import { ContentRenderScope } from '../common/content-render-scope.model'
import { BuildingType } from '../building-type'

export class ContentView implements BaseEntity {
    constructor(public id?: number,
                public textContent?: string,
                public fileName?: string,
                public contentType?: ContentType,
                public constructionType?: ConstructionType,
                public buildingTypes?: BuildingType[],
                public renderScope?: ContentRenderScope,
                public materialId?: number,
                public materialName?: string,
                public materialSubGroupId?: number,
                public materialGroupName?: string,
                public materialGroupId?: number,
                public materialSubGroupName?: string,
                public craftId?: number,
                public craftName?: string,
                public projectId?: number,
                public projectName?: string,
                public attachmentId?: number,
                public attachmentName?: string,
                public roomId?: number,
                public roomName?: string) {
    }

    public getParent(): string {
        if (this.materialId) {
            return 'material'
        }

        if (this.materialGroupId) {
            return 'material-group'
        }

        if (this.materialSubGroupId) {
            return 'material-sub-group'
        }

        if (this.craftId) {
            return 'craft'
        }

        if (this.projectId) {
            return 'project'
        }

        if (this.attachmentId) {
            return 'attachment'
        }

        if (this.roomId) {
            return 'room'
        }

        return null
    }

    public getParentId(): number {
        if (this.materialId) {
            return this.materialId
        }

        if (this.materialGroupId) {
            return this.materialGroupId
        }

        if (this.materialSubGroupId) {
            return this.materialSubGroupId
        }

        if (this.craftId) {
            return this.craftId
        }

        if (this.projectId) {
            return this.projectId
        }

        if (this.attachmentId) {
            return this.attachmentId
        }

        if (this.roomId) {
            return this.roomId
        }

        return null
    }
}
