import { BaseEntity, BookAccessType, BookType } from '../common'
import { Linkable } from '../common/linkable'
import { BuildingTypeCategory } from '../building-type-category'
import { Content } from '../content/content.model'
import { ConstructionTypeMatching } from '@app/data-access/common/construction-type-matching.model'

export class BuildingType implements BaseEntity, Linkable {
    constructor(
        public id?: number,
        public name?: string,
        public alias?: string,
        public order?: number,
        public description?: string,
        public content?: Content,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public bookType?: BookType,
        public bookAccessType?: BookAccessType,
        public buildingTypeCategory?: BuildingTypeCategory,
        public relevantFor?: BuildingType[],
        public constructionTypeMatching?: ConstructionTypeMatching
    ) {
        if (!this.content) {
            this.content = new Content()
        }
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/building-type/${this.id}/edit`
    }
}
