<form name="report-form" role="form" novalidate #newReportForm="ngForm" class="form-horizontal" *ngIf="hasLoaded">



    <h2>
        <span jhiTranslate="kstandards.report.home.title">Report</span>
        <span jhiTranslate="{{'kstandards.report.reportTypes.' + report.reportType}}">Reports</span>
    </h2>
    <div class="row" *ngIf="report">
        <div class="col-md-10">
            <h2>
                <span jhiTranslate="kstandards.report.home.editLabel" *ngIf="!isNew" [translateValues]="{id:  report.id }">Edit
                    report {{ report.id }}</span>
                <span jhiTranslate="kstandards.report.home.createLabel" *ngIf="isNew">Create a report</span>
            </h2>
            <div class="form-group">
                <label class="form-control-label" jhiTranslate="kstandards.report.name" for="field_name">Report
                    Name</label>
                <input type="text" class="form-control" name="name" id="field_name" [(ngModel)]="report.name" required>
            </div>

            <div class="form-group">
                <label class="form-control-label" jhiTranslate="kstandards.report.buildingType" for="field_building_type">Building Type</label>
                <select class="custom-select" id="field_building_type" name="building_type" [(ngModel)]="report.buildingType" required [disabled]="!isNew">
                    <option [ngValue]="null"></option>
                    <option [ngValue]="buildingTypeOption.id === report.buildingType?.id ? report.buildingType : buildingTypeOption" *ngFor="let buildingTypeOption of buildingTypes; trackBy: trackId">
                        {{buildingTypeOption.name }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label class="form-control-label" jhiTranslate="kstandards.material.constructionType" for="field_type">Construction Type</label>
                <select class="form-control" name="type" [(ngModel)]="report.constructionType" id="field_type" required [disabled]="!isNew">
                    <option value="CONSTRUCTION_MODERNIZATION">{{'kstandards.ConstructionType.CONSTRUCTION_MODERNIZATION' | translate}}</option>
                    <option value="CONSTRUCTION">{{'kstandards.ConstructionType.CONSTRUCTION' | translate}}</option>
                    <option value="MODERNIZATION">{{'kstandards.ConstructionType.MODERNIZATION' | translate}}</option>
                </select>
            </div>

            <div class="half-width" *ngIf="!isNew">
                <h3 jhiTranslate="kstandards.report.detail.title" id="detail-title">Report</h3>
                <table class="table table-striped" aria-describedby="detail-title">
                    <thead>
                    <tr>
                        <th jhiTranslate="kstandards.report.status" scope="col">Status</th>
                        <th jhiTranslate="kstandards.report.download" scope="col">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td jhiTranslate="{{'kstandards.report.reportStatuses.' + report.reportStatus}}"></td>
                        <td>
                            <button type="button" class="btn btn-secondary" (click)="downloadReport(report.id)" *ngIf="report.reportStatus === 'FINISHED'" jhiTranslate="kstandards.report.download">Download Report
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-md-2 global-buttons">
            <div class="form-group" *ngIf="isNew">
                <button type="button" (click)="save()" [disabled]="newReportForm.form.invalid" class="btn btn-special">
                    <icon shape="save" cssClasses="icon-white"></icon>&nbsp;<span jhiTranslate="kstandards.report.actions.create">Create report</span>
                </button>
            </div>
            <div class="form-group" *ngIf="!isNew && !report.deletedAt">
                <button type="button" (click)="save()" [disabled]="newReportForm.form.invalid || isSaving || report.reportStatus !== 'FINISHED'" class="btn btn-special">
                    <icon shape="save" cssClasses="icon-white"></icon>&nbsp;<span jhiTranslate="kstandards.report.actions.update">Save report</span>
                </button>
            </div>
            <div class="form-group" *ngIf="!isNew && !report.deletedAt">
                <button type="submit" [routerLink]="['/', { outlets: { popup: 'report/'+ report.id + '/delete'} }]" replaceUrl="true" class="btn btn-danger" [disabled]="newReportForm.form.invalid || isSaving || (report.reportStatus !== 'FAILED' && report.reportStatus !== 'FINISHED' && report.reportStatus !== 'NO_DATA')">
                    <fa-icon icon="times"></fa-icon>
                    <span class="d-none d-md-inline" jhiTranslate="kstandards.report.actions.delete">Delete Report</span>
                </button>
            </div>
            <a class="btn btn-secondary" data-dismiss="modal" [routerLink]="['/report-new']">
                <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <reports-by-type-list [report]="this.report" (downloadReport)="downloadReport($event)"></reports-by-type-list>
        </div>
    </div>
</form>
