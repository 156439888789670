import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
        if (value.length < limit) {
            return `${value.substr(0, limit)}`
        }

        if (completeWords) {
            const desiredLimit = limit
            limit = value.substr(0, limit).lastIndexOf(' ')
            if (limit < 0) {
                limit = desiredLimit
            }
        }
        return `${value.substr(0, limit)}${ellipsis}`
    }
}
