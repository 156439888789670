import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiEventManager } from 'ng-jhipster'

@Component({
    selector: 'jhi-elasticsearch-release-modal',
    templateUrl: './release-modal.component.html'
})
export class ReleaseModalComponent {

    constructor(public activeModal: NgbActiveModal,
                private readonly eventManager: JhiEventManager) {
    }

    clear() {
        this.activeModal.dismiss('clear')
    }

    confirmSave() {
        this.eventManager.broadcast({
            name: 'statusChangedToPublish',
            content: 'The status is changed to published.'
        })

        this.activeModal.dismiss(true)
    }
}
