import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '@app/app.constants'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Changelog } from './changelog.model'
import { Arrangeable, createHttpParams, ResponseWrapper } from '../common'
import { map } from 'rxjs/operators'
import { DateUtil } from '@app/util/date.util'
import { Change } from './change.model'
import { Content } from '../content'

@Injectable()
export class ChangelogService {

    private readonly resourceUrl = SERVER_API_URL + 'api/changelog'

    private readonly engineSearchUrl = SERVER_API_URL + 'api/_search/changelog'

    constructor(private readonly httpClient: HttpClient) {
    }

    query(showClosed: boolean, showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString()).set('showClosed', showClosed.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.engineSearchUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    create(changelog: Changelog): Observable<Changelog> {
        return this.httpClient.post(this.resourceUrl, changelog)
    }

    update(changelog: Changelog): Observable<Changelog> {
        return this.httpClient.put(this.resourceUrl, changelog)
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    findAll(showClosed: boolean): Observable<Changelog[]> {
        const params = new HttpParams().set('showClosed', String(showClosed))
        return this.httpClient.get<Changelog[]>(this.resourceUrl, {params})
            .pipe(map((res) => this.convertResponseArray(res)))
    }

    updateChanges(id: number, changes: Arrangeable[]): Observable<Change[]> {
        return this.httpClient.put<Change[]>(`${this.resourceUrl}/${id}/arrange-changes`, changes).pipe(
            map((change) => this.convertChanges(change)))
    }

    queryChanges(id: number): Observable<Change[]> {
        return this.httpClient.get<Change[]>(`${this.resourceUrl}/${id}/arrange-changes`).pipe(
            map((change) => this.convertChanges(change)))
    }

    convertChanges(changes: Change []): Change [] {
        return changes.map((change) => {
            return Object.assign(new Change(), change)
        }).map((change) => {
            change.shortDescription = Object.assign(new Content(), change.shortDescription)
            change.changelog = Object.assign(new Changelog(), change.changelog)
            return change
        })
    }

    findAllByBuildingTypeIds(buildingTypeIds: number[], showClosed: boolean, req?: any): Observable<Changelog[]> {
        const params = createHttpParams(req).set('showClosed', showClosed.toString())
        return this.httpClient.get<Changelog[]>(`${this.resourceUrl}/building-type?buildingTypeIds=${buildingTypeIds.join(',')}`, {params})
            .pipe(map((res) => this.convertResponseArray(res)))
    }

    findById(changelogId: number): Observable<Changelog> {
        return this.httpClient.get<Changelog>(`${this.resourceUrl}/${changelogId}`)
    }

    translateChanges(changelogId: number, languageIds: number[]): Observable<any> {
        return this.httpClient.post(`${this.resourceUrl}/${changelogId}/translate`, languageIds)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    private convertResponseArray(res: any): Changelog[] {
        const result = []
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]))
        }
        return result
    }

    /**
     * Convert a returned JSON object to Language.
     */
    private convertItemFromServer(json: any): Changelog {
        const entity: Changelog = Object.assign(new Changelog(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }
}
