import { BaseEntity } from '../common'
import { Country } from '../country'
import { Tag } from '../tag'
import { FileBlob } from './file-blob.model'

export class File implements BaseEntity {
    constructor(public id?: number,
                public name?: string,
                public createCountry?: Country,
                public description?: string,
                public filename?: string,
                public size?: number,
                public mainFile?: FileBlob,
                public previewFile?: FileBlob,
                public url?: string,
                public tags?: Tag[],
                public version?: number,
                public landscape?: boolean,
                public pageOrientations?: string,
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string) {
        if (!this.tags) {
            this.tags = []
        }

        if (this.createCountry?.code) {
            this.createCountry.code = this.createCountry.code.toLowerCase()
        }
    }
}
