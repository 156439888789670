import { Component, Input, OnInit } from '@angular/core'
import { Content, OriginalTranslationDiff, Translation, TranslationService } from '@app/data-access'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'

@Component({
    selector: 'content-diff',
    templateUrl: 'content-diff.component.html',
    styleUrls: ['content-diff.component.scss']
})
export class ContentDiffComponent implements OnInit {

    public defaultLocale: string = DefaultLocaleSingleton.instance.defaultLocale

    @Input()
    public content: Content

    public translation: Translation

    public originalTranslationDiffs: OriginalTranslationDiff[]

    public selectedTranslationDiff: OriginalTranslationDiff

    constructor(private readonly translationService: TranslationService) {
    }

    ngOnInit() {
        this.translation = this.content.translations.find(
            (translation: Translation) => translation.language.locale === DefaultLocaleSingleton.instance.defaultLocale
        )
        this.getOriginalTranslationsDiffs()
    }

    public getOriginalTranslationsDiffs() {
        this.selectedTranslationDiff = null
        this.translationService.getOriginalTranslationDiffs(this.translation.id).subscribe(
            (originalTranslationDiffs) => {
                this.originalTranslationDiffs = originalTranslationDiffs
                this.selectedTranslationDiff = this.originalTranslationDiffs[0]
            }
        )
    }
}
