import { BaseEntity } from '../common'
import { Content } from '../content/content.model'
import { Linkable } from '../common/linkable'
import { Arrangeable } from '../common/arrangeable'

export class RoomBookColumn implements BaseEntity, Arrangeable, Linkable {
    constructor(public id?: number,
                public name?: string,
                public order?: number,
                public content?: Content,
                public version?: number,
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string) {
        if (!this.content) {
            this.content = new Content()
        }
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/room-book-column/${this.id}/edit`

    }

    getLabelForArrangeableList(): string {
        return this.name
    }
}
