
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '../../app.constants'

import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'
import { ChangeTag } from '../changelog/change-tag.model'

@Injectable()
export class ChangeTagService implements EntityService<ChangeTag> {

    private readonly resourceUrl = SERVER_API_URL + 'api/change-tags'

    private readonly engineSearchUrl = SERVER_API_URL + 'api/_search/change-tags'

    private readonly engineSerachSuggestUrl = SERVER_API_URL + 'api/_suggest/change-tags'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(tag: ChangeTag): Observable<ChangeTag> {
        const copy = this.convert(tag)
        return this.httpClient.post<ChangeTag>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(tag: ChangeTag): Observable<ChangeTag> {
        const copy = this.convert(tag)
        return this.httpClient.put<ChangeTag>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<ChangeTag>  {
        return this.httpClient.get<ChangeTag>(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get<ChangeTag[]>(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<ChangeTag[]>(this.engineSearchUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    suggest(query: string): Observable<ResponseWrapper> {
        const params = {query}
        return this.httpClient.get<ChangeTag[]>(this.engineSerachSuggestUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    findByNames(names: string[]): Observable<ChangeTag[]>  {
        return this.httpClient.post<ChangeTag[]>(`${this.resourceUrl}/search-by-name`, names)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Tag.
     */
    private convertItemFromServer(json: any): ChangeTag {
        const entity: ChangeTag = Object.assign(new ChangeTag(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }

    /**
     * Convert a Tag to a JSON which can be sent to the server.
     */
    private convert(tag: ChangeTag): ChangeTag {
        const copy: ChangeTag = Object.assign({}, tag)
        return copy
    }
}
