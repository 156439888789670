import { Routes } from '@angular/router'

import { Roles, UserRouteAccessService } from '@app/auth'

import { ChangeComponent } from './change.component'
import { ChangeDialogComponent } from './change-dialog.component'
import { ChangeDeletePopupComponent } from './change-delete-dialog.component'

const changelogPageTitle = 'kstandards.change.home.title'

export const changeRoute: Routes = [
    {
        path: 'changelog/:changelogId/change',
        component: ChangeComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: changelogPageTitle
        },
        canActivate: [UserRouteAccessService]
    }
]

export const changePopupRoute: Routes = [
    {
        path: 'changelog/:changelogId/change-new',
        component: ChangeDialogComponent,
        data: {
            authorities: Roles.VIEWER,
            pageTitle: changelogPageTitle
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'changelog/:changelogId/change/:id/edit',
        component: ChangeDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: changelogPageTitle
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'change/:id/delete',
        component: ChangeDeletePopupComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: changelogPageTitle
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
]
