import { Component, forwardRef, Input } from '@angular/core'
import { SingleContentEditComponent } from './single-content-edit.component'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'

@Component({
    selector: 'single-content-edit-area',
    templateUrl: 'single-content-edit-area.component.html',
    styleUrls: ['single-content-edit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleContentEditAreaComponent),
            multi: true
        }
    ]
})
export class SingleContentEditAreaComponent extends SingleContentEditComponent {

    MATERIAL_CATEGORY_ICON = '<[MATERIAL_CATEGORY_ICON]>'

    @Input()
    public displayAll = true

    public isShowingAll = false

    switchMode() {
        this.isShowingAll = !this.isShowingAll
    }

    public constructor(
        readonly modalService: NgbModal
    ) {
        super(modalService)
    }

    insertMaterialCategory(id){
        const textAreaElement = document.getElementById(id) as HTMLTextAreaElement
        const currentValue = textAreaElement.value
        const start = textAreaElement.selectionStart
        const end = textAreaElement.selectionEnd
        textAreaElement.value = currentValue.substring(0, start) + this.MATERIAL_CATEGORY_ICON + currentValue.substring(end)
        textAreaElement.dispatchEvent(new Event('input'))
        return false
    }

    getTranslationsForLanguages() {
        if (this.displayAll) {
            return this.content.translations.filter(
                translation => this.languageIds.has(translation.language.id)
            )
        }
        if (this.isShowingAll) {
            return this.content.translations.filter(
                translation => this.languageIds.has(translation.language.id)
            )
        } else {
            return this.content.translations.filter(
                translation =>
                    this.languageIds.has(translation.language.id) &&
                    translation.language.locale === DefaultLocaleSingleton.instance.defaultLocale
            )
        }
    }
}
