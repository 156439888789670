<form name="reportsByTypeForm" (ngSubmit)="loadAll()">
    <div *ngIf="reports && reports.length > 0">
        <h2>
            <span jhiTranslate="kstandards.report.home.previousReports" id="previous-reports">Report</span>

            <button class="btn btn-danger float-right" type="submit" [routerLink]="['/', { outlets: { popup: 'report/' + ((report.id) ? report.id : -1) + '/reportType/'+ report.reportType + '/delete'} }]" replaceUrl="true">
                <fa-icon icon="times"></fa-icon>
                <span class="d-none d-md-inline" jhiTranslate="kstandards.report.actions.deleteAll">Delete All Reports</span>
            </button>
        </h2>
        <br>
        <div class="row">
        </div>
        <div class="table-hover">
            <table class="table table-striped" aria-describedby="previous-reports">
                <thead>
                <tr jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="reset.bind(this)">
                    <th jhiSortBy="id" scope="col"><span jhiTranslate="global.field.id">ID</span> <fa-icon icon="sort"></fa-icon>
                    </th>
                    <th jhiSortBy="name" scope="col"><span jhiTranslate="kstandards.report.name">Name</span> <fa-icon icon="sort"></fa-icon></th>
                    <th jhiSortBy="createdAt" scope="col"><span jhiTranslate="kstandards.report.createdAt">Created at</span> <fa-icon icon="sort"></fa-icon></th>
                    <th jhiSortBy="updatedAt" scope="col"><span jhiTranslate="kstandards.report.updatedAt">Updated at</span> <fa-icon icon="sort"></fa-icon></th>
                    <th jhiSortBy="reportStatus" scope="col"><span jhiTranslate="kstandards.report.status">Report Status</span> <fa-icon icon="sort"></fa-icon></th>
                    <th scope="col">
                        <span jhiTranslate="kstandards.report.download">Download Report</span>
                    </th>
                    <th scope="col"><span jhiTranslate="kstandards.report.actions.delete">Delete Report</span></th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let report of reports ;trackBy: trackId">
                    <td><a routerLink (click)="redirect(report)" class="hand">{{
                        report.id }}</a></td>
                    <td>{{ report.name }}</td>
                    <td>{{ report.createdAt }}</td>
                    <td>{{ report.updatedAt }}</td>
                    <td><span jhiTranslate="{{'kstandards.report.reportStatuses.' + report.reportStatus}}">Status</span></td>
                    <td>
                        <button type="button" class="btn btn-secondary" (click)="download(report.id)" *ngIf="report.reportStatus === 'FINISHED'" jhiTranslate="kstandards.report.download">Download Report
                        </button>
                    </td>
                    <td>
                        <button type="submit" [routerLink]="['/', { outlets: { popup: 'report/'+ report.id + '/delete'} }]" (click)="loadAll()" [queryParams]="{redirect: false}" replaceUrl="true" class="btn btn-danger" [disabled]="(report.reportStatus !== 'FAILED' && report.reportStatus !== 'FINISHED' && report.reportStatus !== 'NO_DATA')">
                            <fa-icon icon="times"></fa-icon>
                            <span class="d-none d-md-inline" jhiTranslate="kstandards.report.actions.delete">Delete Report</span>
                        </button>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>
