import { NgModule, LOCALE_ID } from '@angular/core'
import { Title } from '@angular/platform-browser'

import {
    JhipsterSharedLibsModule,
    JhiLanguageHelper,
    FindLanguageFromKeyPipe,
    JhiAlertComponent,
    IconComponent,
    EnumKeyValueListPipe
} from './'

@NgModule({
    imports: [
        JhipsterSharedLibsModule,
    ],
    declarations: [
        FindLanguageFromKeyPipe,
        EnumKeyValueListPipe,
        JhiAlertComponent,
        IconComponent,
    ],
    providers: [
        JhiLanguageHelper,
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en'
        }
    ],
    exports: [
        JhipsterSharedLibsModule,
        FindLanguageFromKeyPipe,
        EnumKeyValueListPipe,
        JhiAlertComponent,
        IconComponent,
    ]
})
export class JhipsterSharedCommonModule {}
