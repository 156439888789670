import { Route } from '@angular/router'

import { JhiConfigurationComponent } from './configuration.component'
import { Roles } from '@app/auth'

export const configurationRoute: Route = {
    path: 'jhi-configuration',
    component: JhiConfigurationComponent,
    data: {
        authorities: Roles.SUPERADMIN,
        pageTitle: 'configuration.title'
    }
}
