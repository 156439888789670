<form name="reindexForm" (ngSubmit)="reindex()">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="searchengine.reindex.dialog.title">Confirm reindex operation</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="activeModal.dismiss('closed')">&times;</button>
    </div>
    <div class="modal-body">

        <p jhiTranslate="searchengine.reindex.dialog.question">
            Are you sure you want to reindex Elasticsearch? This could take a while!
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="activeModal.dismiss('closed')">
            <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="searchengine.reindex.dialog.cancel">Cancel</span>
        </button>
        <button type="submit" class="btn btn-danger">
            <fa-icon icon="exclamation"></fa-icon>&nbsp;<span jhiTranslate="searchengine.reindex.dialog.confirm">Reindex</span>
        </button>
    </div>
</form>
