import { HttpParams } from '@angular/common/http'

export const createHttpParams = (req?: any): HttpParams => {

    let params: HttpParams = new HttpParams()
    if (req) {

        if (req.page) {
            params = params.set('page', req.page)
        }

        if (req.size) {
            params = params.set('size', req.size)
        } else {
            // Set default size to 2000 if nothing else is set
            params = params.set('size', '2000')
        }

        if (req.sort) {
            params = params.set('sort', req.sort)
        }
        if (req.query) {
            params = params.set('query', req.query)
        }

        for (const key in req) {
            if (!params.has(key)) {
                params = params.set(key, req[key])
            }
        }
    }

    return params
}
