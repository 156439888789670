
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class JhiConfigurationService {

    constructor(private readonly httpClient: HttpClient) {
    }

    get(): Observable<any> {
        return this.httpClient.get('management/configprops').pipe(map((res: any) => {
            const properties: any[] = []

            const propertiesObject = res.contexts.jhipster.beans

            for (const key in propertiesObject) {
                if (propertiesObject.hasOwnProperty(key)) {
                    properties.push(propertiesObject[key])
                }
            }

            return properties.sort((propertyA, propertyB) => {
                return (propertyA.prefix === propertyB.prefix) ? 0 :
                    (propertyA.prefix < propertyB.prefix) ? -1 : 1
            })
        }))
    }

    getEnv(): Observable<any> {
        return this.httpClient.get('management/env').pipe(map((res: any) => {
            const properties: any = {}
            const propertySources = res.propertySources

            for (const propertyObject of propertySources) {
                const name = propertyObject.name
                const detailProperties = propertyObject.properties
                const vals: any[] = []
                for (const keyDetail in detailProperties) {
                    if (detailProperties.hasOwnProperty(keyDetail)) {
                        vals.push({key: keyDetail, val: detailProperties[keyDetail]['value']})
                    }
                }
                properties[name] = vals
            }
            return properties
        }))
    }
}
