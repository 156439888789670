<div class="modal-header">
    <h2 class="modal-title" jhiTranslate="kstandards.change.copy.action">Copy to another changelog</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
</div>
<div class="modal-body">
<table *ngIf="changelogs?.length > 0" class="table table-striped table-hover" aria-describedby="table-title">
    <thead>
    <tr>
        <th scope="col">
            <span jhiTranslate="global.field.id">ID</span>
        </th>
        <th scope="col">
            <span jhiTranslate="kstandards.changelog.name">Name</span>
        </th>
        <th scope="col">
            <span jhiTranslate="kstandards.changelog.buildingType">Name</span>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr class="table-selectable-element" *ngFor="let changelog of changelogs" (click)="changelogSelected(changelog)">
        <td class="table-selectable-element">{{changelog.id}}</td>
        <td class="table-selectable-element">{{changelog.name}}</td>
        <td class="table-selectable-element">{{changelog.buildingType?.name}}</td>
    </tr>
    </tbody>
</table>
</div>
