<form role="form" #contentForm="ngForm" (change)="onTemporaryChange($event)" novalidate *ngIf="content">
    <div class="row content-header">
        <div class="col-lg-10">
            <h2>
                <span *ngIf="content.id" jhiTranslate="kstandards.content.editModalTitle" [translateValues]="{id:  content.id }">Edit content {{ content.id }}</span>
                <span *ngIf="!content.id" jhiTranslate="kstandards.content.newModalTitle">New content</span>
            </h2>
            <div class="form-group row" *ngIf="editContext">
                <div class="col-lg-2" [jhiTranslate]="editContext.label"></div>
                <div class="col-lg-10 context-name">{{ editContext.name }}</div>
            </div>
            <ng-container *ngIf="mode === EDIT_MODE">
                <div *ngIf="!!content.createCountry" class="form-group row">
                    <label class="col-form-label col-lg-2" jhiTranslate="kstandards.content.createdByTheCountry">Created
                        by the country</label>
                    <div class="col-lg-10">
                        <span style="padding-bottom: 0.25rem;" class="flag-icon flag-icon-{{ FlagUtil.getFlag(content.createCountry.code?.toLowerCase()) }}" [title]="content.createCountry.name">
                        </span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-lg-2" jhiTranslate="kstandards.content.contentType">Content
                        Type</label>
                    <div class="col-lg-10" (change)="onContentTypeChange($event)" [ngClass]="{'radio-area-disabled': !hasEditPermissions}">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label" tooltipClass="help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.contentTypes.TEXT' | translate">
                                <input class="form-check-input" type="radio" name="contentType" (click)="maybeWarnUserAboutContentTypeChange($event, 'TEXT')" [(ngModel)]="content.contentType" value="TEXT" [disabled]="!hasEditPermissions">
                                {{'kstandards.ContentType.TEXT' |
                                translate}}
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label" tooltipClass="help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.contentTypes.FILE' | translate">
                                <input class="form-check-input" type="radio" name="contentType" (click)="maybeWarnUserAboutContentTypeChange($event, 'FILE')" [(ngModel)]="content.contentType" value="FILE" [disabled]="!hasEditPermissions">
                                {{'kstandards.ContentType.FILE' |
                                translate}}
                            </label>
                        </div>
                        <div class="form-check form-check-inline" *ngIf="hasRoombookType">
                            <label class="form-check-label" tooltipClass="help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.contentTypes.ROOMBOOK' | translate">
                                <input class="form-check-input" type="radio" name="contentType" (click)="maybeWarnUserAboutContentTypeChange($event, 'ROOMBOOK')" [(ngModel)]="content.contentType" value="ROOMBOOK" [disabled]="!hasEditPermissions">
                                {{'kstandards.ContentType.ROOMBOOK' | translate}}
                            </label>
                        </div>
                        <div class="form-check form-check-inline" *ngIf="hasProductListType">
                            <label class="form-check-label" tooltipClass="help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.contentTypes.PRODUCT_LIST' | translate">
                                <input class="form-check-input" type="radio" name="contentType" (click)="maybeWarnUserAboutContentTypeChange($event, 'PRODUCT_LIST')" [(ngModel)]="content.contentType" value="PRODUCT_LIST" [disabled]="!hasEditPermissions">
                                {{'kstandards.ContentType.PRODUCT_LIST' | translate}}
                            </label>
                        </div>
                        <div class="form-check form-check-inline" *ngIf="hasChangelogType">
                            <label class="form-check-label" tooltipClass="help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.contentTypes.CHANGELOG' | translate">
                                <input class="form-check-input" type="radio" name="contentType" (click)="maybeWarnUserAboutContentTypeChange($event, 'CHANGELOG')" [(ngModel)]="content.contentType" value="CHANGELOG" [disabled]="!hasEditPermissions">
                                {{'kstandards.ContentType.CHANGELOG' | translate}}
                            </label>
                        </div>
                    </div>
                </div>

                <div *ngIf="hasAdditionalScope && scopeCollapsed" class="row form-group">
                    <div class="col-form-label col-lg-2" jhiTranslate="kstandards.contentManager.scope">Scope</div>
                    <div class="col-lg-10" style="display: flex;">
                        <ul class="badge-list">
                            <li *ngFor="let buildingType of content.buildingTypes">
                                <custom-badge [str]="buildingType.alias ? buildingType.alias : buildingType.name">
                                </custom-badge>
                            </li>
                            <li *ngFor="let country of content.countries" class="flag-icon flag-icon-{{ FlagUtil.getFlag(country.code) }}" [title]="country.name">
                            </li>
                        </ul>
                        <div [ngbTooltip]="(!hasEditPermissions ? 'kstandards.contentManager.help.editScopeNotAllowed'
                            : 'kstandards.contentManager.help.scopeEdit') | translate">
                            <button type="button" class="btn btn-secondary" (click)="editScope()" jhiTranslate="kstandards.contentManager.editScope" [disabled]="!hasEditPermissions">Edit</button>
                        </div>

                    </div>

                </div>

                <div *ngIf="hasAdditionalScope && !scopeCollapsed" class="form-group row">
                    <div class="col-form-label col-lg-2">
                        <label jhiTranslate="kstandards.content.buildingTypes" for="field_buildingTypes">Building
                            Type</label>
                        <fa-icon icon="info-circle" tooltipClass="wide-help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.buildingTypes' | translate"></fa-icon>
                    </div>
                    <div class="col-lg-8">
                        <ng-select [multiple]="true" [items]="this.buildingTypes" name="building_types" id="field_buildingTypes" bindLabel="name" [closeOnSelect]="false" [trackByFn]="trackById" [compareWith]="compareBuildingTypes" [(ngModel)]="content.buildingTypes">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                <span>{{ item.name }}</span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let-clear="clear" let-search="searchTerm">
                                <span [ngOptionHighlight]="search">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-lg-2">
                        <button type="button" class="btn btn-secondary" [disabled]="content.buildingTypes.length >= buildingTypes.length" (click)="selectAllBuildingTypes()" jhiTranslate="kstandards.contentManager.selectAllBuildingTypes">Select all</button>
                    </div>
                </div>

                <div *ngIf="hasAdditionalScope && !scopeCollapsed" class="form-group row">
                    <div class="col-form-label col-lg-2">
                        <label jhiTranslate="kstandards.content.countries" for="field_countries">Countries</label>
                        <fa-icon icon="info-circle" tooltipClass="wide-help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.countries' | translate"></fa-icon>
                    </div>
                    <div class="col-lg-8">
                        <ng-select [multiple]="true" [items]="this.countries" name="countries" id="field_countries" bindLabel="name" [closeOnSelect]="false" [trackByFn]="trackById" [compareWith]="compareCountries" [(ngModel)]="content.countries">

                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                <span>{{ item.name }}</span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item" let-clear="clear" let-search="searchTerm">
                                <span [ngOptionHighlight]="search">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-lg-2">
                        <div [ngbTooltip]="(!hasCreatePermissions ? 'kstandards.contentManager.help.selectAllCountriesNotAllowed' : '')  | translate" openDelay="500">
                            <button type="button" class="btn btn-secondary" [disabled]="content.countries.length >= countries.length || !hasCreatePermissions" (click)="selectAllCountries()" jhiTranslate="kstandards.contentManager.selectAllCountries">Select all</button>
                        </div>
                    </div>
                </div>

                <div class="form-group row" *ngIf="resetWorkflowStatus">
                    <div class="col-form-label col-lg-2" jhiTranslate="kstandards.content.originalWorkflowStatus">
                        Original workflow status</div>
                    <div class="col-lg-10">
                        <span class="badge" [class.badge-success]="originalWorkflowStatus === 'PUBLISHED'" [class.badge-danger]="originalWorkflowStatus === 'DRAFT'" [class.badge-warning]="originalWorkflowStatus === 'REQUIRES_TRANSLATION'" [class.badge-special]="originalWorkflowStatus === 'TRANSLATION_DONE'">{{
                            'kstandards.WorkflowStatus.' + originalWorkflowStatus | translate }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <ng-template #workflowStatusTooltip>
                        <ul>
                            <li><strong jhiTranslate="kstandards.WorkflowStatus.DRAFT"></strong>: <span jhiTranslate="kstandards.contentManager.help.workflowStatuses.DRAFT"></span></li>
                            <li><strong jhiTranslate="kstandards.WorkflowStatus.REQUIRES_TRANSLATION"></strong>: <span jhiTranslate="kstandards.contentManager.help.workflowStatuses.REQUIRES_TRANSLATION"></span>
                            </li>
                            <li><strong jhiTranslate="kstandards.WorkflowStatus.TRANSLATION_DONE"></strong>: <span jhiTranslate="kstandards.contentManager.help.workflowStatuses.TRANSLATION_DONE"></span>
                            </li>
                            <li><strong jhiTranslate="kstandards.WorkflowStatus.PUBLISHED"></strong>: <span jhiTranslate="kstandards.contentManager.help.workflowStatuses.PUBLISHED"></span>
                            </li>
                        </ul>
                    </ng-template>

                    <div class="col-form-label col-lg-2">
                        <label jhiTranslate="kstandards.content.workflowStatus" for="field_workflowStatus">Workflow
                            Status</label>
                        <fa-icon icon="info-circle" triggers="hover" placement="right" popoverClass="help-popover" [popoverTitle]="'kstandards.content.workflowStatus' | translate" [ngbPopover]="workflowStatusTooltip"></fa-icon>
                    </div>

                    <div class="col-lg-10">
                        <select class="form-control" name="workflowStatus" [(ngModel)]="content.workflowStatus" id="field_workflowStatus" required [disabled]="!hasEditPermissions">
                            <option value="DRAFT">{{'kstandards.WorkflowStatus.DRAFT' | translate}}</option>
                            <option value="REQUIRES_TRANSLATION">{{'kstandards.WorkflowStatus.REQUIRES_TRANSLATION' |
                                translate}}</option>
                            <option value="TRANSLATION_DONE">{{'kstandards.WorkflowStatus.TRANSLATION_DONE' |
                                translate}}</option>
                            <option value="PUBLISHED">{{'kstandards.WorkflowStatus.PUBLISHED' | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-lg-2" jhiTranslate="kstandards.content.renderScope" for="field_workflowStatus">Render scope</label>
                    <div class="col-lg-10">
                        <select class="form-control" name="renderScope" [(ngModel)]="content.renderScope" id="field_renderScope" required [disabled]="!hasEditPermissions">
                            <option value="WEB_PDF">{{'kstandards.RenderScope.WEB_PDF' | translate}}</option>
                            <option value="WEB">{{'kstandards.RenderScope.WEB' | translate}}</option>
                            <option value="PDF">{{'kstandards.RenderScope.PDF' | translate}}</option>
                        </select>
                    </div>
                </div>
                <ng-container *ngIf="content.contentType === 'ATTACHMENT_LINK'">
                    <div class="form-row form-group">
                        <div class="col-md-2">
                            <span jhiTranslate="kstandards.content.linkedAttachment">Anhang</span>
                        </div>
                        <div class="col-md-6">
                            <span class="selected-attachment" *ngIf="content.linkedAttachment">
                                <a [routerLink]="['/attachment/'+ content.linkedAttachment.id + '/edit']">{{
                                    content.linkedAttachment?.name }}</a>
                            </span>
                            <span class="selected-attachment" *ngIf="!content.linkedAttachment" jhiTranslate="kstandards.content.noAttachmentSelected">
                                No attachment selected
                            </span>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-danger" (click)="removeLinkedAttachment()" *ngIf="content.linkedAttachment">
                                <icon class="icon-white" shape="trash"></icon><span jhiTranslate="kstandards.content.removeAttachment">Remove attachment</span>
                            </button>
                            <button class="btn btn-primary" (click)="openAttachmentPicker()" jhiTranslate="kstandards.content.chooseAttachment">Choose attachment</button>
                        </div>
                    </div>
                </ng-container>

                <div class="form-group row" *ngIf="hasConstructionType">
                    <label class="col-form-label col-lg-2" jhiTranslate="kstandards.content.constructionType" for="field_content_constructionType">Construction Type</label>
                    <div class="col-lg-10">
                        <select class="form-control" name="constructionType" [(ngModel)]="content.constructionType" id="field_content_constructionType" [disabled]="!hasEditPermissions">
                            <option value="CONSTRUCTION">{{'kstandards.ConstructionType.CONSTRUCTION' | translate}}
                            </option>
                            <option value="MODERNIZATION">{{'kstandards.ConstructionType.MODERNIZATION' | translate}}
                            </option>
                            <option value="CONSTRUCTION_MODERNIZATION">
                                {{'kstandards.ConstructionType.CONSTRUCTION_MODERNIZATION' | translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="hasComponentType">
                    <label class="col-form-label col-lg-2" jhiTranslate="kstandards.content.componentType.name" for="field_content_componentType">Component Type</label>
                    <div class="col-lg-10">
                        <select class="form-control" name="componentType" [(ngModel)]="content.componentType" id="field_content_componentType" [disabled]="!hasEditPermissions">
                            <option jhiTranslate="kstandards.content.optionNone" [ngValue]="null"></option>
                            <option value="BASIC" jhiTranslate="kstandards.content.componentType.BASIC"></option>
                            <option value="EXTENDED" jhiTranslate="kstandards.content.componentType.EXTENDED"></option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="hasImplementationType">
                    <label class="col-form-label col-lg-2" jhiTranslate="kstandards.content.implementationType" for="field_content_implementationType">Implementation Type</label>
                    <div class="col-lg-10">
                        <select class="form-control" name="implementationType" [(ngModel)]="content.implementationType" id="field_content_implementationType" [disabled]="!hasEditPermissions">
                            <option jhiTranslate="kstandards.content.optionNone" [ngValue]="null"></option>
                            <option *ngFor="let impType of implementationTypesArr; let idx = index;" [value]="impType" jhiTranslate="kstandards.content.implementationTypeOption" [translateValues]="{option: idx + 1}"></option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="hasMaterialCategory">
                    <label class="col-form-label col-lg-2" jhiTranslate="kstandards.content.materialCategory" for="field_content_implementationType">Material Category</label>
                    <div class="col-lg-10">
                        <select class="form-control" name="materialCategory" [(ngModel)]="content.materialCategory" id="field_content_materialCategory" [disabled]="!hasEditPermissions">
                            <option jhiTranslate="kstandards.content.optionNone" [ngValue]="null"></option>
                            <option *ngFor="let materialCategory of materialCategoryOptions" [ngValue]="materialCategory.id === content.materialCategory?.id ? content.materialCategory : materialCategory">{{ materialCategory.name }}
                            </option>
                        </select>
                    </div>
                </div>                
                <input type="hidden" name="order" [(ngModel)]="content.order">
            </ng-container>
        </div>
        <div class="col-lg-2 global-buttons">
            <div class="form-group" *ngIf="canSaveWithSignificantChanges" [ngbTooltip]="(!hasEditPermissions  || !hasCreatePermissions || !hasSavePermissions ? 'kstandards.contentManager.help.saveContentNotAllowed' : '')  | translate" openDelay="500" placement="left">
                <button type="button" (click)="save(true)" class="btn btn-primary save-with-significant-changes-button" [disabled]="!contentForm.valid || !hasEditPermissions || !hasCreatePermissions || !hasSavePermissions">
                    <icon cssClasses="icon-white" shape="add-notebook"></icon>
                    <span jhiTranslate="entity.action.saveWithSignificantChanges">Save with significant changes</span>
                </button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-special save-button" (click)="save(false)" [disabled]="!contentForm.valid" jhiTranslate="kstandards.content.updateContent">Update
                    content</button>
            </div>
            <div class="form-group" *ngIf="mode !== DIFF_MODE" [ngbTooltip]="(!hasEditPermissions || !hasCreatePermissions  || !hasSavePermissions ? 'kstandards.contentManager.help.showChangesNotAllowed' : '')  | translate" openDelay="500" placement="left">
                <button type="button" class="btn btn-warning" (click)="showMode(DIFF_MODE)" [disabled]="!content.id || content.contentType === 'ROOMBOOK' || content.contentType === 'PRODUCT_LIST' || content.contentType === 'CHANGELOG' || !hasEditPermissions || !hasCreatePermissions || !hasSavePermissions" jhiTranslate="kstandards.content.showChanges">Show changes</button>
            </div>
            <div class="form-group" *ngIf="mode !== EDIT_MODE">
                <button type="button" class="btn btn-info" (click)="showMode(EDIT_MODE)" [disabled]="content.contentType === 'ROOMBOOK' || content.contentType === 'PRODUCT_LIST' || content.contentType === 'CHANGELOG'" jhiTranslate="kstandards.content.hideChanges">Edit content</button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-secondary discard-button" (click)="clear()" jhiTranslate="kstandards.content.cancelEdit">Cancel</button>
            </div>
        </div>

    </div>
    <div class="row translations" *ngIf="content.contentType !== 'ROOMBOOK' && content.contentType !== 'PRODUCT_LIST' && content.contentType !== 'CHANGELOG' && mode === EDIT_MODE">

        <div class="col-lg-6">
            <h3 jhiTranslate="kstandards.content.primaryLanguage">Primary language</h3>
            <div class="translation-label">
                <label for="field_content_textContent_{{ defaultTranslation.language.locale }}" jhiTranslate="kstandards.content.translation.textContent" [translateValues]="{ languageName: defaultTranslation.language.name }">Content {{
                    defaultTranslation.language.name }}</label>
            </div>
            <ng-container *ngIf="content.contentType === 'FILE' && !isPrimaryLanguageEditingContainerReadOnly">
                <div class="row">
                    <div class="col-md-10">
                        <span class="selected-file" *ngIf="defaultTranslation.file">
                            <a *ngIf="hasSavePermissions" [routerLink]="['/file/'+ defaultTranslation.file.id + '/edit']">{{ defaultTranslation.file?.name
                                }}</a>
                            <a *ngIf="!hasSavePermissions">{{ defaultTranslation.file?.name
                                }}</a>
                        </span>
                        <span class="selected-file" *ngIf="!defaultTranslation.file" jhiTranslate="kstandards.content.noFileSelected">
                            No file selected
                        </span>
                    </div>
                    <div class="col-md-2" *ngIf="defaultTranslation.file && hasSavePermissions">
                        <button class="btn btn-danger" (click)="removeFile(defaultTranslation)">
                            <icon shape="trash"></icon>
                        </button>
                    </div>
                </div>
                <div class="form-group" style="width: 243px;" [ngbTooltip]="(!hasCreatePermissions || !hasSavePermissions ? 'kstandards.contentManager.help.addAttachmentNotAllowed' : '')  | translate" openDelay="500">
                    <button class="btn btn-primary" (click)="openFilePicker(defaultTranslation)" jhiTranslate="kstandards.content.chooseFile" [disabled]="!hasCreatePermissions|| !hasSavePermissions">Choose
                        file</button>
                    <button class="btn btn-secondary" (click)="useFileAsDefault()" jhiTranslate="kstandards.content.useFileAsDefault" [disabled]="!hasCreatePermissions|| !hasSavePermissions">Use as
                        default</button>
                </div>
            </ng-container>

            <div class="form-group">
                <tui-editor *ngIf="content.contentType !== 'FILE'" id="field_content_textContent_{{ defaultTranslation.language.locale }}" name="content-{{ defaultTranslation.language.locale }}" [(ngModel)]="defaultTranslation.textContent" (change)="onTemporaryChange($event)" [isTranslationContentContainer]="false" [isEditorReadOnly]="isPrimaryLanguageEditingContainerReadOnly || !hasCreatePermissions || !hasSavePermissions" [isMaterialCategory]="content.materialCategory != null">
                </tui-editor>
                <input *ngIf="content.contentType === 'FILE' && !isPrimaryLanguageEditingContainerReadOnly" id="field_content_textContent_{{ defaultTranslation.language.locale }}" class="form-control" type="text" name="content-single-line-{{ defaultTranslation.language.locale }}" (keydown)="handleSingleLineKeydown($event)" [(ngModel)]="defaultTranslation.textContent">
            </div>
        </div>
        <div class="col-lg-6" *ngIf="languages.length > 1">
            <h3><span jhiTranslate="kstandards.content.selectedTranslation">Translation</span>
                <fa-icon icon="info-circle" [styles]="{'stroke': '#F44336', 'color': '#F44336'}" tooltipClass="help-tooltip" [ngbTooltip]="'kstandards.contentManager.help.selectedTranslation' | translate"></fa-icon>
            </h3>
            <div class="translation-label">
                <select class="custom-select" name="selectedLanguage" [(ngModel)]="selectedLanguage" (change)="switchSelectedLanguage(); $event.stopPropagation()">
                    <ng-container *ngFor="let language of languages; let i = index">
                        <option [ngValue]="language" *ngIf="language.id !== defaultTranslation.language.id">{{
                            language.name }}</option>
                    </ng-container>
                </select>
            </div>
            <ng-container *ngIf="content.contentType === 'FILE'">
                <div class="row">
                    <div class="col-md-10">
                        <span class="selected-file" *ngIf="selectedTranslation.file">
                            <a [routerLink]="['/file/'+ selectedTranslation.file.id + '/edit']">{{ selectedTranslation.file?.name
                                }}</a>
                        </span>
                        <span class="selected-file" *ngIf="!selectedTranslation.file" jhiTranslate="kstandards.content.noFileSelected">
                            No file selected
                        </span>
                    </div>
                    <div class="col-md-2" *ngIf="selectedTranslation.file">
                        <button class="btn btn-danger" (click)="removeFile(selectedTranslation)">
                            <icon shape="trash"></icon>
                        </button>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary" (click)="openFilePicker(selectedTranslation)" jhiTranslate="kstandards.content.chooseFile">Choose file</button>
                </div>
            </ng-container>
            <div class="form-group" *ngIf="selectedTranslation">
                <tui-editor *ngIf="content.contentType !== 'FILE'" name="translation-content-{{ selectedTranslation.language.locale }}" id="field_content_textContent_{{ selectedTranslation.language.locale }}" [(ngModel)]="selectedTranslation.textContent" (change)="onTemporaryChange($event)" [isTranslationContentContainer]="true" [isEditorReadOnly]="false" [isMaterialCategory]="content.materialCategory != null">
                </tui-editor>
                <input *ngIf="content.contentType === 'FILE'" class="form-control" id="field_content_textContent_{{ selectedTranslation.language.locale }}" type="text" name="content-single-line-{{ selectedTranslation.language.locale }}" (keydown)="handleSingleLineKeydown($event)" [(ngModel)]="selectedTranslation.textContent">
            </div>

        </div>
    </div>
</form>

<content-diff *ngIf="mode === DIFF_MODE" [content]="content"></content-diff>