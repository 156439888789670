import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '@app/app.constants'
import { HttpClient } from '@angular/common/http'
import { MaterialCategory } from '@app/data-access'
import { createHttpParams, ResponseWrapper } from '@app/data-access'


@Injectable()
export class MaterialCategoryService {

    private readonly resourceUrl = SERVER_API_URL + 'api/material-category'
    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/material-category'

    constructor(private readonly httpClient: HttpClient) {
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => new ResponseWrapper(res.headers, res.body, res.status)))
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => new ResponseWrapper(res.headers, res.body , res.status)))
    }

    create(materialCategory: MaterialCategory, fileContent: File): Observable<MaterialCategory> {
        const formData = new FormData()
        formData.append('entity', new Blob([JSON.stringify(materialCategory)], {type: 'application/json'}))
        formData.append('icon', fileContent)

        return this.httpClient.post(this.resourceUrl, formData).pipe(map((res) => {
            return res
        }))
    }

    update(materialCategory: MaterialCategory, fileContent: File): Observable<MaterialCategory> {
        const formData = new FormData()
        formData.append('entity', new Blob([JSON.stringify(materialCategory)], {type: 'application/json'}))
        if (fileContent) formData.append('icon', fileContent)

        return this.httpClient.put(this.resourceUrl, formData).pipe(map((res) => {
            return res
        }))
    }

    find(id: number): Observable<MaterialCategory> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return res
        }))
    }

    deactivate(id: number): Observable<MaterialCategory> {
        return this.httpClient.patch(`${this.resourceUrl}/${id}`, { operation: 'DEACTIVATE'})
    }
}
