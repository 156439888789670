import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../../shared'

import {
    SearchengineReindexComponent,
    SearchengineReindexModalComponent,
    SearchEnginReindexService,
    searchengineReindexRoute
} from './'

const ADMIN_ROUTES = [
    searchengineReindexRoute
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ADMIN_ROUTES)
    ],
    declarations: [
        SearchengineReindexComponent,
        SearchengineReindexModalComponent
    ],
    entryComponents: [
        SearchengineReindexModalComponent
    ],
    providers: [
        SearchEnginReindexService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class JhipsterSearchEngineReindexModule {}
