import { Component, Input } from '@angular/core'
import { Content, Language } from '@app/data-access'

@Component({
    selector: 'content-preview',
    templateUrl: 'content-preview.component.html'
})
export class ContentPreviewComponent {

    @Input()
    content: Content

    @Input()
    language: Language

}
