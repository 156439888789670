import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BuildingType, BuildingTypeService, Country, Tag, TagService } from '@app/data-access'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'entity-search',
    templateUrl: './search.component.html',
    styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges, OnDestroy {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    @Input()
    entity: string

    @Input()
    hasBuildingTypes: boolean

    @Input()
    buildingTypesPath: string

    buildingTypes: BuildingType[]

    buildingTypeId: number

    @Input()
    searchFilter: string[] = []

    @Input()
    showDeactivatedCheckbox = true

    @Input()
    defaultFilterByAll = true

    @Input()
    tags: Tag[]

    @Input()
    hasEditPermissions = true

    @Input()
    tooltipMessage = ''

    @Input()
    createCountries: Country[] = []

    searchWord: string
    filterBy: string
    showDeactivated = false

    withImages: string

    tagsSubject = new BehaviorSubject<Tag[]>([])
    public availableTags: Tag[] = []
    public appliedTags: Tag[] = []

    public selectedCreateCountries: Country[] = []
    public createCountryCodes: string[]

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly tagService: TagService,
        private readonly buildingTypeService: BuildingTypeService
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.searchWord = params['searchWord'] || ''
            if (!params['filterBy']) {
                this.filterBy = this.defaultFilterByAll ? 'none' : (this.searchFilter.length > 0 ? this.searchFilter[0] : '')
            } else {
                this.filterBy = params['filterBy']
            }

            this.showDeactivated = (params['showDeactivated'] === 'true')
            this.withImages = params['withImages']
            if (params['buildingTypeId']) {
                this.buildingTypeId = parseInt(params['buildingTypeId'], 10)
            } else {
                this.buildingTypeId = null
            }

            this.createCountryCodes = params['createCountryCodes'] ? params['createCountryCodes'].split(',') : []
        })
        this.buildingTypeService.query(false, { sort: ['name,asc'] }).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe((res) => {
                this.buildingTypes = res.json
            })
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.createCountryCodes?.length && !this.selectedCreateCountries?.length) {
            this.selectedCreateCountries = this.createCountries.filter((country: Country) => this.createCountryCodes.includes(country.code?.toLowerCase()))
        }

        if (changes['tags']) {
            if (this.tags) {
                this.appliedTags = this.tags
            }
        }
    }

    search() {
        this.router.navigate([this.entity], {
            queryParams: {
                searchWord: this.searchWord,
                tags: this.appliedTags.map((e) => e.name).join(','),
                filterBy: this.filterBy,
                buildingTypeId: this.buildingTypeId,
                buildingTypesPath: this.buildingTypesPath,
                showDeactivated: this.showDeactivated,
                withImages: this.withImages,
                createCountryCodes: this.selectedCreateCountries?.map((country: Country) => country.code?.toLowerCase()).join(',')
            }
        })
    }

    reset() {
        this.selectedCreateCountries = []
        this.router.navigate([this.entity], {
            queryParams: {
                showDeactivated: this.showDeactivated,
                buildingTypeId: null,
                createCountryCodes: null
            }
        })
    }

    onTagsChange(newTags) {
        this.tagsSubject.next(newTags)
    }

    searchSuggestions(criteria: string) {
        this.tagService
            .suggest(criteria)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((res) => this.availableTags = res.json)
    }

    trackById(country: Country): number {
        return country.id
    }

    compareCreateCountries(selected: Country, item: Country) {
        return selected.id === item.id
    }
}
