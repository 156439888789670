import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { File } from '@app/data-access'

@Component({
    selector: 'pdf-preview',
    templateUrl: 'pdf-preview.component.html',
    styleUrls: ['pdf-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfPreviewComponent implements OnChanges {

    @Input()
    public file: File

    public url

    public isImage = false

    ngOnChanges(changes: SimpleChanges) {

        if (changes.file) {

            if (this.file.mainFile.fileType.startsWith('application/pdf')) {

                this.isImage = true
                this.url = this.file.previewFile.presignedUrl
            } else {
                throw new Error('File is not a PDF.')
            }
        }

    }
}
