import { Report } from './report.model'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs/index'
import { ActivatedRoute, Router } from '@angular/router'
import { ResponseWrapper } from '@app/data-access'
import { JhiAlertService, JhiEventManager } from 'ng-jhipster'
import { ReportType } from './report-types.model'
import { ReportService } from './report.service'
import { BaseReportComponent } from './report-base.component'
import { BuildingType, BuildingTypeService, RoomGroup, RoomGroupService } from '@app/data-access'

@Component({
    selector: 'jhi-rooms-by-building-type-report',
    templateUrl: './report-rooms-with-materials-dialog.component.html'
})
export class ReportRoomsWithMaterialsDialogComponent extends BaseReportComponent implements OnInit, OnDestroy {
    eventSubscriber: Subscription
    buildingTypes: BuildingType[]
    reportTypes = ReportType
    roomGroups: RoomGroup[]

    constructor(
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        private readonly buildingTypeService: BuildingTypeService,
        private readonly eventManager: JhiEventManager,
        protected readonly jhiAlertService: JhiAlertService,
        protected readonly reportsService: ReportService,
        private readonly roomGroupService: RoomGroupService
    ) {
        super(route, router, reportsService, jhiAlertService)
    }

    ngOnInit() {
        this.reportType = ReportType.ROOMS_WITH_MATERIALS
        super.ngOnInit()
        this.buildingTypeService.query(false, {sort: ['name,asc']})
            .subscribe((res: ResponseWrapper) => {
                this.buildingTypes = res.json
            })

        this.roomGroupService.query(false, {sort: ['number,asc']})
            .subscribe((res) => {
                this.roomGroups = res.json
        })

        this.registerChangeInReport()
    }

    public static trackBuildingTypeById(index: number, item: BuildingType) {
        return item.id
    }

    getFilterQuery(): string {
        return `rooms.buildingTypes.id :(${this.report.buildingType.id})`
    }

    buildingTypeSelected() {
        if (this.report.buildingType) {
            this.roomGroups = []
            this.roomGroupService.search(this.getFilterQuery(),
                {
                    sort: ['number,asc']
                }).subscribe((res) => {
                this.roomGroups = res.json
            })
        }
    }

    public onSaveSuccess(result: Report) {
        this.report = result

        if (this.isNew) {
            this.router.navigate(['/report/roomsWithMaterials/' + result.id + '/edit'])
        }
    }

    registerChangeInReport() {
        this.eventSubscriber = this.eventManager.subscribe('reportDeleted', (response) => this.goToReportListPage())
    }

    onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
    }

    trackId(index: number, item: RoomGroup) {
        return item.id
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
        this.cancelledPolling.next(true)
        this.cancelledPolling.complete()
    }
}
