import { Component, OnInit } from '@angular/core'
import { WebsocketService } from '@app/shared'
import { OAuthService } from 'angular-oauth2-oidc'

@Component({
    template: ''
})
export class OAuth2Component implements OnInit {

    constructor(
        private readonly oauthService: OAuthService,
        private readonly websocketService: WebsocketService
    ) {
    }

    ngOnInit(): void {
        const isAuthenticated = this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()
        if (isAuthenticated) {
            this.websocketService.connect()
        }
    }
}
