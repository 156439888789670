import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ProfileEnvironmentEnum, ProfileService } from '@app/layouts/profiles/profile.service'
import { TokenParser } from '@app/oauth2/parsers/token.parser'
import { NullValidationHandler, OAuthEvent, OAuthService } from 'angular-oauth2-oidc'
import { uniq } from 'lodash-es'
import { JhiAlert, JhiAlertService } from 'ng-jhipster'
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { OAuth2Config } from '../shared/config/oauth2/oauth2.config'
import { Countries } from './countries'
import { HostCompanyIdEnum, RoleIdEnum } from './roles'
import { StateStorageService } from './state-storage.service'

@Injectable()
export class OAuth2Service {

    public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl
    public countryCodeFromActiveRole$: BehaviorSubject<string> = new BehaviorSubject<string>(this.stateStorageService.getSessionUserCountryCode())
    private authenticationState$ = new ReplaySubject<boolean>(1)
    private authState = false
    private account$ = new ReplaySubject<any>(1)
    private account
    private alerts: JhiAlert[] = []

    constructor(private readonly oauth2Config: OAuth2Config,
        private readonly oauthService: OAuthService,
        private readonly stateStorageService: StateStorageService,
        private readonly jhiAlertService: JhiAlertService,
        private readonly router: Router,
        private readonly profileService: ProfileService
    ) {
    }

    /**
     *
     * Configure the OAuth2 API.
     */
    public initOAuth() {
        this.oauth2Config.loadConfigurationFile().then((config) => {
            // We ignore the id_token validation because it is not properly provided
            this.oauthService.tokenValidationHandler = new NullValidationHandler()
            this.oauthService.configure({
                issuer: config['issuer'],
                redirectUri: `${window.location.origin}/`,
                clientId: config['clientId'],
                responseType: 'code',
                scope: config['scope'],
                strictDiscoveryDocumentValidation: config['strictDiscoveryDocumentValidation'],
                // Disable at_hash check for id_token. It is not provided
                disableAtHashCheck: true,
                requestAccessToken: true,
                clearHashAfterLogin: false,
                logoutUrl: config['logoutUrl'],
            })

            // Resource server specified by Himli Koray Tekin
            this.oauthService.customQueryParams = {
                'resourceServer': 'IdentityProviderRSUE'
            }

            this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
                this.updateState(false)
            })

            this.oauthService.events.pipe(filter(({ type }) => type === 'logout' || type === 'token_received')).subscribe((event: OAuthEvent) => {
                switch (event.type) {
                    case 'logout':
                        this.account$ = null
                        this.authenticationState$?.next(null)
                        break
                    case 'token_received':
                        this.updateState(true)

                        // Emit success alert
                        this.jhiAlertService.success('global.auth.successfulLogin', 'user')
                        break
                }
            })
        })
        this.stateStorageService.profileEnvironment$?.subscribe(env => {
            this.isLidl = env === ProfileEnvironmentEnum.Lidl
        })
    }

    private navigateAfterLogin(): void {
        // Redirect to the proper route
        const redirectUrl = this.stateStorageService.getUrl()
        // Clean up state storage
        this.stateStorageService.storeUrl(null)
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl)
        } else {
            this.router.navigate(['/'])
        }
    }

    private updateState(isCheckCountryCode: boolean) {
        const isAuthenticated = this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()
        this.authState = isAuthenticated
        this.authenticationState$?.next(isAuthenticated)
        if (isAuthenticated) {
            this.account = TokenParser.parse(this.oauthService.getAccessToken())
            this.account$?.next(this.account)
            if (isCheckCountryCode) {
                this.checkCountryCodeAndEnv()
            }

            this.checkMissingRolesAndShowAlert()
        } else {
            this.account$?.next(null)
        }
    }

    private checkCountryCodeAndEnv(): void {
        if (!this.stateStorageService.getSessionUserCountryCode()) {
            const countryCodes$ = this.getDistinctCountryCodesFromRoles(this.getUserRolesWithEditPermissions())
            const profileInfo$ = this.profileService.getProfileInfo()

           combineLatest([countryCodes$, profileInfo$]).pipe(take(1)).subscribe(([countryCodes, profileInfo]) => {
                this.isLidl = profileInfo.lidl
                if (this.isLidl && countryCodes.length > 1) {
                    this.router.navigate(['/authorities', { outlets: { popup: 'select' } }], { replaceUrl: true })
                } else if (this.isLidl && countryCodes.length === 1) {
                    this.countryCodeFromActiveRole$.next(countryCodes[0])
                    this.stateStorageService.storeSessionUserCountryCode(countryCodes[0])
                    this.navigateAfterLogin()
                } else if (this.isLidl && countryCodes.length === 0) {
                    this.countryCodeFromActiveRole$.next(null)
                    this.stateStorageService.storeSessionUserCountryCode(null)
                    this.navigateAfterLogin()
                } else {
                    this.navigateAfterLogin()
                }
           })
        }
    }

    get authenticationState(): Observable<boolean> {
        return this.authenticationState$.asObservable()
    }

    get userIdentity(): Observable<any> {
        return this.account$.asObservable()
    }

    public isAuthenticated() {
        return this.authState
    }

    /**
     *
     * Checks if there is a specific role.
     * @param {string[]} authorities - roles to check.
     * @returns {Promise<boolean>} - promise of the authorization result.
     */
    public hasAnyAuthority(authorities: any[]): Observable<boolean> {
        let concatenatedAuthorities: string[] = []
        if (authorities.some(el => Array.isArray(el))) {
            authorities.forEach(el => concatenatedAuthorities = concatenatedAuthorities.concat(el))
        }
        return this.account$.pipe(map((account) => {
            return this.hasAnyAuthorityDirect(account, concatenatedAuthorities.length ? concatenatedAuthorities : authorities)
        }))
    }

    /**
 *
 * Check if the user has the required roles.
 * @param {string[]} authorities - the authorities provided as data for the route.
 * @returns {boolean} - does the user have any of them.
 */
    private hasAnyAuthorityDirect(account: any, authorities: string[]): boolean {
        if (!account) {
            return false
        }

        if (this.getHostCompanyIdFromRole() === HostCompanyIdEnum.LStandards && !this.allLidlEditorAuthoritiesHaveValidCountryCode(authorities)) {
            return false
        }

        for (const authority of authorities) {
            if (account.authorities.indexOf(authority) !== -1) {
                return true
            }
        }

        return false
    }


    private allLidlEditorAuthoritiesHaveValidCountryCode(authorities: string[]): boolean {
        let haveLidlAuthoritiesValidCountryCode = true

        if (!authorities || !authorities.length) {
            return false
        }
        authorities.forEach(authority => {
            const authorityParts: string[] = authority.split('-')

            // check only lidl authorities - kaufland authorities have no country
            if (authority.split(`-${authorityParts[authorityParts.length - 1]}`)[0] === `${HostCompanyIdEnum.LStandards}-${RoleIdEnum.Editor}`
                && !Countries.COUNTRY_CODES.includes(authorityParts[authorityParts.length - 1])) {

                haveLidlAuthoritiesValidCountryCode = false
            }
        })
        return haveLidlAuthoritiesValidCountryCode
    }

    /**
     *
     * Perform login with the implicit flow.
     */
    public login(url?: string) {
        if (url) {
            this.stateStorageService.storeUrl(url)
        }

        this.oauthService.initCodeFlow()
    }

    /**
     *
     * Logout without redirecting which causes a page refresh.
     */
    public logout() {
        this.stateStorageService.storeUrl(null)
        this.stateStorageService.clear()
        this.oauthService.logOut()
    }

    public getHostCompanyIdFromRole(): HostCompanyIdEnum {
        const kStandards: string = !!this.account && this.account.authorities.find((authority: string) => authority.includes(HostCompanyIdEnum.KStandards))
        const lStandards: string = !!this.account && this.account.authorities.find((authority: string) => authority.includes(HostCompanyIdEnum.LStandards))

        if (!!kStandards && !lStandards) {
            return HostCompanyIdEnum.KStandards
        } else if (!kStandards && !!lStandards) {
            return HostCompanyIdEnum.LStandards
        } else {
            return undefined
        }
    }

    public getUserRolesWithEditPermissions(): Observable<string[]> {
        return this.account$.pipe(
            map(account => account?.authorities
                .filter((a: string) => a.includes(RoleIdEnum.Editor) || a.includes(RoleIdEnum.Admin) || a.includes(RoleIdEnum.Publisher))
            )
        )
    }

    public getDistinctCountryCodesFromRoles(roles: Observable<string[]>): Observable<string[]> {
        return roles.pipe(
            map((rs: string[]) => uniq(rs.map((r: string) => this.getCountryCodeFromRole(r))))
        )
    }

    public getCountryCodeFromRole(role: string): string {
        if (role && role.includes('-')) {
            const parts = role.split('-')
            return parts[parts.length - 1]
        } else {
            return null
        }
    }

    public setUserActiveCountry(countryCode: string, reload = false): void {
        this.countryCodeFromActiveRole$.next(countryCode ? countryCode : null)
        this.stateStorageService.storeSessionUserCountryCode(countryCode ? countryCode : null)

        if (reload) {
            window.location.reload()
        }
    }

    private checkMissingRolesAndShowAlert(): void {
        const profileEnvironment: ProfileEnvironmentEnum = this.stateStorageService.getProfileEnvironment()

        let alertMsgId: string

        if (
            (!this.account['authorities'] || this.account['authorities'].length === 0)
            && profileEnvironment === ProfileEnvironmentEnum.Lidl
            && !this.isAlertActive('error.anyRoleMissingForLidlUser')
        ) {
            alertMsgId = 'error.anyRoleMissingForLidlUser'
        } else if (
            (!this.account['authorities'] || this.account['authorities'].length === 0)
            && profileEnvironment === ProfileEnvironmentEnum.Kaufland
            && !this.isAlertActive('error.anyRoleMissingForKauflandUser')
        ) {
            alertMsgId = 'error.anyRoleMissingForKauflandUser'
        }

        if (!alertMsgId) {
            return
        }
        const alertOptions: JhiAlert = {
            type: 'danger',
            msg: alertMsgId,
            params: { id: alertMsgId }
        }
        this.alerts.push(alertOptions)
        this.jhiAlertService.addAlert(alertOptions, this.alerts)
    }

    private isAlertActive(id: string): boolean {
        return this.alerts.map((alert: JhiAlert) => alert?.params?.id).includes(id)
    }
}

