import { Component, OnDestroy, OnInit } from '@angular/core'
import { JhiAlertService } from 'ng-jhipster'
import { ActivatedRoute } from '@angular/router'
import { ResponseWrapper } from '@app/data-access'
import { RenderedBook, RenderedBookService } from '@app/data-access'
import { switchMap, takeUntil, tap } from 'rxjs/operators'
import { Subject } from 'rxjs'

@Component({
    selector: 'jhi-release-generated-files',
    templateUrl: './release-generated-files.component.html'
})
export class ReleaseGeneratedFilesComponent implements OnInit, OnDestroy {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    isLoading = false

    renderedBook: RenderedBook

    releaseId: number

    releaseContextId: number

    constructor(private readonly renderedBookService: RenderedBookService,
                private readonly jhiAlertService: JhiAlertService,
                private readonly route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.pipe(
            tap((params) => {
                this.isLoading = true
                this.releaseId = params['releaseId']
                this.releaseContextId = params['releaseContextId']
            }),
            switchMap((params) => this.renderedBookService.find(params['renderedBookId'])),
            takeUntil(this.componentDestroyed)
        ).subscribe(
            (renderedBook) => {
                this.renderedBook = renderedBook
                this.isLoading = false
            }, (res: ResponseWrapper) => this.onError(res.json))
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
        this.isLoading = false
    }
}
