import { BaseEntity } from '../common'
import { Project } from '../project'
import { Language } from '../language'

export class ReleaseContext implements BaseEntity {
    public constructor(
        public id?: number,
        public project?: Project,
        public language?: Language,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public deletable?: boolean,
    ) {
    }
}
