import { ChangeEntry } from './change-entry.model'
import { Page, ResponseWrapper } from '@app/data-access'
import { takeUntil } from 'rxjs/operators'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { ChangeEntryService } from './change-entry.service'

@Component({
    selector: 'change-entry-table',
    templateUrl: './change-entry-table.component.html'
})
export class ChangeEntryTableComponent implements OnInit, OnDestroy  {

    private readonly INITIAL_PAGE: number = 0
    private readonly ITEMS_PER_PAGE: number = 10
    private readonly componentDestroyed: Subject<boolean> = new Subject()

    @Input()
    forUser = false

    page: Page<ChangeEntry>

    predicate: any

    reverse: any

    constructor(private readonly changeEntryService: ChangeEntryService) {
        this.predicate = 'updatedAt'
        this.reverse = false
    }

    ngOnInit() {
        this.loadAll(this.INITIAL_PAGE)
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    loadAll(pageNumber: number) {
        this.page = null
        this.changeEntryService.query(this.forUser, {
            page: pageNumber,
            size: this.ITEMS_PER_PAGE,
            sort: this.sort()
        }).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe(
                (res: ResponseWrapper) => this.page = res.json
            )
    }

    reset() {
        this.loadAll(this.INITIAL_PAGE)
    }

    sort() {
        return [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')]
    }

    onPageChange(page) {
        // Pages start from 1, but in the pagination the fist one is 0
        this.loadAll(page - 1)
    }

    trackId(item: ChangeEntry): number {
        return item.id
    }
}
