import { Content } from '../content'
import { BaseEntity } from '../common/base-entity'
import { TranslationJobStatus } from './translation-job-status'
import { Language } from '../language'

export class TranslationJob implements BaseEntity {

    constructor(
        public id?: number,
        public sourceText?: string,
        public content?: Content,
        public skipWorkflowUpdate?: boolean,
        public status?: TranslationJobStatus,
        public sourceLanguage?: Language,
        public targetLanguage?: Language,
        public translationBatchId?: number,
        public xliff?: string,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {

    }

}
