export class ProfileInfo {
    activeProfiles: string[]
    ribbonEnv: string
    inProduction: boolean
    swaggerEnabled: boolean
    lidl: boolean
    defaultLocale: string
    currentStage: string
    baseVersion: string
}
