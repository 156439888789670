import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { JhiAlertService } from 'ng-jhipster'
import { combineLatest, of, throwError } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import { OAuth2Service } from './oauth2.service'


@Injectable()
export class UserRouteAccessService implements CanActivate {

    constructor(private readonly router: Router,
                private readonly oauth2Service: OAuth2Service,
                private readonly jhiAlertService: JhiAlertService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {

        const authorities = route.data['authorities']
        // We need to call the checkLogin / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkLogin(authorities, state.url)
    }

    checkLogin(authorities: string[], url: string): Promise<boolean> {
        // Check if we need authorities at all
        if (!authorities || authorities.length === 0) {
            // In case of 404 we don't want to logout
            // this.principal.identity()
            // If we don't need authorities return true
            return Promise.resolve(true)
        }

        return combineLatest([this.oauth2Service.authenticationState, this.oauth2Service.hasAnyAuthority(authorities)])
            .pipe(
                switchMap(([authState, hasAuthorities]) => {
                    if (!authState) {
                        return throwError('User is not authenticated')
                    } else {
                        return of(hasAuthorities)
                    }
                }),
                first()
            )
            .toPromise()
            .then((hasAuthorities: boolean) => {
                if (hasAuthorities) {
                    return true
                }

                // Occurs when you don't have permissions for the operation
                this.jhiAlertService.error('error.auth.unauthorised')
                this.router.navigate(['/'])
                return false
            })
            .catch(() => {
                this.oauth2Service.login(url)
                return false
            })
    }
}
