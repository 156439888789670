import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '@app/app.constants'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DateUtil } from '@app/util/date.util'
import { Content } from '../content'
import { Change } from './change.model'
import { ChangeStatus } from './change-status.model'
import { CostCharacteristic } from '../changelog/cost-characteristic.model'
import { createHttpParams, ResponseWrapper } from '../common'

@Injectable()
export class ChangeService {

    private readonly resourceUrl = SERVER_API_URL + 'api/change'

    private readonly engineSearchUrl = SERVER_API_URL + 'api/_search/change'

    constructor(private readonly httpClient: HttpClient) {
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        return undefined
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.engineSearchUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    create(change: Change): Observable<Change> {
        return this.httpClient.post(this.resourceUrl, change)
            .pipe(map((res) => this.convertItemFromServer(res, false)))

    }

    update(change: Change): Observable<Change> {
        return this.httpClient.put(this.resourceUrl, change)
            .pipe(map((res) => this.convertItemFromServer(res, false)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    findAll(): Observable<Change[]> {
        return this.httpClient.get<Change[]>(this.resourceUrl).pipe(
            map((res) => this.convertResponseArray(res)))
    }

    findAllByChangelogId(changelogId: number, req?: any, showDeactivated = false): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get<Change[]>(`api/changelog/${changelogId}/change`, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res))
        )
    }

    copy(id: number, changelogId: number): Observable<Change> {
        return this.httpClient.get<Change>(`${this.resourceUrl}/${id}/copyTo/${changelogId}`)
            .pipe(map((res) => this.convertItemFromServer(res, false)))
    }

    findById(change: number): Observable<Change> {
        return this.httpClient.get<Change>(`${this.resourceUrl}/${change}`)
            .pipe(map((res) => this.convertItemFromServer(res, false)))
    }

    private convertResponseArray(res: any): Change[] {
        const result = []
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i], true))
        }
        return result
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i], false))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Language.
     */
    private convertItemFromServer(json: any, processDates: boolean): Change {
        const entity: Change = Object.assign(new Change(), json)

        entity.shortDescription = Object.assign(new Content(), entity.shortDescription)

        if (!entity.tags) {
            entity.tags = []
        }

        if (!entity.cost) {
            entity.cost = Object.assign(new CostCharacteristic(), entity.cost)
        }

        if (!entity.status) {
            entity.status = ChangeStatus.OPEN
        }

        if (processDates) {
            if (entity.startDate) {
                entity.startDate = DateUtil.parse(entity.startDate)
            }

            if (entity.completionDate) {
                entity.completionDate = DateUtil.parse(entity.completionDate)
            }
        }

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }
}
