import { BaseEntity } from '../common/base-entity'
import { TranslationBatchStatus } from './translation-batch-status.model'
import { TranslationJobView } from '../translation-job/translation-job-view.model'

export class TranslationBatchView implements BaseEntity {
    constructor(
        public id?: number,
        public status?: TranslationBatchStatus,
        public translationJobs?: TranslationJobView[],
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
    ) {
        {
            if (!this.translationJobs) {
                this.translationJobs = []
            }
        }
    }
}
