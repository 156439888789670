import { BaseEntity, ContentContainerEntity } from '../common'

import { Arrangeable } from '../common/arrangeable'
import { Linkable } from '../common/linkable'
import { Deletable } from '../common/deletable'
import { Content } from '../content'
import { Craft } from '../craft'
import { Product } from '../product'
import { WorkflowStatusSummary } from '../common'
import { BuildingType } from '../building-type'
import { Attachment } from '../attachment'

export class MaterialGroup implements BaseEntity, ContentContainerEntity, Arrangeable, Linkable, Deletable {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public workflowStatusSummary?: WorkflowStatusSummary,
        public contents?: Content[],
        public products?: Product[],
        public craft?: Craft,
        public order?: number,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public deletable?: boolean,
        public buildingTypes?: BuildingType[],
        public linkedAttachments?: Attachment[],
    ) {
        if (!this.buildingTypes) {
            this.buildingTypes = []
        }
    }

    get allContents(): Content[] {
        return (Object as any).values(this.contents)
            .reduce((accumulator, contents) => accumulator.concat(contents), [])
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/material-group/${this.id}/edit`
    }

    getLabelForArrangeableList(): string {
        return this.name
    }
}
