import { BaseEntity, ConstructionType, ContentContainerEntity } from '../common'
import { Content } from '../content/content.model'
import { AttachmentCategory } from '../attachment-category'
import { WorkflowStatusSummary } from '../common/workflow-status-summary.model'
import { BuildingType } from '../building-type/building-type.model'
import { Country } from '../country/country.model'
import { Craft } from '../craft/craft.model'
import { Material } from '../material/material.model'
import { MaterialGroup } from '../material-group/material-group.model'
import { MaterialSubGroup } from '../material-sub-group/material-sub-group.model'
import { Room } from '../room/room.model'

export class Attachment implements BaseEntity, ContentContainerEntity {
    constructor(
        public id?: number,
        public name?: string,
        public description?: any,
        public createCountry?: Country,
        public workflowStatusSummary?: WorkflowStatusSummary,
        public constructionType?: ConstructionType,
        public contents?: Content[],
        public buildingTypes?: BuildingType[],
        public countries?: Country[],
        public attachmentCategory?: AttachmentCategory,
        public crafts?: Craft[],
        public materials?: Material[],
        public materialGroups?: MaterialGroup[],
        public materialSubGroups?: MaterialSubGroup[],
        public rooms?: Room[],
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public deletable?: boolean
    ) {
        if (!this.contents) {
            this.contents = []
        }

        if (!this.buildingTypes) {
            this.buildingTypes = []
        }

        if (!this.countries) {
            this.countries = []
        }

        if (this.createCountry?.code) {
            this.createCountry.code = this.createCountry.code.toLowerCase()
        }
    }
}
