import { Component, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { JhiHealthService } from './health.service'
import { JhiHealthModalComponent } from './health-modal.component'

@Component({
    selector: 'jhi-health',
    templateUrl: './health.component.html'
})
export class JhiHealthCheckComponent implements OnInit {
    healthData: any
    updatingHealth: boolean

    constructor(
        private readonly modalService: NgbModal,
        private readonly healthService: JhiHealthService
    ) {

    }

    ngOnInit() {
        this.refresh()
    }

    baseName(name: string) {
        return this.healthService.getBaseName(name)
    }

    getBadgeClass(statusState) {
        switch (statusState) {
            case 'UP':
                return 'badge-success'
            case 'DOWN':
                return 'badge-danger'
            case 'yellow':
            default:
                return 'badge-warning'
        }
    }

    refresh() {
        this.updatingHealth = true

        this.healthService.checkHealth().subscribe((health) => {
            this.healthData = this.healthService.transformHealthData(health)
            this.updatingHealth = false
        }, (error) => {
            if (error.status === 503) {
                this.healthData = this.healthService.transformHealthData(error.json())
                this.updatingHealth = false
            }
        })
    }

    showHealth(health: any) {
        const modalRef  = this.modalService.open(JhiHealthModalComponent)
        modalRef.componentInstance.currentHealth = health
        modalRef.result.then((result) => {
            // Left blank intentionally, nothing to do here
        }, (reason) => {
            // Left blank intentionally, nothing to do here
        })
    }

    subSystemName(name: string) {
        return this.healthService.getSubSystemName(name)
    }

}
