import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { Log } from './log.model'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class LogsService {
    constructor(private readonly httpClient: HttpClient) { }

    changeLevel(log: Log): Observable<Log> {
        return this.httpClient.put<Log>('management/logs', log)
    }

    findAll(): Observable<Log[]> {
        return this.httpClient.get<Log[]>('management/logs')
    }
}
