<div class="form-group">
    <label class="form-control-label" [jhiTranslate]="'kstandards.multisearch.'+entityName">
        {{entityName}}
    </label>
    <ng-select [items]="items$ | async" bindLabel="name" name="item-search" [addTag]="false" [multiple]="true" (add)="this.onAdded.emit($event)" [hideSelected]="true" [trackByFn]="trackByFn" [compareWith]="compareById" [loading]="itemsLoading" [typeahead]="itemsInput$" (change)="onChange()" [(ngModel)]="model" [readonly]="readonly">

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
            <strong *ngIf="item.label || item.number">{{ item.label || item.number}} | </strong> {{ item.name }}
            <span class="multisearch-is-deleted" *ngIf="item.deletedAt">&#x274C;</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-clear="clear" let-search="searchTerm">
            <strong *ngIf="item.label || item.number" [ngOptionHighlight]="search">{{ item.label ||
                item.number}}</strong><strong *ngIf="item.label">: </strong> <span [ngOptionHighlight]="search">{{
                item.name }}</span>
            <ul class="badge-list">
                <li *ngFor="let buildingType of item.buildingTypes">
                    <custom-badge [str]="buildingType?.alias ? buildingType?.alias : buildingType?.name">
                    </custom-badge>
                </li>
                <li *ngIf="item.buildingType">
                    <custom-badge [str]="item.buildingType?.alias ? item.buildingType?.alias : item.buildingType?.name">
                    </custom-badge>
                </li>
                <li *ngIf="item.deletedAt">
                    <custom-badge [str]="'entity.isDeactivatedSimple' | translate" background="#f00" foreground="#000">
                    </custom-badge>
                </li>
            </ul>
        </ng-template>
    </ng-select>
</div>