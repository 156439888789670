import { Component, OnInit } from '@angular/core'
import { DatePipe } from '@angular/common'
import { JhiParseLinks } from 'ng-jhipster'

import { Audit } from './audit.model'
import { AuditsService } from './audits.service'
import { ITEMS_PER_PAGE } from '../../shared'

@Component({
  selector: 'jhi-audit',
  templateUrl: './audits.component.html'
})
export class AuditsComponent implements OnInit {

    audits: Audit[]

    fromDate: string

    itemsPerPage: any

    links: any

    page: number

    orderProp: string

    reverse: boolean

    toDate: string

    totalItems: number

    datePipe: DatePipe

    constructor(
        private readonly auditsService: AuditsService,
        private readonly parseLinks: JhiParseLinks
    ) {
        this.itemsPerPage = ITEMS_PER_PAGE
        this.page = 1
        this.reverse = false
        this.orderProp = 'timestamp'
        this.datePipe = new DatePipe('en')
    }

    getAudits() {
        return this.sortAudits(this.audits)
    }

    loadPage(page: number) {
        this.page = page
        this.onChangeDate()
    }

    ngOnInit() {
        this.setDateRange()
        this.onChangeDate()
    }

    onChangeDate() {
        this.auditsService.query({page: this.page - 1, size: this.itemsPerPage,
            fromDate: this.fromDate, toDate: this.toDate}).subscribe((res) => {

            this.audits = res.body
            this.links = this.parseLinks.parse(res.headers.get('link'))
            this.totalItems = + res.headers.get('X-Total-Count')
        })
    }

    setDateRange() {
        const dateFormat = 'yyyy-MM-dd'
        // Today + 1 day - needed if the current day must be included
        const today: Date = new Date()
        today.setDate(today.getDate() + 1)
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        this.toDate = this.datePipe.transform(date, dateFormat)

        today.setDate(date.getDate() - 4)
        const date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        this.fromDate = this.datePipe.transform(date2, dateFormat)
    }

    private sortAudits(audits: Audit[]) {
        audits = audits.slice(0).sort((a, b) => {
            if (a[this.orderProp] < b[this.orderProp]) {
                return -1
            } else if ([b[this.orderProp] < a[this.orderProp]]) {
                return 1
            } else {
                return 0
            }
        })

        return this.reverse ? audits.reverse() : audits
    }
}
