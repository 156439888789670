import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiEventManager } from 'ng-jhipster'
import { ReleasePopupService } from './release-popup.service'
import { ReleaseService } from '@app/data-access'

@Component({
    templateUrl: './unpublished-releases-delete-dialog.component.html'
})
export class UnpublishedReleasesDeleteDialogComponent {

    public disabled = false

    constructor(private readonly releaseService: ReleaseService,
                public activeModal: NgbActiveModal,
                private readonly eventManager: JhiEventManager) {
    }

    clear() {
        this.activeModal.dismiss('cancel')
    }

    confirmDelete() {
        this.disabled = true
        this.releaseService.deleteUnpublishedReleases().subscribe((response) => {
            this.disabled = false
            this.eventManager.broadcast('releaseListModification')
            this.activeModal.dismiss(true)
        }, (response) => {
                this.activeModal.dismiss(true)
            }
        )
    }
}

@Component({
    template: ''
})
export class UnpublishedReleaseDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any

    constructor(private readonly route: ActivatedRoute,
                private readonly releasePopupService: ReleasePopupService) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.releasePopupService
                .open(UnpublishedReleasesDeleteDialogComponent as Component)
        })
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe()
    }
}
