import { JhipsterSharedModule } from '../shared/shared.module'
import { OAUTH2_ROUTE } from './oauth2.route'
import { RouterModule } from '@angular/router'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { OAuth2Component } from './oauth2.component'
import { OAuth2Service } from '../auth/oauth2.service'

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild([OAUTH2_ROUTE])
    ],
    declarations: [
        OAuth2Component,
    ],
    entryComponents: [],
    providers: [
        OAuth2Service
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OAuth2Module {
}
