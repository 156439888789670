import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '../../app.constants'

import { createHttpParams, ResponseWrapper } from '../common'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'
import { FaqCategory } from './faq-category.model'

@Injectable()
export class FaqCategoryService implements EntityService<FaqCategory> {

    private readonly resourceUrl = SERVER_API_URL + 'api/faq-category'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/faq-category'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(faqCategory: FaqCategory): Observable<FaqCategory> {
        const copy = this.convert(faqCategory)
        return this.httpClient.post<FaqCategory>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(faqCategory: FaqCategory): Observable<FaqCategory> {
        const copy = this.convert(faqCategory)
        return this.httpClient.put<FaqCategory>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<FaqCategory> {
        return this.httpClient.get<FaqCategory>(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get<FaqCategory[]>(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<FaqCategory[]>(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to faqCategory.
     */
    private convertItemFromServer(json: any): FaqCategory {
        const entity: FaqCategory = Object.assign(new FaqCategory(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }

    /**
     * Convert a FaqCategory to a JSON which can be sent to the server.
     */
    private convert(faqCategory: FaqCategory): FaqCategory {
        const copy: FaqCategory = Object.assign({}, faqCategory)
        copy.name = copy.name.trim()
        return copy
    }
}
