import { Arrangeable, BaseEntity, ConstructionType, Linkable } from '../common'
import { Changelog } from './changelog.model'
import { ChangeStatus } from './change-status.model'
import { Content } from '../content'
import { Country } from '../country'
import { Material } from '../material'
import { Room } from '../room'
import { Craft } from '../craft'
import { MaterialGroup } from '../material-group'
import { MaterialSubGroup } from '../material-sub-group'
import { Attachment } from '../attachment'
import { ChangeType } from './change-type.model'
import { CostCharacteristic } from '../changelog/cost-characteristic.model'
import { ChangeTag } from '../changelog/change-tag.model'

export class Change implements BaseEntity, Arrangeable, Linkable {
    constructor(
        public id?: number,
        public order?: number,
        public shortDescription?: Content,
        public changelog?: Changelog,
        public status?: ChangeStatus,
        public type?: ChangeType,
        public startDate?: string,
        public completionDate?: string,
        public currentStatusDescription?: string,
        public responsibleEditor?: string,
        public source?: string,
        public contactPerson?: string,
        public proposingCountry?: Country,
        public tags?: ChangeTag[],
        public cost?: CostCharacteristic,
        public costCharacteristic?: string,
        public calculationApproach?: string,
        public constructionType?: ConstructionType,
        public countries?: Country[],
        public materials?: Material[],
        public rooms?: Room[],
        public crafts?: Craft[],
        public materialGroups?: MaterialGroup[],
        public materialSubGroups?: MaterialSubGroup[],
        public attachments?: Attachment[],
        public booklet?: boolean,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }

    getLabelForArrangeableList(): string {
        if (!this.shortDescription.defaultTranslation) {
            return ''
        }
        return this.shortDescription.defaultTranslation.textContent
    }

    getMainEditLink(): string | null {
        if (!this.id || !this.changelog.id) {
            return null
        }

        return `/changelog/${this.changelog.id}/change/${this.id}/edit`
    }
}
