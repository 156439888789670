import { Component, OnDestroy, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiEventManager } from 'ng-jhipster'
import { ActivatedRoute, Router } from '@angular/router'
import { ChangePopupService } from './change-popup.service'
import { Change, ChangeService } from '@app/data-access'

@Component({
    selector: 'jhi-change-delete-dialog',
    templateUrl: './change-delete-dialog.component.html'
})
export class ChangeDeleteDialogComponent {

    change: Change

    constructor(
        private readonly changeService: ChangeService,
        public activeModal: NgbActiveModal,
        private readonly eventManager: JhiEventManager,
        private readonly router: Router
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel')
    }

    confirmDelete(id: number) {
        this.changeService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'changeListModification',
                content: 'Deleted a change'
            })
            this.activeModal.dismiss(true)
        })
        setTimeout(() => {
            this.router.navigate([`changelog/${this.change.changelog.id}/change`])
        }, 0)
    }

}

@Component({
    selector: 'jhi-change-delete-popup',
    template: ''
})
export class ChangeDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any

    constructor(
        private readonly route: ActivatedRoute,
        private readonly changePopupService: ChangePopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.changePopupService
                .open(ChangeDeleteDialogComponent as Component, params['id'])
        })
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe()
    }
}
