import { Tag } from '../tag'

export class ChangeTag implements Tag {
    constructor(
        public id?: number,
        public name?: string,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }
}
