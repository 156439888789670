import { Component } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { SearchengineReindexModalComponent } from './searchengine-reindex-modal.component'

@Component({
    selector: 'jhi-searchengine-reindex',
    templateUrl: './searchengine-reindex.component.html'
})
export class SearchengineReindexComponent {

    constructor(
        private readonly modalService: NgbModal
    ) { }

    showConfirm() {
        this.modalService.open(SearchengineReindexModalComponent)
    }
}
