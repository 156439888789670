import { BaseEntity } from '../common'
import { RoomGroup } from '../room-group/room-group.model'
import { RoomAttribute } from './'
import { Content } from '../content'
import { BuildingType } from '../building-type'
import { Attachment } from '../attachment'
import { Arrangeable } from '../common/arrangeable'
import { Linkable } from '../common/linkable'

export class Room implements BaseEntity, Arrangeable, Linkable {
    constructor(public id?: number,
                public order?: number,
                public name?: string,
                public description?: string,
                public number?: string,
                public roomGroup?: RoomGroup,
                public roomAttributes?: RoomAttribute[],
                public version?: number,
                public content?: Content,
                public buildingTypes?: BuildingType[],
                public linkedAttachments?: Attachment[],
                public contents?: Content[],
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string,
                public deletable?: boolean) {
        if (!this.content) {
            this.content = new Content()
        }

        if (!this.roomAttributes) {
            this.roomAttributes = []
        }
    }

    get allContents(): Content[] {
        return (Object as any).values(this.contents)
            .reduce((accumulator, contents) => accumulator.concat(contents), [])
    }

    getLabelForArrangeableList(): string {
        return `${this.number} ${this.name}`
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/room/${this.id}/edit`
    }
}
