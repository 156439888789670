import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OAuth2Service, Roles } from '@app/auth'
import {
    Changelog,
    ChangelogService, ExportStatus, Release,
    ReleaseContext,
    ReleaseContextService,
    ReleaseService,
    RenderedBook,
    RenderedBookService,
    RenderingStatus
} from '@app/data-access'
import { FlagUtil } from '@app/util/flag.util'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiAlertService, JhiEventManager } from 'ng-jhipster'
import { merge, Observable, of, Subject, Subscription, timer } from 'rxjs'
import { auditTime, exhaustMap, filter, retryWhen, switchMap, take, takeUntil } from 'rxjs/operators'
import { ConstructionType } from '../../data-access/common'
import { genericRetryStrategy, WebsocketService } from '../../shared'
import { ConsumerViewConfig } from '../../shared/config/consumer-view/consumer-view.config'
import { ReleaseModalComponent } from './release-modal.component'

@Component({
    selector: 'jhi-release-dialog',
    templateUrl: './release-dialog.component.html',
    styleUrls: ['release-dialog.component.scss']
})
export class ReleaseDialogComponent implements OnInit, OnDestroy {

    public FlagUtil = FlagUtil

    eventSubscriber: Subscription

    saveEventSubscriber: Subscription

    public UPDATE_DELAY = 1000

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    private readonly cancelledPolling: Subject<boolean> = new Subject()

    private refreshTimer = timer(0, 10000)

    public webviewPreviewUrl

    hasLoaded = false

    isRendering = false

    isNew = false

    isSaving = false

    isDisabled = true

    release: Release

    changelogs: Changelog[]

    releaseContext: ReleaseContext

    currentStringDate: string

    modernizationReferences: RenderedBook[] = []

    constructionReferences: RenderedBook[] = []

    hasEditPermissions: boolean

    hasUnpublishPermissions: boolean

    constructor(private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly jhiAlertService: JhiAlertService,
        private readonly modalService: NgbModal,
        private readonly releaseService: ReleaseService,
        private readonly eventManager: JhiEventManager,
        private readonly oAuth2Service: OAuth2Service,
        private readonly releaseContextService: ReleaseContextService,
        private readonly changelogService: ChangelogService,
        private readonly renderedBookService: RenderedBookService,
        private readonly consumerViewConfig: ConsumerViewConfig,
        private readonly websocketService: WebsocketService) {
    }

    ngOnInit() {
        this.currentStringDate = new Date().toISOString().substring(0, 10)
        this.changelogs = []
        this.hasLoaded = false

        this.consumerViewConfig.getPreviewUrl().subscribe((res) => {
            this.webviewPreviewUrl = res
        })

        this.route
            .params
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((params) => {
                if (params['id']) {
                    this.isRendering = true
                    const updates$ = this.websocketService.subscribeToReleaseUpdates(params['id']).pipe(auditTime(this.UPDATE_DELAY))

                    merge(of('initial fetching'), updates$)
                        .pipe(
                            exhaustMap(() => this.releaseService.find(params['id'])
                                .pipe(
                                    retryWhen(
                                        genericRetryStrategy({
                                            excludedStatusCodes: [400, 401, 403, 404],
                                            maxRetryAttempts: 10,
                                            scalingDuration: 5000
                                        })
                                    )
                                )
                            ),
                            takeUntil(this.cancelledPolling),
                            takeUntil(this.componentDestroyed),
                        )
                        .subscribe((release) => {
                            this.setRelease(release)
                        }, (error) => this.onError(error)
                        )

                    this.releaseContextService.find(params['releaseContextId'])
                        .pipe(takeUntil(this.componentDestroyed))
                        .subscribe((releaseContext) => {
                            this.releaseContext = releaseContext
                            this.hasLoaded = true
                            this.loadChangelogs()
                        })
                } else {
                    this.releaseContextService.find(params['releaseContextId'])
                        .pipe(takeUntil(this.componentDestroyed))
                        .subscribe((releaseContext) => {
                            this.release = new Release()
                            this.release.releaseContext = releaseContext
                            this.release.publicationDate = this.currentStringDate
                            this.release.webOnly = false
                            this.releaseContext = releaseContext
                            this.loadChangelogs()
                            this.isNew = true
                            this.hasLoaded = true
                        })
                }
            }, () => {
                return this.router.navigateByUrl('not-found', { skipLocationChange: true })
            })

        this.route
            .params
            .pipe(
                filter((params) => !!params.releaseContextId),
                switchMap((params) => this.renderedBookService.findAllPublishedByReleaseContextId(params['releaseContextId'])),
                takeUntil(this.componentDestroyed)
            )
            .subscribe((renderedBooks) => {
                this.modernizationReferences = []
                this.constructionReferences = []
                renderedBooks.forEach((rb) => {
                    if (!this.isNew && this.release && this.release.webOnly === true) {
                        rb.renderingStatus = RenderingStatus.NOT_GENERATING
                    }
                    if (rb.constructionType === ConstructionType.MODERNIZATION) {
                        this.modernizationReferences.push(rb)
                    } else {
                        this.constructionReferences.push(rb)
                    }
                })
            })

        this.registerChangeInRelease()
        this.registerChangeInStatus()

        this.oAuth2Service.hasAnyAuthority(Roles.INT_EDITOR_AND_PUBLISHER).pipe(take(1)).subscribe((hasEditPermissions: boolean) => {
            this.hasEditPermissions = hasEditPermissions
        })

        this.oAuth2Service.hasAnyAuthority([Roles.ADMIN, Roles.SUPERADMIN]).pipe(take(1)).subscribe((hasUnpublishPermissions: boolean) => {
            this.hasUnpublishPermissions = hasUnpublishPermissions
        })
    }

    private loadChangelogs() {
        this.changelogService.findAllByBuildingTypeIds([this.releaseContext.project.buildingType.id], true, {
            sort: ['id', 'desc']
        })
            .subscribe((changelogs) => {
                this.changelogs = changelogs
                if (this.isNew && this.changelogs.length) {
                    this.release.changelog = this.changelogs[0]
                }
            })
    }

    private setRelease(release: Release) {
        this.release = release
        if (this.release.publicationDate === null) {
            this.release.publicationDate = release.createdAt.substr(0, 10)
        }
        this.hasLoaded = true
        if (this.numNonFinished === 0) {
            this.cancelledPolling.next(true)
            this.cancelledPolling.complete()
            this.isDisabled = false
            this.isRendering = false
        }
        // Check if the release is published, because it should be disabled
        if (this.release.published) {
            this.isDisabled = true
        }
    }

    onPreviewChange(val: boolean) {
        if (!val) {
            this.release.skipAttachments = false
        }
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
    }

    public isEditor() {
        return this.oAuth2Service.hasAnyAuthority(Roles.EDITOR_AND_PUBLISHER)
    }

    public isAdmin() {
        return this.oAuth2Service.hasAnyAuthority(Roles.ADMIN)
    }

    public isPublisher() {
        return this.oAuth2Service.hasAnyAuthority(Roles.PUBLISHER)
    }

    public isPublishingEnabled() {
        return this.isAdmin() || (!this.release.preview && this.isPublisher())
    }

    get numNonFinished() {

        return this.release.renderedBooks.reduce((acc: number, renderedBook: RenderedBook) => {

            const pdfInProgress = [RenderingStatus.WAITING, RenderingStatus.PROCESSING, RenderingStatus.SENT_TO_RENDERER].includes(renderedBook.renderingStatus)
            const webviewRenderingInProgress = [RenderingStatus.WAITING, RenderingStatus.PROCESSING].includes(renderedBook.webviewStatus)
            const webviewFailed = renderedBook.webviewStatus === RenderingStatus.FAILED
            const webviewExportInProgress = !webviewFailed && [ExportStatus.WAITING, ExportStatus.SENDING].includes(renderedBook.exportStatus)

            if (pdfInProgress || webviewRenderingInProgress || webviewExportInProgress) {
                return acc + 1
            } else {
                return acc
            }
        }, 0)
    }

    gotoConsumerViewPreview($event, renderedBook: RenderedBook) {
        $event.preventDefault()
        // tslint:disable-next-line:max-line-length
        const previewUrl = `${this.webviewPreviewUrl}${this.release.releaseContext.project.urlFragment}/${this.release.releaseContext.language.urlFragment}/${renderedBook.constructionType === ConstructionType.CONSTRUCTION ? 'construction' : 'modernization'}?releaseId=${this.encodeUrlBase64(renderedBook.consumerViewReleaseId.toString())}`
        window.open(previewUrl, '_blank')

    }

    showLog(renderedBookId: number) {
        this.router.navigate(['../renderedBook/' + renderedBookId + '/log'], { relativeTo: this.route })
    }

    promptSave() {
        if (this.release.published) {
            this.modalService.open(ReleaseModalComponent)
        } else {
            this.save()
        }
    }

    save() {
        this.isSaving = true
        if (this.release.id !== undefined) {
            this.subscribeToSaveResponse(
                this.releaseService.update(this.release))
        } else {
            this.subscribeToSaveResponse(
                this.releaseService.create(this.release))
        }
    }

    reupload(renderedBook: RenderedBook) {
        const bookPolling: Subject<boolean> = new Subject()
        renderedBook.exportStatus = ExportStatus.SENDING
        this.renderedBookService.reupload(renderedBook.id)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                const id = this.route.snapshot.params['id']
                if (id) {
                    this.refreshTimer
                        .pipe(
                            exhaustMap((value) => this.releaseService.find(id)
                                .pipe(
                                    retryWhen(
                                        genericRetryStrategy({
                                            excludedStatusCodes: [400, 401, 403, 404],
                                            maxRetryAttempts: 10,
                                            scalingDuration: 5000
                                        })
                                    )
                                )
                            ),
                            takeUntil(bookPolling),
                            takeUntil(this.componentDestroyed),
                        )
                        .subscribe((release) => {
                            const newExportStatus = release.renderedBooks.find((updatedBook) => updatedBook.id === renderedBook.id).exportStatus
                            renderedBook.exportStatus = newExportStatus
                            if (newExportStatus === ExportStatus.SENT || newExportStatus === ExportStatus.FAILED) {
                                bookPolling.next(true)
                                bookPolling.complete()
                            }
                        }, (error) => {
                            this.onError(error)
                            bookPolling.next(true)
                            bookPolling.complete()
                        }
                        )
                }
            })
    }

    private subscribeToSaveResponse(result: Observable<Release>) {
        result.pipe(
            takeUntil(this.componentDestroyed))
            .subscribe((res: Release) =>
                this.onSaveSuccess(res),
                (error) => {
                },
                () => this.completeSaving())
    }

    private onSaveSuccess(result: Release) {
        this.setRelease(result)

        this.isSaving = false
        if (this.isNew) {
            this.router.navigate(['../release/' + result.id + '/edit'], { relativeTo: this.route })
        }
    }

    private completeSaving() {
        this.isSaving = false
    }

    goToReleaseContextListPage() {
        setTimeout(() => {
            this.router.navigate(['../../../edit'], { relativeTo: this.route })
        }, 0)
    }

    registerChangeInRelease() {
        this.eventSubscriber = this.eventManager.subscribe('releaseDeleted', (response) => this.goToReleaseContextListPage())
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()

        this.cancelledPolling.next(true)
        this.cancelledPolling.complete()

        if (typeof this.eventSubscriber !== 'undefined') this.eventManager.destroy(this.eventSubscriber)
        if (typeof this.saveEventSubscriber !== 'undefined') this.eventManager.destroy(this.saveEventSubscriber)

    }

    registerChangeInStatus() {
        this.saveEventSubscriber = this.eventManager.subscribe('statusChangedToPublish', (response) => {
            this.isDisabled = true
            this.save()
        }
        )
    }

    publish(id: number) {
        const renderedBook = this.release.renderedBooks.find((rb) => rb.id === id)
        this.renderedBookService.publish(renderedBook.id).pipe(takeUntil(this.componentDestroyed)).subscribe((res) => {
            renderedBook.published = true
            this.release.published = true
            this.isDisabled = true
        }, (error) => this.onError(error))
    }

    unpublish(id: number) {
        console.log('UNPUBLISH BOOK', id)
        const renderedBook = this.release.renderedBooks.find((rb) => rb.id === id)
        this.renderedBookService.unpublish(renderedBook.id).pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
            renderedBook.published = false
            this.release.published = this.release.constructionReference?.published || this.release.modernizationReference?.published
            this.isDisabled = true
        }, (error) => this.onError(error))
    }

    public encodeUrlBase64(value: string): string {
        return encodeURIComponent(btoa(value))
    }
}
