<div>
    <h2>
        <span jhiTranslate="health.title" id="health-title">Health Checks</span>
        <button class="btn btn-primary float-right" (click)="refresh()">
            <fa-icon icon="sync"></fa-icon> <span jhiTranslate="health.refresh.button">Refresh</span>
        </button>
    </h2>
    <div class="table-hover">
        <table id="healthCheck" class="table table-striped" aria-describedby="health-title">
            <thead>
                <tr>
                    <th jhiTranslate="health.table.service" scope="col">Service Name</th>
                    <th class="text-center" jhiTranslate="health.table.status" scope="col">Status</th>
                    <th class="text-center" jhiTranslate="health.details.details" scope="col">Details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let health of healthData">
                    <td>{{'health.indicator.' + baseName(health.name) | translate}} {{subSystemName(health.name)}}</td>
                    <td class="text-center">
                        <span class="badge" [ngClass]="getBadgeClass(health.status)" jhiTranslate="{{'health.status.' + health.status}}">
                            {{health.status}}
                        </span>
                    </td>
                    <td class="text-center">
                        <a class="hand" (click)="showHealth(health)" *ngIf="health.details || health.error">
                            <fa-icon icon="eye"></fa-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
