import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'

import { RouterModule } from '@angular/router'
import { NotFoundComponent } from './not-found.component'
import { notFoundRoute } from './not-found.route'
import { JhipsterSharedModule } from '../shared/shared.module'

const ROUTES = [
    ...notFoundRoute
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ROUTES)
    ],
    declarations: [NotFoundComponent],
    providers: [],
    exports: [NotFoundComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class NotFoundModule {
}
