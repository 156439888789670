import { BaseEntity, ConstructionType, WorkflowStatus } from '../common'
import { Translation } from '../translation'
import { Language } from '../language'
import { Attachment } from '../attachment'
import { BuildingType } from '../building-type'
import { Country } from '../country'
import { ContentRenderScope } from '../common/content-render-scope.model'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'
import { MaterialCategory } from '../material-category'

export const enum ContentType {
    FILE = 'FILE',
    TEXT = 'TEXT',
    ATTACHMENT_LINK = 'ATTACHMENT_LINK',
    ROOMBOOK = 'ROOMBOOK',
    PRODUCT_LIST = 'PRODUCT_LIST',
    CHANGELOG = 'CHANGELOG'
}

export const enum ComponentType {
    BASIC = 'BASIC',
    EXTENDED = 'EXTENDED'
}

export const enum ImplementationType {
    IMPLEMENTATION_TYPE_1 = 'IMPLEMENTATION_TYPE_1',
    IMPLEMENTATION_TYPE_2 = 'IMPLEMENTATION_TYPE_2',
    IMPLEMENTATION_TYPE_3 = 'IMPLEMENTATION_TYPE_3',
    IMPLEMENTATION_TYPE_4 = 'IMPLEMENTATION_TYPE_4',
    IMPLEMENTATION_TYPE_5 = 'IMPLEMENTATION_TYPE_5',
    IMPLEMENTATION_TYPE_6 = 'IMPLEMENTATION_TYPE_6',
    IMPLEMENTATION_TYPE_7 = 'IMPLEMENTATION_TYPE_7',
    IMPLEMENTATION_TYPE_8 = 'IMPLEMENTATION_TYPE_8',
    IMPLEMENTATION_TYPE_9 = 'IMPLEMENTATION_TYPE_9',
    IMPLEMENTATION_TYPE_10 = 'IMPLEMENTATION_TYPE_10',
}

export class Content implements BaseEntity {
    constructor(
        public id?: number,
        public order?: number,
        public contentType?: ContentType,
        public constructionType?: ConstructionType,
        public translations?: Translation[],
        public linkedAttachment?: Attachment,
        public buildingType?: BuildingType,
        public buildingTypes?: BuildingType[],
        public componentType?: ComponentType,
        public implementationType?: ImplementationType,
        public materialCategory?: MaterialCategory,
        public countries?: Country[],
        public createCountry?: Country,
        public workflowStatus?: WorkflowStatus,
        public renderScope?: ContentRenderScope,
        public mapped?: boolean,
        public createdAt?: string,
        public updatedAt?: string,
    ) {
        if (!translations) {
            this.translations = []
        }

        if (!buildingTypes) {
            this.buildingTypes = []
        }

        if (!countries) {
            this.countries = []
        }

        // By default, all new contents should have CONSTRUCTION_MODERNIZATION
        if (constructionType === null || constructionType === undefined) {
            this.constructionType = ConstructionType.CONSTRUCTION_MODERNIZATION
        }

        if (this.createCountry?.code) {
            this.createCountry.code = this.createCountry.code.toLowerCase()
        }
    }

    public get defaultTranslation(): Translation {
        return this.translations.find((translation: Translation) => translation.language.locale === DefaultLocaleSingleton.instance.defaultLocale)
    }

    public translationForLanguage(language: Language): Translation {
        return this.translations.find((translation: Translation) => {
            return translation.language && translation.language.id === language.id
        })
    }

    public textContentInLanguage(language: Language) {
        const translationForLanguage = this.translationForLanguage(language)

        if (translationForLanguage) {
            return translationForLanguage.textContent
        } else {
            return '[missing translation]'
        }
    }
}
