import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router'
import { OAuth2Service } from '@app/auth'
import { WebsocketService } from '@app/shared'
import { JhiLanguageService } from 'ng-jhipster'
import { JhiLanguageHelper } from '../../shared'
import { LanguageChoiceService } from '../../shared/language/language-choice.service'


@Component({
    selector: 'jhi-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class JhiMainComponent implements OnInit, OnDestroy {

    isIE: boolean

    constructor(
        private readonly jhiLanguageHelper: JhiLanguageHelper,
        private readonly jhiLanguageService: JhiLanguageService,
        private readonly languageChoiceService: LanguageChoiceService,
        private readonly router: Router,
        private readonly websocketService: WebsocketService,
        private readonly oauth2Service: OAuth2Service,
    ) { }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        this.isIE = window.navigator.userAgent.toLowerCase().indexOf('trident') > -1
        let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : ''
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title
        }
        return title
    }

    ngOnInit() {
        this.oauth2Service.initOAuth()

        this.oauth2Service.authenticationState.subscribe((authenticationState: boolean) => {
            if (authenticationState) {
                this.websocketService.connect()
            }
        })

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.jhiLanguageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root))
            }
        })

        const preferredLanguage = this.languageChoiceService.getPreferredLanguage()
        this.jhiLanguageService.changeLanguage(preferredLanguage)
    }

    ngOnDestroy() {
        this.websocketService.disconnect()
    }
}
