import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { FileContentWrapper } from '../file'
import { HttpClient, HttpParams } from '@angular/common/http'
import { RenderedBook } from './rendered-book.model'

@Injectable()
export class RenderedBookService {

    private readonly resourceUrl = SERVER_API_URL + 'api/renderedBooks'

    private readonly fileContentUrl = this.resourceUrl + '/file'

    private readonly webviewZipUrl = this.resourceUrl + '/webview'

    constructor(private readonly httpClient: HttpClient) {
    }

    getBookPdf(id: number, version: number): Observable<FileContentWrapper> {
        const params = new HttpParams().set('version', String(version))
        return this.httpClient.get(`${this.fileContentUrl}/${id}`, {
            params,
            observe: 'response',
            responseType: 'blob'
        }).pipe(
            map((res) => {
                return new FileContentWrapper(res.headers.get('Content-Type'), res.body, res.status)
            }))
    }

    getWebviewJson(id: number, version: number): Observable<FileContentWrapper> {
        const params = new HttpParams().set('version', String(version))
        return this.httpClient.get(`${this.webviewZipUrl}/${id}`, {
            params,
            observe: 'response',
            responseType: 'blob'
        }).pipe(
            map((res) => {
                return new FileContentWrapper(res.headers.get('Content-Type'), new Blob([res.body], {type: 'text/json; charset=utf-8'}), res.status)
            }))
    }

    publish(id: number): Observable<RenderedBook> {
        return this.httpClient.put(`${this.resourceUrl}/${id}/publish`, {}).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    unpublish(id: number): Observable<any> {
        return this.httpClient.put(`api/books/${id}/unpublish`, {})
    }

    reupload(id: number): Observable<RenderedBook> {
        return this.httpClient.post(`${this.resourceUrl}/${id}/reupload`, {}).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<RenderedBook> {
        return this.httpClient.get<RenderedBook>(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    findAllPublishedByReleaseContextId(releaseContextId: number): Observable<RenderedBook[]> {
        return this.httpClient.get<RenderedBook[]>(`${this.resourceUrl}/releaseContext/${releaseContextId}`)
    }

    /**
     * Convert a returned JSON object to RenderedBook.
     */
    private convertItemFromServer(json: any): RenderedBook {
        const entity: RenderedBook = Object.assign(new RenderedBook(), json)
        return entity
    }
}
