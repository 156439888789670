<form name="release-form" role="form" novalidate #newReleaseForm="ngForm" class="form-horizontal" *ngIf="hasLoaded">

    <div class="row">
        <div class="col-md-10">
            <h2>
                <span jhiTranslate="kstandards.release.home.editLabel" *ngIf="!isNew" [translateValues]="{id:  release?.id }">Edit
                    release {{ release?.id }}</span>
                <span jhiTranslate="kstandards.release.home.createLabel" [translateValues]="{name: release?.project?.name }" *ngIf="isNew">Create
                    a release {{ release?.project?.name }}</span>
                <span *ngIf="release?.deletedAt" class="badge badge-warning" jhiTranslate="entity.isDeactivatedSimple">deactivated</span>
            </h2>
            <div *ngIf="releaseContext" class="metadata">
                <div>
                    <span jhiTranslate="kstandards.releaseContext.project">Project</span>:
                    <a [routerLink]="['/project/' + releaseContext?.project?.id + '/edit']" [class.disabled]="!hasEditPermissions">{{
                        releaseContext?.project?.name }}</a>
                </div>
                <div>
                    <span jhiTranslate="kstandards.releaseContext.constructionType"></span>:
                    <span>{{ 'kstandards.ConstructionType.' + releaseContext?.project?.constructionType |
                        translate}}</span>
                </div>
                <div>
                    <span jhiTranslate="kstandards.releaseContext.country">Country</span>:
                    <span>{{ releaseContext?.project?.country?.name }}</span>
                    <span class="flag-icon flag-icon-{{ FlagUtil.getFlag(releaseContext?.project?.country?.code) }}"></span>
                </div>
                <div>
                    <span jhiTranslate="kstandards.releaseContext.language">Language</span>: {{
                    releaseContext?.language?.name | titlecase }}
                </div>
                <div>
                    <span jhiTranslate="kstandards.release.releaseYear"></span>:
                    <span>{{ release?.releaseYear ? release?.releaseYear : release?.project?.projectYear}}</span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-control-label" jhiTranslate="kstandards.release.name" for="field_name">Release
                    Name</label>
                <input type="text" class="form-control" name="name" id="field_name" [disabled]="!isEditor() || (isDisabled && !isNew) || isRendering" [(ngModel)]="release.name" required>
            </div>
            <div class="form-group">
                <label class="form-control-label" jhiTranslate="kstandards.release.publicationDate" for="field_publication_date">Publication date</label>
                <input type="date" class="form-control" name="publication-date" id="field_publication_date" [disabled]="!isEditor() || !isNew || isRendering" [(ngModel)]="release.publicationDate" required>
            </div>
            <div class="select-dropdown" *ngIf="changelogs.length">
                <label for="field_changelog" class="form-control-label">{{'kstandards.changelog.home.title' |
                    translate}}</label>
                <select class="form-control custom-select" id="field_changelog" name="changelog" [(ngModel)]="release.changelog" [disabled]="!isEditor() || (isDisabled && !isNew) || isRendering">
                    <option [ngValue]="null"></option>
                    <option [ngValue]="changelog.id === release?.changelog?.id ? release?.changelog : changelog" *ngFor="let changelog of changelogs;">
                        {{changelog.name}}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="release.preview" (ngModelChange)="onPreviewChange($event)" name="preview" id="field_preview" [disabled]="!isNew">
                    <label class="form-control-label" jhiTranslate="kstandards.release.preview" for="field_preview">Preview</label>
                </div>
            </div>

            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="release.skipAttachments" name="skipAttachments" id="field_skipAttachments" [disabled]="!isNew || !release?.preview">
                    <label class="form-control-label" jhiTranslate="kstandards.release.skipAttachments" for="field_skipAttachments">Skip attachments</label>
                </div>
            </div>

            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="release.debugMode" name="debug-mode" id="field_debugMode" [disabled]="!isNew">
                    <label class="form-control-label" jhiTranslate="kstandards.release.debugMode" for="field_debugMode">Preserve information for developers</label>
                </div>
            </div>

            <div class="form-group">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" (onChange)="release.webOnly = !release.webOnly" [(ngModel)]="release.webOnly" name="webOnly" id="field_webOnly" [disabled]="!isNew">
                    <label class="custom-control-label" for="field_webOnly" jhiTranslate="kstandards.release.{{release.webOnly? 'webOnly':'pdfWeb'}}">Release only Web</label>
                </div>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="release.additionalUpdate" name="additional-update" id="field_additionalUpdate" [disabled]="!isNew && (isSaving || isRendering || isDisabled || !isEditor())">
                    <label class="form-control-label" jhiTranslate="kstandards.release.additionalUpdateCheckbox" for="field_additionalUpdate"></label>
                </div>
            </div>

            <h3 *ngIf="constructionReferences.length || modernizationReferences.length" jhiTranslate="kstandards.release.home.editReferenceLabel">Edit References</h3>
            <div class="form-group" *ngIf="constructionReferences.length && (releaseContext?.project?.constructionType == 'CONSTRUCTION' || releaseContext?.project?.constructionType == 'CONSTRUCTION_MODERNIZATION')">
                <label class="form-control-label" jhiTranslate="kstandards.releaseContext.referenceBook.construction" for="field_construction_references">Construction Books</label>
                <select required class="custom-select rendered-books-select" id="field_construction_references" name="construction_references" [(ngModel)]="release.constructionReference" [disabled]="!isNew">
                    <option [ngValue]="null"></option>
                    <option [ngValue]="constructionReference.id === release?.constructionReference?.id ? release?.constructionReference : constructionReference" *ngFor="let constructionReference of constructionReferences" [title]="'kstandards.release.home.referenceTooltip' | translate">
                        <span *ngIf="!constructionReference.published">[X]</span>
                        {{constructionReference.name + ' - (' + constructionReference.releaseId + ')' + ' - ' +
                        constructionReference.releaseName }}
                    </option>
                </select>
            </div>
            <div class="form-group" *ngIf="modernizationReferences.length && (releaseContext?.project?.constructionType == 'MODERNIZATION' || releaseContext?.project?.constructionType == 'CONSTRUCTION_MODERNIZATION')">
                <label class="form-control-label" jhiTranslate="kstandards.releaseContext.referenceBook.modernization" for="field_modernization_references">Modernization Books</label>
                <select required class="custom-select rendered-books-select" id="field_modernization_references" name="modernization_references" [(ngModel)]="release.modernizationReference" [disabled]="!isNew">
                    <option [ngValue]="null"></option>
                    <option [ngValue]="modernizationReference.id === release?.modernizationReference?.id ? release?.modernizationReference : modernizationReference" *ngFor="let modernizationReference of modernizationReferences" [title]="'kstandards.release.home.referenceTooltip' | translate">
                        <span *ngIf="!modernizationReference.published">[X]</span>
                        {{modernizationReference.name + ' - (' + modernizationReference.releaseId + ')' + ' - ' +
                        modernizationReference.releaseName }}

                    </option>
                </select>
            </div>

            <div class="books" *ngIf="!isNew">
                <h3 jhiTranslate="kstandards.release.books" id="books-title">Books</h3>
                <table class="table table-striped" aria-describedby="books-title">
                    <thead>
                        <tr>
                            <th jhiTranslate="kstandards.release.constructionType" scope="col">Construction type</th>
                            <th jhiTranslate="kstandards.release.renderingStatus" scope="col">Rendering status</th>
                            <th jhiTranslate="kstandards.release.downloadPdf" scope="col">Download PDF</th>
                            <th jhiTranslate="kstandards.release.webviewStatus" scope="col">Webview status</th>
                            <th jhiTranslate="kstandards.release.exportStatus" scope="col">Export status</th>
                            <th jhiTranslate="kstandards.release.preview" scope="col">Preview</th>
                            <th jhiTranslate="kstandards.release.actions.showLog" scope="col">Show Log</th>
                            <th jhiTranslate="kstandards.release.generatedFiles" scope="col">Generated files</th>
                            <th jhiTranslate="kstandards.release.published.label" scope="col">Published</th>
                            <th jhiTranslate="kstandards.release.actions.reupload" scope="col">Reupload</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let renderedBook of release?.renderedBooks">
                            <td>{{ 'kstandards.ConstructionType.' + renderedBook.constructionType | translate }}</td>
                            <td>{{ 'kstandards.release.RenderingStatus.' + renderedBook.renderingStatus | translate }}
                            </td>
                            <td>
                                <a type="button" class="btn btn-secondary" target="_blank" [href]="renderedBook.pdf.presignedUrl" rel="noopener noreferrer" *ngIf="renderedBook.renderingStatus === 'FINISHED'" jhiTranslate="kstandards.release.downloadPdf">Download PDF
                                </a>
                            </td>
                            <td>{{ 'kstandards.release.RenderingStatus.' + renderedBook.webviewStatus | translate }}
                            </td>
                            <td>{{ 'kstandards.release.ExportStatus.' + renderedBook.exportStatus | translate }}</td>
                            <td>
                                <a class="btn btn-secondary" href="{{ webviewPreviewUrl }}{{ release?.releaseContext.project.urlFragment }}/{{ release?.releaseContext.language.urlFragment }}/{{ renderedBook.constructionType === 'CONSTRUCTION' ? 'construction' : 'modernization' }}?releaseId={{ encodeUrlBase64(renderedBook.consumerViewReleaseId)}}" (click)="gotoConsumerViewPreview($event, renderedBook)" *ngIf="renderedBook.exportStatus === 'SENT'" jhiTranslate="kstandards.release.preview">Preview
                                </a>
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary" (click)="showLog(renderedBook.id)" *ngIf="renderedBook.renderingStatus === 'FINISHED' || renderedBook.renderingStatus === 'FAILED' || renderedBook.webviewStatus === 'FINISHED' || renderedBook.webviewStatus === 'FAILED'" jhiTranslate="kstandards.release.actions.showLog">Show Log
                                </button>
                            </td>
                            <td>
                                <a class="btn btn-primary" [routerLink]="['/release-context/'+releaseContext?.id+'/release/'+release?.id+'/renderedBook/'+renderedBook?.id+'/generated-files']" *ngIf="renderedBook.webviewStatus === 'FINISHED' || renderedBook.renderingStatus === 'FINISHED' || renderedBook.renderingStatus === 'FAILED' || renderedBook.renderingStatus === 'SENT_TO_RENDERER'" jhiTranslate="kstandards.release.generatedFiles">Generated files
                                </a>
                            </td>
                            <td>
                                <div *ngIf="(renderedBook?.renderingStatus === 'FINISHED'  || renderedBook?.renderingStatus === 'NOT_GENERATING') && renderedBook.exportStatus === 'SENT'">
                                    <button *ngIf="!renderedBook.published" type="button" [disabled]="!(isPublishingEnabled() | async)" class="btn btn-primary" (click)="publish(renderedBook.id)" jhiTranslate="kstandards.release.actions.publish">
                                        Publish
                                    </button>
                                    <div *ngIf="renderedBook?.published && !hasUnpublishPermissions">&#9989;</div>
                                    <button *ngIf="renderedBook?.published && hasUnpublishPermissions" type="button" class="btn btn-primary" (click)="unpublish(renderedBook.id)" jhiTranslate="kstandards.release.actions.unpublish">
                                        Unpublish
                                    </button>
                                </div>
                            </td>
                            <td>
                                <button type="button" class="btn btn-primary" (click)="reupload(renderedBook)" *ngIf="isAdmin() && !release?.deletedAt" [disabled]="renderedBook.exportStatus !== 'FAILED' || isRendering || isSaving" jhiTranslate="kstandards.release.actions.reupload">Reupload
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="col-md-2 global-buttons">
            <div class="form-group" *ngIf="isNew">
                <button type="button" (click)="save()" [disabled]="newReleaseForm.form.invalid" class="btn btn-special">
                    <icon shape="save" cssClasses="icon-white"></icon>&nbsp;<span jhiTranslate="kstandards.release.actions.create">Create release</span>
                </button>
            </div>

            <div class="form-group" *ngIf="!isNew && !release?.deletedAt">
                <button type="button" (click)="promptSave()" [disabled]="newReleaseForm.form.invalid || isSaving || isRendering || isDisabled || !isEditor()" class="btn btn-special">
                    <icon shape="save" cssClasses="icon-white"></icon>&nbsp;<span jhiTranslate="kstandards.release.actions.update">Save release</span>
                </button>
            </div>
            <div class="form-group" *ngIf="!isNew && !release?.deletedAt">
                <button type="submit" [routerLink]="['/', { outlets: { popup: 'release/' + release?.id + '/delete'} }]" replaceUrl="true" class="btn btn-danger" [disabled]="newReleaseForm.form.invalid || isSaving || isRendering || isDisabled || !isEditor()">
                    <fa-icon icon="times"></fa-icon>
                    <span class="d-none d-md-inline" jhiTranslate="kstandards.release.actions.deleteSingle">Delete
                        Release</span>
                </button>
            </div>
            <a *ngIf="!isNew" class="btn btn-secondary" data-dismiss="modal" [routerLink]="['../../../edit']">
                <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
            </a>
            <a *ngIf="isNew" class="btn btn-secondary" data-dismiss="modal" [routerLink]="['../edit']">
                <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
            </a>
        </div>
    </div>
</form>
