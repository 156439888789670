<div>
    <h2>
        <span jhiTranslate="searchengine.reindex.title">Reindex Elasticsearch</span>
    </h2>

    <p jhiTranslate="searchengine.reindex.instructions1">
        This will send a request to reindex all data from your primary datasource into Elasticsearch. This will fix
        missing data and mapping issues, however you will lose any ES data and mapping that was defined outside of your
        application. This request will return immediately and the reindexing will run in the background. You will see
        "Elasticsearch: Successfully performed reindexing" in your logs when this is complete.
    </p>
    <p jhiTranslate="searchengine.reindex.instructions2">
        This can take a long time and is susceptible to concurrency issues so it should be performed when the number of
        users is low.
    </p>
    <button class="btn btn-primary float-right" (click)="showConfirm()">
        <fa-icon icon="exclamation"></fa-icon> <span jhiTranslate="searchengine.reindex.go">Reindex</span>
    </button>
</div>
