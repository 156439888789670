import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'enumKeyValueList'
})

export class EnumKeyValueListPipe implements PipeTransform {
    transform(value: any, args: any[]): any {
        const items: any[] = []
        for (const key of Object.keys(value)) {
            items.push({key, value: value[key]})
        }
        return items
    }
}
