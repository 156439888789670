import { Injectable } from '@angular/core'

@Injectable({providedIn: 'root'})
export class LocationHandlerService {

    go(url: string) {
        window.location.href = url
    }

}
