import { BaseEntity } from '../common'
import { Project } from '../project'
import { ProjectRelationType } from './project-relation-type'

export class ProductProjectRelation implements BaseEntity {
    constructor(public id?: number,
                public relationType?: ProjectRelationType,
                public project?: Project) {
    }
}
