import { BaseEntity } from '../common'
import { Project } from '../project'
import { Language } from '../language'
import { RenderedBook } from './'
import { ReleaseContext } from '../release-context/release-context.model'
import { Changelog } from '../changelog'

export class Release implements BaseEntity {
    public constructor(
        public id?: number,
        public releaseYear?: number,
        public name?: string,
        public project?: Project,
        public language?: Language,
        public published?: boolean,
        public preview?: boolean,
        public debugMode?: boolean,
        public skipAttachments?: boolean,
        public changelog?: Changelog,
        public renderedBooks?: RenderedBook[],
        public releaseContext?: ReleaseContext,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public additionalUpdate?: boolean,
        public publicationDate?: string,
        public constructionReference?: RenderedBook,
        public modernizationReference?: RenderedBook,
        public webOnly?: boolean
    ) {
    }
}
