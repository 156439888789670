import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Change, Changelog, ChangelogService } from '@app/data-access'
import { Router } from '@angular/router'

@Component({
    selector: 'jhi-change-copy-dialog',
    styleUrls: ['./change-copy-dialog.component.scss'],
    templateUrl: './change-copy-dialog.component.html'
})
export class ChangeCopyDialogComponent implements OnInit{

    change: Change

    changelogs: Changelog[]

    constructor(
        public activeModal: NgbActiveModal,
        public changelogService: ChangelogService,
        public router: Router
    ) {
    }

    changelogSelected(changelog: Changelog): void{
        setTimeout(() => {
            this.router.navigate([`/changelog/${changelog.id}/change-new`],
                { queryParams: { sourceChangeId: this.change.id } })
            }, 0)
        this.clear()
    }

    ngOnInit(): void {
        this.changelogService.query(true, false, {sort: ['id,desc']})
            .subscribe((changelogs) => {
                this.changelogs = changelogs.json
            })
    }

    getQuery() {
        return `NOT id :(${this.change.changelog?.id}) AND status :(OPEN)`
    }

    clear() {
        this.activeModal.dismiss('cancel')
    }
}
