import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiEventManager } from 'ng-jhipster'
import { ReportService } from './report.service'
import { Report } from './report.model'
import { ReportPopupService } from './report-popup.service'

@Component({
    selector: 'jhi-report-delete-dialog',
    templateUrl: './report-delete-dialog.component.html'
})
export class ReportDeleteDialogComponent {

    report: Report
    redirect: boolean

    constructor(private readonly reportService: ReportService,
                public activeModal: NgbActiveModal,
                private readonly eventManager: JhiEventManager) {
    }

    clear() {
        this.activeModal.dismiss('cancel')
    }

    confirmDelete(id: number) {
        this.reportService.delete(id).subscribe((response) => {
                if (this.redirect === true) {
                    this.eventManager.broadcast({
                        name: 'reportDeleted',
                        content: 'Delete a Report'
                    })
                } else {
                    this.eventManager.broadcast({
                        name: 'reportsDeleteByType',
                        content: 'A Report By Type is deleted'
                    })
                }
                this.activeModal.dismiss(true)
            }, (response) => {
                this.activeModal.dismiss(true)
            }
        )
    }
}

@Component({
    selector: 'jhi-report-delete-popup',
    template: ''
})
export class ReportDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any
    queryParamsSub: any

    constructor(private readonly route: ActivatedRoute,
                private readonly reportPopupService: ReportPopupService) {
    }

    ngOnInit() {

        this.routeSub = this.route.params.subscribe((params) => {
            this.reportPopupService
                .open(ReportDeleteDialogComponent as Component, params, this.route.snapshot.queryParams['redirect'])
        })
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe()
    }
}
