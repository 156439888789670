<div>
    <h2>
        <span jhiTranslate="kstandards.release.showLog.title">Show Log</span>
    </h2>

    <div class="row">
        <div class="col-lg-10">


            <ngb-tabset *ngIf="!isLoading">
                <ngb-tab *ngIf="renderedBook?.log" [title]="'kstandards.release.pdfLog' | translate">
                    <ng-template ngbTabContent>
                        <pre>{{renderedBook.log}}</pre>
                    </ng-template>
                </ngb-tab>
                <ngb-tab *ngIf="renderedBook?.webviewLog" [title]="'kstandards.release.webviewLog' | translate">
                    <ng-template ngbTabContent>
                        <pre>{{renderedBook.webviewLog}}</pre>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>

        <div class="col-lg-10" *ngIf="isLoading">
            <fa-icon icon="spinner" [spin]="true"></fa-icon>
        </div>

        <div class="col-lg-2 global-buttons">
            <div class="form-group">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="goBack()">
                    <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
                </button>
            </div>
        </div>
    </div>



</div>
