import { BaseEntity } from '../common'
import { Linkable } from '../common/linkable'
import { Language } from '../language/language.model'

export class Country implements BaseEntity, Linkable {
    constructor(
        public id?: number,
        public name?: string,
        public code?: string,
        public order?: number,
        public mainLanguage?: Language,
        public description?: string,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/country/${this.id}/edit`
    }
}
