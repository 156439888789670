import { Subscription } from 'rxjs'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Report } from './report.model'
import { ResponseWrapper } from '@app/data-access'
import { ActivatedRoute, Router } from '@angular/router'
import { ReportService } from './report.service'
import { JhiAlertService, JhiEventManager } from 'ng-jhipster'
import { BaseReportComponent } from './report-base.component'
import { ReportType } from './report-types.model'
import { BuildingType, BuildingTypeService } from '@app/data-access'

@Component({
    selector: 'jhi-rooms-by-building-type-report',
    templateUrl: './report-rooms-by-building-type-dialog.component.html'
})
export class ReportRoomsByBuildingTypeDialogComponent extends BaseReportComponent implements OnInit, OnDestroy {
    eventSubscriber: Subscription
    buildingTypes: BuildingType[]
    report: Report

    constructor(
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        private readonly buildingTypeService: BuildingTypeService,
        private readonly eventManager: JhiEventManager,
        protected readonly jhiAlertService: JhiAlertService,
        protected readonly reportsService: ReportService) {
        super(route, router, reportsService, jhiAlertService)
    }

    ngOnInit() {
        this.reportType = ReportType.ROOMS_BY_BUILDING_TYPE
        super.ngOnInit()
        this.buildingTypeService.query(false, {sort: ['name,asc']})
            .subscribe((res: ResponseWrapper) => {
                this.buildingTypes = res.json
            })

        this.registerChangeInReport()
    }

    public static trackBuildingTypeById(index: number, item: BuildingType) {
        return item.id
    }

    onSaveSuccess(result: Report) {
        this.report = result
        if (this.isNew) {
            this.router.navigate(['/report/roomsByBuildingType/' + result.id + '/edit'])
        }
    }

    registerChangeInReport() {
        this.eventSubscriber = this.eventManager.subscribe('reportDeleted', (response) => this.goToReportListPage())
    }

    trackBuildingTypeById(index: number, item: BuildingType) {
        return item.id
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
        this.cancelledPolling.next(true)
        this.cancelledPolling.complete()
    }
}
