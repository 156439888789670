import { throwError as observableThrowError, Observable } from 'rxjs'

import { catchError, tap } from 'rxjs/operators'
import { JhiAlertService } from 'ng-jhipster'
import { Injectable, Injector } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

    private alertService: JhiAlertService

    constructor(private readonly injector: Injector) {
        setTimeout(() => this.alertService = this.injector.get(JhiAlertService))
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((res) => {
                if (!(res instanceof HttpResponse) || !this.alertService || req.headers.has('X-Ignore-Alerts')) {
                    return res
                } else {
                    const headers = res.headers.keys().filter(this.filterKey)

                    headers.sort()
                    const alertKey = res.headers.get(headers[0] || '')
                    if (typeof alertKey === 'string') {
                        const alertParam = res.headers.get(headers[1])
                        this.alertService.success(alertKey, {param: alertParam}, null)
                    }

                    return res
                }
            }),
            catchError((error) => {
                return observableThrowError(error)
            }))
    }

    private  filterKey(key: string) {
        return key.toLowerCase().endsWith('app-alert') || key.toLowerCase().endsWith('app-params')
    }
}
