import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { Material } from './material.model'
import { Content } from '../content/content.model'
import { createHttpParams, ResponseWrapper, ConversionUtil } from '../common'
import { HttpClient, HttpParams } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { MaterialSubGroup } from '../material-sub-group'
import { MaterialGroup } from '../material-group'
import { Craft } from '../craft'
import { Product } from '../product/product.model'
import { EntityService } from '../entity'
import { MaterialWithImages } from './material-with-images.model'
import { MaterialProjectRoomRelation } from './material-project-room-relation.model'

@Injectable()
export class MaterialService implements EntityService<Material> {

    private readonly resourceUrl = SERVER_API_URL + 'api/materials'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/materials'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(material: Material): Observable<Material> {
        const copy = this.convert(material)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(material: Material, contentId?: number): Observable<Material> {
        const copy = this.convert(material)
        let params = {}
        if (contentId) {
            params = {contentId}
        }
        return this.httpClient.put(this.resourceUrl, copy, {params}).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<Material> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    copy(id: number): Observable<Material> {
        return this.httpClient.get(`${this.resourceUrl}/${id}/copy`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    findProducts(materialId: number): Observable<Product[]> {
        return this.httpClient.get<Product[]>(`${this.resourceUrl}/${materialId}/products`)
    }

    saveProducts(materialId: number, products: Product[]): Observable<any> {
        return this.httpClient.put(`${this.resourceUrl}/${materialId}/products`, products)
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    getRoomRelations(materialId: number): Observable<MaterialProjectRoomRelation[]> {
        return this.httpClient.get<MaterialProjectRoomRelation[]>(`${this.resourceUrl}/${materialId}/room-relations`).pipe(
            map((mprs) => ConversionUtil.convertRelations(mprs)))
    }

    getCopyRoomRelations(materialId: number): Observable<MaterialProjectRoomRelation[]> {
        return this.httpClient.get<MaterialProjectRoomRelation[]>(`${this.resourceUrl}/${materialId}/room-relations/copy`).pipe(
            map((mprs) => ConversionUtil.convertRelations(mprs)))
    }

    updateRoomRelations(materialId: number, relations: MaterialProjectRoomRelation[]): Observable<MaterialProjectRoomRelation[]> {
        return this.httpClient.put<MaterialProjectRoomRelation[]>(`${this.resourceUrl}/${materialId}/room-relations`, relations)
    }

    findByLabelAndBuildingType(label: string, buildingTypeId: number): Observable<Material[]> {
        const params = new HttpParams().set('buildingTypeId', buildingTypeId.toString(10))
            .set('label', label)
        return this.httpClient.get<Material[]>(`${this.resourceUrl}/filter`, {params})
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchWithImages(query: string, withImages: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query).set('withImages', withImages.toString())
        return this.httpClient.get(this.searchEngineUrl + '/with-images', {params, observe: 'response'}).pipe(
            map((res) => this.convertResponseWithImages(res)))
    }

    searchAutocomplete(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query).set('size', '50')
        return this.httpClient.get(this.searchEngineUrl + '/autocomplete', {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        let response

        if (res.body.length) {
            for (const value of res.body) {
                result.push(this.convertItemFromServer(value))
            }
            response = result
        } else if (res.body.content?.length) {
            for (const value of res.body.content) {
                result.push(this.convertItemFromServer(value))
            }
            response = { ...res.body, content: result }
        }
        return new ResponseWrapper(res.headers, response , res.status)
    }

    private convertResponseWithImages(res): ResponseWrapper {
        const result = []
        if (res.body.length) {
            for (const value of res.body){
                const entity: MaterialWithImages = Object.assign(new MaterialWithImages(), value)
                result.push(entity)
            }
        } else if (res.body.content?.length) {
            for (const value of res.body.content){
                const entity: MaterialWithImages = Object.assign(new MaterialWithImages(), value)
                result.push(entity)
            }
        }
        return new ResponseWrapper(res.headers, { ...res.body, content: result }, res.status)
    }

    /**
     * Convert a returned JSON object to Material.
     */
    private convertItemFromServer(json: any): Material {
        const entity: Material = Object.assign(new Material(), json)

        // In case Search Engine returns null for the contents
        if (!entity.contents) {
            entity.contents = []
        }

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        // Make sure that material.attachments are typed to make sure
        // that custom getters can be called from templates
        entity.contents = entity.contents.map((element) => {
            return Object.assign(new Content(), element)
        })

        if (entity.materialSubGroup) {
            entity.materialSubGroup = Object.assign(new MaterialSubGroup(), entity.materialSubGroup)

            if (entity.materialSubGroup.materialGroup) {
                entity.materialSubGroup.materialGroup = Object.assign(new MaterialGroup(), entity.materialSubGroup.materialGroup)

                if (entity.materialSubGroup.materialGroup.craft) {
                    entity.materialSubGroup.materialGroup.craft = Object.assign(new Craft(), entity.materialSubGroup.materialGroup.craft)
                }
            }

        }

        return entity
    }

    /**
     * Convert a Material to a JSON which can be sent to the server.
     */
    private convert(material: Material): Material {
        const copy: Material = Object.assign({}, material)
        return copy
    }
}
