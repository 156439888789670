// DO NOT EDIT THIS FILE, EDIT THE WEBPACK COMMON CONFIG INSTEAD, WHICH WILL MODIFY THIS FILE
/* @toreplace VERSION */
/* @toreplace DEBUG_INFO_ENABLED */
/* @toreplace SERVER_API_URL */
export const VERSION = process.env.VERSION
export const DEBUG_INFO_ENABLED = process.env.DEBUG_INFO_ENABLED
export const SERVER_API_URL = process.env.SERVER_API_URL
export const WEBSOCKET_URL = process.env.WEBSOCKET_URL
export const MAX_FILE_SIZE = 104857600
export const SWAGGER_URL = process.env.SWAGGER_URL
