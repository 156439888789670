<ng-container *ngIf="!inEditMode && !inPickContentMode && !inTranslationMode">
    <h3 jhiTranslate="kstandards.contentManager.contents">Contents</h3>
    <div class="filters form-inline">
        <div class="form-group" *ngIf="hasConstructionType">
            <label for="selected-construction-type" jhiTranslate="kstandards.contentManager.selectedConstructionType">Construction type</label>
            <select id="selected-construction-type" class="custom-select ml-2" [(ngModel)]="selectedConstructionType" (change)="onConstructionTypeFilterChange()">
                <option value="CONSTRUCTION">{{'kstandards.ConstructionType.CONSTRUCTION' | translate}}</option>
                <option value="MODERNIZATION">{{'kstandards.ConstructionType.MODERNIZATION' | translate}}</option>
            </select>
        </div>

        <div class="form-group" *ngIf="hasAdditionalScope">
            <label for="selected-building-type" jhiTranslate="kstandards.contentManager.selectedBuildingType">Building
                type</label>
            <select class="custom-select ml-2" id="selected-building-type" name="building-type" [(ngModel)]="selectedBuildingType" (change)="onBuildingTypeFilterChange()">
                <option [ngValue]="null" jhiTranslate="kstandards.contentManager.noBuildingTypeSelected">All</option>
                <option [ngValue]="buildingType" *ngFor="let buildingType of buildingTypes">{{ buildingType.name }}
                </option>
            </select>
        </div>
        <div class="form-group" *ngIf="hasAdditionalScope">
            <label for="selected-country" jhiTranslate="kstandards.contentManager.selectedCountry">Country</label>
            <select class="custom-select ml-2" id="selected-country" name="country" [(ngModel)]="selectedCountry" (change)="onCountryFilterChange()">
                <option [ngValue]="null" jhiTranslate="kstandards.contentManager.noCountrySelected">All</option>
                <option [ngValue]="country" *ngFor="let country of countriesForFilter">{{ country.name }}</option>
            </select>
        </div>
        <div class="form-group" *ngIf="isLidl">
            <label for="selected-create-country" jhiTranslate="kstandards.contentManager.selectedCreateCountry">Country</label>
            <select class="custom-select ml-2" id="selected-create-country" name="create-country" [(ngModel)]="selectedCreateCountry" (change)="onCreateCountryFilterChange()">
                <option [ngValue]="null" jhiTranslate="kstandards.contentManager.noCountrySelected">All</option>
                <option [ngValue]="createCountry" *ngFor="let createCountry of createCountries">{{ createCountry.name }}
                </option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-5">
            <div class="row">
                <div class="form-group col-lg-6">
                    <button type="button" class="btn btn-secondary btn-block" (click)="navigateToNewContent()" jhiTranslate="kstandards.contentManager.addContent">Add content</button>
                </div>

                <div class="form-group col-lg-6">
                    <button type="button" class="btn btn-secondary btn-block" (click)="inPickContentMode = !inPickContentMode" jhiTranslate="kstandards.contentManager.pickContents">Pick content</button>
                </div>
            </div>
        </div>
    </div>

    <div class="row content-overview">
        <div class="col-lg-5">
            <div ngxDroppable="dnd-content" [model]="contents" *ngIf="defaultLanguage" (drop)="updateContentOrder(true)">
                <div *ngFor="let content of contents; let i = index;" [ngxDraggable]="userCountryCode === undefined || (userCountryCode !== undefined && (userCountryCode === RoleIdEnum.International || userCountryCode === content.createCountry?.code.toLowerCase())) ? ['dnd-content'] : ['']" [model]="content">

                    <div class="content-container highlighted-content-container-{{ content.id }}" [ngClass]="{'lidl': isLidl}" [class.faded]="!isContentVisible(content)">
                        <div class="content-properties">
                            <ul class="badge-list">
                                <li *ngIf="hasConstructionType" class="badge" [class.badge-secondary]="content.constructionType === 'CONSTRUCTION_MODERNIZATION'" [class.badge-special]="content.constructionType === 'MODERNIZATION'" [class.badge-primary]="content.constructionType === 'CONSTRUCTION'">{{
                                    'kstandards.ConstructionType.' + content.constructionType | translate }}
                                </li>
                                <li class="badge" [class.badge-success]="content.workflowStatus === 'PUBLISHED'" [class.badge-danger]="content.workflowStatus === 'DRAFT'" [class.badge-warning]="content.workflowStatus === 'REQUIRES_TRANSLATION'" [class.badge-special]="content.workflowStatus === 'TRANSLATION_DONE'">{{
                                    'kstandards.WorkflowStatus.' + content.workflowStatus | translate }}
                                </li>
                                <li>
                                    <custom-badge [str]="content.renderScope ? content.renderScope : 'NO_SCOPE'">
                                    </custom-badge>
                                </li>
                            </ul>
                            <div class="content-type">{{ 'kstandards.ContentType.' + content.contentType | translate }}
                            </div>
                        </div>
                        <div class="content-header" *ngIf="hasAdditionalScope">
                            <!-- We have to avoid whitespace between the <li> elements in the list in order to have consistent badge spacing -->

                            <ul class="badge-list">
                                <li *ngFor="let buildingType of content.buildingTypes">
                                    <custom-badge [str]="buildingType.alias ? buildingType.alias : buildingType.name">
                                    </custom-badge>
                                </li>
                                <li *ngFor="let country of content.countries" class="flag-icon flag-icon-{{ FlagUtil.getFlag(country.code) }}" [title]="country.name"></li>
                            </ul>

                        </div>


                        <div class="content-preview">
                            <content-preview [content]="content" [language]="defaultLanguage"></content-preview>
                        </div>
                        <div class="content-toolbar">
                            <ul *ngIf="!!content.createCountry" style="display: inline; margin: 0 0.25rem 0 0.25rem; padding: 0;">
                                <li style="padding-bottom: 0.25rem;" class="flag-icon flag-icon-{{ FlagUtil.getFlag(content.createCountry.code?.toLowerCase()) }}" [title]="createdByCountryTitle + ' ' + content.createCountry.name">
                                </li>
                            </ul>
                            <button type="button" class="btn" placement="bottom" openDelay="500" [ngbTooltip]="'kstandards.contentManager.editContent' | translate" (click)="navigateToEdit(content)">
                                <icon shape="edit"></icon>
                            </button>
                            <span *ngIf="userCountryCode === undefined || (userCountryCode !== undefined && (userCountryCode === RoleIdEnum.International || userCountryCode === content.createCountry?.code.toLowerCase()))" dnd-sortable-handle class="btn" placement="bottom" openDelay="500" [ngbTooltip]="'kstandards.contentManager.moveContent' | translate">
                                <icon shape="hamburger"></icon>
                            </span>
                            <button *ngIf="content.workflowStatus === 'REQUIRES_TRANSLATION'" type="button" class="btn" placement="bottom" openDelay="500" [ngbTooltip]="'kstandards.contentManager.translate' | translate" (click)="navigateToTranslate(content)">
                                <fa-icon icon="language"></fa-icon>
                            </button>
                            <button type="button" class="btn" placement="bottom" openDelay="500" [ngbTooltip]="'kstandards.contentManager.linkedFileHint' | translate" *ngIf="content.defaultTranslation.file && hasAttachmentEditPermissions" [routerLink]="['/file/'+content.translationForLanguage(defaultLanguage).file.id + '/edit']">
                                <icon shape="attachment"></icon>
                            </button>
                            <button type="button" class="btn" placement="bottom" openDelay="500" [ngbTooltip]="'kstandards.contentManager.linkedAttachmentHint' | translate" *ngIf="content.linkedAttachment && hasAttachmentEditPermissions" [routerLink]="['/attachment/'+content.linkedAttachment.id + '/edit']">
                                <icon shape="attachment"></icon>
                            </button>
                            <button *ngIf="userCountryCode === undefined || (userCountryCode !== undefined && (userCountryCode === RoleIdEnum.International || userCountryCode === content.createCountry?.code.toLowerCase()))" type="button" class="btn float-right" (click)="unmapContent(i)" placement="bottom" openDelay="500" [ngbTooltip]="'kstandards.contentManager.unmapContent' | translate">
                                <icon shape="trash"></icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-7" *ngIf="contents && contents.length > 0 && languages && languages.length > 0">
            <div>
                <label for="languages" jhiTranslate="kstandards.contentManager.selectedLanguage">Language</label>
                <select class="custom-select" id="languages" name="languages" [(ngModel)]="selectedLanguage">
                    <option [ngValue]="language" *ngFor="let language of languages">{{ language.name }}</option>
                </select>
            </div>
            <div class="preview-content card-body highlighted-content-preview-{{ content.id }}" [ngClass]="{'lidl': isLidl}" *ngFor="let content of contents;">
                <div (click)="jumpToContentRefAndHighlight(content)" class="jump-arrow">
                    &larr;
                </div>
                <content-preview [content]="content" [language]="selectedLanguage" *ngIf="isContentVisible(content)">
                </content-preview>
            </div>
        </div>

    </div>
</ng-container>

<div *ngIf="editMode">
    <content-edit name="contentToEdit" [(ngModel)]="contentToEdit" [editContext]="editContext" [hasConstructionType]="hasConstructionType" [hasComponentType]="hasComponentType" [hasMaterialCategory]="hasMaterialCategory" [hasImplementationType]="hasImplementationType" [hasRoombookType]="hasRoombookType" [canSaveWithSignificantChanges]="canSaveWithSignificantChanges" [hasProductListType]="hasProductListType" [hasChangelogType]="hasChangelogType" (temporaryChange)="contentEdited()" (finishedEditing)="endEditMode($event)" [hasAdditionalScope]="hasAdditionalScope" [buildingTypes]="buildingTypes" [countries]="countries" [languages]="languages" [resetWorkflowStatus]="true">
    </content-edit>
</div>

<div *ngIf="inPickContentMode">
    <content-picker [existingContents]="contents" [defaultLanguage]="defaultLanguage" [hasAdditionalScope]="hasAdditionalScope" [userCountryCode]="userCountryCode" [isLidl]="isLidl" (finishedPicking)="endPickingMode()"></content-picker>
</div>

<div *ngIf="inTranslationMode">
    <content-translation (finished)="endTranslationMode()" [content]="contentToEdit" [languages]="languages">
    </content-translation>
</div>
