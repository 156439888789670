<div class="modal-header">
    <h2 class="modal-title">
                <span *ngIf="content.id" [translateValues]="{id:  content.id }" jhiTranslate="kstandards.contentManager.translation.title">Translate
                    content {{ content.id }}</span>
    </h2>
    <button (click)="close()" aria-hidden="true" class="close" data-dismiss="modal" type="button"></button>
</div>
<div class="modal-body">
    <content-translation (finished)="finished" [content]="content" [languages]="languages" [modal]="true"></content-translation>
</div>
<div class="modal-footer">
    <div class="form-group">
        <button (click)="contentTranslationComponent.createTranslationJobs(true)" class="btn btn-success" jhiTranslate="kstandards.contentManager.translation.createTranslationJobs" type="button">Create translation
            jobs
        </button>
    </div>
    <div class="form-group">
        <button (click)="contentTranslationComponent.endTranslationMode()" class="btn btn-secondary" jhiTranslate="kstandards.contentManager.translation.cancel" type="button">Cancel
        </button>
    </div>
</div>
