import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { NgxWebstorageModule } from 'ngx-webstorage'
import { JhipsterSharedModule } from './shared'
import { JhipsterHomeModule } from './home/home.module'
import { JhipsterAdminModule } from './admin/admin.module'
import { JhipsterEntityModule } from './entities/entity.module'
import { PaginationConfig } from './blocks/config/uib-pagination.config'
import { OAuth2Module } from './oauth2/oauth2.module'
import { NotFoundModule } from './not-found/not-found.module'
import { ContentManagerModule } from './content-manager/content-manager.module'
import { AppRoutingModule } from './app-routing.module'
import { HttpInterceptorModule } from './blocks/blocks.module'

// jhipster-needle-angular-add-module-import JHipster will add new module here
import {
    ActiveMenuDirective,
    ErrorComponent,
    FooterComponent,
    JhiMainComponent,
    NavbarComponent,
    PageRibbonComponent,
    ProfileService,
    VersionService
} from './layouts'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { JhiConfigService, missingTranslationHandler, translatePartialLoader } from 'ng-jhipster'
import { HttpClient } from '@angular/common/http'
import { DataAccessModule } from '@app/data-access'
import { AuthModule } from './auth'

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import {
    faUser,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faEye,
    faBan,
    faTimes,
    faArrowLeft,
    faSave,
    faPlus,
    faPencilAlt,
    faBars,
    faFile,
    faThList,
    faUserPlus,
    faRoad,
    faTachometerAlt,
    faHeart,
    faList,
    faBell,
    faBook,
    faHdd,
    faFlag,
    faWrench,
    faClock,
    faCloud,
    faSignOutAlt,
    faSignInAlt,
    faCalendarAlt,
    faSearch,
    faTrashAlt,
    faAsterisk,
    faTasks,
    faHome,
    faLanguage,
    faAngleRight,
    faAngleLeft,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faTrash,
    faSpinner,
    faFileArchive,
    faArrowRight,
    faChevronUp,
    faChevronDown,
    faCheck,
    faExclamation,
    faInfo,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons'

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-' }),
        JhipsterSharedModule,
        JhipsterHomeModule,
        JhipsterAdminModule,
        JhipsterEntityModule,
        ContentManagerModule,
        OAuth2Module,
        DataAccessModule,
        NotFoundModule,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translatePartialLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useFactory: missingTranslationHandler,
                deps: [JhiConfigService]
            }
        }),
        HttpInterceptorModule,
        AuthModule
        // jhipster-needle-angular-add-module JHipster will add new module here
    ],
    exports: [
        AuthModule
    ],
    declarations: [
        JhiMainComponent,
        NavbarComponent,
        ErrorComponent,
        PageRibbonComponent,
        ActiveMenuDirective,
        FooterComponent
    ],
    providers: [
        ProfileService,
        VersionService,
        PaginationConfig,
    ],
    bootstrap: [JhiMainComponent]
})
export class JhipsterAppModule {

    constructor(library: FaIconLibrary) {

        // Adds the SVG icon to the library so you can use it in your page
        library.addIcons(faUser)
        library.addIcons(faSort)
        library.addIcons(faSortUp)
        library.addIcons(faSortDown)
        library.addIcons(faSync)
        library.addIcons(faEye)
        library.addIcons(faBan)
        library.addIcons(faCheck)
        library.addIcons(faTimes)
        library.addIcons(faArrowLeft)
        library.addIcons(faSave)
        library.addIcons(faPlus)
        library.addIcons(faPencilAlt)
        library.addIcons(faBars)
        library.addIcons(faHome)
        library.addIcons(faThList)
        library.addIcons(faUserPlus)
        library.addIcons(faRoad)
        library.addIcons(faTachometerAlt)
        library.addIcons(faHeart)
        library.addIcons(faList)
        library.addIcons(faBell)
        library.addIcons(faTasks)
        library.addIcons(faBook)
        library.addIcons(faHdd)
        library.addIcons(faFlag)
        library.addIcons(faWrench)
        library.addIcons(faClock)
        library.addIcons(faCloud)
        library.addIcons(faSignOutAlt)
        library.addIcons(faSignInAlt)
        library.addIcons(faCalendarAlt)
        library.addIcons(faSearch)
        library.addIcons(faTrashAlt)
        library.addIcons(faAsterisk)
        library.addIcons(faLanguage)
        library.addIcons(faAngleRight)
        library.addIcons(faAngleDoubleRight)
        library.addIcons(faAngleLeft)
        library.addIcons(faAngleDoubleLeft)
        library.addIcons(faFile)
        library.addIcons(faTrash)
        library.addIcons(faSpinner)
        library.addIcons(faFileArchive)
        library.addIcons(faArrowRight)
        library.addIcons(faChevronUp)
        library.addIcons(faChevronDown)
        library.addIcons(faExclamation)
        library.addIcons(faInfo)
        library.addIcons(faInfoCircle)
    }

}
