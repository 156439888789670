import { BaseEntity } from '../common'
import { Content } from '../content'
import { Arrangeable } from '../common/arrangeable'
import { Linkable } from '../common/linkable'
import { Deletable } from '../common/deletable'

export class RoomGroup implements BaseEntity, Arrangeable, Linkable, Deletable {

    constructor(public id?: number,
                public order?: number,
                public name?: string,
                public description?: string,
                public number?: string,
                public rooms?: BaseEntity[],
                public version?: number,
                public content?: Content,
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string,
                public deletable?: boolean) {
        if (!this.content) {
            this.content = new Content()
        }
    }

    getLabelForArrangeableList(): string {
        return `${this.number} ${this.name}`
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/room-group/${this.id}/edit`
    }
}
