
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '../../app.constants'

import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { RoomBookColumn } from './room-book-column.model'
import { Content } from '../content/content.model'
import { Arrangeable } from '../common/arrangeable'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'

@Injectable()
export class RoomBookColumnService implements EntityService<RoomBookColumn> {

    private readonly resourceUrl = SERVER_API_URL + 'api/room-book-columns'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/room-book-columns'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(roomBookColumn: RoomBookColumn): Observable<RoomBookColumn> {
        const copy = this.convert(roomBookColumn)
        return this.httpClient.post<RoomBookColumn>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(roomBookColumn: RoomBookColumn): Observable<RoomBookColumn> {
        const copy = this.convert(roomBookColumn)
        return this.httpClient.put<RoomBookColumn>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<RoomBookColumn> {
        return this.httpClient.get<RoomBookColumn>(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get<RoomBookColumn[]>(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    queryArrangedRoomBookColumns(): Observable<ResponseWrapper> {
        return this.httpClient.get<RoomBookColumn[]>(`${this.resourceUrl}/arrange`, {observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    updateArrangedRoomBookColumns(roomBookColumn: Arrangeable[]): Observable<ResponseWrapper> {
        return this.httpClient.put<RoomBookColumn[]>(`${this.resourceUrl}/arrange`, roomBookColumn, {observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<RoomBookColumn[]>(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to RoomBookColumn.
     */
    private convertItemFromServer(json: any): RoomBookColumn {
        const entity: RoomBookColumn = Object.assign(new RoomBookColumn(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        entity.content = Object.assign(new Content(), entity.content)
        return entity
    }

    /**
     * Convert a RoomBookColumn to a JSON which can be sent to the server.
     */
    private convert(roomBookColumn: RoomBookColumn): RoomBookColumn {
        const copy: RoomBookColumn = Object.assign({}, roomBookColumn)
        copy.content = Object.assign(new Content(), copy.content)
        return copy
    }
}
