import { Routes } from '@angular/router'

import { Roles, UserRouteAccessService } from '@app/auth'
import {
    ReleaseDeletePopupComponent,
    ReleaseDialogComponent, ReleaseGeneratedFilesComponent,
    ReleaseLogComponent,
    UnpublishedReleaseDeletePopupComponent
} from './'

const releasePageTitle = 'kstandards.release.home.title'
const releaseShowLogPageTitle = 'kstandards.release.showLog.title'

export const releaseRoutes: Routes = [
    {
        path: 'release-context/:releaseContextId/release-new',
        component: ReleaseDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: releasePageTitle
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'release-context/:releaseContextId/release/:id/edit',
        component: ReleaseDialogComponent,
        data: {
            authorities: Roles.VIEWER,
            pageTitle: releasePageTitle
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'release-context/:releaseContextId/release/:releaseId/renderedBook/:renderedBookId/log',
        component: ReleaseLogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: releaseShowLogPageTitle
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'release-context/:releaseContextId/release/:releaseId/renderedBook/:renderedBookId/generated-files',
        component: ReleaseGeneratedFilesComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: releaseShowLogPageTitle
        },
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'release/:releaseId/delete',
        component: ReleaseDeletePopupComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: releasePageTitle
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'release/deleteUnpublishedReleases',
        component: UnpublishedReleaseDeletePopupComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: releasePageTitle
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
]
