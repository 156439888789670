import { BaseEntity } from '@app/data-access'
import { BuildingType, Manufacturer, RoomGroup } from '@app/data-access'
import { ReportType } from './report-types.model'
import { ReportStatus } from './report-status.model'

export class Report implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public buildingType?: BuildingType,
        public manufacturer?: Manufacturer,
        public reportType?: ReportType,
        public reportStatus?: ReportStatus,
        public roomGroup?: RoomGroup,
        public constructionType?: string,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
        if (!this.reportStatus) {
            this.reportStatus = ReportStatus.CREATED
        }
    }

    getReportEditLink(): string {
        // return ReportType.ROOMS_BY_BUILDING_TYPE === this.reportType ? 'roomsByBuildingType' : 'roomsWithMaterials'
        let editLink = ''
        switch (this.reportType) {
            case ReportType.ROOMS_BY_BUILDING_TYPE:
            case ReportType.MATERIALS_BY_BUILDING_TYPE:
                editLink = 'roomsByBuildingType'
                break
            case ReportType.ROOMS_WITH_MATERIALS:
                editLink = 'roomsWithMaterials'
                break
            case ReportType.PRODUCTS_BY_MANUFACTURER:
                editLink = 'productsByManufacturer'
                break
            case ReportType.MATERIAL_VARIANTS:
                editLink = 'materialVariants'
                break
            case ReportType.MATERIALS_AND_ROOMS_WITHOUT_IMAGES:
                editLink = 'materialsAndRoomsWithoutImages'
                break
        }
        return editLink
    }
}
