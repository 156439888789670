import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '../../app.constants'

import { createHttpParams, ResponseWrapper } from '../common'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'
import { FileTag } from './file-tag.model'
import { Country } from '../country'

@Injectable()
export class TagService implements EntityService<FileTag> {

    private readonly resourceUrl = SERVER_API_URL + 'api/tags'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/tags'

    private readonly engineSearchSuggestUrl = SERVER_API_URL + 'api/_suggest/tags'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(tag: FileTag): Observable<FileTag> {
        const copy = this.convert(tag)
        return this.httpClient.post<FileTag>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(tag: FileTag): Observable<FileTag> {
        const copy = this.convert(tag)
        return this.httpClient.put<FileTag>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<FileTag>  {
        return this.httpClient.get<FileTag>(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get<FileTag[]>(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: any, req?: any): Observable<ResponseWrapper> {
        let params = createHttpParams(req)
        if (query['createCountryCodes']) {
            params = params.set('createCountryCodes', query['createCountryCodes'])
        }
        return this.httpClient.get<FileTag[]>(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    getCreateCountries(): Observable<Country[]> {
        return this.httpClient.get<Country[]>(`${this.resourceUrl}/createCountries`)
    }

    suggest(query: string): Observable<ResponseWrapper> {
        const params = {query}
        return this.httpClient.get<FileTag[]>(this.engineSearchSuggestUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    findByNames(names: string[]): Observable<FileTag[]>  {
        return this.httpClient.post<FileTag[]>(`${this.resourceUrl}/search-by-name`, names)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Tag.
     */
    private convertItemFromServer(json: any): FileTag {
        const entity: FileTag = Object.assign(new FileTag(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        if (entity.createCountry) {
            entity.createCountry.code = entity.createCountry.code.toLowerCase()
        }

        return entity
    }

    /**
     * Convert a Tag to a JSON which can be sent to the server.
     */
    private convert(tag: FileTag): FileTag {
        const copy: FileTag = Object.assign({}, tag)
        copy.name = copy.name.trim()
        return copy
    }
}
