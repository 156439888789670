import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { DiffMatchPatchModule } from 'ng-diff-match-patch'

import {
    AttachmentPickerComponent,
    ContentDiffComponent,
    ContentEditComponent,
    ContentManagerComponent,
    ContentPickerComponent,
    ContentPreviewComponent,
    ContentTranslationComponent,
    FilePickerComponent,
    SemanticDiffComponent,
    SingleContentEditAreaComponent,
    SingleContentEditCompactComponent
} from './'

import { JhipsterSharedModule } from '../shared'
import { RouterModule } from '@angular/router'
import { TuiEditorModule } from './tui-editor'
import { ContentTranslationModalComponent } from '@app/content-manager/content-translation-modal.component'

@NgModule({
    imports: [
        CommonModule,
        JhipsterSharedModule,
        ReactiveFormsModule,
        DiffMatchPatchModule,
        RouterModule,
        TuiEditorModule
    ],
    declarations: [
        FilePickerComponent,
        AttachmentPickerComponent,
        ContentManagerComponent,
        ContentEditComponent,
        ContentPickerComponent,
        ContentPreviewComponent,
        SingleContentEditAreaComponent,
        SingleContentEditCompactComponent,
        SemanticDiffComponent,
        ContentDiffComponent,
        ContentTranslationComponent,
        ContentTranslationModalComponent
    ],
    entryComponents: [
        FilePickerComponent,
        AttachmentPickerComponent,
        ContentTranslationModalComponent
    ],
    exports: [
        FilePickerComponent,
        AttachmentPickerComponent,
        ContentManagerComponent,
        ContentEditComponent,
        ContentPreviewComponent,
        ContentPreviewComponent,
        SingleContentEditAreaComponent,
        SingleContentEditCompactComponent,
        SemanticDiffComponent,
        ContentDiffComponent,
        ContentTranslationComponent
    ]
})
export class ContentManagerModule { }
