import { BaseEntity } from '../common'
import { Linkable } from '../common/linkable'
import { Content } from '../content'

export class BuildingTypeCategory implements BaseEntity, Linkable {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public content?: Content,
        public version?: number,
        public order?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
        if (!this.content) {
            this.content = new Content()
        }
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/building-type-category/${this.id}/edit`
    }
}
