
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { SERVER_API_URL } from '../../app.constants'

import { Country } from './country.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'

@Injectable()
export class CountryService implements EntityService<Country> {

    private readonly resourceUrl = SERVER_API_URL + 'api/countries'

    private readonly engineSearchUrl = SERVER_API_URL + 'api/_search/countries'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(country: Country): Observable<Country> {
        const copy = this.convert(country)
        return this.httpClient.post<Country>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(country: Country): Observable<Country> {
        const copy = this.convert(country)
        return this.httpClient.put<Country>(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<Country> {
        return this.httpClient.get<Country>(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get<Country[]>(this.resourceUrl, { params, observe: 'response' }).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    deleteCountryAndContents(id: number): Observable<any> {
        return this.httpClient.delete(`api/fixes/migrate-remove-country-with-contents?countryId=${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<Country[]>(this.engineSearchUrl, { params, observe: 'response' }).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Country.
     */
    private convertItemFromServer(json: any): Country {
        const entity: Country = Object.assign(new Country(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        if (entity.code) {
            entity.code = entity.code.toLowerCase()
        }

        return entity
    }

    /**
     * Convert a Country to a JSON which can be sent to the server.
     */
    private convert(country: Country): Country {
        const copy: Country = Object.assign({}, country)
        return copy
    }
}
