<nav class="navbar navbar-dark navbar-expand-md jh-navbar">
    <div class="jh-logo-container float-left">
        <a class="jh-navbar-toggler d-lg-none float-right" href="javascript:void(0);" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
            <fa-icon icon="bars"></fa-icon>
        </a>
        <a class="navbar-brand logo float-left" routerLink="/" (click)="collapseNavbar()">
            <span class="logo-img" [ngStyle]="{'background' : 'url(' + logoUrl + ') no-repeat center center'}"></span>
            <span *ngIf="companyLetter" jhiTranslate="global.title" [translateValues]="{companyLetter: companyLetter}" class="navbar-title">K-Standards</span>
            <span *ngIf="environment && environment.indexOf('PROD') === -1" class="navbar-subtitle">{{this.environment}}</span>
        </a>
    </div>
    <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed" [ngSwitch]="isAuthenticated()">
        <ul class="navbar-nav ml-auto">
            <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER" ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="books-menu">
                    <span>
                        <span jhiTranslate="global.menu.book.main">
                            Book
                        </span>
                    </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li *jhiHasAnyAuthority="Roles.ADMIN">
                        <a class="dropdown-item" routerLink="building-type-category" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.buildingTypeCategory">Building Type Category</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.ADMIN">
                        <a class="dropdown-item" routerLink="building-type" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.buildingType">Building Type</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.ADMIN">
                        <a class="dropdown-item" routerLink="country" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.country">Country</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.ADMIN">
                        <a class="dropdown-item" routerLink="language" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.language">Language</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.ADMIN">
                        <a class="dropdown-item" routerLink="project" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.project">Project</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="craft" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.craft">Craft</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="material-group" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.materialGroup">Material Group</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="material-sub-group" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.materialSubGroup">Material Sub Group</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="material" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.material">Material</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.ADMIN">
                        <a class="dropdown-item" routerLink="material-category" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.materialCategory">Material Category</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="content" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.book.content">Content</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER" ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="roombook-menu">
                    <span>
                        <span jhiTranslate="global.menu.roomBook.main">
                            Room Book
                        </span>
                    </span>
                </a>

                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li>
                        <a class="dropdown-item" routerLink="room-group" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.roomBook.roomGroup">Room Group</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="room" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.roomBook.room">Room</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="room-book-column" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.roomBook.roomBookColumn">Room Book Column</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER" ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="products-menu">
                    <span>
                        <span jhiTranslate="global.menu.products.main">
                            Products
                        </span>
                    </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="product-group" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.products.productGroup">Product Group</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="product" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.products.product">Product</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="manufacturer" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.products.manufacturer">Manufacturer</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="/product/import" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.products.importProducts">Import Products</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER" ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="media-menu">
                    <span>
                        <span jhiTranslate="global.menu.media.main">
                            Media
                        </span>
                    </span>
                </a>

                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="attachment-category" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.media.attachmentCategory">Attachment category</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="attachment" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.media.attachment">Attachment</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="file" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.media.file">File</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="tag" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.media.tag">Tag</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link" routerLink="release-context" (click)="collapseNavbar()">
                    <span>
                        <span jhiTranslate="global.menu.release">Release</span>
                    </span>
                </a>
            </li>

            <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER" ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="translation-menu">
                    <span>
                        <span jhiTranslate="global.menu.translation.main">Translation</span>
                    </span>
                </a>

                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li>
                        <a class="dropdown-item" [routerLink]="['/translation-batch/import']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="kstandards.translationBatch.home.import">Import Translation Batch</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="translation-job" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.translation.translationJob">Translation Job</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="translation-batch" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.translation.translationBatch">Translation Batch</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li *jhiHasAnyAuthority="Roles.INT_EDITOR_AND_PUBLISHER" ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="changelog-menu">
                    <span>
                        <span jhiTranslate="global.menu.changelog">Changelog</span>
                    </span>
                </a>

                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li>
                        <a class="dropdown-item" routerLink="changelog" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.changelogs">Changelog</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" routerLink="change-tag" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.changeTag">Tag</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li *jhiHasAnyAuthority="[Roles.INT_EDITOR_AND_PUBLISHER, Roles.MONITORING]" ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="admin-menu">
                    <span>
                        <span jhiTranslate="global.menu.admin.main">Administration</span>
                    </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li *jhiHasAnyAuthority="[Roles.SUPERADMIN, Roles.MONITORING]">
                        <a class="dropdown-item" routerLink="jhi-metrics" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="tachometer-alt"></fa-icon>
                            <span jhiTranslate="global.menu.admin.metrics">Metrics</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.MONITORING">
                        <a class="dropdown-item" routerLink="jhi-health" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="heart"></fa-icon>
                            <span jhiTranslate="global.menu.admin.health">Health</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.SUPERADMIN">
                        <a class="dropdown-item" routerLink="jhi-configuration" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="list"></fa-icon>
                            <span jhiTranslate="global.menu.admin.configuration">Configuration</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="audits" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="bell"></fa-icon>
                            <span jhiTranslate="global.menu.admin.audits">Audits</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.SUPERADMIN">
                        <a class="dropdown-item" routerLink="logs" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="tasks"></fa-icon>
                            <span jhiTranslate="global.menu.admin.logs">Logs</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.SUPERADMIN">
                        <a class="dropdown-item" [href]="swaggerUrl" (click)="collapseNavbar()">
                            <fa-icon icon="book"></fa-icon>
                            <span jhiTranslate="global.menu.admin.apidocs">API</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.SUPERADMIN">
                        <a class="dropdown-item" routerLink="searchengine-reindex" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="search"></fa-icon>&nbsp;
                            <span jhiTranslate="global.menu.admin.searchengine-reindex">SearchEngine Reindex</span>
                        </a>
                    </li>
                    <li *jhiHasAnyAuthority="Roles.EDITOR_AND_PUBLISHER">
                        <a class="dropdown-item" routerLink="report-new" routerLinkActive="active" (click)="collapseNavbar()">
                            <fa-icon icon="asterisk"></fa-icon>&nbsp;
                            <span jhiTranslate="global.menu.admin.reports">Reports</span>
                        </a>
                    </li>
                </ul>
            </li>
            <div *ngIf="isLidl && (distinctCountryCodesFromEditingUserRoles$ | async)?.length > 1" style="display: flex; width: 150px;">
                <li ngbDropdown class="nav-item dropdown pointer" *jhiHasAnyAuthority="[Roles.ADMIN, Roles.INT_EDITOR_AND_PUBLISHER, Roles.EDITOR_AND_PUBLISHER, Roles.MONITORING]">
                    <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="active-country-menu">
                        <span>
                            <span jhiTranslate="global.menu.activeCountry">User Roles</span>
                        </span>
                    </a>
                    <ul class="dropdown-menu ml-auto" ngbDropdownMenu>
                        <li *ngFor="let countryCode of distinctCountryCodesFromEditingUserRoles$ | async">
                            <a class="dropdown-item" (click)="oauth2Service.setUserActiveCountry(countryCode, true)" [jhiActiveMenu]="countryCode" [ngClass]="countryCode === (countryCodeFromActiveRole$ | async) ? 'nav-active-menu-item' : ''">{{countryCode}}</a>
                        </li>
                    </ul>
                </li>
                <div style="color: limegreen;">{{ countryCodeFromActiveRole$ | async }}</div>
            </div>

            <li ngbDropdown placement="bottom-right" class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                    <span>
                        <fa-icon icon="user"></fa-icon>
                        <span *ngSwitchCase="true">
                            <span>{{account?.login}}</span>
                        </span>
                        <span *ngSwitchCase="false">
                            <span jhiTranslate="global.menu.account.main">
                                Account
                            </span>
                        </span>
                    </span>
                </a>
                <ul class="dropdown-menu dropdown-menu-right ml-auto" ngbDropdownMenu>
                    <li *ngFor="let language of languages">
                        <a class="dropdown-item" [jhiActiveMenu]="language" href="javascript:void(0);" (click)="changeLanguage(language);collapseNavbar();">{{language |
                            findLanguageFromKey}}</a>
                    </li>
                    <li *ngSwitchCase="true">
                        <a class="dropdown-item" (click)="logout()" id="logout">
                            <fa-icon icon="sign-out-alt"></fa-icon>
                            <span jhiTranslate="global.menu.account.logout">Sign out</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="false">
                        <a class="dropdown-item" (click)="login()" id="login">
                            <fa-icon icon="sign-in-alt"></fa-icon>
                            <span jhiTranslate="global.menu.account.login">Sign in</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="true">
                        <a class="dropdown-item" (click)="gotoUserManual()" id="user_manual">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.account.user-manual">User manual</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="true">
                        <a class="dropdown-item" id="faq" routerLink="faq">
                            <fa-icon icon="asterisk"></fa-icon>
                            <span jhiTranslate="global.menu.account.faq">User manual</span>
                        </a>
                    </li>
                    <li *ngSwitchCase="true">
                        <a class="dropdown-item" id="faq" routerLink="faq">
                            <fa-icon icon="info"></fa-icon>
                            <span>BK v:{{ bkversion }} FE v:{{ version }}</span>
                        </a>
                    </li>                    
                </ul>
            </li>
        </ul>
    </div>
</nav>
