import { LocationStrategy } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Roles } from '@app/auth/roles'
import { KAUFLAND, LIDL } from '@app/shared/constants/company.constants'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'
import { JhiLanguageService } from 'ng-jhipster'
import { BehaviorSubject, Observable } from 'rxjs'
import { SERVER_API_URL, SWAGGER_URL, VERSION } from '../../app.constants'
import { OAuth2Service } from '../../auth/oauth2.service'
import { Account, JhiLanguageHelper } from '../../shared'
import { LanguageChoiceService } from '../../shared/language/language-choice.service'
import { ProfileService } from '../profiles/profile.service'

@Component({
    selector: 'jhi-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: [
        'navbar.scss'
    ]
})
export class NavbarComponent implements OnInit {
    public Roles = Roles
    account: Account
    inProduction: boolean
    isNavbarCollapsed: boolean
    languages: any[]
    swaggerUrl = SWAGGER_URL
    version: string
    bkversion: string
    environment: string
    logoUrl: string
    companyLetter: string
    isLidl: boolean = this.oauth2Service.isLidl
    distinctCountryCodesFromEditingUserRoles$: Observable<string[]> = this.oauth2Service.getDistinctCountryCodesFromRoles(this.oauth2Service.getUserRolesWithEditPermissions())
    countryCodeFromActiveRole$: BehaviorSubject<string> = this.oauth2Service.countryCodeFromActiveRole$

    constructor(
        public readonly oauth2Service: OAuth2Service,
        private readonly languageService: JhiLanguageService,
        private readonly languageHelper: JhiLanguageHelper,
        private readonly languageChoiceService: LanguageChoiceService,
        private readonly profileService: ProfileService,
        private readonly router: Router,
        private readonly locationStrategy: LocationStrategy,
    ) {
        this.version = VERSION ? VERSION : ''
        this.bkversion = ''
        this.isNavbarCollapsed = true
        this.logoUrl = SERVER_API_URL + '/api/icon/logo'
    }

    ngOnInit() {
        this.languageHelper.getAll().then((languages) => {
            this.languages = languages
        })

        this.profileService.getProfileInfo().subscribe((profileInfo) => {
            this.inProduction = profileInfo.inProduction
            this.environment = this.determineEnvironment(profileInfo.currentStage)
            DefaultLocaleSingleton.instance.defaultLocale = profileInfo.defaultLocale
            this.companyLetter = profileInfo.lidl ? LIDL : KAUFLAND
            this.updateFaviconAndPageTitle()
            this.profileService.storeEnvironment(profileInfo)
            this.isLidl = profileInfo.lidl
            this.bkversion = profileInfo.baseVersion
        })

        this.oauth2Service.userIdentity.subscribe(
            (userIdentity) => this.account = userIdentity,
            () => this.account = null)
    }

    changeLanguage(languageKey: string) {
        this.languageChoiceService.setPreferredLanguage(languageKey)
        this.languageService.changeLanguage(languageKey)

        if (this.router.url === this.locationStrategy.getBaseHref()) {
            this.updateFaviconAndPageTitle()
        }
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true
    }

    isAuthenticated() {
        return this.oauth2Service.isAuthenticated()
    }

    login() {
        this.oauth2Service.login()
    }

    logout() {
        this.collapseNavbar()
        this.oauth2Service.logout()
    }

    gotoUserManual() {
        window.open('https://confluence.kaufland/display/BE/Handbuch+K-Standards', '_blank')
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed
    }

    private updateFaviconAndPageTitle() {
        if (document.getElementById('favicon')) {
            document.getElementById('favicon').setAttribute('href', `${SERVER_API_URL}/api/icon/favicon`)
        }

        this.languageHelper.updateTitle()
    }

    private determineEnvironment(currentStage: string) {
        if (currentStage.toLocaleLowerCase() === 'kfllocal') {  return 'LOCAL' }
        if (currentStage.toLocaleLowerCase() === 'kfldev') {  return 'DEV' }
        if (currentStage.toLocaleLowerCase() === 'kflqa') {  return 'QA' }
        if (currentStage.toLocaleLowerCase() === 'kflprd') {  return 'PROD' }

        if (currentStage.toLocaleLowerCase() === 'ldllocal') {  return 'LIDL-LOCAL' }
        if (currentStage.toLocaleLowerCase() === 'ldldev') {  return 'LIDL-DEV' }
        if (currentStage.toLocaleLowerCase() === 'ldlqa') {  return 'LIDL-QA' }
        if (currentStage.toLocaleLowerCase() === 'ldlprd') {  return 'LIDL-PROD' }
    }
    search() {

    }
}
