import { BaseEntity } from '../common'

export class Language implements BaseEntity {
    constructor(
        public id?: number,
        public locale?: string,
        public name?: string,
        public urlFragment?: string,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }
}
