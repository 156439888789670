import { Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { HostCompanyIdEnum } from '@app/auth'
import { OAuth2Service } from '@app/auth/oauth2.service'
import { TranslateService } from '@ngx-translate/core'
import { default as Editor } from '@toast-ui/editor'
import '@toast-ui/editor/dist/i18n/de-de'
import { capitalize } from 'lodash'
import { EDITOR_TYPE } from './editor-type'

export const DEFAULT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TuiEditorComponent),
    multi: true
}

const START_OPENED_COLLAPSIBLE = '<[START_OPENED_COLLAPSIBLE]>'
const START_CLOSED_COLLAPSIBLE = '<[START_CLOSED_COLLAPSIBLE]>'
const END_COLLAPSIBLE = '<[END_COLLAPSIBLE]>'
const MATERIAL_CATEGORY_ICON = '<[MATERIAL_CATEGORY_ICON]>'
const TOREMOVE_MATERIAL_CATEROGY_ICON = '&lt;[MATERIAL_CATEGORY_ICON]&gt;'

@Component({
    selector: 'tui-editor',
    templateUrl: './tui-editor.component.html',
    styleUrls: ['./tui-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DEFAULT_VALUE_ACCESSOR]
})
export class TuiEditorComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {

    @Input()
    public initialMode = EDITOR_TYPE.WYSIWYG

    @Input()
    public isTranslationContentContainer = undefined

    @Input()
    public isEditorReadOnly = false

    @Input()
    public isMaterialCategory = false

    @Output()
    public change = new EventEmitter<string>()

    @ViewChild('editor', { read: ElementRef, static: true })
    private child: ElementRef

    public editor: Editor

    public isLidl: boolean = this.oauth2Service.isLidl

    private onChange = (textContent: string) => { }

    private onTouch = () => { }

    private lastEmittedValue

    private hasSeenAnyValue = false

    private value = ''

    constructor(private readonly translateService: TranslateService,
        private readonly ngZone: NgZone,
        private readonly oauth2Service: OAuth2Service) { }

    ngOnInit() {

        this.initEditor(this.translateService.getDefaultLang())

        this.translateService.onLangChange.subscribe((langChangeEvent) => {

            // We re-initialize the editor if the language changes
            if (this.editor) {
                this.cleanupEditor()
            }

            this.initEditor(langChangeEvent.lang)

        })
    }

    ngOnDestroy() {
        this.cleanupEditor()
    }

    public copyToClipboard(value: string) {
        value = value.replace(capitalize(EDITOR_TYPE.MARKDOWN) + EDITOR_TYPE.WYSIWYG.toUpperCase(), '')

        if (!!navigator.clipboard) {
            navigator.clipboard.writeText(value)
        }
    }

    private cleanupEditor() {
        this.ngZone.runOutsideAngular(() => {
            const editorElements = this.editor.getEditorElements()
            if (!!editorElements.mdEditor && !!editorElements.mdPreview && !!editorElements.wwEditor) {
                this.editor.destroy()
            }
        })
    }

    private initEditor(language: string) {
        this.setEditorReadOnlyStatus()

        this.ngZone.runOutsideAngular(() => {
            this.editor = new Editor({
                initialValue: this.value,
                el: this.child.nativeElement,
                initialEditType: this.initialMode,
                previewStyle: 'vertical',
                height: '600px',
                usageStatistics: false,
                hideModeSwitch: false,
                language,
                plugins: [],
                toolbarItems: this.isEditorReadOnly ? [] : [['heading'], ['bold', 'italic'], ['ul', 'ol', 'indent', 'outdent'], ['table'],
                [{
                    name: 'openedCollapsible',
                    el: this.createButtonForOpenedCollapsible(),
                    tooltip: 'Add opened collapsible',
                }, {
                    name: 'closedCollapsible',
                    el: this.createButtonForClosedCollapsible(),
                    tooltip: 'Add closed collapsible',
                }, {
                    name: 'addLink',
                    el: this.createButtonForLink(),
                    tooltip: 'Add Link',
                }]
                ],
            })
            if (this.isMaterialCategory) {
                this.editor.insertToolbarItem({groupIndex : 4, itemIndex : 3}, {
                    name: 'addIcon',
                    el: this.createButtonForAddingMaterialCategoryIcon(),
                    tooltip: 'Add Material Category Icon',
                })
            }
            this.editor.on('change', () => this.prepareForChange())
            this.editor.on('focus', () => this.onTouch())
        })
    }

    ngOnChanges(val) {
        if (this.editor !== undefined) {
            if (this.isMaterialCategory) {
                this.editor.insertToolbarItem( {groupIndex : 4, itemIndex : 3}, {
                    name: 'addIcon',
                    el: this.createButtonForAddingMaterialCategoryIcon(),
                    tooltip: 'Add Material Category Icon',
                })
            } else {
                this.editor.removeToolbarItem('addIcon')
                this.editor.setSelection(0, this.editor.getHTML().length)
                let content = this.editor.getHTML()
                content = content.split(TOREMOVE_MATERIAL_CATEROGY_ICON).join('')
                this.editor.setHTML(content)
            }
        }
    }

    private createButtonForOpenedCollapsible() {
        const button = document.createElement('button')

        button.className = 'toastui-editor-toolbar-icons last'
        button.style.backgroundImage = 'none'
        button.style.margin = '0'
        button.innerHTML = `<i>OC</i>`
        button.addEventListener('click', () => {
            this.editor.replaceSelection(START_OPENED_COLLAPSIBLE + this.editor.getSelectedText() + ' ' + END_COLLAPSIBLE)
        })

        return button
    }

    private createButtonForClosedCollapsible() {
        const button = document.createElement('button')
        button.className = 'toastui-editor-toolbar-icons last'
        button.style.backgroundImage = 'none'
        button.style.margin = '0'
        button.innerHTML = `<i>CC</i>`
        button.addEventListener('click', () => {
            this.editor.replaceSelection(START_CLOSED_COLLAPSIBLE + this.editor.getSelectedText() + ' ' + END_COLLAPSIBLE)
        })

        return button
    }

    private createButtonForLink() {
        const button = document.createElement('button')
        button.setAttribute('id', 'linkButton')
        button.disabled = true
        button.className = 'toastui-editor-toolbar-icons last'
        button.style.backgroundImage = 'none'
        button.style.margin = '0'
        button.innerHTML = `<i>LINK</i>`
        button.addEventListener('click', () => {
            this.editor.insertText('[Text](link)')
        })

        return button
    }

    private createButtonForAddingMaterialCategoryIcon() {
        const button = document.createElement('button')

        button.className = 'toastui-editor-toolbar-icons last'
        button.style.backgroundImage = 'none'
        button.style.margin = '0'
        button.innerHTML = `<i>IMG</i>`
        button.addEventListener('click', () => {
            this.editor.insertText(MATERIAL_CATEGORY_ICON)
        })

        return button
    }

    private setEditorReadOnlyStatus(): void {
        if (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.LStandards) {
            if (this.isTranslationContentContainer) {
                this.isEditorReadOnly = false
            }
        } else if (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.KStandards) {
            this.isEditorReadOnly = false
        }
    }

    private prepareForChange() {
        const linkButton  = document.getElementById('linkButton') as HTMLButtonElement
        linkButton.disabled = this.editor.isMarkdownMode() ? false : true

        const markdownData = this.editor.getMarkdown()

        const valueToEmit = this.removeBrElements(markdownData)

        // Avoid emitting for new translations
        if (this.lastEmittedValue === undefined && valueToEmit === '') {
            return
        }

        this.value = valueToEmit

        // Avoid emitting if the value has not changed
        if (this.lastEmittedValue !== valueToEmit) {
            this.lastEmittedValue = valueToEmit
            this.onChange(valueToEmit)
            this.change.emit(valueToEmit)
        }

    }

    writeValue(value: any): void {
        if (value !== null) {

            // Make sure that setting the value does not trigger a change
            if (!this.hasSeenAnyValue) {
                this.lastEmittedValue = value
            }

            this.hasSeenAnyValue = true
            this.value = value


            // We can only write to the editor if it's initialized
            if (this.editor) {
                this.ngZone.runOutsideAngular(() => {
                    this.editor.setMarkdown(this.transformHardLineBreaksToBr(value))
                })
            }
        }
    }

    // Converts hard line
    private transformHardLineBreaksToBr(value: any): any {
        return value
    }

    private removeBrElements(value: any): any {
        return value.replace(/^<br(\s*\/)?>$/gm, '')
    }

    registerOnChange(fn: any): void {
        this.onChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented: setDisabledState.')
    }
}

