import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class ConsumerViewConfig {

    public readonly configUrl: string = 'api/configuration/consumerViewPreviewUrl'

    constructor(private readonly httpClient: HttpClient) {
    }

    getPreviewUrl(): Observable<string> {
        return this.httpClient.get(`${this.configUrl}`, {responseType: 'text'})
    }
}
