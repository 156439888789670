import { BaseEntity } from '../common'
import { FaqCategory } from './faq-category.model'

export class FaqEntry implements BaseEntity {
    constructor(
        public id?: number,
        public question?: string,
        public answer?: string,
        public category?: FaqCategory,
        public order?: number,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }
}
