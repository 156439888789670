import { Component, Input } from '@angular/core'

@Component({
    selector: 'semantic-diff',
    templateUrl: 'semantic-diff.component.html'
})
export class SemanticDiffComponent {

    @Input()
    textContent: string

    @Input()
    oldTextContent: string
}
