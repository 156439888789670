<form class="file-picker" *ngIf="hasLoaded">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="kstandards.filepicker.title">File picker</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>
    <div class="modal-body">

        <div class="form-row filters">
            <div class="form-group col-md-8">
                <label for="filepicker-search" jhiTranslate="kstandards.filepicker.search">Search string</label>
                <input id="filepicker-search" type="text" class="form-control" name="search" [formControl]="searchField">
            </div>
            <div class="form-group col-md-4">
                <label for="filepicker-category" jhiTranslate="kstandards.filepicker.categoryLabel">Category</label>
                <select class="custom-select" name="category" [formControl]="categoryField">
                    <option value="ALL" jhiTranslate="kstandards.filepicker.category.ALL">All files</option>
                    <option value="IMAGE" jhiTranslate="kstandards.filepicker.category.IMAGE">Images</option>
                    <option value="PDF" jhiTranslate="kstandards.filepicker.category.PDF">PDFs</option>
                </select>
            </div>

            <div class="form-group col-md-12">
                <label class="form-control-label" jhiTranslate="kstandards.tag.home.title" for="field_tags">Tag</label>
                <tag-holder id="field_tags" name="tags" [tagFactory]="tagFactory" [enableNewTags]="false" [availableTags]="availableTags" [(ngModel)]="appliedTags" (ngModelChange)="onTagsChange($event)" (changeCriteria)="searchSuggestions($event)"></tag-holder>
            </div>
        </div>
        <div class="row">
            <ng-container *ngIf="files.length > 0; else noFiles">
                <div class="col-md-3 file" [class.selected]="selectedFile?.id === file.id" (click)="selectFile(file)" *ngFor="let file of files; let i = index;" [title]="file.name">
                    <div class="file-preview">
                        <embedded-image [file]="file" [preview]="true" *ngIf="file.mainFile?.fileType.startsWith('image/')"></embedded-image>
                        <pdf-preview [file]="file" *ngIf="file.mainFile?.fileType.startsWith('application/pdf')"></pdf-preview>
                        <span *ngIf="!file.mainFile?.fileType.match('^(image/|application/pdf)')" class="fa fa-file"></span>
                    </div>
                    <span class="file-name">
                    {{ file.name }}
                    </span>
                </div>
            </ng-container>

            <ng-template #noFiles>
                <div class="col-md-12"><p jhiTranslate="kstandards.filepicker.noFiles">No files found.</p></div>
            </ng-template>
        </div>
        <div class="row" [hidden]="totalCount <= itemsPerPage">
            <div class="col-md-12 d-flex justify-content-center">
                <ngb-pagination #pagination [collectionSize]="totalCount" [maxSize]="5" [pageSize]="itemsPerPage" (pageChange)="onPageChange($event)" [(page)]="page" aria-label="Pagination"></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
            <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
        </button>
        <button type="button" (click)="save()" class="btn btn-primary">
            <span jhiTranslate="entity.action.save">Save</span>
        </button>
    </div>
</form>
