import { Component, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ReportService } from './report.service'
import { Report } from './report.model'

@Injectable()
export class ReportPopupService {
    private ngbModalRef: NgbModalRef

    constructor(private readonly modalService: NgbModal,
                private readonly router: Router,
                private readonly reportService: ReportService) {
        this.ngbModalRef = null
    }

    open(component: Component, params: any, redirect = true): Promise<NgbModalRef> {
        const id = params['id']
        const reportType = params['reportType']
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null
            if (isOpen) {
                resolve(this.ngbModalRef)
            }
            if (id && id > 0) {
                this.reportService.find(id).subscribe((report) => {
                    this.ngbModalRef = this.reportModalRef(component, report, redirect)
                    resolve(this.ngbModalRef)
                })
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    const report = new Report()
                    report.reportType = reportType
                    this.ngbModalRef = this.reportModalRef(component, report, redirect)
                    resolve(this.ngbModalRef)
                }, 0)
            }
        })
    }

    reportModalRef(component: Component, report: Report, redirect: boolean): NgbModalRef {
        const modalRef = this.modalService.open(component, {size: 'lg', backdrop: 'static'})
        modalRef.componentInstance.report = report
        modalRef.componentInstance.redirect = redirect
        modalRef.result.then((result) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true})
            this.ngbModalRef = null
        }, (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true})
            this.ngbModalRef = null
        })
        return modalRef
    }
}
