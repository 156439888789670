import { ActivatedRoute, Router } from '@angular/router'
import { OnInit, Component } from '@angular/core'
import { Tag, TagService } from '../../data-access'

@Component({
    template: ''
})
export abstract class BaseListComponent implements OnInit {
    searchWord: string

    showDeactivated = false
    isInSearchMode = false

    filterByParam: string
    searchWordParam: string
    buildingTypeId: number
    buildingTypesPath: string
    tags: Tag[]
    createCountryCodes: string[]

    protected constructor(protected router: Router, protected route: ActivatedRoute, protected tagService?: TagService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.searchWordParam = params['searchWord'] || ''
            this.filterByParam = params['filterBy']
            this.showDeactivated = (params['showDeactivated'] === 'true')
            this.buildingTypeId = params['buildingTypeId']
            this.buildingTypesPath = params['buildingTypesPath']
            this.createCountryCodes = params['createCountryCodes'] ? params['createCountryCodes'].split(',') : []
            const urlTags = params['tags']
            if (urlTags && this.tagService) {
                const stringTags = urlTags.split(',')
                this.tagService.findByNames(stringTags).subscribe((tags) => {
                    this.tags = tags
                    this.search()
                })
            } else if (this.createCountryCodes.length) {
                this.search()
            } else {
                if (this.searchWordParam.trim().length > 0 || this.buildingTypeId) {
                    this.search()
                } else {
                    this.loadAll()
                }
            }
        })
    }

    getSearchQuery(): string {
        let query = ''
        if (this.searchWordParam) {
            if (this.filterByParam && this.filterByParam !== 'none') {
                query = this.filterByParam + ':(' + this.searchWordParam + ')'
            } else {
                query = this.searchWordParam
            }
        }
        if (!this.showDeactivated) {
            query += (query.trim().length > 0 ? ' AND ' : '') + '!_exists_:deletedAt'
        }
        if (this.buildingTypeId) {
            query += (query.trim().length > 0 ? ' AND ' : '') +  this.buildingTypesPath + ':(' + this.buildingTypeId + ')'
        }
        if (!this.searchWordParam && !this.buildingTypeId) {
            this.isInSearchMode = false
            this.reset()
        }
        return query
    }

    abstract search()

    abstract loadAll()

    abstract reset()
}
