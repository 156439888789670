import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { BuildingTypeCategory } from './building-type-category.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { Content } from '../content'
import { EntityService } from '../entity'

@Injectable()
export class BuildingTypeCategoryService implements EntityService<BuildingTypeCategory> {

    private readonly resourceUrl = SERVER_API_URL + 'api/building-type-categories'

    private readonly engineSearchUrl = SERVER_API_URL + 'api/_search/building-type-categories'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(buildingTypeCategory: BuildingTypeCategory): Observable<BuildingTypeCategory> {
        const copy = this.convert(buildingTypeCategory)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(buildingTypeCategory: BuildingTypeCategory): Observable<BuildingTypeCategory> {
        const copy = this.convert(buildingTypeCategory)
        return this.httpClient.put(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<BuildingTypeCategory> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.engineSearchUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res: any): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to BuildingTypeCategory.
     */
    private convertItemFromServer(json: any): BuildingTypeCategory {
        const entity: BuildingTypeCategory = Object.assign(new BuildingTypeCategory(), json)

        entity.content = Object.assign(new Content(), entity.content)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }

    /**
     * Convert a BuildingTypeCategory to a JSON which can be sent to the server.
     */
    private convert(buildingType: BuildingTypeCategory): BuildingTypeCategory {
        const copy: BuildingTypeCategory = Object.assign({}, buildingType)
        return copy
    }
}
