export class FlagUtil {

    static getFlag(code: string): string {
        code = code?.toLowerCase()

        switch (code) {
            case 'int':
                code = 'eu'
                break
            case 'nie':
                code = 'gb-nir'
                break
            case 'uk':
                code = 'gb'
                break
            case 'en':
            case 'eng':
                code = 'gb-eng'
                break
            case 'sct':
                code = 'gb-sct'
                break
            case 'wls':
                code = 'gb-wls'
                break
            default:
                break
        }
        return code
    }

    static getFlagFromLocale(locale: string): string {
        const isoCode = locale.split('-').pop()

        return FlagUtil.getFlag(isoCode.toLocaleLowerCase())
    }
}
