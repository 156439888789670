import { Injectable } from '@angular/core'
import { LocalStorageService } from 'ngx-webstorage'
import { LANGUAGES } from './language.constants'

@Injectable()
export class LanguageChoiceService {

    constructor(private readonly $localStorage: LocalStorageService) {}

    /**
     * Returns the preferred user language.
     * @returns {string} - preferred language.
     */
    getPreferredLanguage(): string {
        // 1. Check if we have a stored language
        const savedLanguage = this.$localStorage.retrieve('preferredLanguage')
        if (savedLanguage) {
            return savedLanguage
        }

        // 2. Check if we have a language from the browser
        let browserLanguage = null
        LANGUAGES.forEach((availableLanguage) => {
            if (navigator.language.startsWith(availableLanguage)) {
                browserLanguage = availableLanguage
            }
        })

        if (browserLanguage) {
            return browserLanguage
        }

        // 3. If no language is available provide english as default
        return 'en'
    }

    /**
     * Sets a preferred language.
     * @param {string} lang - chosen language.
     */
    setPreferredLanguage(lang: string) {
        this.$localStorage.store('preferredLanguage', lang)
    }
}
