import { Component, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ReleaseService, Release } from '@app/data-access'

@Injectable()
export class ReleasePopupService {
    private ngbModalRef: NgbModalRef

    constructor(private readonly modalService: NgbModal,
                private readonly router: Router,
                private readonly releaseService: ReleaseService) {
        this.ngbModalRef = null
    }

    open(component: Component, id?: number | any): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null
            if (isOpen) {
                resolve(this.ngbModalRef)
            }

            if (id) {
                this.releaseService.find(id).subscribe((release) => {
                    this.ngbModalRef = this.releaseModalRef(component, release)
                    resolve(this.ngbModalRef)
                })
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.ngbModalRef = this.releaseModalRef(component, new Release())
                    resolve(this.ngbModalRef)
                }, 0)
            }
        })
    }

    releaseModalRef(component: Component, release: Release): NgbModalRef {
        const modalRef = this.modalService.open(component, {size: 'lg', backdrop: 'static'})
        modalRef.componentInstance.release = release
        modalRef.result.then((result) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true})
            this.ngbModalRef = null
        }, (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true})
            this.ngbModalRef = null
        })
        return modalRef
    }
}
