<div class="tag-container">
    <div class="form-control tag-holder">
                <span *ngFor="let appliedTag of appliedTags">
                    <tag-input [tag]="appliedTag" [hasEditPermissions]="hasEditPermissions || appliedTag.isNotSaved" (remove)="removeTag(appliedTag)"></tag-input>
                </span>
        <div class="dropdown">
            <input type="text" name="tags" autocomplete="off" placeholder="{{'kstandards.tagInput.add' | translate}}" [(ngModel)]="criteria" (input)="showAvailableTags();" (ngModelChange)="emitCriteriaChangeEvent()" (keydown)="addKeyListeners($event)">
            <ul *ngIf="areAvailableTagsShown && !(getAvailableTags().length === 0 && !enableNewTags)" class="dropdown-content">
                <li *ngFor="let availableTag of getAvailableTags()" (mouseover)="changeSelectedTag(availableTag)" (click)="selectTag(availableTag)" [ngClass]="{'selected-tag' : selectedTag === availableTag}">
                    {{ availableTag.name }}
                </li>
                <li [jhiTranslate]="tagAlreadyApplied(criteria) ? 'kstandards.tagInput.tagAlreadyApplied' : 'kstandards.tagInput.create'" (click)="createNewTag()" *ngIf="!isLoadingSuggestions && getAvailableTags().length === 0 && enableNewTags">Press enter or click to create a new tag</li>
            </ul>
        </div>
    </div>
</div>
