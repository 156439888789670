<form role="form" novalidate *ngIf="content">
    <div class="row content-header">
        <div class="col-lg-10">
            <h2 *ngIf="!modal">
                <span *ngIf="content.id" jhiTranslate="kstandards.contentManager.translation.title" [translateValues]="{id:  content.id }">Translate
                    content {{ content.id }}</span>
            </h2>

            <!-- Language selection -->
            <h3><span jhiTranslate="kstandards.content.mode.translation.languages">Languages</span> <fa-icon icon="info-circle" placement="right" [ngbTooltip]="'kstandards.contentManager.translation.help.languages' | translate"></fa-icon></h3>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-check">
                        <input class="form-check-input" (change)="selectAllLanguages()" [(ngModel)]="allSelected" type="checkbox" name="translation-mode-select-all-languages" id="translation-mode-select-all-languages">
                        <label class="form-check-label" for="translation-mode-select-all-languages" jhiTranslate="kstandards.contentManager.translation.selectAll">
                            Select All
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-3" *ngFor="let language of languageOptions; trackBy:trackLanguage">
                    <div class="form-check">
                        <input class="form-check-input" (change)="updateSelectedLanguages()" type="checkbox" [(ngModel)]="language.checked" name="translation-mode-language-{{ language.id }}" id="translation-mode-language-{{ language.id }}">
                        <label class="form-check-label" for="translation-mode-language-{{ language.id }}">
                            {{ language.name }}
                        </label>
                    </div>
                </div>
            </div>

            <!-- Warnings -->
            <div class="alerts">
                <ngb-alert type="warning" [dismissible]="false" *ngFor="let warning of warningMessages"><span [jhiTranslate]="warning.code" [translateValues]="{line: warning.line}"></span></ngb-alert>
            </div>

            <!-- Text unit preview -->
            <div class="text-unit-preview" *ngIf="numSelectedLanguages > 0">
                <h3 jhiTranslate="kstandards.contentManager.translation.preview" id="preview-title">Preview</h3>
                <table class="table table-striped" aria-describedby="preview-title">
                    <thead>
                        <tr>
                            <th scope="col">Source</th>
                            <th scope="col">
                                <select name="preview-language" (change)="changePreviewLanguage()" [(ngModel)]="previewLanguage">
                                    <option [ngValue]="language" *ngFor="let language of selectedLanguages">{{ language.name
                                        }}</option>
                                </select>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let textUnit of textUnits">
                            <td>
                                <ng-container *ngFor="let sourceStringPart of textUnit.sourceParts">
                                    <ng-container *ngIf="sourceStringPart.type === 'TEXT'">{{ $any(sourceStringPart).text }}</ng-container>
                                    <span class="badge badge-success" *ngIf="sourceStringPart.type === 'TAG_PLACEHOLDER'" jhiTranslate="kstandards.contentManager.translation.placeholder" [translateValues]="{index: sourceStringPart.index }">Placeholder
                                        {{ $any(sourceStringPart).index }}</span>
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let targetStringPart of textUnit.targetParts">
                                    <ng-container *ngIf="targetStringPart.type === 'TEXT'">{{ $any(targetStringPart).text }}</ng-container>
                                    <span class="badge badge-success" *ngIf="targetStringPart.type === 'TAG_PLACEHOLDER'" jhiTranslate="kstandards.contentManager.translation.placeholder" [translateValues]="{index: targetStringPart.index }">Placeholder
                                        {{ $any(targetStringPart).index }}</span>
                                </ng-container>
                                <span *ngIf="textUnit.targetParts.length === 0" class="no-matching-target" jhiTranslate="kstandards.contentManager.translation.noMatchingTarget">No
                                    matching target</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div *ngIf="!modal" class="col-lg-2 global-buttons">
            <div class="form-group">
                <button type="button" class="btn btn-success" placement="left" openDelay="500" tooltipClass="wide-help-tooltip" [ngbTooltip]="'kstandards.contentManager.translation.help.create' | translate" (click)="createTranslationJobs()" jhiTranslate="kstandards.contentManager.translation.createTranslationJobs">Create translation jobs
                </button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-secondary" (click)="endTranslationMode()" jhiTranslate="kstandards.contentManager.translation.cancel">Cancel
                </button>
            </div>
        </div>
    </div>
</form>
