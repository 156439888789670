import { Subscription } from 'rxjs'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Report } from './report.model'
import { ResponseWrapper } from '@app/data-access'
import { ActivatedRoute, Router } from '@angular/router'
import { ReportService } from './report.service'
import { JhiAlertService, JhiEventManager } from 'ng-jhipster'
import { BaseReportComponent } from './report-base.component'
import { ReportType } from './report-types.model'
import { Manufacturer, ManufacturerService } from '@app/data-access'

@Component({
    selector: 'jhi-products-by-manufacturer-report',
    templateUrl: './report-products-by-manufacturer-dialog.component.html'
})
export class ReportProductsByManufacturerDialogComponent extends BaseReportComponent implements OnInit, OnDestroy {
    eventSubscriber: Subscription
    manufacturers: Manufacturer[]
    report: Report

    constructor(
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        private readonly manufacturerService: ManufacturerService,
        private readonly eventManager: JhiEventManager,
        protected readonly jhiAlertService: JhiAlertService,
        protected readonly reportsService: ReportService) {
        super(route, router, reportsService, jhiAlertService)
    }

    ngOnInit() {
        this.reportType = ReportType.PRODUCTS_BY_MANUFACTURER
        super.ngOnInit()
        this.manufacturerService.query(false, {sort: ['name,asc']})
            .subscribe((res: ResponseWrapper) => {
                this.manufacturers = res.json
            })

        this.registerChangeInReport()
    }

    public static trackManufacturerById(index: number, item: Manufacturer) {
        return item.id
    }

    onSaveSuccess(result: Report) {
        this.report = result
        if (this.isNew) {
            this.router.navigate(['/report/productsByManufacturer/' + result.id + '/edit'])
        }
    }

    registerChangeInReport() {
        this.eventSubscriber = this.eventManager.subscribe('reportDeleted', (response) => this.goToReportListPage())
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
        this.cancelledPolling.next(true)
        this.cancelledPolling.complete()
    }

    trackId(item): number {
        return item.id
    }
}
