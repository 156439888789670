<form class="form-inline" (ngSubmit)="search()">
    <input [(ngModel)]="searchWord" [ngbTooltip]="'kstandards.search.help.searchWord' | translate" placement="top" triggers="focus:blur" name="searchWord" class="form-control mr-sm-2" type="text">
    <tag-holder *ngIf="tags" class="mr-sm-2" id="field_tags" name="tags" [enableNewTags]="false" [availableTags]="availableTags" [(ngModel)]="appliedTags" (ngModelChange)="onTagsChange($event)" (changeCriteria)="searchSuggestions($event)"></tag-holder>
    <div class="form-group">
        <div class="form-check" *ngIf="searchFilter.length > 0">
            <input type="radio" id="search-filter-none" class="form-check-input" name="search_filter" [(ngModel)]="filterBy" value="none">
            <label for="search-filter-none" class="form-check-label search-filter-translation">{{'kstandards.search.none' | translate}}</label>
        </div>
        <div *ngFor="let sf of searchFilter; let i = index" class="form-check">
            <input type="radio" id="search-filter-{{ sf }}" class="form-check-input" name="search_filter" [(ngModel)]="filterBy" value="{{ sf }}">
            <label for="search-filter-{{ sf }}" [ngbTooltip]="'kstandards.search.help.filters.' + sf | translate" tooltipClass="help-tooltip" class="form-check-label search-filter-translation">{{'kstandards.search.' +
                sf | translate}}</label>
        </div>
        <div *ngIf="hasBuildingTypes" class="form-group">

            <div class="search-filter-buildingType">
                <select class="form-control custom-select" id="field_buildingType" name="buildingType" [(ngModel)]="buildingTypeId">
                    <option [ngValue]="null">{{'kstandards.search.buildingType' | translate}}</option>
                    <option [ngValue]="buildingType.id" *ngFor="let buildingType of buildingTypes">
                        {{buildingType.alias ? buildingType.alias : buildingType.name}}
                    </option>
                </select>
            </div>
            <label for="field_buildingType" class="sr-only">{{'kstandards.search.buildingType' | translate}}</label>
        </div>
    </div>
   
    <div *ngIf="createCountries && createCountries.length > 1">
        <ng-select [multiple]="true" [items]="createCountries" name="create_countries" id="field_createCountries" bindLabel="name" [closeOnSelect]="false" [trackByFn]="trackById" [compareWith]="compareCreateCountries" [(ngModel)]="selectedCreateCountries" (change)="search()" [placeholder]="'kstandards.search.createCountry' | translate">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span>{{ item.name }}</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-clear="clear" let-search="searchTerm">
                <span [ngOptionHighlight]="search">{{ item.name }}</span>
            </ng-template>
        </ng-select>
    </div>

    <button class="btn btn-info my-2 my-sm-0 mr-2 ml-2" type="submit" [disabled]="(!searchWord && appliedTags.length == 0) && !this.buildingTypeId && !this.selectedCreateCountries" jhiTranslate="kstandards.file.home.search">Search
    </button>


    <div class="form-check" *ngIf="showDeactivatedCheckbox" [ngbTooltip]="tooltipMessage" openDelay="500">
        <input [disabled]="!hasEditPermissions" type="checkbox" class="form-check-input" id="show-deactivated-items" name="include_deactivated_items" [(ngModel)]="showDeactivated" [value]="showDeactivated">
        <label for="show-deactivated-items" class="show-deactivated-items form-check-label" jhiTranslate="kstandards.search.showDeactivatedItems">Show Deactivated Items</label>
    </div>

    <button id="showAllButton" class="btn btn-primary my-2 my-sm-0" type="button" (click)="reset()" jhiTranslate="kstandards.file.home.showAll">Show all</button>
</form>