import { BaseEntity, ContentContainerEntity } from '../common'
import { Country } from '../country/country.model'
import { BuildingType } from '../building-type/building-type.model'
import { Material } from '../material/material.model'
import { Content } from '../content/content.model'

import { WorkflowStatusSummary } from '../common/workflow-status-summary.model'
import { ConstructionType } from '../common/construction-type.model'

export class Project implements BaseEntity, ContentContainerEntity {
    constructor(public id?: number,
                public name?: string,
                public urlFragment?: string,
                public description?: string,
                public workflowStatusSummary?: WorkflowStatusSummary,
                public constructionType?: ConstructionType,
                public country?: Country,
                public buildingType?: BuildingType,
                public materials?: Material[],
                public contents?: Content[],
                public version?: number,
                public projectYear?: number,
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string
    ) {
        if (!this.contents) {
            this.contents = []
        }
    }
}
