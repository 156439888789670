import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { BaseEntity } from '@app/data-access'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'ng-select-group-selectable',
    templateUrl: 'ng-select-group-selectable.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NgSelectGroupSelectableComponent),
            multi: true
        }
    ]
})
export class NgSelectGroupSelectableComponent implements ControlValueAccessor {


    model: any[]

    @Input()
    public items: any[]

    @Input()
    public groupBy: Function

    @Input()
    public readonly: boolean

    @Output()
    change: EventEmitter<any> = new EventEmitter()

    onChange: any

    onTouch: any

    trackBy(item): number {
        return item.id
    }

    compare(selected: BaseEntity, item: BaseEntity) {
        return selected.id === item.id
    }

    registerOnChange(fn: any): void {
        console.log('registerOnChange')
        this.onChange = fn
    }

    handleChange(value) {
        this.onChange(value)
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }

    writeValue(value: any) {
        if (value !== undefined && this.model !== value) {
            this.model = value
        }
    }

    setDisabledState?(isDisabled: boolean): void {
        // Not implemented
    }
}
