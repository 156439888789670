<div class="row">
    <div class="col-md-12">

        <div *ngIf="!isAuthenticated">
            <p class="lead" jhiTranslate="home.subtitle">Please, log in.</p>
        </div>

        <div class="row" *ngIf="isAuthenticated">
            <change-entry-table [forUser]="true" class="col col-12 col-sm-12 col-lg-12 col-xl-6 my-changes">
            </change-entry-table>
            <change-entry-table class="col col-12 col-sm-12 col-lg-12 col-xl-6 all-changes"></change-entry-table>
        </div>

    </div>
</div>