import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class JhiMetricsService {

    constructor(private readonly httpClient: HttpClient) {
    }

    getMetrics(): Observable<any> {
        return this.httpClient.get('management/metrics')
    }

    threadDump(): Observable<any> {
        return this.httpClient.get('management/dump')
    }
}
