import { Route } from '@angular/router'

import { LogsComponent } from './logs.component'
import { Roles } from '@app/auth'

export const logsRoute: Route = {
    path: 'logs',
    component: LogsComponent,
    data: {
        authorities: Roles.SUPERADMIN,
        pageTitle: 'logs.title'
    }
}
