export class DefaultLocaleSingleton {

    private static _instance: DefaultLocaleSingleton
    private _defaultLocale = 'de-DE'

    static get instance(): DefaultLocaleSingleton {
        if (!this._instance) {
            this._instance = new DefaultLocaleSingleton()
        }
        return this._instance
    }

    get defaultLocale(): string {
        return this._defaultLocale
    }

    set defaultLocale(value: string) {
        this._defaultLocale = value
    }
}
