import { WarningCode } from '../code/warning-code'

export class WarningMessage {

    constructor(private _code: WarningCode, private _line: string) {
        this._code = _code
        this._line = _line
    }

    get code(): WarningCode {
        return this._code
    }

    get line(): string {
        return this._line
    }
}
