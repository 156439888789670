import { Route } from '@angular/router'

import { HomeComponent } from './'
import { UserRouteAccessService } from '../auth/user-route-access-service'

export const HOME_ROUTE: Route = {
    path: '',
    component: HomeComponent,
    data: {
        authorities: [],
    },
    canActivate: [UserRouteAccessService]
}
