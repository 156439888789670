import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { HostCompanyIdEnum, OAuth2Service, RoleIdEnum } from '@app/auth'
import {
    BuildingType,
    Content,
    ContentType,
    Country,
    Language,
    MaterialCategoryService,
    ResponseWrapper,
    Translation,
    WorkflowStatus
} from '@app/data-access'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'
import { FlagUtil } from '@app/util/flag.util'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { cloneDeep } from 'lodash-es'
import { AttachmentPickerComponent, ContentEditMode, EditContext, FilePickerCategory, FilePickerComponent } from '.'
import { FinishedEditingEvent } from './editor-events'
import { MaterialCategory } from '../data-access'


/**
 * This component renders the edit view for a single content.
 *
 * It implements the ControlValueAccessor interface. When writeValue()
 * is called, the provided content is cloned deeply. Only if the user
 * triggers the "save" action, the changes are propagated to the
 * model.
 */
@Component({
    selector: 'content-edit',
    templateUrl: 'content-edit.component.html',
    styleUrls: ['content-edit.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContentEditComponent),
            multi: true
        }
    ]
})
export class ContentEditComponent implements OnInit, ControlValueAccessor {
    private readonly componentDestroyed: Subject<boolean> = new Subject()

    public FlagUtil = FlagUtil

    public boundContent: Content

    public content: Content

    @Input()
    public buildingTypes: BuildingType[]

    @Input()
    public countries: Country[]

    @Input()
    public languages: Language[]

    @Input()
    public hasConstructionType: boolean

    @Input()
    public hasComponentType: boolean

    @Input()
    public hasImplementationType: boolean

    @Input()
    public hasMaterialCategory: boolean

    @Input()
    public canSaveWithSignificantChanges: boolean

    @Input()
    public hasRoombookType = false

    @Input()
    public hasProductListType = false

    @Input()
    public hasChangelogType = false

    @Input()
    public editContext: EditContext

    /**
     * Some contents can have additional scope, such that they are only valid
     * in specific countries or for specific building types.
     */
    @Input()
    public hasAdditionalScope: boolean

    @Input()
    public resetWorkflowStatus = false

    @Output()
    public finishedEditing = new EventEmitter<FinishedEditingEvent>()

    @Output()
    public temporaryChange = new EventEmitter<boolean>()

    public defaultTranslation: Translation

    public selectedLanguage: Language

    public selectedTranslation: Translation

    public mode: ContentEditMode = ContentEditMode.EDIT

    public readonly EDIT_MODE = ContentEditMode.EDIT
    public readonly DIFF_MODE = ContentEditMode.DIFF

    public scopeCollapsed = true

    public implementationTypesArr = [
        'IMPLEMENTATION_TYPE_1',
        'IMPLEMENTATION_TYPE_2',
        'IMPLEMENTATION_TYPE_3',
        'IMPLEMENTATION_TYPE_4',
        'IMPLEMENTATION_TYPE_5',
        'IMPLEMENTATION_TYPE_6',
        'IMPLEMENTATION_TYPE_7',
        'IMPLEMENTATION_TYPE_8',
        'IMPLEMENTATION_TYPE_9',
    ]

    public materialCategoryOptions: MaterialCategory[]

    public locale = 'en'
    public originalWorkflowStatus: WorkflowStatus

    public isPrimaryLanguageEditingContainerReadOnly: boolean

    public propagateChange = (_: any) => {
    }

    public hasEditPermissions: boolean

    public hasCreatePermissions: boolean

    public hasSavePermissions: boolean

    public isNewContent: boolean

    constructor(private readonly modalService: NgbModal,
        private readonly translateService: TranslateService,
        readonly materialCategoryService: MaterialCategoryService,
        private readonly oauth2Service: OAuth2Service
    ) {
    }

    ngOnInit() {
        this.locale = this.translateService.currentLang

        this.translateService.onLangChange.subscribe((langChange) => {
            this.locale = langChange.lang
        })

        if (this.hasMaterialCategory) {
            this.materialCategoryService.query(false, { sort: ['id,asc'] })
                .pipe(takeUntil(this.componentDestroyed))
                .subscribe((res: ResponseWrapper) => {
                    const result = res.json
                    this.materialCategoryOptions = result.content
                })
        }
    }

    public writeValue(content: Content) {
        if (content !== undefined && content !== null) {
            this.boundContent = content
            this.content = cloneDeep(content)

            this.setEditPermissions()

            this.setPrimaryLanguageEditorStatus()

            if (this.resetWorkflowStatus) {
                this.originalWorkflowStatus = this.content.workflowStatus
                this.content.workflowStatus = WorkflowStatus.DRAFT
            }
            const defaultLanguage = this.languages.find(
                language => language.locale === DefaultLocaleSingleton.instance.defaultLocale
            )
            this.defaultTranslation = this.maybeCreateTranslationForLanguage(defaultLanguage)

            this.selectedLanguage = this.languages.find(
                language => language.locale !== DefaultLocaleSingleton.instance.defaultLocale
            )

            if (this.selectedLanguage) {
                this.switchSelectedLanguage()
            }

            if (!content.id) {
                this.scopeCollapsed = false
            }
        }
    }

    private setEditPermissions() {
        const countryCodeFromUser = this.oauth2Service.countryCodeFromActiveRole$.getValue()
        this.isNewContent = !this.content.id

        this.hasEditPermissions = this.isNewContent || (!this.isNewContent && (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.KStandards
            || countryCodeFromUser?.toLowerCase() === RoleIdEnum.International
            || this.content?.createCountry?.code?.toLowerCase() === countryCodeFromUser?.toLowerCase()
        ))

        this.hasCreatePermissions = this.isNewContent || this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.KStandards
            || countryCodeFromUser?.toLowerCase() === RoleIdEnum.International
            || this.content?.createCountry?.code?.toLowerCase() === countryCodeFromUser?.toLowerCase()

        this.hasSavePermissions = this.isNewContent || this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.KStandards
            || countryCodeFromUser?.toLowerCase() === RoleIdEnum.International
    }

    private setPrimaryLanguageEditorStatus() {
        if (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.KStandards) {
            this.isPrimaryLanguageEditingContainerReadOnly = false
        }
        else if (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.LStandards) {
            // undefined = new content without createCountry
            const isCreateCountryInternational: boolean = this.content.createCountry && this.content.createCountry.code?.toLowerCase() === RoleIdEnum.International

            const isLidlRoleInternational: boolean = this.oauth2Service.countryCodeFromActiveRole$.getValue() === RoleIdEnum.International

            this.isPrimaryLanguageEditingContainerReadOnly = isCreateCountryInternational === undefined
                ? false
                : !isLidlRoleInternational && isCreateCountryInternational
        }
    }

    public registerOnChange(fn) {
        this.propagateChange = fn
    }

    public registerOnTouched() {

    }

    public save(isSignificantChange?: boolean) {
        this.boundContent = cloneDeep(this.content)
        this.propagateChange(this.boundContent)
        this.finishedEditing.emit(new FinishedEditingEvent(true, isSignificantChange))
    }

    public clear() {
        this.finishedEditing.emit(new FinishedEditingEvent(false, false))
    }

    public showMode(mode: ContentEditMode) {
        this.mode = mode
    }

    public onTemporaryChange($event: Event): void {
        this.temporaryChange.emit(true)
    }

    private maybeCreateTranslationForLanguage(language: Language): Translation {
        let translationForLanguage = this.content.translationForLanguage(language)
        if (!translationForLanguage) {
            const newTranslation = new Translation()
            newTranslation.language = language
            this.content.translations.push(newTranslation)
            translationForLanguage = newTranslation
        }

        return translationForLanguage
    }

    public switchSelectedLanguage(): void {
        const translationForLanguage = this.maybeCreateTranslationForLanguage(this.selectedLanguage)
        this.selectedTranslation = translationForLanguage
    }

    public useFileAsDefault(): void {
        this.languages.forEach((language) => {
            const translationForLanguage = this.maybeCreateTranslationForLanguage(language)
            translationForLanguage.file = this.defaultTranslation.file
        })
    }

    private getFilePickerCategory(): FilePickerCategory {
        return FilePickerCategory.ALL
    }

    public openFilePicker(translation: Translation) {
        const modalRef: NgbModalRef = this.modalService.open(FilePickerComponent as Component, { size: 'lg' })

        modalRef.componentInstance.category = this.getFilePickerCategory()

        if (translation.file) {
            modalRef.componentInstance.firstSelectedFile = translation.file
        }

        modalRef.result.then((selectedFile) => {
            translation.file = selectedFile
        }).catch((reason) => {
        })
    }

    public removeFile(translation: Translation) {
        translation.file = null
    }

    public openAttachmentPicker() {
        const modalRef: NgbModalRef = this.modalService.open(AttachmentPickerComponent as Component, { size: 'lg' })
        const component: AttachmentPickerComponent = modalRef.componentInstance

        if (this.content.linkedAttachment) {
            component.firstSelectedAttachment = this.content.linkedAttachment
        }

        modalRef.result.then((selectedAttachment) => {
            this.content.linkedAttachment = selectedAttachment
        }).catch((reason) => {
        })
    }

    removeLinkedAttachment() {
        this.content.linkedAttachment = null
    }

    private wouldRemoveIncompatibleFiles(newContentType: string): boolean {

        const hasAnyFiles = this.content.translations.filter((translation: Translation) => !!translation.file).length > 0

        // If we don't have any files attached to any translation, there won't be a problem
        if (!hasAnyFiles) {
            return false
        }

        // When switching to any other type than file, we warn the user that attached files will be lost
        if (newContentType !== ContentType.FILE) {
            return true
        }

        return false

    }

    wouldRemoveLinkedAttachment(newContentType): boolean {
        return newContentType !== ContentType.ATTACHMENT_LINK && !!this.content.linkedAttachment
    }

    public maybeWarnUserAboutContentTypeChange($event: Event, newContentType: string) {
        if (this.wouldRemoveIncompatibleFiles(newContentType) && !confirm(this.translateService.instant('kstandards.content.warnAboutDestructiveContentTypeChange'))) {
            $event.preventDefault()
            $event.stopPropagation()
        }

        if (this.wouldRemoveLinkedAttachment(newContentType) && !confirm(this.translateService.instant('kstandards.content.warnAboutRemovingLinkedAttachment'))) {
            $event.preventDefault()
            $event.stopPropagation()
        }
    }

    onContentTypeChange($event: Event) {
        const contentType = this.content.contentType

        if (contentType !== ContentType.ATTACHMENT_LINK) {
            this.content.linkedAttachment = null
        }

        this.content.translations.forEach((translation: Translation) => {
            if (translation.file) {

                if (ContentType.FILE !== contentType) {
                    translation.file = null
                }
            }
        })
    }

    // Disable the Enter key for single-line text fields
    handleSingleLineKeydown($event: KeyboardEvent) {
        if ($event.keyCode === 13) {
            $event.preventDefault()
        }
    }

    trackById(buildingType: BuildingType | Country): number {
        return buildingType.id
    }

    editScope() {
        this.scopeCollapsed = false
    }

    selectAllCountries() {
        this.content.countries = this.countries
    }

    selectAllBuildingTypes() {
        this.content.buildingTypes = this.buildingTypes
    }

    compareBuildingTypes(selected: BuildingType, item: BuildingType) {
        return selected.id === item.id
    }

    compareCountries(selected: Country, item: Country) {
        return selected.id === item.id
    }
}
