import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core'
import { Tag } from '../../data-access'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'tag-holder',
    templateUrl: 'tag-holder.component.html',
    styleUrls: ['tag-holder.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagHolderComponent),
            multi: true
        }
    ]
})
export class TagHolderComponent implements OnChanges, ControlValueAccessor {

    @Input()
    public enableNewTags = true

    @Input()
    public availableTags: Tag[] = []

    @Input()
    public tagFactory: any

    @Input()
    public hasEditPermissions = true

    // Model
    public appliedTags: Tag[] = []

    public onChangeCallback: (_: any) => {}

    @Output()
    public changeCriteria: EventEmitter<string> = new EventEmitter<string>(true)

    /*
    Used for tag filtering.
     */
    public criteria = ''

    public selectedTag: Tag = null

    public areAvailableTagsShown: boolean

    public isLoadingSuggestions = false

    constructor(private _eref: ElementRef) {

    }

    writeValue(tags: Tag[]): void {
        this.appliedTags = tags
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }

    registerOnTouched(fn: any): void {
        // Ignore
    }

    /**
     *
     * Check if suggested available tags have changed.
     */
    ngOnChanges(changes: SimpleChanges) {
        const newAvailableTags = changes.availableTags.currentValue
        if (newAvailableTags) {
            this.availableTags = newAvailableTags
            this.selectFirstAvailableTag()
            this.isLoadingSuggestions = false
        }
    }

    /**
     *
     * Get all available tags for the dropdown.
     * @returns {Tag[]} - available tags to choose from.
     */
    getAvailableTags(): Tag[] {
        return this.availableTags
            .filter((availableTag) => this.appliedTags.map((appliedTag) => appliedTag.name).indexOf(availableTag.name) === -1)
            .filter((availableTag) => availableTag.name.toLowerCase().includes(this.criteria.toLowerCase().trim()))
    }

    /**
     *
     * Apply actions on key events.
     * keyCode === 40 means Down.
     * keyCode === 38 means Up.
     * keyCode === 13 means Enter.
     * keyCode === 9 means Tab.
     * keyCode === 8 means Backspace.
     * keyCode === 27 means Escape.
     * @param event - key event.
     */
    addKeyListeners(event) {
        const KEY_DOWN = 40
        const KEY_UP = 38
        const KEY_TAB = 9
        const KEY_ENTER = 13
        const KEY_BACKSPACE = 8
        const KEY_ESCAPE = 27

        const availableTags = this.getAvailableTags()
        let currentIndex
        switch (event.keyCode) {
            // If down select next
            case KEY_DOWN:
                let nextIndex = 0
                if (!this.selectedTag) {
                    this.selectedTag = availableTags[nextIndex]
                    return
                }

                currentIndex = availableTags.indexOf(this.selectedTag)
                if (currentIndex < availableTags.length - 1) {
                    nextIndex = currentIndex + 1
                }

                this.selectedTag = availableTags[nextIndex]
                break
            // If up select previous
            case KEY_UP:
                let prevIndex = availableTags.length - 1
                if (!this.selectedTag) {
                    this.selectedTag = availableTags[prevIndex]
                    return
                }

                currentIndex = availableTags.indexOf(this.selectedTag)
                if (currentIndex > 0) {
                    prevIndex = currentIndex - 1
                }

                this.selectedTag = availableTags[prevIndex]
                break
            // Enter or Tab
            case KEY_TAB:
            case KEY_ENTER:
                event.preventDefault()
                this.selectedTag ? this.addTag(this.selectedTag) : this.addTag(this.tagFactory(this.criteria))
                this.clean()
                this.hideAvailableTags()
                break
            // Backspace
            case KEY_BACKSPACE:
                if (this.criteria.length === 0 && (this.hasEditPermissions || this.appliedTags[this.appliedTags.length - 1].isNotSaved)) {
                    this.removeLastTag()
                }
                break
            // Escape
            case KEY_ESCAPE:
                this.hideAvailableTags()
        }
    }

    /**
     * Show available tags.
     */
    showAvailableTags() {
        this.areAvailableTagsShown = true
    }

    /**
     * Select the first in the menu.
     */
    selectFirstAvailableTag() {
        this.selectedTag = this.getAvailableTags()[0]
    }

    /**
     *
     * Select a tag from the available ones.
     * @param {Tag} availableTag
     */
    selectTag(availableTag: Tag) {
        this.addTag(availableTag)
        this.clean()
        this.hideAvailableTags()
    }

    /**
     *
     * Select tag.
     * @param {Tag} availableTag - the new selected tag.
     */
    changeSelectedTag(availableTag: Tag) {
        this.selectedTag = availableTag
    }

    /**
     *
     * Create a new tag.
     */
    createNewTag() {
        this.addTag(this.tagFactory(this.criteria))
        this.clean()
        this.hideAvailableTags()
    }

    /**
     *
     * Remove tag.
     * @param {Tag} appliedTag - tag to be removed.
     */
    removeTag(appliedTag: Tag) {
        const index = this.appliedTags.indexOf(appliedTag)
        this.appliedTags.splice(index, 1)
        this.onChangeCallback(this.appliedTags)
    }

    /**
     *
     * Remove the last tag.
     */
    removeLastTag() {
        this.appliedTags.pop()
        this.onChangeCallback(this.appliedTags)
    }

    /**
     *
     * When you click outside of the element hide the suggestions.
     * @param event - outside click.
     */
    @HostListener('document:click', ['$event'])
    onClickOutside(event) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.hideAvailableTags()
        }
    }

    emitCriteriaChangeEvent() {
        if (!this.isLoadingSuggestions) {
            this.isLoadingSuggestions = true
            this.changeCriteria.emit(this.criteria)
        }
    }

    /**
     *
     * Add a tag if it is not added yet.
     * Also ignore tags with no names.
     * @param {Tag} tag - new tag.
     */
    private addTag(tag: Tag) {
        // If we are loading suggestions don't add.
        if (this.isLoadingSuggestions) {
            return
        }

        // Check if the tag is valid.
        if (!tag || tag.name.trim().length === 0) {
            return
        }

        // Check if we allow new tags.
        if (!this.enableNewTags && !tag.id) {
            return
        }

        // Check if the tag is applied
        if (this.tagAlreadyApplied(tag.name)){
            return
        }

        tag.isNotSaved = true
        this.appliedTags.push(tag)
        this.onChangeCallback(this.appliedTags)
    }

    tagAlreadyApplied(tagName: string){
        return this.appliedTags.some((appliedTag) => appliedTag.name.toLowerCase().trim() === tagName.toLowerCase().trim())
    }

    /**
     *
     * Clean the criteria and the selected tag after the adding.
     */
    private clean() {
        this.criteria = ''
        this.selectedTag = null
    }

    /*
     *
     * Hide available tags.
     */
    private hideAvailableTags() {
        this.areAvailableTagsShown = false
    }
}
