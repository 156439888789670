import { Report } from './report.model'
import { takeUntil } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router'
import { ITEMS_PER_PAGE } from '../../shared'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subject } from 'rxjs'
import { ReportService } from './report.service'
import { JhiEventManager } from 'ng-jhipster'
import { Subscription } from 'rxjs/index'

@Component({
    selector: 'reports-by-type-list',
    templateUrl: './reports-by-type.component.html'
})
export class ReportByTypeListComponent implements OnInit, OnDestroy {
    private componentDestroyed: Subject<boolean> = new Subject()
    eventSubscriber: Subscription
    reports: Report[]

    links: any
    page: any
    itemsPerPage: number
    predicate: any
    reverse: any
    totalItems: number
    currentReportId: number

    @Input()
    report: Report

    @Output() downloadReport = new EventEmitter<number>()

    constructor(private readonly reportsService: ReportService,
                protected router: Router,
                protected route: ActivatedRoute,
                private readonly eventManager: JhiEventManager, ) {
        this.reports = []
        this.itemsPerPage = ITEMS_PER_PAGE
        this.page = 0
        this.links = {
            last: 0
        }
        this.predicate = 'id'
        this.reverse = false
    }

    ngOnInit() {
        this.currentReportId = parseInt(this.route.snapshot.params['id'], 10)
        this.registerChangeReportsDelete()
        this.loadAll()
    }

    redirect(report: Report) {
        this.router.navigate(['/report/' + report.getReportEditLink() + '/' + report.id + '/edit'])
        this.currentReportId = report.id
        this.loadAll()
    }

    trackId(index: number, item: Report) {
        return item.id
    }

    registerChangeReportsDelete() {
        this.eventSubscriber = this.eventManager.subscribe('reportsDeleteByType', (response) => this.loadAll())
    }

    loadAll() {
        this.reportsService.findAllByType(this.report.reportType, {sort: this.sort()})
            .pipe(
                takeUntil(this.componentDestroyed)
            ).subscribe(
            (reports) => {
                this.reports = reports
                if (this.currentReportId) {
                    this.reports = reports.filter((report) =>
                            report.id !== this.currentReportId
                    )
                }
            }
        )
    }

    sort() {
        if (this.predicate.startsWith('deletedAt')) {
            return [this.predicate + ',' + (this.reverse ? 'desc' : 'asc')]
        }
        return [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')]
    }

    public goToReportEditPage(report: Report) {
        this.router.navigate(['/report/' + report.getReportEditLink() + '/' + report.id + '/edit'])

    }

    reset() {
        this.loadAll()
    }

    download(id) {
        this.downloadReport.emit(id)
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
        this.eventManager.destroy(this.eventSubscriber)
    }
}
