import { Route } from '@angular/router'

import { JhiMetricsMonitoringComponent } from './metrics.component'
import { Roles } from '@app/auth'

export const metricsRoute: Route = {
    path: 'jhi-metrics',
    component: JhiMetricsMonitoringComponent,
    data: {
        authorities: Roles.SUPERADMIN,
        pageTitle: 'metrics.title'
    }
}
