import { Component, OnDestroy, OnInit, HostBinding } from '@angular/core'
import { JhiAlertService } from 'ng-jhipster'

@Component({
    selector: 'jhi-alert',
    template: `<ngb-toast [autohide]="true" [delay]="5000" [ngClass]="setClasses(alert)" (hide)="alert.close()" *ngFor="let alert of alerts"><div [innerHtml]="alert.msg"></div></ngb-toast>`,
})
export class JhiAlertComponent implements OnInit, OnDestroy {
    alerts: any[]

    constructor(private readonly alertService: JhiAlertService) { }

    @HostBinding('class.toasts')
    someField = true

    ngOnInit() {
        this.alerts = this.alertService.get()
    }

    ngOnDestroy() {
        this.alerts = []
    }

    setClasses(alert) {
        return {
            ['alert-' + alert.type]: true
        }
    }

}
