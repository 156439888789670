import { BaseEntity, ContentContainerEntity } from '../common'
import { Content } from '../content/content.model'
import { MaterialGroup } from '../material-group/material-group.model'
import { Arrangeable } from '../common/arrangeable'
import { RoomBookColumn } from '../room-book-column'
import { WorkflowStatusSummary } from '../common/workflow-status-summary.model'
import { Linkable } from '../common/linkable'
import { Deletable } from '../common/deletable'
import { BuildingType } from '../building-type'
import { Attachment } from '../attachment'

export class MaterialSubGroup implements BaseEntity, ContentContainerEntity, Arrangeable, Linkable, Deletable {
    constructor(public id?: number,
                public name?: string,
                public description?: string,
                public order?: number,
                public workflowStatusSummary?: WorkflowStatusSummary,
                public contents?: Content[],
                public materialGroup?: MaterialGroup,
                public roomBookColumn?: RoomBookColumn,
                public version?: number,
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string,
                public deletable?: boolean,
                public buildingTypes?: BuildingType[],
                public linkedAttachments?: Attachment[],
            ) {
        if (!this.buildingTypes) {
            this.buildingTypes = []
        }
    }

    get allContents(): Content[] {
        return (Object as any).values(this.contents)
            .reduce((accumulator, contents) => accumulator.concat(contents), [])
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }
        return `/material-sub-group/${this.id}/edit`
    }

    getLabelForArrangeableList(): string {
        return this.name
    }
}
