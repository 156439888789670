import { Injectable } from '@angular/core'
import { ProjectCopyProgressReport } from '@app/data-access'
import { TokenParser } from '@app/oauth2/parsers/token.parser'
import * as RxStomp from '@stomp/rx-stomp'
import { OAuthService } from 'angular-oauth2-oidc'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as SockJS from 'sockjs-client'

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    private rxStomp: RxStomp.RxStomp

    constructor(private readonly oauthService: OAuthService) { }

    connect(): void {

        // We don't need to do anything if we already have an RxStomp client.
        // Instead, the existing instance will reconnect with the current token
        // after 10 seconds.
        if (this.rxStomp) {
            return
        }

        const stompConfig: RxStomp.RxStompConfig = {
            webSocketFactory: () => new SockJS(`${location.protocol}//${location.host}/websocket/registry?access_token=${this.oauthService.getAccessToken()}`),

            // If disconnected, it will retry after 10 seconds
            reconnectDelay: 10000,

            beforeConnect: (client) => {

                const parsedToken = TokenParser.parse(this.oauthService.getAccessToken())
                // If there is no token or the token is expired, don't try to connect
                if (parsedToken === null || parsedToken.activeUntil < new Date().getTime()) {
                    console.log('Token not available or expired. Disconnecting WebSocket.')
                    client.deactivate()
                    return
                }


            }
        }

        // Create an instance. The first RxStomp is the UMD module name and other is the class name
        this.rxStomp = new RxStomp.RxStomp()

        // You can set additional configuration here
        this.rxStomp.configure(stompConfig)

        // Attempt to connect
        this.rxStomp.activate()

    }

    disconnect(): void {
        if (this.rxStomp) {
            this.rxStomp.deactivate()
        }
    }

    subscribeToReleaseUpdates(releaseId: number): Observable<string> {
        return this.rxStomp.watch(`/topic/release.${releaseId}`).pipe(map((message) => message.body))
    }

    subscribeToProjectCopy(sourceProjectId: number, destinationProjectId: number): Observable<ProjectCopyProgressReport> {
        return this.rxStomp.watch(`/topic/project-copy.${sourceProjectId}.${destinationProjectId}`).pipe(map((message) => JSON.parse(message.body)))
    }
}
