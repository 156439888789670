
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { Content } from './content.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { ContentView } from './content-view.model'
import { EntityService } from '../entity'

@Injectable()
export class ContentService implements EntityService<Content> {

    private readonly resourceUrl = SERVER_API_URL + 'api/contents'

    private readonly engineSearchUrl = SERVER_API_URL + 'api/_search/contents'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(content: Content): Observable<Content> {
        const copy = this.convert(content)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(content: Content): Observable<Content> {
        const copy = this.convert(content)
        return this.httpClient.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<Content> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    getXliff(id: number, targetLanguage: number): Observable<string> {
        return this.httpClient.get(`${this.resourceUrl}/${id}/xliff/${targetLanguage}`, {responseType: 'text'})
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res, true)))
    }

    getAllContentsByFileId(id: number): Observable<ResponseWrapper> {
        return this.httpClient.get(`${this.resourceUrl}/file/${id}`, {observe: 'response'}).pipe(
            map((res) => this.convertResponse(res, true)))
    }

    getAllMaterialAttachmentUsagesByAttachmentId(id: number): Observable<ResponseWrapper> {
        return this.httpClient.get(`${this.resourceUrl}/material-attachment-usage/${id}`, {observe: 'response'}).pipe(
            map((res) => this.convertResponse(res, true)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.engineSearchUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res, true)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    queryUnmappedContents(req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req)
        return this.httpClient.get(`${this.resourceUrl}/unmapped`, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchUnmappedContents(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(`${this.engineSearchUrl}/unmapped`, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    private convertResponse(res: any, isView?: boolean): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i], isView))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Content.
     */
    private convertItemFromServer(json: any, isView?: boolean): Content {
        const entity: Content = isView ? Object.assign(new ContentView(), json) : Object.assign(new Content(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.createCountry) {
            entity.createCountry.code = entity.createCountry.code.toLowerCase()
        }

        return entity
    }

    /**
     * Convert a Content to a JSON which can be sent to the server.
     */
    private convert(content: Content): any {
        const copy: any = Object.assign({}, content)
        return copy
    }
}
