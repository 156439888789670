<div>
    <h2>
        <span id="table-title" jhiTranslate="kstandards.change.home.title">Changelogs</span>
        <button class="btn btn-primary float-right jh-create-entity create-language" [routerLink]="['/changelog/'+changelogId+'/change-new']">
            <fa-icon icon="plus"></fa-icon>
            <span jhiTranslate="kstandards.change.home.createLabel">
            Create new Change
            </span>
        </button>
        <button [routerLink]="['/changelog/'+changelogId+'/translate']" class="btn btn-info float-right jh-create-entity create-language button-right">
            <fa-icon icon="language"></fa-icon>
            <span jhiTranslate="kstandards.changelog.translate.translateButton">
                Translate Changes
            </span>
        </button>
        <button [routerLink]="['/changelog/'+changelogId+'/arrange-changes']" class="btn btn-info float-right jh-create-entity create-language button-right">
            <span jhiTranslate="kstandards.change.arrangeChangesButton">
                Arrange Changes
            </span>
        </button>
        <button class="btn btn-secondary float-right jh-create-entity create-language button-right" [routerLink]="['/changelog/'+changelogId+'/edit']">
            <fa-icon icon="ban"></fa-icon>
            <span jhiTranslate="entity.action.cancel">
            Cancel
            </span>
        </button>
    </h2>
    <br>
    <div class="table-hover" *ngIf="changes">
        <table class="table table-striped" aria-describedby="table-title">
            <thead>

            <tr jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="reset.bind(this)">
                <th scope="col" jhiSortBy="id"><span jhiTranslate="global.field.id">ID</span><fa-icon icon="sort"></fa-icon> </th>
                <th scope="col" jhiSortBy="order"><span jhiTranslate="kstandards.change.home.order">Order</span> <fa-icon icon="sort"></fa-icon></th>
                <th scope="col"><span jhiTranslate="kstandards.change.home.shortDescriptionLabel">Short description</span> </th>
                <th scope="col" jhiSortBy="constructionType"><span jhiTranslate="kstandards.change.constructionType">Name</span> <fa-icon icon="sort"></fa-icon></th>
                <th scope="col"><span jhiTranslate="kstandards.change.currentStatusDescription">Current status</span> </th>
                <th scope="col"><span jhiTranslate="kstandards.change.responsibleEditor">Responsible editor</span> </th>
                <th scope="col" jhiSortBy="updatedAt"><span jhiTranslate="kstandards.language.updatedAt">Updated at</span> <fa-icon icon="sort"></fa-icon></th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody infiniteScroll (scrolled)="loadPage(page + 1)" [infiniteScrollThrottle]="50" [infiniteScrollDistance]="2">
            <tr *ngFor="let change of changes ;trackBy: trackId">
                <td><a [routerLink]="['/changelog/'+ change.changelog.id + '/change/'+change.id+'/edit']">{{change.id}}</a></td>
                <td>{{ change.order }}</td>
                <td [innerHTML]="change.shortDescription?.defaultTranslation?.textContent | previewMaterialCategoryIcon : change.shortDescription?.materialCategory"></td>
                <td>{{ 'kstandards.ConstructionType.'+change.constructionType | translate}}</td>
                <td>{{ change.currentStatusDescription }}</td>
                <td>{{ change.responsibleEditor }}</td>
                <td>{{ change.updatedAt }}</td>
                <td class="text-right actions">
                    <div class="btn-group flex-btn-group-container">
                        <button type="submit" [routerLink]="['/changelog/'+ change.changelog.id + '/change/'+change.id+'/edit']" class="btn btn-primary btn-sm">
                            <fa-icon icon="pencil-alt"></fa-icon>
                            <span class="d-none d-md-inline" jhiTranslate="entity.action.edit">Edit</span>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
