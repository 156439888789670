import { AfterViewInit, Component, EventEmitter, OnDestroy, ViewChild } from '@angular/core'
import { Content } from '@app/data-access'
import { ContentTranslationComponent } from '@app/content-manager/content-translation.component'

@Component({
    selector: 'content-translation-modal',
    templateUrl: 'content-translation-modal.component.html',
})
export class ContentTranslationModalComponent implements AfterViewInit, OnDestroy {

    languages = []

    content: Content

    finished = new EventEmitter<any>()

    @ViewChild(ContentTranslationComponent)
    contentTranslationComponent: ContentTranslationComponent

    ngOnDestroy(): void {
    }

    close(): void {
        this.contentTranslationComponent.endTranslationMode()
    }

    ngAfterViewInit(): void {
        this.contentTranslationComponent.finished.subscribe(() => {
            this.finished.emit(true)
        })
    }

}
