import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators'
import { BehaviorSubject, combineLatest as observableCombineLatest, merge as observableMerge, of, Subject } from 'rxjs'
import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms/'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiAlertService } from 'ng-jhipster'

import { ResponseWrapper, File as FileModel } from '@app/data-access'
import { HttpErrorResponse } from '@angular/common/http'
import { Tag, FileService, TagService } from '@app/data-access'
import { FileTag } from '@app/data-access'

export enum FilePickerCategory {
    ALL = 'ALL',
    IMAGE = 'IMAGE',
    PDF = 'PDF'
}

@Component({
    selector: 'jhi-file-picker',
    templateUrl: 'file-picker.component.html',
    styleUrls: ['file-picker.scss']
})
export class FilePickerComponent implements OnInit {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    @Input()
    category = FilePickerCategory.ALL

    @Input()
    firstSelectedFile: FileModel

    page = 1

    pageSubject = new BehaviorSubject<number>(1)
    termSubject = new BehaviorSubject<string>('')
    categorySubject = new BehaviorSubject<FilePickerCategory>(FilePickerCategory.ALL)
    tagsSubject = new BehaviorSubject<Tag[]>([])

    searchField = new FormControl()
    categoryField = new FormControl()

    public itemsPerPage = 12

    public totalCount: number

    public files: FileModel[]
    public hasLoaded = false
    public selectedFile: FileModel

    public availableTags: Tag[] = []
    public appliedTags: Tag[] = []

    constructor(
        public activeModal: NgbActiveModal,
        private readonly fileService: FileService,
        private readonly tagService: TagService,
        private readonly jhiAlertService: JhiAlertService
    ) { }

    ngOnInit() {
        this.selectedFile = this.firstSelectedFile

        this.categoryField.setValue(this.category)
        this.searchField.setValue('')

        this.searchField.valueChanges
        .pipe(
            debounceTime(200),
            distinctUntilChanged()
        )
        .subscribe((value) => this.termSubject.next(value))

        this.categoryField.valueChanges.subscribe((value) => {
            this.categorySubject.next(value)
        })

        // If either the term or category changes, we jump back to page 1
        const termCategoryChange = observableMerge(this.categorySubject, this.termSubject)
        termCategoryChange.subscribe((_) => {
            this.page = 1
            this.pageSubject.next(1)
        })

        // We need to debounce the state change events by a tiny amount
        // in case there is an additional jump to page 1
        const stateChange = observableCombineLatest(
            this.categorySubject,
            this.termSubject,
            this.pageSubject,
            this.tagsSubject
        ).pipe(debounceTime(1))

        stateChange.pipe(
            switchMap(([category, term, page, tags]) => {
                return this.fileService.searchFilesByName(
                    this.getQueryES(category, term, tags), {
                        page: page - 1,
                        size: this.itemsPerPage,
                        sort: ['updatedAt,desc']
            }).pipe(catchError((err) => of(null)))

            }),
            )
            .subscribe(
                (res: ResponseWrapper) => this.onSuccess(res),
                (res: HttpErrorResponse) => this.onError(res.error)
            )

    }

    onTagsChange(newTags) {
        this.tagsSubject.next(newTags)
    }

    tagFactory(name) {
        return new FileTag(name)
    }

    searchSuggestions(criteria: string) {
        this.tagService
            .suggest(criteria)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((res) => this.availableTags = res.json)
    }

    private onSuccess(res: ResponseWrapper) {
        if (!res) {
            return
        }
        this.totalCount = parseInt(res.headers.get('X-Total-Count'), 10)

        this.files = res.json
        this.hasLoaded = true
    }

    private getQueryES(category, term, tags) {
        const query = {}
        const contentTypeFilter = this.getContentTypeFilter(category)
        query['name'] = term
        query['mainFile.fileType'] = contentTypeFilter != null ? contentTypeFilter : ''
        query['tags'] = tags
        return query
    }

    private getContentTypeFilter(category: FilePickerCategory) {
        if (category === FilePickerCategory.IMAGE) {
            return 'image/'
        }

        if (category === FilePickerCategory.PDF) {
            return 'application/pdf'
        }

        return null
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
    }

    clear() {
        this.activeModal.dismiss()
    }

    save() {
        this.activeModal.close(this.selectedFile)
    }

    selectFile(file: FileModel) {
        this.selectedFile = file
    }

    onPageChange(page) {
        this.pageSubject.next(page)
    }

}
