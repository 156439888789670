<div *ngIf="content.contentType === 'TEXT'" [innerHtml]="content.textContentInLanguage(language) | preview : content.materialCategory?.icon?.presignedUrl"></div>
<figure *ngIf="content.contentType === 'FILE'  && content.translationForLanguage(language)?.file?.mainFile?.fileType.startsWith('image/')">
    <embedded-image [file]="content.translationForLanguage(language).file"></embedded-image>
    <br>
    <a [routerLink]="['/file/'+content.translationForLanguage(language).file.id+'/edit']">{{ content.translationForLanguage(language)?.file.name }}</a>
    <figcaption [innerHtml]="content.textContentInLanguage(language) | preview"></figcaption>
</figure>
<figure *ngIf="content.contentType === 'FILE'  && content.translationForLanguage(language)?.file?.mainFile?.fileType.startsWith('application/pdf')">
    <pdf-preview [file]="content.translationForLanguage(language).file"></pdf-preview>
    <a [routerLink]="['/file/'+content.translationForLanguage(language).file.id+'/edit']">{{ content.translationForLanguage(language)?.file?.name }} <code *ngIf="content.translationForLanguage(language) && content.translationForLanguage(language).file">{{ content.translationForLanguage(language).file.mainFile?.fileType }}</code></a>
</figure>
<div *ngIf="content.contentType === 'FILE' && !content.translationForLanguage(language)?.file?.mainFile?.fileType.match('^(image/|application/pdf)')">
    <p>{{ content.translationForLanguage(language)?.file?.name }} <code *ngIf="content.translationForLanguage(language) && content.translationForLanguage(language).file">{{ content.translationForLanguage(language).file.mainFile?.fileType }}</code></p>
    <p class="text-center">{{ content.textContentInLanguage(language) }}</p>
</div>
<div *ngIf="content.contentType === 'ATTACHMENT_LINK'">
    <a class="attachment-link" *ngIf="content.linkedAttachment">{{ content.textContentInLanguage(language) }}</a>
    <span role="button">
        <code [routerLink]="['/attachment/'+ content.linkedAttachment.id + '/edit']" *ngIf="content.linkedAttachment">{{content.linkedAttachment.name}}</code>
    </span>
</div>
<div *ngIf="content.contentType === 'ROOMBOOK'" jhiTranslate="kstandards.ContentType.ROOMBOOK">
    Roombook
</div>
<div *ngIf="content.contentType === 'PRODUCT_LIST'" jhiTranslate="kstandards.ContentType.PRODUCT_LIST">
    Product list
</div>
