import { Injectable } from '@angular/core'
import { SessionStorageService } from 'ngx-webstorage'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class StateStorageService {

    private profileEnvironmentSubject = new BehaviorSubject<string|null>(null)
    profileEnvironment$ = this.profileEnvironmentSubject.asObservable()

    constructor(
        private readonly $sessionStorage: SessionStorageService
    ) { }

    clear() {
        this.$sessionStorage.clear()
    }

    getPreviousState() {
        return this.$sessionStorage.retrieve('previousState')
    }

    resetPreviousState() {
        this.$sessionStorage.clear('previousState')
    }

    storePreviousState(previousStateName, previousStateParams) {
        const previousState = { name: previousStateName, params: previousStateParams }
        this.$sessionStorage.store('previousState', previousState)
    }

    getDestinationState() {
        return this.$sessionStorage.retrieve('destinationState')
    }

    storeUrl(url: string) {
        this.$sessionStorage.store('previousUrl', url)
    }

    getUrl() {
        return this.$sessionStorage.retrieve('previousUrl')
    }

    storeDestinationState(destinationState, destinationStateParams, fromState) {
        const destinationInfo = {
            destination: {
                name: destinationState.name,
                data: destinationState.data,
            },
            params: destinationStateParams,
            from: {
                name: fromState.name,
            }
        }
        this.$sessionStorage.store('destinationState', destinationInfo)
    }

    storeProfileEnvironment(environment: string) {
        this.$sessionStorage.store('environment', environment)
        this.profileEnvironmentSubject.next(environment)
    }

    getProfileEnvironment() {
        const profileEnv = this.$sessionStorage.retrieve('environment')
        this.profileEnvironmentSubject.next(profileEnv)
        return profileEnv
    }

    storeSessionUserCountryCode(sessionUserCountryCode: string) {
        this.$sessionStorage.store('sessionUserCountryCode', sessionUserCountryCode)
    }

    getSessionUserCountryCode() {
        return this.$sessionStorage.retrieve('sessionUserCountryCode')
    }
}
