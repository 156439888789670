<form name="statusForm" (ngSubmit)="confirmSave()">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="kstandards.release.dialog.confirm">Confirm save</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>
    <div class="modal-body">

        <p jhiTranslate="kstandards.release.dialog.message">
            Changing the status to "Publish" is an irreversible process! Do you want to save the changes?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
            <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="kstandards.release.dialog.cancel">Cancel</span>
        </button>
        <button type="submit" class="btn btn-special">
            <icon shape="save" cssClasses="icon-white"></icon>&nbsp;<span jhiTranslate="kstandards.release.dialog.save">Save</span>
        </button>
    </div>
</form>
