import { Pipe, PipeTransform } from '@angular/core'
import { MaterialCategory } from '../../data-access'

const MATERIAL_CATEGORY_ICON = '<[MATERIAL_CATEGORY_ICON]>'

@Pipe({
    name: 'previewMaterialCategoryIcon'
})
export class PreviewMaterialCategoryIconPipe implements PipeTransform {

    transform(text: string, materialCategory: MaterialCategory): string {
        if (!text) {
            return ''
        }
        return text.includes(MATERIAL_CATEGORY_ICON) ? this.replaceIcon(text, materialCategory) : text
    }

    private replaceIcon(text: string, materialCategory: MaterialCategory): string {
        return text.split(MATERIAL_CATEGORY_ICON).join('<img class="icon" src="' + materialCategory?.icon?.presignedUrl + '">')
    }
}
