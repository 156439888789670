import { JhipsterSharedModule } from '../shared/shared.module'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AuthExpiredInterceptor } from './interceptor/auth-expired.interceptor'
import { NgModule } from '@angular/core'
import { ErrorHandlerInterceptor } from './interceptor/errorhandler.interceptor'
import { NotificationInterceptor } from './interceptor/notification.interceptor'
import { HttpRequestInterceptor } from './interceptor/http-request.interceptor'

@NgModule({
    imports: [
        JhipsterSharedModule,
        HttpClientModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        }
    ]
})
export class HttpInterceptorModule { }
