import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { File as FileModel } from './file.model'
import { FileContentWrapper } from './file-content-wrapper'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { Country } from '../country'

@Injectable()
export class FileService {

    private readonly resourceUrl = SERVER_API_URL + 'api/files'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/files'

    private readonly duplicateFileCheckUrl = SERVER_API_URL + 'api/files/duplicate'

    private readonly searchEngineSearchFilesByNameUrl = SERVER_API_URL + 'api/_search/files/searchByNameAndContentTypeAndTags'

    private readonly fileContentUrl = this.resourceUrl + '/content'

    private readonly filePreviewUrl = this.resourceUrl + '/preview'

    private readonly filePresignedUrl = this.resourceUrl + '/presigned-url'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(file: FileModel, fileContent: File, ignoreAlerts?: boolean): Observable<FileModel> {
        let headers = null
        if (ignoreAlerts === true) {
            headers = new HttpHeaders({'X-Ignore-Alerts': 'yes'})
        }
        const entity = this.convert(file)

        const formData = new FormData()
        formData.append('entity', new Blob([JSON.stringify(entity)], {type: 'application/json'}))
        formData.append('file', fileContent)

        return this.httpClient.post(this.resourceUrl, formData, {headers}).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(file: FileModel, fileContent: File): Observable<FileModel> {
        const entity = this.convert(file)

        const formData = new FormData()
        formData.append('entity', new Blob([JSON.stringify(entity)], {type: 'application/json'}))

        if (fileContent) {
            formData.append('file', fileContent)
        }
        return this.httpClient.put(this.resourceUrl, formData).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<FileModel> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    validateFileChecksum(file: File): Observable<boolean> {

        const formData = new FormData()
        formData.append('file', file)

        return this.httpClient.post<boolean>(this.duplicateFileCheckUrl, formData)
    }

    getFileContent(id: number, version: number, ignoreErrors = false): Observable<FileContentWrapper> {
        return this.getFile(this.fileContentUrl, id, version, ignoreErrors)
    }

    getFilePreview(id: number, version: number): Observable<FileContentWrapper> {
        return this.getFile(this.filePreviewUrl, id, version, true)
    }

    getPresignedUrl(blobFileName: string): Observable<string> {
        return this.httpClient.get(`${this.filePresignedUrl}/${blobFileName}`, { responseType: 'text'})
    }

    private getFile(baseUrl: string, id: number, version: number, ignoreErrors: boolean) {
        const params = new HttpParams().set('version', String(version))
        let headers = new HttpHeaders()
        if (ignoreErrors) {
            headers = headers.set('X-Ignore-Errors', 'yes')
        }
        return this.httpClient.get(`${baseUrl}/${id}`, { headers, params, observe: 'response', responseType: 'blob' }).pipe(map((res) => {
            return new FileContentWrapper(res.headers.get('Content-Type'), res.body, res.status)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map(res => new ResponseWrapper(res.headers, res.body, res.status)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    // Not used
    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchFilesByName(query: any, req?: any): Observable<ResponseWrapper | HttpErrorResponse> {
        const headers = new HttpHeaders({'X-Ignore-Errors': 'yes'})
        const params = createHttpParams(req)
        return this.httpClient.post(this.searchEngineSearchFilesByNameUrl, query, {params, observe: 'response', headers}).pipe(
            map((res) => this.convertResponse(res)))
    }

    getCreateCountries(): Observable<Country[]> {
        return this.httpClient.get<Country[]>(`${this.resourceUrl}/createCountries`)
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to File.
     */
    private convertItemFromServer(json: any): FileModel {
        const entity: FileModel = Object.assign(new FileModel(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        if (entity.createCountry) {
            entity.createCountry.code = entity.createCountry.code.toLowerCase()
        }

        return entity
    }

    /**
     * Convert a File to a JSON which can be sent to the server.
     */
    private convert(file: FileModel): FileModel {
        const copy: FileModel = Object.assign({}, file)
        return copy
    }
}
