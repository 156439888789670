import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { Craft } from './craft.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { Content } from '../content/content.model'
import { HttpClient } from '@angular/common/http'
import { Arrangeable } from '../common/arrangeable'
import { MaterialGroup } from '../material-group/material-group.model'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'

@Injectable()
export class CraftService implements EntityService<Craft> {

    private readonly resourceUrl = SERVER_API_URL + 'api/crafts'

    private readonly engineSearchUrl = SERVER_API_URL + 'api/_search/crafts'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(craft: Craft): Observable<Craft> {
        const copy = this.convert(craft)
        return this.httpClient.post(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(craft: Craft, contentId?): Observable<Craft> {
        const copy = this.convert(craft)
        let params = {}
        if (contentId) {
            params = {contentId}
        }
        return this.httpClient.put(this.resourceUrl, copy, {params}).pipe(map((res) =>
            this.convertItemFromServer(res)
        ))
    }

    find(id: number): Observable<Craft> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    copy(id: number): Observable<Craft> {
        return this.httpClient.get(`${this.resourceUrl}/${id}/copy`).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    queryMaterialGroups(id: number): Observable<MaterialGroup[]> {
        return this.httpClient.get<MaterialGroup[]>(`${this.resourceUrl}/${id}/material-groups`).pipe(
            map((materialGroups) => this.convertMaterialGroups(materialGroups)))
    }

    convertMaterialGroups(materialGroups: MaterialGroup []): MaterialGroup [] {
        return materialGroups.map((materialGroup) => {
            return Object.assign(new MaterialGroup(), materialGroup)
        })
    }

    updateMaterialGroups(id: number, materialGroups: Arrangeable[]): Observable<MaterialGroup[]> {
        return this.httpClient.put<MaterialGroup[]>(`${this.resourceUrl}/${id}/material-groups`, materialGroups).pipe(map((updatedMaterialGroups) =>
            this.convertMaterialGroups(updatedMaterialGroups)))
    }

    queryArrangedCrafts(): Observable<ResponseWrapper> {
        return this.httpClient.get<Craft[]>(`${this.resourceUrl}/arrange`, {observe: 'response'}).pipe(map((res) => this.convertResponse(res)))
    }

    updateArrangedCrafts(crafts: Arrangeable[]): Observable<ResponseWrapper> {
        return this.httpClient.put<Craft[]>(`${this.resourceUrl}/arrange`, crafts, {observe: 'response'}).pipe(map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.engineSearchUrl, {
            params,
            observe: 'response'
        }).pipe(map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query).set('size', '50')
        return this.httpClient.get(this.engineSearchUrl + '/autocomplete', {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Craft.
     */
    private convertItemFromServer(json: any): Craft {
        const entity: Craft = Object.assign(new Craft(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        // In case Search Engine returns null for the contents
        if (!entity.contents) {
            entity.contents = []
        }

        // Make sure that craft.contents are typed to make sure
        // that custom getters can be called from templates
        if (json.contents) {
            entity.contents = json.contents.map((content) => Object.assign(new Content(), content))
        }

        return entity
    }

    /**
     * Convert a Craft to a JSON which can be sent to the server.
     */
    private convert(craft: Craft): Craft {
        const copy: Craft = Object.assign({}, craft)
        return copy
    }

}
