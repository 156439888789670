import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Attachment, AttachmentService, ResponseWrapper } from '@app/data-access'
import { FlagUtil } from '@app/util/flag.util'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { JhiAlertService } from 'ng-jhipster'
import { BehaviorSubject, combineLatest as observableCombineLatest } from 'rxjs'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators'

@Component({
    selector: 'jhi-attachment-picker',
    templateUrl: 'attachment-picker.component.html',
    styleUrls: ['attachment-picker.scss']
})
export class AttachmentPickerComponent implements OnInit {

    public FlagUtil = FlagUtil

    @Input()
    firstSelectedAttachment: Attachment

    public page = 1
    public itemsPerPage = 10

    public search = new FormControl()

    public pageSubject = new BehaviorSubject<number>(1)
    public termSubject = new BehaviorSubject<string>('')

    public totalCount: number

    public attachments: Attachment[]
    public hasLoaded = false
    public selectedAttachment: Attachment

    constructor(
        public activeModal: NgbActiveModal,
        private readonly attachmentService: AttachmentService,
        private readonly jhiAlertService: JhiAlertService
    ) { }

    ngOnInit() {
        this.selectedAttachment = this.firstSelectedAttachment

        this.search.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
            this.termSubject.next(value)
            this.page = 1
            this.pageSubject.next(1)
        })

        const stateChange = observableCombineLatest(
            this.termSubject,
            this.pageSubject
        ).pipe(debounceTime(1))

        stateChange.pipe(
            switchMap(([term, page]) => {
                return this.attachmentService.searchAttachmentsByName(this.getQuery(term, page))
            }))
            .subscribe(
                (res: ResponseWrapper) => this.onSuccess(res),
                (res: ResponseWrapper) => this.onError(res)
            )

    }

    private getQuery(term, page) {
        const query = {
            page: page - 1,
            size: this.itemsPerPage
        }
        query['name'] = term
        return query
    }

    private onSuccess(res: ResponseWrapper) {
        this.attachments = res.json

        this.totalCount = parseInt(res.headers.get('X-Total-Count'), 10)

        this.hasLoaded = true
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
    }

    clear() {
        this.activeModal.dismiss()
    }

    save() {
        this.activeModal.close(this.selectedAttachment)
    }

    selectAttachment(attachment: Attachment) {
        this.selectedAttachment = attachment
    }

    onPageChange(page) {
        this.page = page
        this.pageSubject.next(page)
    }
}
