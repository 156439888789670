import { Countries } from './countries'

export enum HostCompanyIdEnum {
    KStandards = 'kstandards',
    LStandards = 'l-standards',
}

export enum CountryIdEnum {
    International = 'int',
    Germany = 'de'
}

export enum RoleIdEnum {
    Superadmin = 'superadmin',
    Admin = 'admin',
    Publisher = 'publisher',
    Editor = 'editor',
    Monitoring = 'monitor',
    Viewer = 'viewer',
    International = 'int'
}
export class Roles {
    private static ROLE_SUPERADMIN_KAUFLAND = `${HostCompanyIdEnum.KStandards}-${RoleIdEnum.Superadmin}`
    private static ROLE_ADMIN_KAUFLAND = `${HostCompanyIdEnum.KStandards}-${RoleIdEnum.Admin}`
    private static ROLE_PUBLISHER_KAUFLAND = `${HostCompanyIdEnum.KStandards}-${RoleIdEnum.Publisher}`
    private static ROLE_EDITOR_KAUFLAND = `${HostCompanyIdEnum.KStandards}-${RoleIdEnum.Editor}`
    private static ROLE_MONITORING_KAUFLAND = `${HostCompanyIdEnum.KStandards}-${RoleIdEnum.Monitoring}`
    private static ROLE_VIEWER_KAUFLAND = `${HostCompanyIdEnum.KStandards}-${RoleIdEnum.Viewer}`

    public static ROLE_SUPERADMIN_LIDL = `${HostCompanyIdEnum.LStandards}-${RoleIdEnum.Superadmin}-${RoleIdEnum.International}`
    public static ROLE_ADMIN_LIDL = `${HostCompanyIdEnum.LStandards}-${RoleIdEnum.Admin}-${RoleIdEnum.International}`
    private static ROLE_PUBLISHER_LIDL = `${HostCompanyIdEnum.LStandards}-${RoleIdEnum.Publisher}-${RoleIdEnum.International}`
    private static ROLE_INT_EDITOR_LIDL = `${HostCompanyIdEnum.LStandards}-${RoleIdEnum.Editor}-${RoleIdEnum.International}`

    public static ROLE_EDITOR_LIDL_FOR_ALL_COUNTRIES: string[] =
        Countries.COUNTRY_CODES.map(countryCode => Roles.ROLE_INT_EDITOR_LIDL.replace(`-${RoleIdEnum.International}`, `-${countryCode}`))
    public static ROLE_EDITOR_LIDL_FOR_ALL_COUNTRIES_NOT_INT: string[] =
        Countries.COUNTRY_CODES.filter((country: string) => country !== 'int').map(countryCode => Roles.ROLE_INT_EDITOR_LIDL.replace(`-${RoleIdEnum.International}`, `-${countryCode}`))

    private static ROLE_MONITORING_LIDL = `${HostCompanyIdEnum.LStandards}-${RoleIdEnum.Monitoring}-${RoleIdEnum.International}`
    private static ROLE_VIEWER_LIDL = `${HostCompanyIdEnum.LStandards}-${RoleIdEnum.Viewer}-${RoleIdEnum.International}`

    public static SUPERADMIN = [
        Roles.ROLE_SUPERADMIN_KAUFLAND,
        Roles.ROLE_SUPERADMIN_LIDL
    ]
    public static ADMIN = [
        Roles.ROLE_ADMIN_KAUFLAND,
        Roles.ROLE_ADMIN_LIDL,
        ...Roles.SUPERADMIN
    ]
    public static PUBLISHER = [
        Roles.ROLE_PUBLISHER_KAUFLAND,
        Roles.ROLE_PUBLISHER_LIDL,
        ...Roles.ADMIN
    ]
    public static EDITOR = [
        Roles.ROLE_EDITOR_KAUFLAND,
        ...Roles.ROLE_EDITOR_LIDL_FOR_ALL_COUNTRIES,
        ...Roles.ADMIN
    ]
    public static INT_EDITOR = [
        Roles.ROLE_EDITOR_KAUFLAND,
        Roles.ROLE_INT_EDITOR_LIDL,
        ...Roles.ADMIN
    ]
    public static EDITOR_AND_PUBLISHER = [
        Roles.ROLE_PUBLISHER_KAUFLAND,
        Roles.ROLE_PUBLISHER_LIDL,
        ...Roles.EDITOR
    ]
    public static INT_EDITOR_AND_PUBLISHER = [
        Roles.ROLE_PUBLISHER_KAUFLAND,
        Roles.ROLE_PUBLISHER_LIDL,
        ...Roles.INT_EDITOR
    ]
    public static VIEWER = [
        Roles.ROLE_VIEWER_KAUFLAND,
        Roles.ROLE_VIEWER_LIDL,
        ...Roles.EDITOR_AND_PUBLISHER
    ]
    public static MONITORING = [
        Roles.ROLE_MONITORING_KAUFLAND,
        Roles.ROLE_MONITORING_LIDL,
        ...Roles.SUPERADMIN
    ]
}
