
import { throwError as observableThrowError, Observable } from 'rxjs'

import { catchError } from 'rxjs/operators'
import { JhiAlertService } from 'ng-jhipster'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly alertService: JhiAlertService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error: any) => {
            if (!req.headers.has('X-Ignore-Errors')) {
                if (error instanceof HttpErrorResponse && error.status !== 401) {
                    if (error.status === 404) {
                        this.router.navigateByUrl('not-found', { skipLocationChange: true })
                    }

                    this.handleError(error)

                }
            }
            return observableThrowError(error)
        }))
    }

    private handleError(httpErrorResponse: HttpErrorResponse) {
        let i = 0
        switch (httpErrorResponse.status) {
            // connection refused, server not reachable
            case 0:
                this.addErrorAlert('Server not reachable', 'error.server.not.reachable')
                break

            case 400:
                const arr = httpErrorResponse.headers.keys()
                let errorHeader = null
                let entityKey = null
                arr.forEach((entry) => {
                    if (entry.endsWith('app-error')) {
                        errorHeader = httpErrorResponse.headers.get(entry)
                    } else if (entry.endsWith('app-params')) {
                        entityKey = httpErrorResponse.headers.get(entry)
                    }
                })
                if (errorHeader) {
                    const entityName = this.translateService.instant('global.menu.entities.' + entityKey)
                    this.addErrorAlert(errorHeader, errorHeader, { entityName })
                } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
                    const fieldErrors = httpErrorResponse.error.fieldErrors
                    for (i = 0; i < fieldErrors.length; i++) {
                        const fieldError = fieldErrors[i]
                        // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                        const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]')
                        const fieldName = this.translateService.instant('kstandards.' +
                            fieldError.objectName.replace('DTO', '') + '.' + convertedField)
                        this.addErrorAlert(
                            'Error on field "' + fieldName + '"', 'error.' + fieldError.message, { fieldName })
                    }
                } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                    this.addErrorAlert(httpErrorResponse.error.message, httpErrorResponse.error.message, httpErrorResponse.error.params)
                } else {
                    this.addErrorAlert(httpErrorResponse.error)
                }
                break

            case 404:
                this.addErrorAlert('Not found', 'error.url.not.found')
                break

            default:
                if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                    this.addErrorAlert(httpErrorResponse.error.message)
                } else {
                    this.addErrorAlert(httpErrorResponse.error)
                }
        }
    }

    private addErrorAlert(message, key?, data?) {
        key = (key && key !== null) ? key : message
        this.alertService.addAlert(
            {
                type: 'danger',
                msg: key,
                params: data,
                timeout: 0
            },
            []
        )
    }
}
