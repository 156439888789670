import { Validator } from './validator'
import { Warning } from './warning/warning'
import { WarningCollector } from './warning/collector/warning-collector'
import { WarningMessage } from './warning/message/warning-message'

export class TranslationValidator implements Validator {

    private warnings: Warning[]

    constructor() {
        this.warnings = WarningCollector.collect()
    }

    validate(text: string): WarningMessage[] {
        const warningMessages = []
        this.warnings.forEach((warning) => {
            let match
            do {
                match = warning.pattern.exec(text)
                if (match) {
                    const line = match[0]
                    warningMessages.push(new WarningMessage(warning.code, line))
                }
            } while (match)

            warning.pattern.lastIndex = 0 // Reset
        })

        return warningMessages
    }
}
