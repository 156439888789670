import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Tag } from '../../data-access'

@Component({
    selector: 'tag-input',
    templateUrl: 'tag-input.component.html',
    styleUrls: ['tag-input.component.scss']
})
export class TagInputComponent {

    @Input()
    public tag: Tag

    @Input()
    public hasEditPermissions = true

    @Output()
    public remove: EventEmitter<boolean> = new EventEmitter(true)

    emitRemoveEvent() {
        this.remove.emit(true)
    }
}
