import { Route } from '@angular/router'

import { JhiHealthCheckComponent } from './health.component'
import { Roles } from '@app/auth'

export const healthRoute: Route = {
    path: 'jhi-health',
    component: JhiHealthCheckComponent,
    data: {
        authorities: Roles.MONITORING,
        pageTitle: 'health.title'
    }
}
