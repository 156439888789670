import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../shared'
import {
    adminState,
    AuditsComponent,
    AuditsService,
    JhiConfigurationComponent,
    JhiConfigurationService,
    JhiHealthCheckComponent,
    JhiHealthModalComponent,
    JhiHealthService,
    JhiMetricsMonitoringComponent,
    JhiMetricsMonitoringModalComponent,
    JhiMetricsService,
    LogsComponent,
    ReportByTypeListComponent,
    ReportDeleteAllDialogComponent,
    ReportDeleteAllPopupComponent,
    ReportDeleteDialogComponent,
    ReportDeletePopupComponent,
    ReportMaterialsAndRoomsWithoutImagesDialogComponent,
    ReportMaterialsByBuildingTypeDialogComponent,
    ReportMaterialVariantsDialogComponent,
    ReportNewComponent,
    ReportPopupService,
    ReportProductsByManufacturerDialogComponent,
    ReportRoomsByBuildingTypeDialogComponent,
    ReportRoomsWithMaterialsDialogComponent,
    ReportService
} from './'
import { JhipsterSearchEngineReindexModule } from './searchengine-reindex/searchengine-reindex.module'

/* jhipster-needle-add-admin-module-import - JHipster will add admin modules imports here */

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(adminState),
        JhipsterSearchEngineReindexModule,
        /* jhipster-needle-add-admin-module - JHipster will add admin modules here */
    ],
    declarations: [
        AuditsComponent,
        LogsComponent,
        JhiConfigurationComponent,
        JhiHealthCheckComponent,
        JhiHealthModalComponent,
        JhiMetricsMonitoringComponent,
        JhiMetricsMonitoringModalComponent,
        ReportNewComponent,
        ReportRoomsByBuildingTypeDialogComponent,
        ReportRoomsWithMaterialsDialogComponent,
        ReportMaterialsByBuildingTypeDialogComponent,
        ReportMaterialsAndRoomsWithoutImagesDialogComponent,
        ReportProductsByManufacturerDialogComponent,
        ReportMaterialVariantsDialogComponent,
        ReportByTypeListComponent,
        ReportDeletePopupComponent,
        ReportDeleteDialogComponent,
        ReportDeleteAllDialogComponent,
        ReportDeleteAllPopupComponent
    ],
    entryComponents: [
        JhiHealthModalComponent,
        JhiMetricsMonitoringModalComponent,
        ReportRoomsByBuildingTypeDialogComponent,
        ReportRoomsWithMaterialsDialogComponent,
        ReportMaterialsByBuildingTypeDialogComponent,
        ReportMaterialsAndRoomsWithoutImagesDialogComponent,
        ReportProductsByManufacturerDialogComponent,
        ReportMaterialVariantsDialogComponent,
        ReportByTypeListComponent,
        ReportDeletePopupComponent,
        ReportDeleteDialogComponent,
        ReportDeleteAllDialogComponent,
        ReportDeleteAllPopupComponent
    ],
    providers: [
        AuditsService,
        JhiConfigurationService,
        JhiHealthService,
        JhiMetricsService,
        // LogsService,
        ReportService,
        ReportPopupService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JhipsterAdminModule {}
