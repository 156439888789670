import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { OAuth2Config } from '@app/shared'
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc'
import {
    HasAnyAuthorityDirective,
    OAuth2Service,
    StateStorageService
} from '.'
import { LocationHandlerService } from './location-handler.service'
import { UserRouteAccessService } from './user-route-access-service'

@NgModule({
    imports: [
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [
                    '/api/',
                    'api/',
                    `${window.location.origin}/api`,
                    '/management/',
                    'management/',
                    `${window.location.origin}/management`
                ],
                sendAccessToken: true
            }
        }),
    ],
    declarations: [
        HasAnyAuthorityDirective,
    ],
    providers: [
        StateStorageService,
        OAuth2Service,
        OAuth2Config,
        UserRouteAccessService,
        {
            provide: OAuthStorage,
            useValue: localStorage
        },
        LocationHandlerService,
    ],
    exports: [
        HasAnyAuthorityDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AuthModule {
}
