import { Component, OnInit } from '@angular/core'
import { JhiAlertService } from 'ng-jhipster'
import { ActivatedRoute, Router } from '@angular/router'
import { ResponseWrapper } from '@app/data-access'
import { RenderedBook, RenderedBookService } from '@app/data-access'

@Component({
    selector: 'jhi-release-log',
    templateUrl: './release-log.component.html',
    styleUrls: ['./release-log.component.scss']
})
export class ReleaseLogComponent implements OnInit {

    isLoading = false

    renderedBook: RenderedBook

    releaseId: number

    constructor(private readonly renderedBookService: RenderedBookService,
                private readonly jhiAlertService: JhiAlertService,
                private readonly route: ActivatedRoute,
                private readonly router: Router) {
    }

    ngOnInit() {
        this.route
            .params
            .subscribe((params) => {
                if (params['renderedBookId']) {
                    this.fetchLog(params['renderedBookId'])
                }

                if (params['releaseId']) {
                    this.releaseId = params['releaseId']
                }
            })
    }

    private fetchLog(id: number) {
        this.isLoading = true
        this.renderedBookService.find(id).subscribe((renderedBook) => {
                this.renderedBook = renderedBook
                this.isLoading = false
            },
            (res: ResponseWrapper) => this.onError(res.json))

    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
        this.isLoading = false
    }

    goBack() {
        this.router.navigate(['../../../edit'], {relativeTo: this.route})
    }
}
