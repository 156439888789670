import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { Room } from './room.model'
import { createHttpParams, ResponseWrapper, ConversionUtil } from '../common'
import { HttpClient, HttpParams } from '@angular/common/http'
import { RoomAttribute } from './room-attribute.model'
import { Content } from '../content'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'
import { RoomWithImages } from '@app/data-access/room/room-with-images.model'
import { MaterialProjectRoomRelation } from '../material'

@Injectable()
export class RoomService implements EntityService<Room> {

    private readonly resourceUrl = SERVER_API_URL + 'api/rooms'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/rooms'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(room: Room): Observable<Room> {
        const copy = this.convert(room)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(room: Room, contentId?): Observable<Room> {
        const copy = this.convert(room)
        let params = {}
        if (contentId) {
            params = {contentId}
        }
        return this.httpClient.put(this.resourceUrl, copy, {params}).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<Room> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    copy(id: number): Observable<Room> {
        return this.httpClient.get(`${this.resourceUrl}/${id}/copy`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    findByNumberAndBuildingType(number: string, buildingType: number): Observable<Room[]> {
        const params = new HttpParams().set('buildingTypeId', buildingType.toString(10))
            .set('number', number)
        return this.httpClient.get<Room[]>(`${this.resourceUrl}/filter`, {params})
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchWithImages(query: string, withImages: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query).set('withImages', withImages.toString())
        return this.httpClient.get(this.searchEngineUrl + '/with-images', {params, observe: 'response'}).pipe(
            map((res) => this.convertResponseWithImages(res)))
    }

    searchAutocomplete(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query).set('size', '50')
        return this.httpClient.get(this.searchEngineUrl + '/autocomplete', {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    getMaterialRelations(roomId: number): Observable<MaterialProjectRoomRelation[]> {
        return this.httpClient.get<MaterialProjectRoomRelation[]>(`${this.resourceUrl}/${roomId}/material-relations`).pipe(
            map((mprs) => ConversionUtil.convertRelations(mprs))
        )
    }

    getCopyMaterialRelations(roomId: number): Observable<MaterialProjectRoomRelation[]> {
        return this.httpClient.get<MaterialProjectRoomRelation[]>(`${this.resourceUrl}/${roomId}/material-relations/copy`).pipe(
            map((mprs) => ConversionUtil.convertRelations(mprs)))
    }

    updateMaterialRelations(roomId: number, relations: MaterialProjectRoomRelation[]): Observable<MaterialProjectRoomRelation[]> {
        return this.httpClient.put<MaterialProjectRoomRelation[]>(`${this.resourceUrl}/${roomId}/material-relations`, relations).pipe(
            map((mprs) => ConversionUtil.convertRelations(mprs))
        )
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    private convertResponseWithImages(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            const entity: Room = Object.assign(new RoomWithImages(), res.body[i])
            result.push(entity)
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Room.
     */
    private convertItemFromServer(json: any): Room {
        const entity: Room = Object.assign(new Room(), json)

        entity.content = Object.assign(new Content(), entity.content)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        // Make sure that room.roomAttributes are typed to make sure
        // that custom getters can be called from templates
        if (entity.roomAttributes) {
            const mapped = entity.roomAttributes.map((element) => {
                const roomAttr = Object.assign(new RoomAttribute(), element)

                roomAttr.additionalConstructionInformation = Object.assign(new Content(), element.additionalConstructionInformation)
                roomAttr.additionalModernizationInformation = Object.assign(new Content(), element.additionalModernizationInformation)
                roomAttr.roomHeight = Object.assign(new Content(), element.roomHeight)
                roomAttr.roomTemperature = Object.assign(new Content(), element.roomTemperature)
                roomAttr.airExchange = Object.assign(new Content(), element.airExchange)
                roomAttr.illuminance = Object.assign(new Content(), element.illuminance)

                return roomAttr
            })

            entity.roomAttributes = mapped
        }

        if (json.contents) {
            entity.contents = json.contents.map((content) => Object.assign(new Content(), content))
        }

        return entity
    }

    /**
     * Convert a Room to a JSON which can be sent to the server.
     */
    private convert(room: Room): Room {
        const copy: Room = Object.assign({}, room)
        return copy
    }
}
