import { BaseEntity, ContentContainerEntity } from '../common'
import { MaterialGroup } from '../material-group/material-group.model'
import { Content } from '../content/content.model'
import { Arrangeable } from '../common/arrangeable'
import { WorkflowStatusSummary } from '../common/workflow-status-summary.model'
import { Linkable } from '../common/linkable'
import { Deletable } from '../common/deletable'
import { BuildingType } from '../building-type'
import { Attachment } from '../attachment'

export class Craft implements BaseEntity, ContentContainerEntity, Arrangeable, Linkable, Deletable {
    constructor(
        public id?: number,
        public order?: number,
        public name?: string,
        public description?: string,
        public workflowStatusSummary?: WorkflowStatusSummary,
        public materialGroups?: MaterialGroup[],
        public buildingTypes?: BuildingType[],
        public contents?: Content[],
        public linkedAttachments?: Attachment[],
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public deletable?: boolean
    ) {
        if (!this.buildingTypes) {
            this.buildingTypes = []
        }
    }

    get allContents(): Content[] {
        return (Object as any).values(this.contents)
            .reduce((accumulator, contents) => accumulator.concat(contents), [])
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/craft/${this.id}/edit`
    }

    getLabelForArrangeableList(): string {
        return this.name
    }
}
