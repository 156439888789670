
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { Manufacturer } from './manufacturer.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'

@Injectable()
export class ManufacturerService implements EntityService<Manufacturer> {

    private readonly resourceUrl = SERVER_API_URL + 'api/manufacturers'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/manufacturers'

    constructor(private readonly httpClient: HttpClient) { }

    create(manufacturer: Manufacturer): Observable<Manufacturer> {
        const copy = this.convert(manufacturer)
        return this.httpClient.post(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    update(manufacturer: Manufacturer): Observable<Manufacturer> {
        const copy = this.convert(manufacturer)
        return this.httpClient.put(this.resourceUrl, copy).pipe(
            map((res) => this.convertItemFromServer(res)))
    }

    find(id: number): Observable<Manufacturer> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(
            map((res) => this.convertItemFromServer(res)
        ))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<Manufacturer[]>(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    findManufacturerByFileId(id: number): Observable<Manufacturer> {
        return this.httpClient.get(`${this.resourceUrl}/file/${id}`)
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Manufacturer.
     */
    private convertItemFromServer(json: any): Manufacturer {
        const entity: Manufacturer = Object.assign(new Manufacturer(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        return entity
    }

    /**
     * Convert a Manufacturer to a JSON which can be sent to the server.
     */
    private convert(manufacturer: Manufacturer): Manufacturer {
        const copy: Manufacturer = Object.assign({}, manufacturer)
        return copy
    }
}
