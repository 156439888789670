import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { HttpClient } from '@angular/common/http'
import { Release } from './release.model'
import { createHttpParams, ResponseWrapper } from '../common'
import { DateUtil } from '@app/util/date.util'

@Injectable()
export class ReleaseService {

    private readonly resourceUrl = SERVER_API_URL + 'api/releases'

    private readonly resourceReleaseContextUrl = SERVER_API_URL + 'api/release-contexts'

    private readonly resourceReleaseContextsearchEngineUrl = SERVER_API_URL + 'api/_search/release-contexts'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(release: Release): Observable<Release> {
        const copy = this.convert(release)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(release: Release): Observable<Release> {
        const copy = this.convert(release)
        return this.httpClient.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<Release> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return res
        }))
    }

    query(releaseContextId: number, showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(`${this.resourceReleaseContextUrl}/${releaseContextId}/releases`, {
            params,
            observe: 'response'
        }).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    deleteUnpublishedReleases(): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/deleteUnpublishedReleases`)
    }

    search(query: string, releaseContextId: number, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(`${this.resourceReleaseContextsearchEngineUrl}/${releaseContextId}/releases`, {
            params,
            observe: 'response'
        }).pipe(
            map((res) => this.convertResponse(res)))
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Release.
     */
    private convertItemFromServer(json: any): Release {
        const entity: Release = Object.assign(new Release(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        return entity
    }

    /**
     * Convert a Release to a JSON which can be sent to the server.
     */
    private convert(release: Release): Release {
        const copy: Release = Object.assign({}, release)
        return copy
    }
}
