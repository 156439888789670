import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { Content, ContentType, Language, Translation } from '@app/data-access'
import { combineLatest, ReplaySubject, Subject } from 'rxjs'
import { cloneDeep } from 'lodash-es'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { AttachmentPickerComponent } from './attachment-picker.component'
import { ContentTranslationModalComponent } from '@app/content-manager/content-translation-modal.component'
import { FlagUtil } from '@app/util/flag.util'

@Component({
    template: ''
})
export abstract class SingleContentEditComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

    public FlagUtil = FlagUtil

    content: Content

    hasPreparedTranslations = false

    @Input()
    translationWorkflowButton = false

    readonly modalService: NgbModal

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    public languageChange$ = new ReplaySubject<never>(1)
    public contentChange$ = new ReplaySubject<never>(1)

    public propagateChange = (_: Content) => { }
    public onTouched = () => { }
    public disabled = false

    @Input()
    public name = ''

    @Input()
    hasAttachmentPicker = false

    @Input()
    public languages = []

    public languageIds = new Set()

    public ngOnInit() {

        // We need to wait for both the languages and the content to arrive
        combineLatest([this.contentChange$, this.languageChange$]).subscribe(() => {
            if (!this.content) {
                return
            }
            if (this.languages.length === 0) {
                this.hasPreparedTranslations = true
                return
            }
            this.languages.forEach((language: Language) => {
                if (this.content.translationForLanguage(language) === undefined) {
                    const newTranslation = new Translation()
                    newTranslation.language = language
                    this.content.translations.push(newTranslation)
                }
            })

            // Make sure that German is always the first translation
            this.content.translations.sort((a: Translation, b: Translation) => {
                if (a.language.locale === 'de-DE') {
                    return -1
                } else if (b.language.locale === 'de-DE') {
                    return 1
                }

                return a.language.name.localeCompare(b.language.name)
            })

            this.hasPreparedTranslations = true
            this.propagateChange(this.content)
        })
    }

    protected constructor(
        modalService: NgbModal
    ) {
        this.modalService = modalService
    }

    public openAttachmentPicker() {
        const modalRef: NgbModalRef = this.modalService.open(AttachmentPickerComponent as Component, { size: 'lg' })
        const component: AttachmentPickerComponent = modalRef.componentInstance

        if (this.content.linkedAttachment) {
            component.firstSelectedAttachment = this.content.linkedAttachment
        }

        // Automatically convert contents to attachment link
        modalRef.result.then((selectedAttachment) => {
            if (this.checkIfAttachmentHasChanged(selectedAttachment)) {
                this.onTouched()
                this.onChange()
            }
            this.content.contentType = ContentType.ATTACHMENT_LINK
            this.content.linkedAttachment = selectedAttachment
        }).catch(() => {
        })
    }

    private checkIfAttachmentHasChanged(selectedAttachment: any) {
        return (selectedAttachment && this.content.linkedAttachment && selectedAttachment.id !== this.content.linkedAttachment.id) ||
            (!selectedAttachment && this.content.linkedAttachment) ||
            (!this.content.linkedAttachment && selectedAttachment)
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.languages && changes.languages.currentValue) {
            this.languages = changes.languages.currentValue
            this.languageIds = new Set(this.languages.map((language) => language.id))
            this.languageChange$.next()
        }
    }

    public ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    public removeAttachment() {
        this.content.contentType = ContentType.TEXT
        this.content.linkedAttachment = null
        this.onTouched()
        this.onChange()
    }

    abstract getTranslationsForLanguages()

    writeValue(content: Content): void {
        // We edit a deep copy of the content in order to make sure that
        // the form control is marked as dirty when the linked attachment
        // has changed
        this.content = cloneDeep(content)
        this.contentChange$.next()
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    onChange() {
        this.propagateChange(this.content)
    }

    createTranslationJob() {
        const modalRef: NgbModalRef = this.modalService.open(ContentTranslationModalComponent as Component, { size: 'xl' })
        const component: ContentTranslationModalComponent = modalRef.componentInstance

        component.content = this.content
        component.languages = this.languages
        component.finished.subscribe(() => modalRef.close())
    }

}
