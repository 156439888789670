import { Pipe, PipeTransform } from '@angular/core'

@Pipe({name: 'findLanguageFromKey'})
export class FindLanguageFromKeyPipe implements PipeTransform {
    private readonly languages: any = {
        cs: { name: 'Český' },
        en: { name: 'English' },
        de: { name: 'Deutsch' },
        pl: { name: 'Polski' },
        ro: { name: 'Română' },
        sk: { name: 'Slovenský' }
        // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    }
    transform(lang: string): string {
        return this.languages[lang].name
    }
}
