import { Warning } from './warning'
import { WarningCode } from './code/warning-code'

export class TrailingSpacesWarning extends Warning {

    private static CODE =  WarningCode.W101

    private static REGEX_PATTERN: RegExp = /^\*\*.*\*\*\s{2,}/gm

    constructor() {
        super(TrailingSpacesWarning.CODE, TrailingSpacesWarning.REGEX_PATTERN)
    }
}
