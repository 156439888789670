<div>
    <h2>
        <span jhiTranslate="kstandards.report.new.title">Choose Report</span>
    </h2>

    <br>
    <div class="row">
        <ul>
            <li>
                <a routerLink="../report-rooms-by-building-type">
                    <span jhiTranslate="kstandards.report.reportTypes.ROOMS_BY_BUILDING_TYPE">Rooms By Building Type</span>
                </a>
            </li>
            <li>
                <a routerLink="../report-rooms-with-materials">
                    <span jhiTranslate="kstandards.report.reportTypes.ROOMS_WITH_MATERIALS">Rooms With Materials</span>
                </a>
            </li>
            <li>
                <a routerLink="../report-materials-by-building-type">
                    <span jhiTranslate="kstandards.report.reportTypes.MATERIALS_BY_BUILDING_TYPE">Materials By Building Type</span>
                </a>
            </li>
            <li>
                <a routerLink="../report-products-by-manufacturer">
                    <span jhiTranslate="kstandards.report.reportTypes.PRODUCTS_BY_MANUFACTURER">Products By Manufacturer</span>
                </a>
            </li>
            <li>
                <a routerLink="../report-material-variants">
                    <span jhiTranslate="kstandards.report.reportTypes.MATERIAL_VARIANTS">Material Variants</span>
                </a>
            </li>
            <li>
                <a routerLink="../report-materials-and-rooms-without-images">
                    <span jhiTranslate="kstandards.report.reportTypes.MATERIALS_AND_ROOMS_WITHOUT_IMAGES">Materials And Rooms Without Images</span>
                </a>
            </li>
        </ul>
    </div>
</div>
