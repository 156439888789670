
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { Translation } from './translation.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'
import { OriginalTranslationDiff } from './original-translation-diff.model'

@Injectable()
export class TranslationService implements EntityService<Translation> {

    private readonly resourceUrl = SERVER_API_URL + 'api/translations'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(translation: Translation): Observable<Translation> {
        const copy = this.convert(translation)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(translation: Translation): Observable<Translation> {
        const copy = this.convert(translation)
        return this.httpClient.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<Translation> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req)
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Translation.
     */
    private convertItemFromServer(json: any): Translation {
        const entity: Translation = Object.assign(new Translation(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        return entity
    }

    /**
     * Convert a Translation to a JSON which can be sent to the server.
     */
    private convert(translation: Translation): Translation {
        const copy: Translation = Object.assign({}, translation)
        return copy
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        return undefined
    }

    getOriginalTranslationDiffs(id: number) {
        return this.httpClient.get<OriginalTranslationDiff[]>(`${this.resourceUrl}/${id}/get-original-translation-diffs`)
    }
}
