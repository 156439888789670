<jhi-page-ribbon></jhi-page-ribbon>
<div id="head">
    <router-outlet name="navbar"></router-outlet>
</div>
<div class="container-fluid d-flex flex-column flex-grow-1">
    <div class="card jh-card">
        <div *ngIf="isIE" class="alert alert-danger" jhiTranslate="error.notSupportedBrowser"></div>
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>
</div>
<jhi-footer></jhi-footer>

<jhi-alert aria-live="polite" aria-atomic="true"></jhi-alert>
