import { BaseEntity, ConstructionType } from '../common'
import { FileBlob } from '../file/file-blob.model'
import { ExportStatus } from './export-status.model'
import { GeneratedFile } from './generated-file.model'
import { RenderingStatus } from './rendering-status.model'

export class RenderedBook implements BaseEntity {
    public constructor(
        public id?: number,
        public name?: string,
        public constructionType?: ConstructionType,
        public renderingStatus?: RenderingStatus,
        public webviewStatus?: RenderingStatus,
        public version?: number,
        public log?: string,
        public webviewLog?: string,
        public consumerViewReleaseId?: number,
        public exportStatus?: ExportStatus,
        public pdf?: FileBlob,
        public webviewJson?: FileBlob,
        public published?: boolean,
        public createdAt?: string,
        public releaseId?: number,
        public releaseName?: string,
        public generatedFiles?: GeneratedFile[]

    ) { }
}
