import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { KAUFLAND, LIDL } from '@app/shared/constants/company.constants'
import { TranslateService } from '@ngx-translate/core'
import { OAuth2Service } from '../auth/oauth2.service'
import { Account } from '../shared'

@Component({
    selector: 'jhi-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

    public account: Account

    public isAuthenticated = false

    public isLidl: boolean = this.oauth2Service.isLidl

    constructor(
        private readonly oauth2Service: OAuth2Service,
        private readonly titleService: Title,
        private readonly translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.oauth2Service.authenticationState.subscribe((state) => {
            this.isAuthenticated = !!state
        })

        const companyLetter = this.isLidl ? LIDL : KAUFLAND
        this.translate.get('global.title', { companyLetter }).subscribe((res: string) => {
            this.titleService.setTitle(res)
        })
    }
}
