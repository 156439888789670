import { WarningCode } from './code/warning-code'

export abstract class Warning {

    constructor(private _code: WarningCode, private _pattern: RegExp) {
        this._code = _code
        this._pattern = _pattern
    }

    get code(): WarningCode {
        return this._code
    }

    get pattern(): RegExp {
        return this._pattern
    }
}
