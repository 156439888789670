import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ChangeTagService } from './changelog/change-tag.service'
import { FaqCategoryService } from './faq/faq-category.service'
import {
    AttachmentCategoryService,
    AttachmentService,
    BuildingTypeCategoryService,
    BuildingTypeService,
    ChangeEntityParentService,
    ChangeService,
    ChangelogService,
    ContentService,
    CountryService,
    CraftService,
    FaqEntryService,
    FileService,
    LanguageService,
    ManufacturerService,
    MaterialCategoryService,
    MaterialGroupService,
    MaterialService,
    MaterialSubGroupService,
    ProductGroupService,
    ProductService,
    ProjectService,
    ReleaseContextService,
    RoomBookColumnService,
    RoomGroupService,
    RoomService,
    TagService,
    TranslationBatchService,
    TranslationJobService,
    TranslationService,
    ReleaseService,
    RenderedBookService
} from '.'

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [],
    providers: [
        AttachmentCategoryService,
        AttachmentService, CountryService,
        BuildingTypeService,
        BuildingTypeCategoryService,
        ChangelogService,
        ChangeService,
        ContentService,
        CountryService,
        CraftService,
        FileService,
        LanguageService,
        ManufacturerService,
        MaterialService,
        MaterialGroupService,
        MaterialSubGroupService,
        MaterialCategoryService,
        ProductService,
        ProductGroupService,
        ProjectService,
        ReleaseService,
        ReleaseContextService,
        RenderedBookService,
        RoomService,
        RoomBookColumnService,
        RoomGroupService,
        TagService,
        ChangeTagService,
        ChangeEntityParentService,
        TranslationService,
        TranslationBatchService,
        TranslationJobService,
        FaqCategoryService,
        FaqEntryService
    ]
})
export class DataAccessModule {

}
