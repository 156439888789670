import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { JhipsterSharedModule } from '@app/shared'
import { TuiEditorComponent } from './tui-editor.component'

@NgModule({
  imports: [
    CommonModule,
    JhipsterSharedModule
  ],
  declarations: [TuiEditorComponent],
  exports: [TuiEditorComponent]
})
export class TuiEditorModule { }
