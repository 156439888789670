import { Pipe, PipeTransform } from '@angular/core'
import * as marked from 'marked'
import { MarkedOptions } from 'marked'

const MATERIAL_CATEGORY_ICON = '<[MATERIAL\\_CATEGORY\\_ICON]>'
/**
 * Renders the preview of the textContent of a Content entity.
 * It uses a specifically configured instance of marked.js.
 */
@Pipe({
  name: 'preview'
})
export class PreviewPipe implements PipeTransform {


    transform(text: string, options?: any): any {
        if (!text) {
            return ''
        }

        if (text.includes(MATERIAL_CATEGORY_ICON)) {
            text = text.split(MATERIAL_CATEGORY_ICON).join('<img class="icon" src="' + options + '" >')
        }

        const renderer = new marked.Renderer()

        // Add Bootstrap table classes for better styling
        renderer.table = (header, body) => {
            return '<table class="table table-responsive">\n'
            + '<thead>\n'
            + header
            + '</thead>\n'
            + '<tbody>\n'
            + body
            + '</tbody>\n'
            + '</table>\n'
        }

        // Don't add ids to headings here
        renderer.heading = (content, level, raw) => {
            return `<h${level}>${content}</h${level}>\n`
        }

        const markdownOptions: MarkedOptions = {
            smartLists: true,
            gfm: true,
            renderer
        }

        const transformedMarkdown = marked(text, markdownOptions)

        return transformedMarkdown
    }

}
