export enum ChangeType {
    REQUIREMENT_SALES = 'REQUIREMENT_SALES',

    ENERGY_MANAGEMENT_AND_BCS = 'ENERGY_MANAGEMENT_AND_BCS',

    MODEL_STORE = 'MODEL_STORE',

    LEGAL_REQUIREMENT = 'LEGAL_REQUIREMENT',

    DESIGN_CONCEPT = 'DESIGN_CONCEPT',

    COST_REDUCTION = 'COST_REDUCTION',

    COST_REDUCTION_PROJECT_SPECIFIC = 'COST_REDUCTION_PROJECT_SPECIFIC',

    COST_REDUCTION_MODEL_STORE = 'COST_REDUCTION_MODEL_STORE',

    PROJECT_SPECIFIC = 'PROJECT_SPECIFIC',

    QUALITY_ASSURANCE = 'QUALITY_ASSURANCE',

    EDITORIAL_CHANGE = 'EDITORIAL_CHANGE',

    COST_REDUCTION_MAINTENANCE = 'COST_REDUCTION_MAINTENANCE',

    TECHNICAL_REQUIREMENT = 'TECHNICAL_REQUIREMENT',

    RESULT_FROM_TEST = 'RESULT_FROM_TEST',

    REQUIREMENT_LOGISTICS = 'REQUIREMENT_LOGISTICS',

    SUSTAINABILITY = 'SUSTAINABILITY'
}
