import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { StateStorageService } from '@app/auth'
import { Observable } from 'rxjs'

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(private readonly stateStorageService: StateStorageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers
        if (req.url.startsWith('api/')) {
            const countryCode = this.stateStorageService.getSessionUserCountryCode()
            if (countryCode) {
                headers = headers.set('User-Session-Country-Code', countryCode)
            }
        }

        req = req.clone({
            headers
        })

        return next.handle(req)
    }
}