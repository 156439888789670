<div>
    <h2 [ngSwitch]="forUser" id="change-entry-title">
        <span *ngSwitchCase="true" jhiTranslate="kstandards.changeEntry.titleForUser">My change entries</span>
        <span *ngSwitchCase="false" jhiTranslate="kstandards.changeEntry.titleForAll">All change entries</span>
    </h2>
    <div class="row">
        <div class="col-xl-12">
            <table class="table table-striped table-hover table-responsive" aria-describedby="change-entry-title">
                <thead>
                    <tr jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="reset.bind(this)">
                        <th *ngIf="!forUser" scope="col" jhiSortBy="ldap"><span jhiTranslate="kstandards.changeEntry.ldap">Ldap User</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                        <th scope="col" jhiSortBy="parentName"><span jhiTranslate="kstandards.changeEntry.element">Element</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                        <th scope="col" jhiSortBy="materialLabel"><span jhiTranslate="kstandards.changeEntry.materialLabel">Material label</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                        <th scope="col" jhiSortBy="id"><span jhiTranslate="kstandards.changeEntry.contentId">Content
                                id</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                        <th scope="col" jhiSortBy="contentWorkflowStatus,id"><span jhiTranslate="kstandards.changeEntry.contentStatus">Content status</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                        <th scope="col" jhiSortBy="translationJobStatus,id"><span jhiTranslate="kstandards.changeEntry.translationJobStatus">Translation Job Status</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                        <th scope="col" jhiSortBy="translationJobStatus,id"><span jhiTranslate="kstandards.changeEntry.buildingTypes">Building types</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                        <th scope="col" jhiSortBy="updatedAt"><span jhiTranslate="kstandards.changeEntry.updatedAt">Updated at</span>
                            <fa-icon icon="sort"></fa-icon>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="page && !page.empty">
                    <tr *ngFor="let changeEntry of page.content ;trackBy: trackId">
                        <td *ngIf="!forUser">{{changeEntry.ldap}}</td>
                        <td *ngIf="changeEntry.materialId">
                            <div>
                                <span class="badge badge-success" jhiTranslate="kstandards.changeEntry.material"></span>
                                <a [routerLink]="['../material/' + changeEntry.materialId + '/edit']">
                                    {{changeEntry.parentName}}</a>
                            </div>
                        </td>
                        <td *ngIf="changeEntry.materialGroupId">
                            <div>
                                <span class="badge badge-special" jhiTranslate="kstandards.changeEntry.materialGroup">Material Group</span>
                                <a [routerLink]="['../material-group/' +  changeEntry.materialGroupId + '/edit']">
                                    {{changeEntry.parentName}}</a>
                            </div>
                        </td>
                        <td *ngIf="changeEntry.materialSubGroupId">
                            <div>
                                <span class="badge badge-warning" jhiTranslate="kstandards.changeEntry.materialSubGroup">Material Sub Group</span>
                                <a [routerLink]="['../material-sub-group/' +  changeEntry.materialSubGroupId + '/edit']">
                                    {{changeEntry.parentName}}</a>
                            </div>
                        </td>
                        <td *ngIf="changeEntry.projectId">
                            <div>
                                <span class="badge badge-info" jhiTranslate="kstandards.changeEntry.project">Project</span>
                                <a [routerLink]="['../project/' +  changeEntry.projectId + '/edit']">
                                    {{changeEntry.parentName}}</a>
                            </div>
                        </td>
                        <td *ngIf="changeEntry.craftId">
                            <div>
                                <span class="badge badge-danger" jhiTranslate="kstandards.changeEntry.craft">Craft</span>
                                <a [routerLink]="['../craft/' +  changeEntry.craftId + '/edit']">
                                    {{changeEntry.parentName}}</a>
                            </div>
                        </td>
                        <td *ngIf="changeEntry.attachmentId">
                            <div>
                                <span class="badge badge-dark" jhiTranslate="kstandards.changeEntry.attachment">Attachment</span>
                                <a [routerLink]="['../attachment/' +  changeEntry.attachmentId + '/edit']">
                                    {{changeEntry.parentName}}</a>
                            </div>
                        </td>
                        <td *ngIf="changeEntry.roomId">
                            <div>
                                <span class="badge badge-light" jhiTranslate="kstandards.changeEntry.room">Room</span>
                                <a [routerLink]="['../room/' +  changeEntry.roomId + '/edit']">
                                    {{changeEntry.parentName}}</a>
                            </div>
                        </td>
                        <td>
                            <span>
                                {{changeEntry.materialLabel}}
                            </span>
                        </td>
                        <td *ngIf="changeEntry.materialId">
                            <a [routerLink]="['../material/' +  changeEntry.materialId + '/edit']" [queryParams]="{contentToEdit: changeEntry.id}">{{changeEntry.id}}</a>
                        </td>
                        <td *ngIf="changeEntry.materialGroupId">
                            <a [routerLink]="['../material-group/' +  changeEntry.materialGroupId + '/edit']" [queryParams]="{contentToEdit: changeEntry.id}">{{changeEntry.id}}</a>
                        </td>
                        <td *ngIf="changeEntry.materialSubGroupId">
                            <a [routerLink]="['../material-sub-group/' +  changeEntry.materialSubGroupId + '/edit']" [queryParams]="{contentToEdit: changeEntry.id}">{{changeEntry.id}}</a>
                        </td>
                        <td *ngIf="changeEntry.projectId">
                            <a [routerLink]="['../project/' +  changeEntry.projectId + '/edit']" [queryParams]="{contentToEdit: changeEntry.id}">{{changeEntry.id}}</a>
                        </td>
                        <td *ngIf="changeEntry.craftId">
                            <a [routerLink]="['../craft/' +  changeEntry.craftId + '/edit']" [queryParams]="{contentToEdit: changeEntry.id}">{{changeEntry.id}}</a>
                        </td>
                        <td *ngIf="changeEntry.attachmentId">
                            <a [routerLink]="['../attachment/' +  changeEntry.attachmentId + '/edit']" [queryParams]="{contentToEdit: changeEntry.id}">{{changeEntry.id}}</a>
                        </td>
                        <td *ngIf="changeEntry.roomId">
                            <a [routerLink]="['../room/' +  changeEntry.roomId + '/edit']" [queryParams]="{contentToEdit: changeEntry.id}">{{changeEntry.id}}</a>
                        </td>
                        <td>
                            <span class="badge" [class.badge-success]="changeEntry.contentWorkflowStatus === 'PUBLISHED'" [class.badge-danger]="changeEntry.contentWorkflowStatus === 'DRAFT'" [class.badge-warning]="changeEntry.contentWorkflowStatus === 'REQUIRES_TRANSLATION'" [class.badge-special]="changeEntry.contentWorkflowStatus === 'TRANSLATION_DONE'">
                                {{ 'kstandards.WorkflowStatus.' + changeEntry.contentWorkflowStatus | translate }}
                            </span>
                        </td>
                        <td><span *ngIf="changeEntry.translationJobStatus">{{'kstandards.translationJob.translationJobStatus.'
                                + changeEntry.translationJobStatus.toString().toLowerCase() | translate }}</span>
                        </td>
                        <td>
                            <ul class="badge-list" *ngIf="changeEntry.buildingTypeLabels">
                                <li *ngFor="let buildingTypeLabel of changeEntry.buildingTypeLabels">
                                    <custom-badge [str]="buildingTypeLabel"></custom-badge>
                                </li>
                            </ul>
                        </td>
                        <td>{{changeEntry.updatedAt}}</td>
                    </tr>
                </tbody>
                <div *ngIf="page && page.empty" jhiTranslate="kstandards.changeEntry.noEntries" class="alert alert-secondary" role="alert">
                    No changes found.
                </div>
                <div *ngIf="!page" class="d-flex justify-content-center pt-4">
                    <fa-icon icon="spinner" [spin]="true" [size]="'2x'"></fa-icon>
                </div>
            </table>
        </div>
    </div>
    <div *ngIf="page && !page.empty" class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <ngb-pagination #pagination [collectionSize]="page.totalElements" [pageSize]="page.size" [ellipses]="true" [maxSize]="10" (pageChange)="onPageChange($event)" [page]="page.number + 1" aria-label="Pagination"></ngb-pagination>
        </div>
    </div>
</div>