import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { JhipsterSharedModule } from '../../shared'

import {
    ReleaseDeleteDialogComponent,
    ReleaseDeletePopupComponent,
    ReleaseDialogComponent,
    ReleaseLogComponent,
    ReleaseGeneratedFilesComponent,
    ReleaseModalComponent,
    ReleasePopupService,
    UnpublishedReleaseDeletePopupComponent,
    UnpublishedReleasesDeleteDialogComponent
} from './'

import { releaseRoutes } from './release.route'

const ENTITY_STATES = [
    ...releaseRoutes
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        ReleaseDialogComponent,
        ReleaseLogComponent,
        ReleaseGeneratedFilesComponent,
        ReleaseDeletePopupComponent,
        ReleaseDeleteDialogComponent,
        UnpublishedReleasesDeleteDialogComponent,
        UnpublishedReleaseDeletePopupComponent,
        ReleaseModalComponent
    ],
    entryComponents: [
        ReleaseDialogComponent,
        ReleaseDeletePopupComponent,
        ReleaseDeleteDialogComponent,
        UnpublishedReleasesDeleteDialogComponent,
        UnpublishedReleaseDeletePopupComponent,
        ReleaseModalComponent
    ],
    providers: [
        ReleasePopupService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JHipsterReleaseModule {}
