<div>
    <h2>
        <span id="generated-files" jhiTranslate="kstandards.release.generatedFiles">Generated Files</span>
    </h2>

    <div class="row">
        <div class="col-lg-10" *ngIf="!isLoading">
            <table class="table table-striped" aria-describedby="generated-files">
                <thead>
                <tr>
                    <th jhiTranslate="kstandards.release.name" scope="col">Name</th>
                    <th jhiTranslate="kstandards.release.size" scope="col">Size</th>
                    <th jhiTranslate="kstandards.release.download" scope="col">Download</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>JSON</td>
                    <td>{{renderedBook.webviewJson.size | humanReadable}}</td>
                    <td><a class="btn btn-secondary" target="_blank" [href]="renderedBook.webviewJson.presignedUrl" rel="noopener noreferrer" jhiTranslate="kstandards.release.download">Download</a></td>
                </tr>
                <tr *ngFor="let generatedFile of renderedBook.generatedFiles">
                    <td>{{generatedFile.name}}</td>
                    <td>{{generatedFile.file.size | humanReadable}}</td>
                    <td><a class="btn btn-secondary" target="_blank" [href]="generatedFile.file.presignedUrl" rel="noopener noreferrer" jhiTranslate="kstandards.release.download">Download</a></td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="col-lg-10" *ngIf="isLoading">
            <fa-icon icon="spinner" [spin]="true"></fa-icon>
        </div>

        <div class="col-lg-2 global-buttons">
            <div class="form-group">
                <a class="btn btn-secondary" data-dismiss="modal" [routerLink]="['/release-context/'+releaseContextId+'/release/'+releaseId+'/edit']">
                    <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
                </a>
            </div>
        </div>
    </div>
</div>
