
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { RoleIdEnum } from '@app/auth'
import { ConstructionType, ResponseWrapper, WorkflowStatus } from '@app/data-access'
import { FlagUtil } from '@app/util/flag.util'
import { TranslateService } from '@ngx-translate/core'
import { JhiParseLinks } from 'ng-jhipster'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Content } from '../data-access/content/content.model'
import { ContentService } from '../data-access/content/content.service'
import { ITEMS_PER_PAGE } from '../shared/constants/pagination.constants'

@Component({
    selector: 'content-picker',
    templateUrl: 'content-picker.component.html',
    styleUrls: ['content-manager.scss', 'content-picker.component.scss']
})
export class ContentPickerComponent implements OnInit, OnDestroy {

    public FlagUtil = FlagUtil

    public createdByCountryTitle: string = null

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    @Output()
    finishedPicking = new EventEmitter<boolean>()

    @Input()
    defaultLanguage

    @Input()
    existingContents: Content[]

    @Input()
    hasAdditionalScope

    @Input()
    userCountryCode: string

    @Input()
    isLidl: boolean

    unmappedContents: Content[]

    selectedContents: Map<number, Content>

    deselectedContents: Map<number, Content>

    totalItems: number

    itemsPerPage: number

    links: any

    page: any

    predicate: any

    reverse: any

    searchWord: string

    searchMode: boolean

    selectedConstructionType: ConstructionType = ConstructionType.CONSTRUCTION_MODERNIZATION

    constructor(private readonly contentService: ContentService,
        private readonly parseLinks: JhiParseLinks,
        private readonly translateService: TranslateService,
    ) {
        this.unmappedContents = []
        this.selectedContents = new Map()
        this.deselectedContents = new Map()
        this.itemsPerPage = ITEMS_PER_PAGE
        this.page = 0
        this.links = { last: 0 }
        this.predicate = 'updatedAt'
        this.reverse = false
        this.searchMode = false
    }

    ngOnInit(): void {
        this.createdByCountryTitle = this.translateService.instant('kstandards.contentManager.createdByTheCountry')

        this.load()
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    toggleContent(content: Content) {
        if (content.mapped) {
            content.mapped = false
            this.selectedContents.delete(content.id)
            this.deselectedContents.set(content.id, content)
        } else {
            content.mapped = true
            this.deselectedContents.delete(content.id)
            this.selectedContents.set(content.id, content)
        }
    }

    save() {
        this.selectedContents.forEach((selectedContent) => {
            const existingSelectedContentIndex = this.existingContents.findIndex((existingContent) => existingContent.id === selectedContent.id)
            selectedContent.workflowStatus = WorkflowStatus.PUBLISHED
            selectedContent.constructionType = this.selectedConstructionType

            // If the content container is not building-type aware, we prune the building types upon selection
            if (!this.hasAdditionalScope) {
                selectedContent.buildingTypes = []
            }
            if (existingSelectedContentIndex === -1) {
                this.existingContents.push(selectedContent)
            }
        })

        this.deselectedContents.forEach((deselectedContent) => {
            const deselectedContentIndex = this.existingContents.findIndex((existingContent) => existingContent.id === deselectedContent.id)
            deselectedContent.workflowStatus = WorkflowStatus.DRAFT

            if (deselectedContentIndex !== -1) {
                this.existingContents.splice(deselectedContentIndex, 1)
            }
        })

        this.finishedPicking.emit(true)
    }

    cancel() {
        this.finishedPicking.emit(true)
    }

    loadPage(page) {
        if (page > this.links.last) {
            return
        }

        this.page = page

        if (this.searchMode) {
            this.search()
        } else {
            this.load()
        }
    }

    reset() {
        this.searchMode = false
        this.page = 0
        this.unmappedContents = []
        this.load()
    }

    searchAll() {
        this.searchMode = true
        this.page = 0
        this.unmappedContents = []
        this.search()
    }

    /**
     * Load all unmapped contents.
     * Checks if the existing contents have the unmapped content.
     * The reason is that you can pick content for some entity without
     * persisting it. When you go back to picking it should appear selected
     * if it is already there. Then you cannot add it twice.
     * @param {Content[]} unmappedContents
     */
    private loadUnmappedContents(unmappedContents) {
        unmappedContents.forEach((unmappedContent) => {
            if (this.existingContents.some((existingContent) => existingContent.id === unmappedContent.id)) {
                this.selectedContents.set(unmappedContent.id, unmappedContent)
            }

            if (this.selectedContents.has(unmappedContent.id)) {
                unmappedContent.mapped = true
            }

            if (this.deselectedContents.has(unmappedContent.id)) {
                unmappedContent.mapped = false
            }

            const userCountryCode = this.userCountryCode?.toLowerCase()
            const contentCountryCode = unmappedContent?.createCountry?.code?.toLowerCase()
            if (!this.isLidl
                || (this.isLidl && userCountryCode === RoleIdEnum.International)
                || (this.isLidl && userCountryCode !== RoleIdEnum.International && contentCountryCode === userCountryCode)) {
                this.unmappedContents.push(unmappedContent)
            }
        })
    }

    private search() {
        this.contentService
            .searchUnmappedContents(this.searchWord, {
                page: this.page,
                size: this.itemsPerPage,
                sort: this.sort()
            }).pipe(
                takeUntil(this.componentDestroyed))
            .subscribe((res: ResponseWrapper) => this.onSuccess(res.json, res.headers))
    }

    private load() {
        this.contentService.queryUnmappedContents({
            page: this.page,
            size: this.itemsPerPage,
            sort: this.sort()
        }).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe(
                (res: ResponseWrapper) => this.onSuccess(res.json, res.headers)
            )
    }

    private sort() {
        return [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')]
    }

    private onSuccess(data, headers) {
        this.links = this.parseLinks.parse(headers.get('link'))
        this.totalItems = headers.get('X-Total-Count')
        this.loadUnmappedContents(data)
    }
}
