import { Injectable } from '@angular/core'
import { Change, Craft, Material, MaterialGroup, MaterialSubGroup } from '@app/data-access'

@Injectable()
export class ChangeEntityParentService {

    entityAlreadyAdded(entity: any, change: Change): boolean{
        if (entity instanceof MaterialGroup){
            return change.materialGroups.some((mg) => mg.id === entity.id)
        }
        if (entity instanceof MaterialSubGroup){
            return change.materialSubGroups.some((mg) => mg.id === entity.id)
        }
        if (entity instanceof Craft){
            return change.crafts.some((craft) => craft.id === entity.id)
        }
    }

    addParentEntities(entity: any, change: Change){
        if (entity instanceof Material){
            this.addMaterialParents(change, entity)
        }
        if (entity instanceof MaterialSubGroup){
            this.addMaterialSubGroupParents(change, entity)
        }
        if (entity instanceof MaterialGroup){
            this.addMaterialGroupParents(change, entity)
        }
    }

    addMaterialParents(change: Change, material: Material){
        this.addMaterialSubGroupParents(change, material.materialSubGroup)
    }

    addMaterialSubGroupParents(change: Change, materialSubGroup: MaterialSubGroup){

        const materialGroup = materialSubGroup?.materialGroup

        if (materialGroup && !this.entityAlreadyAdded(materialGroup, change)){
            change.materialGroups = [...change.materialGroups, materialGroup]
        }
        this.addMaterialGroupParents(change, materialGroup)
    }

    addMaterialGroupParents(change: Change, materialGroup: MaterialGroup){

        const craft = materialGroup.craft

        if (craft && !this.entityAlreadyAdded(craft, change)){
            change.crafts = [...change.crafts, craft]
        }
    }

}
