import { Route } from '@angular/router'

import { AuditsComponent } from './audits.component'
import { Roles } from '@app/auth'

export const auditsRoute: Route = {
    path: 'audits',
    component: AuditsComponent,
    data: {
        authorities: Roles.EDITOR_AND_PUBLISHER,
        pageTitle: 'audits.title'
    }
}
