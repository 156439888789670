import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

    constructor(private readonly translateService: TranslateService) {
    }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {

        if (component.canDeactivate()) {
            return true
        }

        return new Observable((observer) => {

            this.translateService.get('global.confirmPendingChanges').subscribe((confirmMessage) => {
                // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
                // when navigating away from your angular app, the browser will show a generic warning message
                // see http://stackoverflow.com/a/42207299/7307355
                observer.next(confirm(confirmMessage))
            })
        })

    }
}
