import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { TranslationJob } from './translation-job.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { TranslationJobView } from './translation-job-view.model'
import { ContentView } from '../content/content-view.model'

@Injectable()
export class TranslationJobService {

    private readonly resourceUrl = SERVER_API_URL + 'api/translation-jobs'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/translation-jobs'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(translationJob: TranslationJob): Observable<TranslationJob> {
        const copy = this.convert(translationJob)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertTranslationJobFromServer(res)
        }))
    }

    export(): Observable<any> {
        return this.httpClient.get(`${this.resourceUrl}/export`, {observe: 'response'})
    }

    update(translationJob: TranslationJob): Observable<TranslationJob> {
        const copy = this.convert(translationJob)
        return this.httpClient.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertTranslationJobFromServer(res)
        }))
    }

    find(id: number): Observable<TranslationJob> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertTranslationJobFromServer(res)
        }))
    }

    findRecentlyCreatedForContent(contentId: number): Observable<ResponseWrapper> {
        return this.httpClient.get(`${this.resourceUrl}/content/${contentId}`, {observe: 'response'}).pipe(
            map((res) => this.convertResponseToTranslationJob(res)))
    }

    save(contentId: number, translationJobs: TranslationJob[]): Observable<ResponseWrapper> {

        const copy = translationJobs.map((translationJob) => this.convert(translationJob))
        return this.httpClient.post(`${this.resourceUrl}/content/${contentId}`, copy, {observe: 'response'}).pipe(
            map((res) => this.convertResponseToTranslationJob(res)))
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req)
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponseToTranslationJobView(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponseToTranslationJobView(res)))
    }

    private convertResponseToTranslationJob(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertTranslationJobFromServer(res.body[i]))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to TranslationJob.
     */
    private convertTranslationJobFromServer(json: any): TranslationJob {
        const entity: TranslationJob = Object.assign(new TranslationJob(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }

    private convertResponseToTranslationJobView(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertTranslationJobViewFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to TranslationJob.
     */
    private convertTranslationJobViewFromServer(json: any): TranslationJobView {
        const entity: TranslationJobView = Object.assign(new TranslationJob(), json)

        if (entity.contentView) {
            entity.contentView = Object.assign(new ContentView(), json.contentView)
        }

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        return entity
    }

    /**
     * Convert a TranslationJob to a JSON which can be sent to the server.
     */
    private convert(translationJob: TranslationJob): TranslationJob {
        const copy: TranslationJob = Object.assign({}, translationJob)
        return copy
    }
}
