import { Content, ContentType } from '../content'
import { Project } from '../project'
import { BaseEntity, ConstructionType } from '../common'

export class RoomAttribute implements BaseEntity {
    constructor(
        public id?: number,
        public project?: Project,
        public roomHeight?: Content,
        public roomTemperature?: Content,
        public illuminance?: Content,
        public airExchange?: Content,
        public additionalConstructionInformation?: Content,
        public additionalModernizationInformation?: Content
    ) {
        // Make sure that all room attributes have a content once they're sent back to the server
        if (!this.additionalConstructionInformation) {
            const newContent = new Content()
            newContent.contentType = ContentType.TEXT
            newContent.constructionType = ConstructionType.CONSTRUCTION
            newContent.order = 0
            this.additionalConstructionInformation = newContent
        }

        // Make sure that all room attributes have a content once they're sent back to the server
        if (!this.additionalModernizationInformation) {
            const newContent = new Content()
            newContent.contentType = ContentType.TEXT
            newContent.constructionType = ConstructionType.MODERNIZATION
            newContent.order = 0
            this.additionalModernizationInformation = newContent
        }

        if (!this.roomHeight) {
            const newContent = new Content()
            newContent.contentType = ContentType.TEXT
            newContent.constructionType = ConstructionType.CONSTRUCTION_MODERNIZATION
            newContent.order = 0
            this.roomHeight = newContent
        }

        if (!this.airExchange) {
            const newContent = new Content()
            newContent.contentType = ContentType.TEXT
            newContent.constructionType = ConstructionType.CONSTRUCTION_MODERNIZATION
            newContent.order = 0
            this.airExchange = newContent
        }

        if (!this.roomTemperature) {
            const newContent = new Content()
            newContent.contentType = ContentType.TEXT
            newContent.constructionType = ConstructionType.CONSTRUCTION_MODERNIZATION
            newContent.order = 0
            this.roomTemperature = newContent
        }

        if (!this.illuminance) {
            const newContent = new Content()
            newContent.contentType = ContentType.TEXT
            newContent.constructionType = ConstructionType.CONSTRUCTION_MODERNIZATION
            newContent.order = 0
            this.illuminance = newContent
        }
    }
}
