import { Country } from '../country'
import { Tag } from './tag.model'

export class FileTag implements Tag {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public createCountry?: Country,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }
}
