<div *ngIf="audits">
    <h2 jhiTranslate="audits.title" id="audits-title">Audits</h2>

    <div class="row">
        <div class="col-md-5">
            <h4 jhiTranslate="audits.filter.title">Filter by date</h4>
            <p class="d-flex">
                <span jhiTranslate="audits.filter.from" class="input-group-addon">from</span>
                <input type="date" class="form-control" name="start" [(ngModel)]="fromDate" (ngModelChange)="onChangeDate()" required>
                <span jhiTranslate="audits.filter.to" class="input-group-addon">to</span>
                <input type="date" class="form-control" name="end" [(ngModel)]="toDate" (ngModelChange)="onChangeDate()" required>
            </p>
        </div>
    </div>

    <div class="table-hover">
        <table class="table table-sm table-striped table-bordered" aria-describedby="audits-title">
            <thead>
            <tr>
                <th (click)="orderProp = 'date'; reverse=!reverse" scope="col"><span jhiTranslate="audits.table.header.date">Date</span></th>
                <th (click)="orderProp = 'user'; reverse=!reverse" scope="col"><span jhiTranslate="audits.table.header.user">User</span></th>
                <th (click)="orderProp = 'entity'; reverse=!reverse" scope="col"><span jhiTranslate="audits.table.header.entity">Entity</span></th>
                <th (click)="orderProp = 'version'; reverse=!reverse" scope="col"><span jhiTranslate="audits.table.header.type">Type</span></th>
                <th (click)="orderProp = 'version'; reverse=!reverse" scope="col"><span jhiTranslate="audits.table.header.version">Version</span></th>
                <th (click)="orderProp = 'state'; reverse=!reverse" scope="col"><span jhiTranslate="audits.table.header.state">State</span></th>
                <th (click)="orderProp = 'changedProperties'; reverse=!reverse" scope="col"><span jhiTranslate="audits.table.header.changedProperties">Changed Properties</span></th>
            </tr>
            </thead>
            <tr *ngFor="let audit of getAudits()">
                <td><span>{{audit.date| date:'medium'}}</span></td>
                <td><small>{{audit.user}}</small></td>
                <td>{{audit.entity}}</td>
                <td>{{audit.type}}</td>
                <td>{{audit.version}}</td>
                <td>{{audit.state}}</td>
                <td>{{audit.changedProperties}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="audits">
        <div class="row justify-content-center">
            <jhi-item-count [page]="page" [total]="totalItems" [itemsPerPage]="itemsPerPage"></jhi-item-count>
        </div>
        <div class="row justify-content-center">
            <ngb-pagination [collectionSize]="totalItems" [(page)]="page" (pageChange)="loadPage(page)" [pageSize]="20" [maxSize]="3" [boundaryLinks]="true"></ngb-pagination>
        </div>
    </div>
</div>
