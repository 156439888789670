import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

import { Validator } from './translation/validation/validator'
import { TranslationValidator } from './translation/validation/translation-validator'
import { JhiAlertService } from 'ng-jhipster'
import { Subject } from 'rxjs'
import {
    Content,
    ContentService,
    Language,
    TranslationJob,
    TranslationJobService,
    TranslationJobStatus
} from '@app/data-access'
import { WarningMessage } from './translation/validation/warning/message/warning-message'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'

interface LanguageOption {
    id: number
    name: string
    checked: boolean
}

enum SourceStringPartType {
    TEXT = 'TEXT',
    TAG_PLACEHODER = 'TAG_PLACEHOLDER'
}

class SourceStringPart {
    public type: SourceStringPartType
}

class SourceStringPartText extends SourceStringPart {

    public type: SourceStringPartType = SourceStringPartType.TEXT

    constructor(public text: string) {
        super()
    }
}

class SourceStringPartPlaceholder extends SourceStringPart {
    public type: SourceStringPartType = SourceStringPartType.TAG_PLACEHODER

    constructor(public index: string) {
        super()
    }
}

class TextUnit {

    constructor(public sourceParts: SourceStringPart[] = [],
                public targetParts: SourceStringPart[] = []) {
    }
}

@Component({
    selector: 'content-translation',
    templateUrl: 'content-translation.component.html',
    styleUrls: ['content-translation.component.scss']
})
export class ContentTranslationComponent implements OnInit, OnDestroy {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    @Input()
    public content: Content

    @Input()
    public languages: Language[]

    @Output()
    public finished = new EventEmitter<boolean>()

    @Input()
    public modal = false

    public languageOptions: LanguageOption[]

    public textUnits: TextUnit[] = []

    public numSelectedLanguages = 0

    public selectedLanguages: LanguageOption[] = []

    public previewLanguage: Language

    public translationJobs: TranslationJob[] = []

    public allSelected = false

    private translationValidator: Validator

    public warningMessages: WarningMessage[] = []

    constructor(private readonly contentService: ContentService,
                private readonly translationJobService: TranslationJobService,
                private readonly alertService: JhiAlertService) {
        this.translationValidator = new TranslationValidator()
    }

    ngOnInit() {
        this.languageOptions = this.languages
            .filter((language) => language.locale !== DefaultLocaleSingleton.instance.defaultLocale)
            .map((language) => {
                return {
                    id: language.id,
                    name: language.name,
                    checked: false
                }
            })

        this.translationJobService.findRecentlyCreatedForContent(this.content.id).subscribe((response) => {
            this.translationJobs = response.json

            // Pre-select existing languages
            for (const translationJob of this.translationJobs) {
                const option = this.languageOptions.find((languageOption: LanguageOption) => languageOption.id === translationJob.targetLanguage.id)

                if (option) {
                    option.checked = true
                }
            }

            this.updateSelectedLanguages()
        })

    }

    ngOnDestroy() {
        this.componentDestroyed.next()
        this.componentDestroyed.complete()
        this.alertService.clear()
    }

    private loadXliffForLanguage(id: number) {

        this.textUnits = []
        this.contentService.getXliff(this.content.id, id).subscribe((xliff) => {
            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(xliff, 'text/xml')
            const transUnits = Array.from(xmlDoc.getElementsByTagName('trans-unit'))
            for (const transUnit of transUnits) {
                const sourceChildNodes = Array.from(transUnit.getElementsByTagName('source')[0].childNodes)
                const targetChildNodes = Array.from(transUnit.getElementsByTagName('target')[0].childNodes)
                const sourceParts: SourceStringPart[] = this.convertChildNodesToSourceStringParts(sourceChildNodes)
                const targetParts: SourceStringPart[] = this.convertChildNodesToSourceStringParts(targetChildNodes)
                this.textUnits.push(new TextUnit(sourceParts, targetParts))
            }
        })
    }

    private convertChildNodesToSourceStringParts(sourceChildNodes: Node[]): SourceStringPart[] {
        const sourceStringParts: SourceStringPart[] = []
        for (const childNode of sourceChildNodes) {
            const nodeType = childNode.nodeType
            switch (nodeType) {
                case Node.TEXT_NODE:
                    sourceStringParts.push(new SourceStringPartText(childNode.textContent))
                    break
                case Node.ELEMENT_NODE:
                    const id = (childNode as Element).getAttribute('id')
                    sourceStringParts.push(new SourceStringPartPlaceholder(id))
                    break
            }
        }
        return sourceStringParts
    }

    trackLanguage(_, languageOption: LanguageOption) {
        return languageOption.id
    }

    selectAllLanguages() {
        this.languageOptions.forEach((languageOption) => languageOption.checked = this.allSelected)
        this.updateSelectedLanguages()
    }

    updateSelectedLanguages() {
        this.selectedLanguages = this.languageOptions
            .filter((languageOption) => languageOption.checked)

        this.numSelectedLanguages = this.selectedLanguages.length

        this.throwWarnings(this.numSelectedLanguages)

        if (this.numSelectedLanguages === 0) {
            this.previewLanguage = null
        } else if (!this.previewLanguage || !this.selectedLanguages.find((selectedLanguage) => this.previewLanguage.id === selectedLanguage.id)) {
            this.previewLanguage = this.selectedLanguages[0]
            this.changePreviewLanguage()
        }
    }

    changePreviewLanguage() {
        if (this.previewLanguage) {
            this.loadXliffForLanguage(this.previewLanguage.id)
        }
    }

    endTranslationMode() {
        this.finished.emit(true)
    }

    createTranslationJobs(skipWorkflowUpdate = false) {

        const translationJobs = []

        /* Create a list with a translation job for each selected language.
           If there already is a job for a language that has now been de-selected,
           we just don't send it to the server. The server will take care of removing it.
        */
        for (const languageOption of this.selectedLanguages) {

            // Only create a new translation job when there is none yet
            const existingTranslationJob = this.translationJobs.find((translationJob) => translationJob.targetLanguage.id === languageOption.id)

            if (!existingTranslationJob) {
                const translationJob = new TranslationJob()
                translationJob.skipWorkflowUpdate = skipWorkflowUpdate
                translationJob.sourceLanguage = this.content.defaultTranslation.language
                translationJob.targetLanguage = this.languages.find((language) => language.id === languageOption.id)
                translationJob.content = this.content
                translationJob.status = TranslationJobStatus.CREATED

                translationJobs.push(translationJob)
            } else {
                translationJobs.push(existingTranslationJob)
            }
        }

        this.translationJobService.save(this.content.id, translationJobs).subscribe((res) => {
            this.finished.emit(true)
        })
    }

    throwWarnings(numSelectedLanguages: number) {
        if (numSelectedLanguages === 0) {
            this.alertService.clear()
            return
        }

        if (this.alertService.get().length !== 0) {
            return
        }

        this.warningMessages = this.translationValidator.validate(this.content.defaultTranslation.textContent)
    }
}
