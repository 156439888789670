import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { StateStorageService } from '@app/auth/state-storage.service'
import { ProfileEnvironmentEnum } from '@app/layouts/profiles/profile.service'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { LIDL, KAUFLAND } from '../constants/company.constants'

import { LANGUAGES } from './language.constants'

@Injectable({ providedIn: 'root' })
export class JhiLanguageHelper {
  renderer: Renderer2 = null
  private _language: BehaviorSubject<string>

  public isLidl: boolean = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private router: Router,
    private readonly stateStorageService: StateStorageService,
    rootRenderer: RendererFactory2
  ) {
    this._language = new BehaviorSubject<string>(this.translateService.currentLang)
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null)
    this.init()
  }

  getAll(): Promise<any> {
    return Promise.resolve(LANGUAGES)
  }

  get language(): Observable<string> {
    return this._language.asObservable()
  }

  /**
   * Update the window title using params in the following
   * order:
   * 1. titleKey parameter
   * 2. $state.$current.data.pageTitle (current state page title)
   * 3. 'global.title'
   */
  updateTitle(titleKey?: string) {
    if (!titleKey) {
      titleKey = this.getPageTitle(this.router.routerState.snapshot.root)
    }

    const companyLetter = this.isLidl ? LIDL : KAUFLAND
    this.translateService.get(titleKey ? titleKey : 'global.title', { companyLetter }).subscribe((title) => {
      this.titleService.setTitle(title)
    })
  }

  private init() {
    this.translateService.onLangChange.subscribe(() => {
      this._language.next(this.translateService.currentLang)
      this.renderer.setAttribute(document.querySelector('html'), 'lang', this.translateService.currentLang)
      this.updateTitle()
    })
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string =
      routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : ''
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title
    }
    return title
  }
}
