<div class="row diff">
    <div class="col-md-6">
        <div class="translation-label">
            <select class="custom-select" name="selectedTranslation" [(ngModel)]="translation" (change)="getOriginalTranslationsDiffs()">
                <ng-container *ngFor="let translation of content.translations">
                    <option [ngValue]="translation" [selected]="translation.language.locale === defaultLocale">{{ translation.language.name }}</option>
                </ng-container>
            </select>
        </div>

        <pre>{{translation.textContent}}</pre>
    </div>

    <div class="col-md-6">
        <div class="translation-label" *ngIf="selectedTranslationDiff">
            <select class="custom-select" name="selectedTranslationDiff" [(ngModel)]="selectedTranslationDiff">
                <ng-container *ngFor="let translationDiff of originalTranslationDiffs">
                    <option [ngValue]="translationDiff">{{ translationDiff.renderedBookName }}</option>
                </ng-container>
            </select>
        </div>

        <semantic-diff *ngIf="selectedTranslationDiff" [textContent]="translation.textContent" [oldTextContent]="selectedTranslationDiff.textContent"></semantic-diff>

        <span *ngIf="!selectedTranslationDiff" jhiTranslate="kstandards.content.translation.unused">This translation has not yet been used in any book</span>
    </div>
</div>
