
export class ContentChangeEvent {

    constructor(
        public contentId?: number,
        public isSignificantChange?: boolean,
        public redirectToTranslate?: boolean
    ) { }
}

export class FinishedEditingEvent {

    constructor(
        public shouldSave?: boolean,
        public isSignificantChange?: boolean
    ) { }
}
