import { Component, OnDestroy, OnInit } from '@angular/core'
import { map, mergeMap, takeUntil } from 'rxjs/operators'
import { BehaviorSubject, combineLatest, Observable, of as observableOf, Subject, Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { JhiEventManager } from 'ng-jhipster'
import {
    Change,
    Changelog,
    ChangelogService,
    ChangeService,
    ChangeStatus,
    ChangeTagService,
    ChangeType,
    ConstructionType,
    Content,
    CostCharacteristic,
    Country,
    CountryService,
    CraftService,
    Language,
    LanguageService, MaterialCategory, MaterialCategoryService, MaterialGroupService,
    MaterialService,
    MaterialSubGroupService,
    ResponseWrapper,
    RoomService,
    Tag
} from '@app/data-access'
import { ChangePopupService } from '../change/change-popup.service'
import { ChangeCopyDialogComponent } from '../change/change-copy-dialog.component'
import { ChangeEntityParentService } from '@app/data-access/changelog/change-entity-parent.service'

@Component({
    selector: 'jhi-change-dialog',
    templateUrl: './change-dialog.component.html',
    styleUrls: ['./change-dialog.component.scss']
})
export class ChangeDialogComponent implements OnInit, OnDestroy {

    eventSubscriber: Subscription

    languages: Language[]

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    tagsSubject = new BehaviorSubject<Tag[]>([])

    changeStatusTypes: ChangeStatus[]

    change: Change

    changelogId: number

    id: number

    changelog: Changelog

    isSaving: boolean

    hasLoaded = false

    isNew = false

    isCopy = false

    countries: Country[]

    constructionTypes: ConstructionType[]

    changeTypes: ChangeType[]

    materialCategories: MaterialCategory[]

    constructor(private readonly changeService: ChangeService,
                private readonly changePopupService: ChangePopupService,
                private readonly changelogService: ChangelogService,
                private readonly languageService: LanguageService,
                private readonly eventManager: JhiEventManager,
                private readonly route: ActivatedRoute,
                readonly materialService: MaterialService,
                readonly materialGroupService: MaterialGroupService,
                readonly roomService: RoomService,
                readonly craftService: CraftService,
                readonly changeEntityParentService: ChangeEntityParentService,
                readonly materialSubGroupService: MaterialSubGroupService,
                readonly changeTagService: ChangeTagService,
                readonly countryService: CountryService,
                readonly materialCategoryService: MaterialCategoryService,
                private readonly router: Router) {
        this.changeStatusTypes = [ChangeStatus.OPEN, ChangeStatus.CLOSED]
        this.constructionTypes = [ConstructionType.CONSTRUCTION, ConstructionType.MODERNIZATION, ConstructionType.CONSTRUCTION_MODERNIZATION]

        this.changeTypes = [ChangeType.COST_REDUCTION, ChangeType.COST_REDUCTION_MAINTENANCE, ChangeType.COST_REDUCTION_MODEL_STORE,
            ChangeType.COST_REDUCTION_PROJECT_SPECIFIC, ChangeType.DESIGN_CONCEPT, ChangeType.EDITORIAL_CHANGE,
            ChangeType.ENERGY_MANAGEMENT_AND_BCS, ChangeType.LEGAL_REQUIREMENT, ChangeType.MODEL_STORE,
            ChangeType.PROJECT_SPECIFIC, ChangeType.QUALITY_ASSURANCE, ChangeType.REQUIREMENT_SALES,
            ChangeType.RESULT_FROM_TEST, ChangeType.TECHNICAL_REQUIREMENT, ChangeType.REQUIREMENT_LOGISTICS, ChangeType.SUSTAINABILITY]
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    trackByFn(item) {
        return item.id
    }

    copyChange(){
        this.changePopupService.openWithEntity(ChangeCopyDialogComponent as Component, this.change)
    }

    ngOnInit(): void {
        this.isSaving = false
        const parameters = combineLatest([this.route.params, this.route.queryParams]).pipe(map(([params, queryParams]) =>
            Object.assign({}, params, queryParams)))

        parameters.pipe(
            mergeMap((params) => {
                this.hasLoaded = false

                if (params['changelogId']) {
                    this.changelogId = params['changelogId']
                }
                if (params['id']) {
                    this.id = params['id']
                    return this.changeService.findById(params['id'])
                } else if (params['sourceChangeId']) {
                    this.isCopy = true
                    return this.changeService.copy(params['sourceChangeId'], this.changelogId)
                } else {
                    const change = new Change()
                    change.tags = []
                    change.status = ChangeStatus.OPEN
                    change.shortDescription = new Content()
                    change.cost = new CostCharacteristic()
                    change.materials = []
                    change.materialGroups = []
                    change.materialSubGroups = []
                    change.crafts = []
                    this.isNew = true
                    return observableOf(change)
                }
            }),
            takeUntil(this.componentDestroyed)
        )
            .subscribe((change) => {
                this.change = change
                this.hasLoaded = true
                if (this.isNew && this.changelogId) {
                    this.changelogService.findById(this.changelogId)
                        .pipe(takeUntil(this.componentDestroyed))
                        .subscribe((changelog) => {
                            this.change.changelog = changelog
                        })

                }
            })
        this.languageService
            .query(false, {sort: ['id,asc']})
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (res: ResponseWrapper) => {
                    this.languages = res.json
                }
            )
        this.countryService.query(false, {sort: ['id', 'asc']})
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (res: ResponseWrapper) => {
                    this.countries = res.json
                }
            )

        this.materialCategoryService.query(false, { sort: ['id,asc'] })
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((res: ResponseWrapper) => this.materialCategories = res.json.content)

        this.registerChangeInChange()
    }

    registerChangeInChange() {
        this.eventSubscriber = this.eventManager.subscribe('changeListModification', (response) => this.goToListPage())
    }

    save(): void {
        this.isSaving = true
        if (this.change.id !== undefined) {
            this.subscribeToSaveResponse(
                this.changeService.update(this.change)
            )
        } else {
            this.change.startDate = new Date().toISOString()
            this.subscribeToSaveResponse(
                this.changeService.create(this.change)
            )
        }
    }

    addAllCountries(): void {
        this.change.countries = this.countries
    }

    private subscribeToSaveResponse(result: Observable<Change>) {
        result.pipe(
            takeUntil(this.componentDestroyed))
            .subscribe((res: Change) =>
                this.onSaveSuccess(res), (error) => {
                this.isSaving = false
            }, () => this.completeSaving())
    }

    onSaveSuccess(result: Change) {
        this.isSaving = false
        this.change = result
        this.goToEditPage()
    }

    onTagsChange(newTags) {
        this.tagsSubject.next(newTags)
    }

    private completeSaving() {
        this.isSaving = false
    }

    goToListPage() {
        setTimeout(() => {
            this.router.navigate(['changelog/' + this.changelogId + '/change'])
        }, 0)
    }

    goToEditPage() {
        setTimeout(() => {
            this.router.navigate([`changelog/${this.changelogId}/change/${this.change.id}/edit`])
        }, 0)
    }

    cancel(): void {
        this.router.navigate(['changelog/' + this.changelogId + '/change'])
    }

}
