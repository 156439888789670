import { Component, OnDestroy, OnInit } from '@angular/core'

@Component({
    selector: 'jhi-new-report',
    templateUrl: './report-new.component.html'
})
export class ReportNewComponent implements OnInit, OnDestroy {
    ngOnInit() {

    }

    ngOnDestroy() {

    }
}
