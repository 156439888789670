import { BaseEntity } from '../common'
import { File } from '../file'
import { Language } from '../language'
import { Content } from '../content'

export class Translation implements BaseEntity {
    constructor(
        public id?: number,
        public textContent?: string,
        public language?: Language,
        public content?: Content,
        public file?: File,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
    ) {
        this.textContent = textContent ? textContent : ''
    }
}
