<h2 jhiTranslate="kstandards.contentManager.unmappedContents">Unmapped Contents</h2>
<div [ngSwitch]="unmappedContents.length > 0">
    <form class="form-inline" (ngSubmit)="searchAll()">
        <input [(ngModel)]="searchWord" name="searchWord" class="form-control mr-sm-2" type="text">
        <button class="btn btn-info my-2 my-sm-0" type="submit" [disabled]="!searchWord" jhiTranslate="kstandards.material.home.search">Search
        </button>
        <button id="showAllButton" class="btn btn-primary my-2 my-sm-0" type="reset" (click)="reset()" jhiTranslate="kstandards.material.home.showAll">Show all
        </button>
    </form>

    <div class="row content-overview">
        <div class="col-lg-10">
            <div *ngSwitchCase="true">
                <div class="row" *ngIf="defaultLanguage" infiniteScroll (scrolled)="loadPage(page + 1)" [infiniteScrollThrottle]="50" [infiniteScrollDistance]="2">
                    <div *ngFor="let content of unmappedContents; let i = index;" class="col-lg-6" (click)="toggleContent(content)">
                        <div class="content-container" [ngClass]="{'selected': content.mapped }">
                            <div class="content-header">
                                <div class="badges">
                                    <span class="badge" [class.badge-secondary]="content.constructionType === 'CONSTRUCTION_MODERNIZATION'" [class.badge-special]="content.constructionType === 'MODERNIZATION'" [class.badge-primary]="content.constructionType === 'CONSTRUCTION'">
                                        {{ 'kstandards.ConstructionType.' + content.constructionType | translate }}
                                    </span>
                                    <span class="badge" [class.badge-success]="content.workflowStatus === 'PUBLISHED'" [class.badge-danger]="content.workflowStatus === 'DRAFT'" [class.badge-warning]="content.workflowStatus === 'REQUIRES_TRANSLATION'" [class.badge-special]="content.workflowStatus === 'TRANSLATION_DONE'">
                                        {{ 'kstandards.WorkflowStatus.' + content.workflowStatus | translate }}
                                    </span>
                                </div>
                                <ul class="badge">
                                    <li class="flag-icon flag-icon-{{ FlagUtil.getFlag(content.createCountry.code?.toLowerCase()) }}" [title]="createdByCountryTitle + ' ' + content.createCountry.name">
                                    </li>
                                </ul>
                                <div class="content-type">{{ 'kstandards.ContentType.' + content.contentType |
                                    translate }}
                                </div>
                            </div>
                            <div class="content-preview">
                                <content-preview [content]="content" [language]="defaultLanguage"></content-preview>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="false">
                <p jhiTranslate="kstandards.contentManager.missingContent">Missing contents</p>
            </div>
        </div>
        <div class="col-lg-2 global-buttons">
            <div class="form-group">
                <label class="col-form-label" jhiTranslate="kstandards.contentManager.constructionType" for="field_contentPicker_constructionType">Construction Type for selected contents</label>

                <select class="form-control" name="constructionType" [(ngModel)]="selectedConstructionType" id="field_contentPicker_constructionType">
                    <option value="CONSTRUCTION">{{'kstandards.ConstructionType.CONSTRUCTION' | translate}}</option>
                    <option value="MODERNIZATION">{{'kstandards.ConstructionType.MODERNIZATION' | translate}}</option>
                    <option value="CONSTRUCTION_MODERNIZATION">
                        {{'kstandards.ConstructionType.CONSTRUCTION_MODERNIZATION' | translate}}</option>
                </select>

            </div>
            <div class="form-group">
                <button type="button" class="btn btn-special" (click)="save()" [disabled]="unmappedContents.length <= 0" [ngbTooltip]="'kstandards.contentManager.help.pickContents' | translate" openDelay="500" placement="left" jhiTranslate="kstandards.contentManager.pickContents">Pick contents
                </button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-secondary" (click)="cancel()" jhiTranslate="kstandards.content.cancelEdit">Cancel
                </button>
            </div>
        </div>
    </div>
</div>