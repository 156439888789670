import { Component, Input } from '@angular/core'
import { Arrangeable } from '../../data-access/common/arrangeable'
import { Linkable } from '../../data-access/common/linkable'

@Component({
    selector: 'entity-arrangement',
    templateUrl: 'entity-arrangement.component.html',
    styleUrls: ['entity-arrangement.component.scss']
})
export class EntityArrangementComponent {

    @Input()
    arrangeableEntities: (Linkable & Arrangeable)[] = []

    @Input()
    disabled = false

    @Input()
    tooltip = ''

    /**
     * When the order of the arrangement is changed
     * emit the newly sorted array.
     */
    public updateContentOrder() {
        let orderId = 1
        for (const arrangeable of this.arrangeableEntities) {
            arrangeable.order = orderId
            orderId++
        }
    }
}
