import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '../shared'

import { ChangeEntryService, ChangeEntryTableComponent, HOME_ROUTE, HomeComponent } from './'

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild([ HOME_ROUTE ] )
    ],
    declarations: [
        HomeComponent,
        ChangeEntryTableComponent
    ],
    entryComponents: [
    ],
    providers: [
        ChangeEntryService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JhipsterHomeModule {}
