<div *ngIf="arrangeableEntities.length > 0">
    <h2 jhiTranslate="kstandards.entityArrangement.orderItems">Order items</h2>
    <div class="form-group" [ngbTooltip]="tooltip" openDelay="500" placement="top" style="width: 195px;">
        <button type="button" class="btn btn-primary" jhiTranslate="kstandards.entityArrangement.updateOrder" (click)="updateContentOrder()" [disabled]="disabled">Update order</button>
    </div>
    <div *ngIf="!disabled" ngxDroppable [model]="arrangeableEntities" (drop)="updateContentOrder()">
        <div class="arrangeable-entity" *ngFor="let arrangeableEntity of arrangeableEntities; let i = index;" ngxDraggable [model]="arrangeableEntity">
            <span ngxDragHandle class="btn" title="{{ 'kstandards.entityArrangement.moveItem' | translate }}">
                <icon shape="hamburger"></icon>
            </span>
            <span class="badge badge-info">{{ arrangeableEntity.order }}</span>
            <a [routerLink]="arrangeableEntity.getMainEditLink()">{{ arrangeableEntity.getLabelForArrangeableList()
                }}</a>
            <ul class="badge-list" *ngIf="!!arrangeableEntity.buildingTypes">
                <li *ngFor="let buildingType of arrangeableEntity?.buildingTypes">
                    <custom-badge [str]="buildingType.alias ? buildingType.alias : buildingType.name"></custom-badge>
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="disabled">
        <div class="arrangeable-entity" *ngFor="let arrangeableEntity of arrangeableEntities; let i = index;">
            <span class="badge badge-info">{{ arrangeableEntity.order }}</span>
            <a [routerLink]="arrangeableEntity.getMainEditLink()">{{ arrangeableEntity.getLabelForArrangeableList()
                }}</a>
            <ul class="badge-list" *ngIf="!!arrangeableEntity.buildingTypes">
                <li *ngFor="let buildingType of arrangeableEntity?.buildingTypes">
                    <custom-badge [str]="buildingType.alias ? buildingType.alias : buildingType.name"></custom-badge>
                </li>
            </ul>
        </div>
    </div>
</div>
<div *ngIf="arrangeableEntities.length === 0">
    <p jhiTranslate="kstandards.entityArrangement.emptyCollection">Nothing to order.</p>
</div>