import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class SearchEnginReindexService {

    constructor(private readonly http: HttpClient) {
    }

    reindex(): Observable<any> {
        return this.http.post('api/search-engine/index', {})
    }
}
