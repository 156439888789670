import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { SearchEnginReindexService } from './searchengine-reindex.service'

@Component({
    selector: 'jhi-searchengine-reindex-modal',
    templateUrl: './searchengine-reindex-modal.component.html'
})
export class SearchengineReindexModalComponent {

    constructor(
        private searchengineReindexService: SearchEnginReindexService,
        public activeModal: NgbActiveModal
    ) { }

    reindex() {
        this.searchengineReindexService.reindex().subscribe(() => this.activeModal.dismiss())
    }
}
