import { Component, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Change, ChangeService } from '@app/data-access'

@Injectable()
export class ChangePopupService {
    private ngbModalRef: NgbModalRef

    constructor(
        private readonly modalService: NgbModal,
        private readonly router: Router,
        private readonly changeService: ChangeService
    ) {
        this.ngbModalRef = null
    }

    openWithEntity(component: Component, change: Change) {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null
            if (isOpen) {
                resolve(this.ngbModalRef)
            }

            if (change) {
                this.ngbModalRef = this.changeModalRef(component, change)
                resolve(this.ngbModalRef)
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.ngbModalRef = this.changeModalRef(component, new Change())
                    resolve(this.ngbModalRef)
                }, 0)
            }
        })
    }

    open(component: Component, id?: number | any): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null
            if (isOpen) {
                resolve(this.ngbModalRef)
            }

            if (id) {
                this.changeService.findById(id).subscribe((change) => {
                    this.ngbModalRef = this.changeModalRef(component, change)
                    resolve(this.ngbModalRef)
                })
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.ngbModalRef = this.changeModalRef(component, new Change())
                    resolve(this.ngbModalRef)
                }, 0)
            }
        })
    }

    changeModalRef(component: Component, change: Change): NgbModalRef {
        const modalRef = this.modalService.open(component, {size: 'lg', backdrop: 'static'})
        modalRef.componentInstance.change = change
        modalRef.result.then((result) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true})
            this.ngbModalRef = null
        }, (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true})
            this.ngbModalRef = null
        })
        return modalRef
    }
}
