import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { ResponseWrapper, createHttpParams } from '@app/data-access'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class ChangeEntryService {

    private readonly resourceUrl = SERVER_API_URL + 'api/change-entries'

    constructor(private readonly httpClient: HttpClient) {
    }

    query(forUser?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('forUser', forUser.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map(res => new ResponseWrapper(res.headers, res.body, res.status)))
    }
}
