export class Countries {
    public static COUNTRY_CODES: string[] = [
        'int',
        'at',
        'ba',
        'be',
        'bg',
        'ch',
        'cy',
        'cz',
        'de',
        'dk',
        'ee',
        'es',
        'fi',
        'fr',
        'gb',
        'gr',
        'hk',
        'hr',
        'hu',
        'ie',
        'it',
        'lt',
        'lu',
        'lv',
        'me',
        'mk',
        'mt',
        'nie',
        'nl',
        'pl',
        'pt',
        'ro',
        'rs',
        'se',
        'si',
        'sk',
        'uk',
        'ua',
        'us'
    ]
}
