import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'
import { Project } from './project.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { Content } from '../content/content.model'
import { HttpClient } from '@angular/common/http'
import { DateUtil } from '../../util/date.util'
import { Country } from '../country'
import { BuildingType } from '../building-type'
import { Product } from '../product/product.model'
import { EntityService } from '../entity'
import { ProjectCopyRequest } from './project-copy-request'

@Injectable()
export class ProjectService implements EntityService<Project> {

    private readonly resourceUrl = SERVER_API_URL + 'api/projects'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/projects'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(project: Project): Observable<Project> {
        const copy = this.convert(project)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(project: Project, contentId?): Observable<Project> {
        const copy = this.convert(project)
        let params = {}
        if (contentId) {
            params = {contentId}
        }
        return this.httpClient.put(this.resourceUrl, copy, {params}).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<Project> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    findRoomLinkedProjects(id: number): Observable<Project[]> {
        return this.httpClient.get<Project[]>(`${this.resourceUrl}/room/${id}`).pipe(
            map((projects) => this.convertProjects(projects)))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    findProducts(projectId: number): Observable<Product[]> {
        return this.httpClient.get<Product[]>(`${this.resourceUrl}/${projectId}/products`)
    }

    saveProducts(projectId: number, products: Product[]): Observable<any> {
        return this.httpClient.put(`${this.resourceUrl}/${projectId}/products`, products)
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    copy(sourceProjectId: number, destinationProjectId: number): Observable<void> {
        return this.httpClient.post<void>(`${this.resourceUrl}/copy`, new ProjectCopyRequest(sourceProjectId, destinationProjectId))
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Project.
     */
    private convertItemFromServer(json: any): Project {
        const entity: Project = Object.assign(new Project(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        // In case Search Engine returns null for the contents
        if (!entity.contents) {
            entity.contents = []
        }

        // Make sure that project.contents are typed to make sure
        // that custom getters can be called from templates
        entity.contents = entity.contents.map((element) => {
            return Object.assign(new Content(), element)
        })

        if (entity.country) {
            entity.country = Object.assign(new Country(), entity.country)
        }
        if (entity.buildingType) {
            entity.buildingType = Object.assign(new BuildingType(), entity.buildingType)
        }
        return entity
    }

    /**
     * Convert a Project to a JSON which can be sent to the server.
     */
    private convert(project: Project): Project {
        const copy: Project = Object.assign({}, project)
        return copy
    }

    convertProjects(projects: Project []): Project [] {
        return projects.map((project) => {
            return this.convert(project)
        })
    }


}
