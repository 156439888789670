import { File } from '@app/data-access'
import {
    Component,
    Input,
    OnChanges,
    ChangeDetectionStrategy,
    SimpleChanges,
    OnDestroy
} from '@angular/core'
import { Subject } from 'rxjs'

@Component({
    selector: 'embedded-image',
    templateUrl: 'embedded-image.component.html',
    styleUrls: ['embedded-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedImageComponent implements OnChanges, OnDestroy {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    @Input()
    public file: File

    @Input()
    public preview = false

    public isImage = false

    public url

    ngOnChanges(changes: SimpleChanges) {

        if (changes.file) {

            if (this.file.mainFile.fileType.startsWith('image/')) {
                this.isImage = true
                this.url = (this.preview) ?
                    this.file.previewFile.presignedUrl :
                    this.file.mainFile.presignedUrl
            } else {
                throw new Error('File is not an image.')
            }
        }

    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

}
