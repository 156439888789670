import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { OAuth2Service } from '@app/auth/oauth2.service'
import { CountryIdEnum, HostCompanyIdEnum, RoleIdEnum, Roles } from '@app/auth/roles'
import {
    BuildingType,
    ConstructionType,
    Content,
    ContentRenderScope,
    ContentType,
    Country,
    CountryService,
    Language,
    LanguageService,
    ResponseWrapper,
    Translation,
    WorkflowStatus
} from '@app/data-access'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'
import { FlagUtil } from '@app/util/flag.util'
import { TranslateService } from '@ngx-translate/core'
import { JhiAlertService } from 'ng-jhipster'
import { combineLatest, ReplaySubject, Subject } from 'rxjs'
import { map, take, takeUntil } from 'rxjs/operators'
import { EditContext } from '.'
import { ContentChangeEvent, FinishedEditingEvent } from './editor-events'
import { uniqBy } from 'lodash-es'

@Component({
    selector: 'content-manager',
    templateUrl: 'content-manager.component.html',
    styleUrls: ['content-manager.scss']
})
export class ContentManagerComponent implements OnInit, OnDestroy, OnChanges {

    public FlagUtil = FlagUtil

    public RoleIdEnum = RoleIdEnum

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    private readonly hasLoadedLanguages: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    private readonly hasLoadedContents: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    @Input()
    public contents: Content[]

    @Input()
    public hasConstructionType = false

    @Input()
    public hasComponentType: boolean

    @Input()
    public hasImplementationType: boolean

    @Input()
    public hasMaterialCategory: boolean

    @Input()
    public defaultConstructionType = ConstructionType.CONSTRUCTION_MODERNIZATION

    @Input()
    public editContext: EditContext

    @Input()
    public hasRoombookType = false

    @Input()
    public hasProductListType = false

    @Input()
    public hasChangelogType = false

    @Input()
    public disabled = false

    @Input()
    public hasAdditionalScope: boolean

    @Input()
    public canSaveWithSignificantChanges: boolean

    @Input()
    public buildingTypes: BuildingType[] = []

    @Output()
    public changes: EventEmitter<ContentChangeEvent> = new EventEmitter<ContentChangeEvent>()

    @Output()
    public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Output()
    public temporaryChange = new EventEmitter<boolean>()

    @Output()
    public orderChanges = new EventEmitter<void>()

    public languages: Language[]

    public countries: Country[] = []

    public countriesForFilter: Country[] = []

    public defaultLanguage: Language

    public selectedConstructionType: ConstructionType

    public selectedBuildingType: BuildingType = null

    public selectedCountry: Country = null

    public selectedLanguage: Language = {}

    public inEditMode = false

    public contentToEdit: Content

    public editIndex = -1

    public inPickContentMode = false

    public inTranslationMode = false

    public canDeactivate = true

    public createdByCountryTitle: string = null

    public userCountryCode: string = undefined // undefined -> Kaufland(Kaufland roles have no country code), Defined -> Lidl

    public createCountries: Country[] = []

    public selectedCreateCountry: Country = null

    public isLidl: boolean = this.oauth2Service.isLidl

    public hasAttachmentEditPermissions: boolean

    constructor(
        private readonly languageService: LanguageService,
        private readonly countryService: CountryService,
        private readonly jhiAlertService: JhiAlertService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly hostElement: ElementRef,
        private readonly oauth2Service: OAuth2Service
    ) {
    }

    ngOnInit() {
        this.loadLanguages()

        this.createCountries = uniqBy(
            this.contents
                .filter((content: Content) => content.createCountry !== null && content.createCountry !== undefined)
                .map((content: Content) => content.createCountry),
            'name')

        this.createdByCountryTitle = this.translateService.instant('kstandards.contentManager.createdByTheCountry')
        if (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.LStandards) {
            this.userCountryCode = this.oauth2Service.countryCodeFromActiveRole$.getValue()
        }

        this.setCountriesForFilter()

        this.countryService
            .query(false, { sort: ['id,asc'] })
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((res: ResponseWrapper) => {
                this.countries = res.json
                if (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.LStandards) {
                    this.filterCountriesForLidlRole()
                }
            })

        if (this.hasConstructionType) {
            this.selectedConstructionType =
                this.defaultConstructionType === ConstructionType.CONSTRUCTION_MODERNIZATION
                    ? ConstructionType.CONSTRUCTION
                    : this.defaultConstructionType
        }

        combineLatest([this.hasLoadedLanguages, this.hasLoadedContents, this.route.queryParams])
            .pipe(map(([, , queryParams]) => queryParams), takeUntil(this.componentDestroyed))
            .subscribe((queryParams) => {
                this.initEditor(queryParams)
            })

        this.oauth2Service.hasAnyAuthority(Roles.ROLE_EDITOR_LIDL_FOR_ALL_COUNTRIES_NOT_INT)
            .pipe(take(1))
            .subscribe((isCountryEditor: boolean) => {
                this.hasAttachmentEditPermissions = !isCountryEditor
            })

        this.contents.forEach((content: Content) => {
            if (!content.defaultTranslation) {
                const contentCreatedFor = this.isLidl ? 'Kaufland' : 'Lidl'
                this.jhiAlertService.error('error.undefinedDefaultTranslation', { contentCreatedFor }, null)
                throw new Error(`Default Translation is undefined.\nMaybe the content was created for ${contentCreatedFor}.\nThe default locale for Lidl is de-INT and for Kaufland de-DE.`)
            }
        })
    }

    public onConstructionTypeFilterChange(): void {
        this.selectedCountry = null
        this.selectedCreateCountry = null
        this.selectedBuildingType = null
    }

    public onBuildingTypeFilterChange(): void {
        this.selectedConstructionType = ConstructionType.CONSTRUCTION
        this.selectedCountry = null
        this.selectedCreateCountry = null
    }

    public onCountryFilterChange(): void {
        this.selectedConstructionType = ConstructionType.CONSTRUCTION
        this.selectedCreateCountry = null
        this.selectedBuildingType = null
    }

    public onCreateCountryFilterChange(): void {
        this.selectedConstructionType = ConstructionType.CONSTRUCTION
        this.selectedCountry = null
        this.selectedBuildingType = null
    }

    private setCountriesForFilter() {
        this.contents.forEach((content: Content) => this.countriesForFilter = [...this.countriesForFilter, ...content.countries])
        this.countriesForFilter = uniqBy(this.countriesForFilter, 'name')
    }

    private loadLanguages() {
        this.languageService
            .query(false, { sort: ['id,asc'] })
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((res: ResponseWrapper) => {
                this.languages = res.json
                if (this.languages.length > 0) {
                    if (this.oauth2Service.getHostCompanyIdFromRole() === HostCompanyIdEnum.LStandards) {
                        this.filterLanguagesForLidlRole()
                    }
                    this.defaultLanguage = this.languages.find(
                        language => language.locale === DefaultLocaleSingleton.instance.defaultLocale
                    )
                    this.selectedLanguage = this.defaultLanguage
                } else {
                    this.defaultLanguage = null
                }
                this.hasLoadedLanguages.next(true)
            }, (res: ResponseWrapper) => this.onError(res.json))
    }

    private filterLanguagesForLidlRole() {
        if (!!this.userCountryCode) {
            this.languages =
                this.userCountryCode === RoleIdEnum.International
                    ? this.languages
                    : this.languages.filter((language: Language) => {
                        const countryId = this.getCountryIdFromLocale(language.locale)
                        return countryId === this.userCountryCode || countryId === CountryIdEnum.International
                    })
        }
    }

    private filterCountriesForLidlRole() {
        if (!!this.userCountryCode) {
            this.countries =
                this.userCountryCode === RoleIdEnum.International
                    ? this.countries
                    : this.countries.filter((country: Country) => {
                        const countryId = this.getCountryIdFromLocale(country.mainLanguage.locale)

                        return countryId === this.userCountryCode
                            || (this.userCountryCode === CountryIdEnum.Germany && country.code.toLowerCase() === CountryIdEnum.Germany && country.mainLanguage.locale === 'de-INT')
                    })
        }
    }

    private getCountryIdFromLocale(locale: string): string {
        if (!!locale && locale.includes('-')) {
            return locale.split('-')[1]?.toLowerCase()
        }
    }

    private initEditor(params: Params) {
        if (params['contentToEdit'] && this.contents) {
            const editId = parseInt(params['contentToEdit'], 10)
            const index = this.contents.findIndex((content) => content.id === editId)
            if (index !== -1) {
                const contentToEdit = this.contents[index]
                this.editContent(contentToEdit, index)
            }
        } else if (!params['contentToEdit'] && this.inEditMode) {
            this.closeEditor()
        } else if (params['newContent']) {
            this.addContent()
        }
        if (params['contentToTranslate'] && this.contents) {
            const editId = parseInt(params['contentToTranslate'], 10)
            const index = this.contents.findIndex((content) => content.id === editId)
            if (index !== -1) {
                const contentToEdit = this.contents[index]
                this.translateContent(contentToEdit, index)
            }
        } else if (!params['contentToTranslate'] && this.inTranslationMode) {
            this.endTranslationMode()
        }
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.contents) {
            const contentsChange = changes.contents

            if (contentsChange.previousValue === undefined && contentsChange.currentValue !== undefined) {
                this.hasLoadedContents.next(true)
            }
        }

        if (changes.disabled) {
            if (changes.disabled.currentValue) {
                this.hostElement.nativeElement.classList.add('disabled')
            } else {
                this.hostElement.nativeElement.classList.remove('disabled')
            }
        }

    }

    set editMode(inEditMode: boolean) {
        this.inEditMode = inEditMode

        this.editModeChange.emit(inEditMode)
    }

    get editMode() {
        return this.inEditMode
    }

    public navigateToEdit(content: Content) {

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                contentToEdit: content.id
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
        })
    }

    public editContent(content: Content, index: number) {
        this.editMode = true
        this.contentToEdit = content
        this.editIndex = index
    }

    isContentVisible(content: Content): boolean {
        return this.checkConstructionType(content)
            && this.checkBuildingType(content)
            && this.checkCountry(content)
            && this.checkCreateCountry(content)
            && !!this.selectedLanguage
    }

    private checkConstructionType(content: Content): boolean {
        if (this.hasConstructionType) {
            return content.constructionType === 'CONSTRUCTION_MODERNIZATION' || content.constructionType === this.selectedConstructionType
        } else {
            return true
        }
    }

    private checkCountry(content: Content): boolean {
        if (this.hasAdditionalScope && this.selectedCountry !== null) {
            return !!content.countries.find((country) => country.id === this.selectedCountry.id)
        } else {
            return true
        }
    }

    private checkCreateCountry(content: Content): boolean {
        if (this.selectedCreateCountry !== null) {
            return content.createCountry.id === this.selectedCreateCountry.id
        } else {
            return true
        }
    }

    private checkBuildingType(content: Content): boolean {
        if (this.hasAdditionalScope && this.selectedBuildingType !== null) {
            return !!content.buildingTypes.find((bt) => bt.id === this.selectedBuildingType.id)
        } else {
            return true
        }
    }

    public endEditMode(event: FinishedEditingEvent) {
        if (event.shouldSave) {
            if (this.editIndex >= 0) {
                this.contents[this.editIndex] = this.contentToEdit
            } else {
                const newContent = this.contentToEdit
                this.contents.push(newContent)
            }
            this.updateContentOrder()
            const redirectToTranslate = this.contentToEdit.workflowStatus === WorkflowStatus.REQUIRES_TRANSLATION
            this.changes.emit(new ContentChangeEvent(this.contentToEdit.id, event.isSignificantChange, redirectToTranslate))
            this.canDeactivate = true
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false,
        })



    }

    closeEditor() {
        this.editMode = false
        this.editIndex = -1
        this.canDeactivate = true
    }

    public endPickingMode() {
        this.inPickContentMode = false
        this.updateContentOrder(true)
    }

    public addContent(): void {
        const newContent = new Content()

        const defaultTranslation = new Translation()
        defaultTranslation.language = this.defaultLanguage

        newContent.translations = [defaultTranslation]
        newContent.contentType = ContentType.TEXT
        newContent.workflowStatus = WorkflowStatus.DRAFT
        newContent.renderScope = ContentRenderScope.WEB_PDF

        if (this.hasConstructionType) {
            newContent.constructionType = this.defaultConstructionType
        }

        this.contentToEdit = newContent

        // editIndex < 0 is used for new contents
        this.editIndex = -1
        this.editMode = true

    }

    public updateContentOrder(userTriggered = false) {
        let orderId = 0
        let hasChanges = false
        for (const content of this.contents) {
            if (content.order !== orderId) {
                hasChanges = true
            }
            content.order = orderId
            orderId++
        }

        if (userTriggered && hasChanges) {
            this.orderChanges.emit()
        }
    }

    public unmapContent(index: number) {
        if (confirm(this.translateService.instant('kstandards.contentManager.confirmUnmappedContent'))) {
            this.contents[index].mapped = false
            this.updateContentOrder()
            this.changes.emit()
        }
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
    }

    public contentEdited() {
        this.canDeactivate = false
        this.temporaryChange.emit(true)
    }

    set translationMode(inTranslationMode: boolean) {
        this.inTranslationMode = inTranslationMode

        this.editModeChange.emit(inTranslationMode)
    }

    get translationMode() {
        return this.inTranslationMode
    }

    public navigateToTranslate(content: Content) {

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                contentToTranslate: content.id
            },
            skipLocationChange: false
        })

    }

    public navigateToNewContent() {

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                'newContent': 1
            },
            skipLocationChange: false
        })

    }

    public translateContent(content: Content, index: number) {
        this.contentToEdit = content
        this.translationMode = true
        this.editIndex = index
    }

    public endTranslationMode() {

        this.translationMode = false
        this.editIndex = -1

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false
        })
    }

    public jumpToContentRefAndHighlight(content: Content): void {
        const resetColorTimeout = 2000
        const highlightColor = this.isLidl ? '#AAD2FF' : '#FCADB1'


        const contentElement = this.hostElement.nativeElement.getElementsByClassName('highlighted-content-container-' + content.id)[0] as HTMLElement
        const previewElement = this.hostElement.nativeElement.getElementsByClassName('highlighted-content-preview-' + content.id)[0] as HTMLElement

        contentElement.scrollIntoView({ block: 'center' })

        contentElement.style.backgroundColor = highlightColor
        previewElement.style.backgroundColor = highlightColor
        setTimeout(() => {
            contentElement.style.backgroundColor = '#efefef'
            previewElement.style.backgroundColor = 'white'
        }, resetColorTimeout)
    }
}
