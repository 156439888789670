import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '@app/app.constants'

import { Product } from './product.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { DateUtil } from '@app/util/date.util'
import { Content, ProductImport } from '@app/data-access'
import { EntityService } from '../entity'
import { LinkedProduct } from '@app/data-access/product/linked-product.model'

@Injectable()
export class ProductService implements EntityService<Product> {

    private readonly resourceUrl = SERVER_API_URL + 'api/products'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/products'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(product: Product): Observable<Product> {
        const copy = this.convert(product)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(product: Product): Observable<Product> {
        const copy = this.convert(product)
        return this.httpClient.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<Product> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    findAllProductsByMaterialId(materialId: number): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.resourceUrl}/material/${materialId}`)
    }

    findAllProductsByMaterialGroupId(materialGroupId: number): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.resourceUrl}/materialGroups/${materialGroupId}`)
    }

    findAllProductsByManufacturerId(manufacturerId: number): Observable<LinkedProduct[]> {
        return this.httpClient.get<LinkedProduct[]>(`${this.resourceUrl}/manufacturer/${manufacturerId}`)
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => new ResponseWrapper(res.headers, res.body, res.status)))
    }

    queryMultiselectModels(): Observable<ResponseWrapper> {
        return this.httpClient.get(this.resourceUrl  + '/multiselect', {observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<Product[]>(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    searchForMultiselect(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get<Product[]>(this.searchEngineUrl + '/multiselect', {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    importFile(file: File): Observable<ProductImport[]> {
        return this.httpClient.post<ProductImport[]>(this.resourceUrl + '/import', file)
    }

    importChanges(products: ProductImport[]): Observable<ProductImport[]> {
        return this.httpClient.post<ProductImport[]>(this.resourceUrl + '/import-changes', products)
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }
        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to Product.
     */
    private convertItemFromServer(json: any): Product {
        const entity: Product = Object.assign(new Product(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        entity.translatableName = Object.assign(new Content(), entity.translatableName)

        return entity
    }

    /**
     * Convert a Product to a JSON which can be sent to the server.
     */
    private convert(product: Product): Product {
        const copy: Product = Object.assign({}, product)
        return copy
    }
}
