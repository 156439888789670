import { BaseEntity } from '../common/base-entity'
import { TranslationBatchStatus } from './translation-batch-status.model'

export class TranslationBatch implements BaseEntity {

    constructor(
        public id?: number,
        public status?: TranslationBatchStatus,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
        public wordCount?: number
    ) {

    }
}
