import { Routes } from '@angular/router'

import { Roles, UserRouteAccessService } from '@app/auth'
import { auditsRoute } from './audits/audits.route'
import { configurationRoute } from './configuration/configuration.route'
import { healthRoute } from './health/health.route'
import { metricsRoute } from './metrics/metrics.route'
import { reportRoute, reportsPopUpRoute } from './reports/report.route'
import { logsRoute } from './logs/logs.route'

const ADMIN_ROUTES = [
    auditsRoute,
    configurationRoute,
    healthRoute,
    logsRoute,
    metricsRoute,
    ...reportRoute
]

export const adminState: Routes = [{
    path: '',
    data: {
        authorities: [Roles.EDITOR_AND_PUBLISHER, Roles.MONITORING]
    },
    canActivate: [UserRouteAccessService],
    children: ADMIN_ROUTES
},
//  Pop up routes
...reportsPopUpRoute
]
