import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { JhipsterSharedModule } from '@app/shared'
import { ContentManagerModule } from '@app/content-manager'
import { ChangeDialogComponent } from './change-dialog.component'
import { ChangeComponent } from './change.component'
import {
    ChangeDeleteDialogComponent,
    ChangeDeletePopupComponent
} from './change-delete-dialog.component'
import { ChangePopupService } from './change-popup.service'
import { changePopupRoute, changeRoute } from './change.route'
import { ChangeEntityParentService, ChangeTagService } from '@app/data-access'
import { ChangeCopyDialogComponent } from '@app/entities/changelog/change/change-copy-dialog.component'

const ENTITY_STATES = [
    ...changeRoute,
    ...changePopupRoute
]

@NgModule({
    imports: [
        JhipsterSharedModule,
        RouterModule.forChild(ENTITY_STATES),
        ContentManagerModule,
    ],
    declarations: [
        ChangeComponent,
        ChangeDialogComponent,
        ChangeDeleteDialogComponent,
        ChangeCopyDialogComponent,
        ChangeDeletePopupComponent,
    ],
    entryComponents: [
        ChangeComponent,
        ChangeDialogComponent,
        ChangeDeletePopupComponent,
        ChangeCopyDialogComponent,
        ChangeDeleteDialogComponent,
    ],
    providers: [
        ChangePopupService,
        ChangeEntityParentService,
        ChangeTagService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JhipsterChangeModule {}
