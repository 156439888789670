import { BaseEntity, Deletable } from '../common'
import { Content } from '../content'

export class ProductGroup implements BaseEntity, Deletable {

    constructor(public id?: number,
                public order?: number,
                public name?: string,
                public description?: string,
                public products?: BaseEntity[],
                public version?: number,
                public content?: Content,
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string,
                public deletable?: boolean) {

        if (!this.content) {
            this.content = new Content()
        }
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/product-group/${this.id}/edit`
    }

}
