import { MaterialProjectRoomRelation } from '../material'
import { Content } from '../content'


export class ConversionUtil {

    public static convertRelations(materialProjectRelations: MaterialProjectRoomRelation[]) {
        return materialProjectRelations.map((mpr) => {
            const copy: MaterialProjectRoomRelation = Object.assign(new MaterialProjectRoomRelation(), mpr)
            const content: Content = Object.assign(new Content(), mpr.content)

            copy.content = content
            return copy
        })
    }

}
