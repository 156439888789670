import { Pipe, PipeTransform } from '@angular/core'

/**
 * Renders the preview of the textContent of a Content entity.
 * It uses a specifically configured instance of marked.js.
 */
@Pipe({
  name: 'humanReadable'
})
export class HumanReadableFilesizePipe implements PipeTransform {

    transform(text: number, options?: any): any {
        if (!text) {
            return ''
        }

        return this.humanFileSize(text)
    }

    private humanFileSize(size: number) {
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
        return '' + (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }

}
