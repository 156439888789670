import { Route } from '@angular/router'
import { Roles, UserRouteAccessService } from '@app/auth'
import { SearchengineReindexComponent } from './searchengine-reindex.component'

export const searchengineReindexRoute: Route = {
    path: 'searchengine-reindex',
    component: SearchengineReindexComponent,
    data: {
        authorities: Roles.SUPERADMIN,
        pageTitle: 'searchengine.reindex.title'
    },
    canActivate: [UserRouteAccessService]
}
