import { Component, Input, OnInit } from '@angular/core'
import { ExpandableListItem } from '@app/data-access'

@Component({
    selector: 'expandable-list-component',
    templateUrl: './expandable-list-component.html',
    styleUrls: ['expandable-list-component.scss']
})
export class ExpandableListComponent implements OnInit {

    @Input()
    public items: ExpandableListItem[]

    @Input()
    public hasEditPermissions = true

    @Input()
    public editLinkPrefix = ''

    @Input()
    limit = 1

    @Input()
    title: string

    initialLimit: number

    isExpanded = false

    constructor() {

    }

    ngOnInit() {
        if (this.limit < 0) {
            this.limit = 1
        }
        this.initialLimit = this.limit
        this.reduce()
    }

    reduce() {
        this.limit = this.initialLimit
        this.isExpanded = false
    }

    expand() {
        this.isExpanded = true
        this.limit = this.items.length
    }

    collapse() {
        if (!this.isExpanded) {
            this.expand()
        } else {
            this.reduce()
        }
    }

    public trackById(item): number {
        return item.id
    }
}
