import { Component, Input } from '@angular/core'

@Component({
    selector: 'custom-badge',
    templateUrl: './custom-badge.component.html',
    styleUrls: ['custom-badge.component.scss']
})
export class CustomBadgeComponent {

    @Input()
    public str: string

    @Input()
    public foreground: string

    @Input()
    public background: string

    public getColor(): string {
        if (this.foreground) {
            return this.foreground
        }
        const rgb = this.stringToColor(this.str)
        const hsl = this.rgbToHsl(rgb[0], rgb[1], rgb[2])
        const luminance = hsl[2]
        return luminance > 0.5 ? '#000' : '#fff'
    }

    public getBackgroundColor(): string {
        if (this.background) {
            return this.background
        }
        const color = this.stringToColor(this.str)
        return `rgb(${color.join(', ')})`
    }

    // See https://stackoverflow.com/a/16348977
    // tslint:disable:no-bitwise
    private stringToColor(str: string): number[] {
        const stringToHash = str.toLowerCase() + 'Brötchen'
        let hash = 0
        for (let i = 0; i < stringToHash.length; i++) {
            hash = stringToHash.charCodeAt(i) + ((hash << 5) - hash)
        }

        const color = []
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF
            color.push(value)
        }

        return color
    }
    // tslint:enable:no-bitwise

    private rgbToHsl(r, g, b) {
        r /= 255
        g /= 255
        b /= 255

        const max = Math.max(r, g, b)
        const min = Math.min(r, g, b)
        let h = (max + min) / 2
        const l = (max + min) / 2
        let s

        if (max === min) {
            h = s = 0 // achromatic
        } else {
            const d = max - min
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break
                case g: h = (b - r) / d + 2; break
                case b: h = (r - g) / d + 4; break
            }

            h /= 6
        }

        return [ h, s, l ]
    }
}
