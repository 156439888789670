import { Material } from './material.model'
import { Room } from '../room'
import { Project } from '../project'
import { Content } from '../content'
import { ConstructionType, BaseEntity } from '../common'

export class MaterialProjectRoomRelation implements BaseEntity {
    constructor(
        public id?: number,
        public room?: Room,
        public material?: Material,
        public project?: Project,
        public content?: Content,
        public constructionType?: ConstructionType
    ) {
    }
}
