import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SERVER_API_URL } from '../../app.constants'

import { RoomGroup } from './room-group.model'
import { ResponseWrapper, createHttpParams } from '../common'
import { HttpClient } from '@angular/common/http'
import { Content } from '../content'
import { Room } from '../room/room.model'
import { Arrangeable } from '../common/arrangeable'
import { DateUtil } from '../../util/date.util'
import { EntityService } from '../entity'

@Injectable()
export class RoomGroupService implements EntityService<RoomGroup> {

    private readonly resourceUrl = SERVER_API_URL + 'api/room-groups'

    private readonly searchEngineUrl = SERVER_API_URL + 'api/_search/room-groups'

    constructor(private readonly httpClient: HttpClient) {
    }

    create(roomGroup: RoomGroup): Observable<RoomGroup> {
        const copy = this.convert(roomGroup)
        return this.httpClient.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    update(roomGroup: RoomGroup): Observable<RoomGroup> {
        const copy = this.convert(roomGroup)
        return this.httpClient.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    find(id: number): Observable<RoomGroup> {
        return this.httpClient.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    copy(id: number): Observable<RoomGroup> {
        return this.httpClient.get(`${this.resourceUrl}/${id}/copy`).pipe(map((res) => {
            return this.convertItemFromServer(res)
        }))
    }

    query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('withDeleted', showDeactivated.toString())
        return this.httpClient.get(this.resourceUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    queryRooms(id: number): Observable<Room[]> {
        return this.httpClient.get<Room[]>(`${this.resourceUrl}/${id}/rooms`).pipe(
            map((rms) => this.convertRooms(rms)))
    }

    updateRooms(id: number, rooms: Arrangeable[]): Observable<Room[]> {
        return this.httpClient.put<Room[]>(`${this.resourceUrl}/${id}/rooms`, rooms).pipe(map((rms) => this.convertRooms(rms)))
    }

    convertRooms(rooms: Room []): Room [] {
        return rooms.map((room) => {
            return Object.assign(new Room(), room)
        })
    }

    queryArrangedRoomGroups(): Observable<ResponseWrapper> {
        return this.httpClient.get(`${this.resourceUrl}/arrange`, {observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    updateArrangedRoomGroups(roomGroups: Arrangeable[]): Observable<ResponseWrapper> {
        return this.httpClient.put<RoomGroup[]>(`${this.resourceUrl}/arrange`, roomGroups, {observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    delete(id: number): Observable<any> {
        return this.httpClient.delete(`${this.resourceUrl}/${id}`)
    }

    search(query: string, req?: any): Observable<ResponseWrapper> {
        const params = createHttpParams(req).set('query', query)
        return this.httpClient.get(this.searchEngineUrl, {params, observe: 'response'}).pipe(
            map((res) => this.convertResponse(res)))
    }

    searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper> {
        return this.search(term, req)
    }

    private convertResponse(res): ResponseWrapper {
        const result = []
        for (let i = 0; i < res.body.length; i++) {
            result.push(this.convertItemFromServer(res.body[i]))
        }

        return new ResponseWrapper(res.headers, result, res.status)
    }

    /**
     * Convert a returned JSON object to RoomGroup.
     */
    private convertItemFromServer(json: any): RoomGroup {
        const entity: RoomGroup = Object.assign(new RoomGroup(), json)

        if (entity.createdAt) {
            entity.createdAt = DateUtil.parse(entity.createdAt)
        }

        if (entity.updatedAt) {
            entity.updatedAt = DateUtil.parse(entity.updatedAt)
        }

        if (entity.deletedAt) {
            entity.deletedAt = DateUtil.parse(entity.deletedAt)
        }

        entity.content = Object.assign(new Content(), entity.content)
        return entity
    }

    /**
     * Convert a RoomGroup to a JSON which can be sent to the server.
     */
    private convert(roomGroup: RoomGroup): RoomGroup {
        const copy: RoomGroup = Object.assign({}, roomGroup)
        return copy
    }
}
