
import { takeUntil } from 'rxjs/operators'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { VersionService } from './version.service'
import { Subject } from 'rxjs'

@Component({
    selector: 'jhi-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    version: string

    constructor(private readonly versionService: VersionService) {
    }

    ngOnInit(): void {
        this.versionService.getVersion().pipe(
            takeUntil(this.componentDestroyed))
            .subscribe((data) => {
                this.version = data.project.version
            })
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }
}
