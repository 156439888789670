import { OnInit, Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { JhiAlertService } from 'ng-jhipster'
import { Observable, Subject, Subscription, timer } from 'rxjs'
import { exhaustMap, retryWhen, takeUntil } from 'rxjs/operators'

import { genericRetryStrategy } from '../../shared'
import { ReportStatus } from './report-status.model'
import { ReportType } from './report-types.model'
import { Report } from './report.model'
import { ReportService } from './report.service'

@Component({
    template: ''
})
export abstract class BaseReportComponent implements OnInit {
    refreshTimer = timer(0, 5000)
    protected readonly componentDestroyed: Subject<boolean> = new Subject()
    protected cancelledPolling: Subject<boolean> = new Subject()
    private refreshTimerSubscription: Subscription

    report: Report
    isNew = false
    isSaving = false
    hasLoaded = false
    reportType: ReportType

    protected constructor(protected readonly route: ActivatedRoute,
                          protected readonly router: Router,
                          protected readonly reportsService: ReportService,
                          protected readonly jhiAlertService: JhiAlertService) {
    }

    ngOnInit(): void {
        this.hasLoaded = false
        this.route
            .params
            .subscribe((params) => {
                if (params['id']) {
                    if (this.refreshTimerSubscription) {
                        this.refreshTimerSubscription.unsubscribe()
                    }
                    this.cancelledPolling = new Subject()
                    this.refreshTimerSubscription = this.refreshTimer
                        .pipe(
                            exhaustMap((value) => this.reportsService.find(params['id'])
                                .pipe(
                                    retryWhen(
                                        genericRetryStrategy({
                                            excludedStatusCodes: [400, 401, 403, 404],
                                            maxRetryAttempts: 10,
                                            scalingDuration: 5000,
                                        })
                                    )
                                )
                            ),
                            takeUntil(this.cancelledPolling),
                            takeUntil(this.componentDestroyed)
                        )
                        .subscribe((report) => {this.setReport(report)}, (error) => this.onError(error))

                } else {
                    this.report = new Report()
                    this.report.reportType = this.reportType
                    this.isNew = true
                    this.hasLoaded = true
                }
            }, () => {
                return this.router.navigateByUrl('not-found', {skipLocationChange: true})
            })
    }

    private setReport(report: Report) {
        this.report = report
        this.hasLoaded = true
        if (report.reportStatus === ReportStatus.FINISHED
            || report.reportStatus === ReportStatus.FAILED
            || report.reportStatus === ReportStatus.NO_DATA) {
            this.cancelledPolling.next(true)
            this.cancelledPolling.complete()
        }
    }

    onError(error: any) {
        this.jhiAlertService.error(error.message, null, null)
    }

    public downloadReport(id) {
        this.reportsService
            .getReportContent(id)
            .pipe(
                takeUntil(this.componentDestroyed))
            .subscribe((response) => {
                const downloadLink = document.createElement('a')
                downloadLink.href = URL.createObjectURL(response.body)
                // content-disposition attachment; filename=translation-batch-2151.zip
                const contentDisposition = response.headers.get('content-disposition')
                let name = ''
                try {
                    const filename = contentDisposition.split(';')[1]
                    name = filename.substr(10)
                } catch (err) {
                    // ignore setting the filename
                }

                downloadLink.download = name
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            })
    }
    save() {
        this.isSaving = true
        if (this.report.id !== undefined) {
            this.subscribeToSaveResponse(
                this.reportsService.update(this.report))
        } else {
            this.subscribeToSaveResponse(
                this.reportsService.create(this.report))
        }
    }

    abstract onSaveSuccess(result: Report)

    private completeSaving() {
        this.isSaving = false
    }

    private subscribeToSaveResponse(result: Observable<Report>) {
        result.pipe(
            takeUntil(this.componentDestroyed))
            .subscribe((res: Report) =>
                    this.onSaveSuccess(res),
                (error) => {
                },
                () => this.completeSaving())
    }

    goToReportListPage() {
        setTimeout(() => {
            this.router.navigate(['report-new'])
        }, 0)
    }
}
