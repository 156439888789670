import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'

import { RouterModule } from '@angular/router'
import { JhipsterChangeModule } from '@app/entities/changelog/change'
import { JHipsterReleaseModule } from './release/release.module'

/* jhipster-needle-add-entity-module-import - JHipster will add entity modules imports here */

const routes = [
    {
        path: 'attachment',
        loadChildren: () => import('./attachment/attachment.module').then((m) => m.JhipsterAttachmentModule)
    },
    {
        path: 'attachment-category',
        loadChildren: () => import('./attachment-category/attachment-category.module').then((m) => m.JhipsterAttachmentCategoryModule)
    },
    {
        path: 'building-type',
        loadChildren: () => import('./building-type/building-type.module').then((m) => m.JhipsterBuildingTypeModule)
    },
    {
        path: 'building-type-category',
        loadChildren: () => import('./building-type-category/building-type-category.module').then((m) => m.JhipsterBuildingTypeCategoryModule)
    },
    {
        path: 'content',
        loadChildren: () => import('./content/content.module').then((m) => m.JhipsterContentModule)
    },
    {
        path: 'country',
        loadChildren: () => import('./country/country.module').then((m) => m.JhipsterCountryModule)
    },
    {
        path: 'craft',
        loadChildren: () => import('./craft/craft.module').then((m) => m.JhipsterCraftModule)
    },
    {
        path: 'file',
        loadChildren: () => import('./file/file.module').then((m) => m.JhipsterFileModule)
    },
    {
        path: 'language',
        loadChildren: () => import('./language/language.module').then((m) => m.JhipsterLanguageModule)
    },
    {
        path: 'manufacturer',
        loadChildren: () => import('./manufacturer/manufacturer.module').then((m) => m.JhipsterManufacturerModule)
    },
    {
        path: 'material',
        loadChildren: () => import('./material/material.module').then((m) => m.JhipsterMaterialModule)
    },
    {
        path: 'material-category',
        loadChildren: () => import('./material-category/material-category.module').then((m) => m.JhipsterMaterialCategoryModule)
    },
    {
        path: 'material-group',
        loadChildren: () => import('./material-group/material-group.module').then((m) => m.JhipsterMaterialGroupModule)
    },
    {
        path: 'material-sub-group',
        loadChildren: () => import('./material-sub-group/material-sub-group.module').then((m) => m.JhipsterMaterialSubGroupModule)
    },
    {
        path: 'product',
        loadChildren: () => import('./product/product.module').then((m) => m.JhipsterProductModule)
    },
    {
        path: 'product-group',
        loadChildren: () => import('./product-group/product-group.module').then((m) => m.JhipsterProductGroupModule)
    },
    {
        path: 'project',
        loadChildren: () => import('./project/project.module').then((m) => m.JhipsterProjectModule)
    },
    {
        path: 'release-context',
        loadChildren: () => import('./release-context/release-context.module').then((m) => m.JHipsterReleaseContextModule)
    },
    {
        path: 'room',
        loadChildren: () => import('./room/room.module').then((m) => m.JhipsterRoomModule)
    },
    {
        path: 'changelog',
        loadChildren: () => import('./changelog/changelog.module').then((m) => m.JhipsterChangelogModule)
    },
    {
        path: 'room-book-column',
        loadChildren: () => import('./room-book-column/room-book-column.module').then((m) => m.JhipsterRoomBookColumnModule)
    },
    {
        path: 'room-group',
        loadChildren: () => import('./room-group/room-group.module').then((m) => m.JhipsterRoomGroupModule)
    },
    {
        path: 'tag',
        loadChildren: () => import('./tag/tag.module').then((m) => m.JhipsterTagModule)
    },
    {
        path: 'translation-batch',
        loadChildren: () => import('./translation-batch/translation-batch.module').then((m) => m.JHipsterTranslationBatchModule)
    },
    {
        path: 'translation-job',
        loadChildren: () => import('./translation-job/translation-job.module').then((m) => m.JHipsterTranslationJobModule)
    },
    {
        path: 'change-tag',
        loadChildren: () => import('./change-tag/change-tag.module').then((m) => m.JhipsterChangeTagModule)
    },
    {
        path: 'revit',
        loadChildren: () => import('./revit/revit.module').then((m) => m.JHipsterRevitModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then((m) => m.JHipsterFaqModule)
    },
    {
        path: 'authorities',
        loadChildren: () => import('./authorities/authorities.module').then((m) => m.AuthoritiesModule)
    },

]

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        JHipsterReleaseModule,
        JhipsterChangeModule
        /* jhipster-needle-add-entity-module - JHipster will add entity modules here */
    ],
    declarations: [],
    entryComponents: [],
    providers: [],
    exports: [RouterModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JhipsterEntityModule {
}
