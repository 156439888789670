import { BaseEntity, ConstructionType, ContentContainerEntity, Linkable, WorkflowStatusSummary } from '../common'
import { Arrangeable } from '../common/arrangeable'
import { Product } from '../product'
import { BuildingType } from '../building-type'
import { Content } from '../content'
import { MaterialSubGroup } from '../material-sub-group'
import { Attachment } from '../attachment'

export class Material implements BaseEntity, ContentContainerEntity, Arrangeable, Linkable {
    constructor(public id?: number,
                public order?: number,
                public name?: string,
                public label?: string,
                public constructionType?: ConstructionType,
                public description?: string,
                public workflowStatusSummary?: WorkflowStatusSummary,
                public products?: Product[],
                public contents?: Content[],
                public buildingTypes?: BuildingType[],
                public materialSubGroup?: MaterialSubGroup,
                public linkedAttachments?: Attachment[],
                public version?: number,
                public createdAt?: string,
                public updatedAt?: string,
                public deletedAt?: string
    ) {
        if (!contents) {
            this.contents = []
        }
        if (!products) {
            this.products = []
        }
        if (!buildingTypes) {
            this.buildingTypes = []
        }
    }

    getLabelForArrangeableList(): string {
        return `${this.name} (${this.label})`
    }

    getMainEditLink(): string | null {
        if (!this.id) {
            return null
        }

        return `/material/${this.id}/edit`
    }
}
